import { useCallback, useMemo } from 'react';

import { INutritionTableFragment, useFeatureNutritionQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

import { applyRounding } from './rounding-rules';

interface IUseFeatureNutritionValue {
  nutritionTables: readonly INutritionTableFragment[];
  roundNutritionValue: (value: number) => number;
  featureNutritionLoading: boolean;
}

const nonNullable = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

export const useFeatureNutrition = (): IUseFeatureNutritionValue => {
  const { featureNutritionId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureNutritionQuery({
    variables: { featureNutritionId },
    skip: !featureNutritionId,
  });

  const { nutritionTables: maybeNutritionTables, roundingRules } = data?.FeatureNutrition ?? {};

  const nutritionTables = useMemo(() => {
    if (!maybeNutritionTables) {
      return [];
    }

    return maybeNutritionTables.filter(nonNullable);
  }, [maybeNutritionTables]);

  const featureNutritionLoading = featureIdsLoading || loading;

  const roundNutritionValue = useCallback(
    (value: number): number => {
      if (featureNutritionLoading || !roundingRules) {
        return value;
      }

      return applyRounding({ value, roundingRules });
    },
    [roundingRules, featureNutritionLoading]
  );

  return {
    nutritionTables,
    featureNutritionLoading,
    roundNutritionValue,
  };
};
