import useEffectOnce from 'hooks/use-effect-once';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';

import { useStoreContext } from '../state/store';

export const useForceStoreSelection = () => {
  const { navigate, setStoreLocatorCallbackUrl, location } = useLocationContext();
  const { serviceMode } = useServiceModeContext();
  const { noStoreSelected } = useStoreContext();

  const verifyStoreSelected = () => {
    if (!serviceMode) {
      setStoreLocatorCallbackUrl(location.pathname);
      navigate(routes.serviceMode);
    }

    if (noStoreSelected) {
      setStoreLocatorCallbackUrl(location.pathname);
      navigate(routes.storeLocator);
    }
  };

  return { verifyStoreSelected };
};

export const useForceStoreSelectionFlagged = () => {
  const shouldForceRestaurantSelection = Boolean(
    useFlag(LaunchDarklyFlag.FORCE_RESTAURANT_SELECTION_FOR_REWARDS)
  );
  const { verifyStoreSelected } = useForceStoreSelection();
  useEffectOnce(() => {
    if (shouldForceRestaurantSelection) {
      verifyStoreSelected();
    }
  });
};
