import React, { Ref } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import noop from 'lodash/noop';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Currency from 'components/currency';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { FloatingLink } from './styled';

const CurrencyBold = styled(Currency)`
  font-weight: ${Styles.fontWeight.heavy};
`;

export interface ICheckoutButtonLink {
  amount: number;
  ref: Ref<HTMLAnchorElement>;
  to: string;
}

const CheckoutButtonLink = ({ amount, ref, to }: ICheckoutButtonLink) => {
  const { formatMessage } = useIntl();
  const enableHomeMenuFabButton = useFlag(LaunchDarklyFlag.ENABLE_MAIN_FAB_BUTTON);

  if (enableHomeMenuFabButton) {
    return null;
  }

  return (
    <FloatingLink
      data-testid="cart-button-mobile"
      aria-label={formatMessage({ id: 'checkout' })}
      onClick={noop}
      to={to}
      ref={ref}
    >
      <CurrencyBold amount={amount} />
      <Icon icon="cart" color="icon-button-secondary" width="16px" aria-hidden />
    </FloatingLink>
  );
};

export default CheckoutButtonLink;
