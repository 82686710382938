import { useCallback, useMemo, useState } from 'react';

import { addDays, isWithinInterval, setHours, setMinutes, setSeconds, subDays } from 'date-fns';
import { isEqual } from 'lodash';
import { compose } from 'utils';

import { IDayPart, IWeekDays, Maybe } from 'generated/sanity-graphql';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useInterval from 'hooks/use-interval';
import { DAY_PART_SELECTIONS } from 'state/main-menu/types';
import { StoreProxy } from 'state/store';
import { getWeekDay } from 'utils/dateTime';
import logger from 'utils/logger';

export interface IDayPartBoundary {
  key: string;
  startTime: Date;
  endTime: Date;
  weekDays?: IDayPart['weekDays'];
}

type IFeatureMenuDayPart = Omit<IDayPart, 'displayName'> & {
  displayName: Maybe<{ locale: Maybe<string> }>;
};

export interface IValidDayPart extends IFeatureMenuDayPart {
  endTime: string;
  key: string;
  startTime: string;
}

export const isValidDayPart = (dayPart: IFeatureMenuDayPart): dayPart is IValidDayPart => {
  return Boolean(dayPart && dayPart.key && dayPart.endTime && dayPart.startTime);
};

// returns a function that converts
// a Date object to the given hour
// and minute on the same day
const setTime = (hour: number, minute: number) =>
  compose<[Date], Date, Date, Date>(
    date => setHours(date, hour),
    date => setMinutes(date, minute),
    date => setSeconds(date, 0)
  );

export const transformDateWithTimeString = (timeString: string): Date => {
  const now = new Date();
  const time = timeString.split(':').map(t => parseInt(t, 10));

  if (time.length !== 2 || time.some(t => isNaN(t))) {
    logger.error({ message: 'Received invalid daypart time-string', timeString });
    return now;
  }

  const [hours, minutes] = time;

  return setTime(hours, minutes)(now);
};

function dayPartTimeStringToTodayTime(
  timeString: string,
  tomorrow?: boolean,
  yesterday?: boolean
): Date {
  const transformedDate = transformDateWithTimeString(timeString);
  if (tomorrow) {
    return addDays(transformedDate, 1);
  }

  if (yesterday) {
    return subDays(transformedDate, 1);
  }

  return transformedDate;
}

export const isDisabledOnWeekDay = (
  date: Date,
  disabledWeekDays: IWeekDays | null | undefined
): boolean => {
  if (!disabledWeekDays) {
    return false;
  }
  const weekDay = getWeekDay(date);
  return Boolean(disabledWeekDays[weekDay]);
};

// given a mapping of daypart to time-string (HH:MM),
// returns a function that accepts the local time and
// returns the daypart name(s) that are currently "active"
const computeActiveDayParts = (dayParts: ReadonlyArray<IValidDayPart>) => {
  // normally we'd just map but the conditional serves a type check that all needed values are present
  const dayPartBoundaries = dayParts.map<IDayPartBoundary>(
    ({ endTime, key, startTime, weekDays }) => {
      const now = new Date();
      const endTimeDate = transformDateWithTimeString(endTime);
      const startTimeDate = transformDateWithTimeString(startTime);
      return {
        // If startTime is after endTime the endtime is tomorrow, however its only tomorrow if we are currently past the end time
        endTime: dayPartTimeStringToTodayTime(
          endTime,
          startTimeDate > endTimeDate && now > endTimeDate
        ),
        key,
        // If the startTime is after the endtime than the startTime was yesterday because the daypart spans multiple days
        startTime: dayPartTimeStringToTodayTime(
          startTime,
          false,
          startTimeDate > endTimeDate && now < endTimeDate
        ),
        weekDays,
      };
    },
    []
  );

  return (now: Date): IDayPartBoundary[] => {
    if (!dayPartBoundaries.length) {
      return [];
    }
    try {
      return dayPartBoundaries.filter(
        ({ endTime, startTime, weekDays }: IDayPartBoundary) =>
          isWithinInterval(now, { start: startTime, end: endTime }) &&
          !isDisabledOnWeekDay(now, weekDays)
      );
    } catch (error) {
      logger.error({ dayParts, error, message: 'Error finding active day-parts' });
      return [];
    }
  };
};

// 60 (s) * 1000 (ms)
export const REFRESH_INTERVAL = 60 * 1000;

/**
 * returns the list of currently active dayparts
 * out of the given dayParts array
 */
export default function useActiveDayParts({
  dayParts,
  store,
}: {
  dayParts: ReadonlyArray<IValidDayPart>;
  store: StoreProxy;
}): IDayPartBoundary[] {
  const computeActive = useMemo(() => computeActiveDayParts(dayParts), [dayParts]);

  const [activeDayParts, setActiveDayParts] = useState<IDayPartBoundary[]>(
    computeActive(new Date())
  );

  const setActiveDayPartsIfChanged = useCallback((newActiveDayParts: IDayPartBoundary[]) => {
    setActiveDayParts(currentlyActiveDayParts =>
      !isEqual(currentlyActiveDayParts, newActiveDayParts)
        ? newActiveDayParts
        : currentlyActiveDayParts
    );
  }, []);

  // refresh active dayparts every 60s
  useInterval(() => {
    setActiveDayPartsIfChanged(computeActive(new Date()));
  }, REFRESH_INTERVAL);

  // set active dayparts on dayparts change
  useEffectOnUpdates(() => {
    setActiveDayPartsIfChanged(computeActive(new Date()));
  }, [dayParts, setActiveDayPartsIfChanged]);

  if (store._id && !store.hasBreakfast) {
    const noBreakfast = (dayPart: IDayPartBoundary) =>
      dayPart.key !== DAY_PART_SELECTIONS.BREAKFAST_KEY;
    return activeDayParts.filter(noBreakfast);
  }

  return activeDayParts;
}
