import styled from 'styled-components';

import { BasicTileContainer } from 'components/marketing-basic-tile/styled';

import {
  DisclaimerMessage as DefaultDisclaimerMessage,
  MarketingTileGroup as DefaultMarketingTileGroup,
  RewardsTitle as DefaultRewardsTitle,
} from './loyalty-marketing-tile-widget.styled.default';

export * from './loyalty-marketing-tile-widget.styled.default';

export const MarketingTileGroup = styled(DefaultMarketingTileGroup)`
  border-radius: 0;
  box-shadow: none;
  ${BasicTileContainer} {
    h3 {
      color: ${Styles.color.alert.info.background};
    }
    p {
      color: ${Styles.color.black};
    }
  }
`;

export const DisclaimerMessage = styled(DefaultDisclaimerMessage)`
  color: ${Styles.color.black};
`;

export const RewardsTitle = styled(DefaultRewardsTitle)`
  color: ${Styles.color.alert.info.background};
  font-size: 2rem;
`;
