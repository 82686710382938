import { LogsInitConfiguration } from '@datadog/browser-logs';
import { RumInitConfiguration } from '@datadog/browser-rum';

import { removeLanguageFromPath } from 'utils/routing';
import { parseUrl } from 'utils/url';

/**
 * Normalizes a DD-RUM view URL s.t. language codes are removed from paths.
 */
export const normalizeUrl = (url: string): string => {
  const urlObject = parseUrl(url);
  if (!urlObject) {
    return url;
  }
  urlObject.pathname = removeLanguageFromPath(urlObject.pathname);
  return urlObject.toString();
};

/**
 * Returns a function for the Datadog logs' `beforeSend` property with built in
 * session-based sampling functionality.
 *
 * @param {number} sampleRate - a number between 0 and 100.
 *  0 = only logs with `bypassSampling: true` go to DataDog.
 *  100 = all logs go to DataDog.
 *  1 - 99 = all logs with `bypassSampling: true` go to DataDog, and _maybe_ all other logs will as well
 *
 * @returns {function} a function suitable to use as the `beforeSend` parameter in DataDog initialization
 */
export const createLogsBeforeSendCallback = (
  sampleRate: number,
  sampleRateMParticle: number
): NonNullable<LogsInitConfiguration['beforeSend']> => {
  const includeAllMessagesFromThisSession = sampleRate !== 0 && sampleRate >= Math.random() * 100;
  const includeThisMParticleEventFromThisSession =
    sampleRateMParticle !== 0 && sampleRateMParticle >= Math.random() * 100;

  return event =>
    (event?.from_mparticle === 1 && includeThisMParticleEventFromThisSession) ||
    includeAllMessagesFromThisSession ||
    Boolean(event?.bypassSampling);
};

/**
 * Callback to run before sending a RUM event to Datadog.
 */
export const rumBeforeSendCallback: NonNullable<RumInitConfiguration['beforeSend']> = event => {
  if (event.type === 'view') {
    event.view.url = normalizeUrl(event.view.url);
  }
  return true;
};
