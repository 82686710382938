import React from 'react';

import { SecondaryButton } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { UpsellCartItemButtonContainer } from './styles';

interface UpsellModalAddToCartProps {
  onClick: VoidFunction;
}

export const UpsellModalAddToCart: React.FC<UpsellModalAddToCartProps> = ({ onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <UpsellCartItemButtonContainer>
      <SecondaryButton onClick={onClick} fullWidth data-testid="upsell-item-add">
        {formatMessage({ id: 'add' })}
      </SecondaryButton>
    </UpsellCartItemButtonContainer>
  );
};
