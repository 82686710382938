import React, { useCallback } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import ActionButton from 'components/action-button';
import useEffectOnce from 'hooks/use-effect-once';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import IconError from './icon';
import theme from './theme';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  background-color: ${Styles.color.grey.five};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

// These styles are taken from the `cart-error` component,
// they should be extracted into a common component
const Header = styled.h2`
  font-family: ${Styles.fontFamily.base};
  letter-spacing: -0.42px;
  line-height: 1.1;
  text-transform: ${theme.textTransform};
  text-align: center;
  max-width: 75%;

  ${Styles.mobileLandscape} {
    font-size: 2rem;
    margin: 1.25rem 0;
  }

  ${Styles.desktop} {
    max-width: 500px;
  }
`;

const Subheading = styled.h3`
  color: ${Styles.color.black};
  font-size: 0.9375rem;
  font-weight: 400;
  text-align: center;
`;

const ERROR_RELOAD_PARAM = 'error-reload';

function ErrorBoundary() {
  const cdp = useCdpContext();
  const { formatMessage } = useIntl();
  const localeTitle = formatMessage({ id: 'errorBoundaryTitle' });
  const localeBody = formatMessage({ id: 'errorBoundaryBody' });
  const { redirect } = useLocationContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const onReload = useCallback(() => {
    cdp.trackEvent({ name: CustomEventNames.APP_ERROR_RELOAD, type: EventTypes.Other });

    // Send the user to the home page to avoid a reload loop
    if (searchParams.has(ERROR_RELOAD_PARAM)) {
      redirect(routes.base);

      return;
    }

    setSearchParams({ [ERROR_RELOAD_PARAM]: '1' });
  }, [cdp, redirect]);

  useEffectOnce(() => {
    cdp.trackEvent({ name: CustomEventNames.APP_ERROR, type: EventTypes.Other });
  });

  return (
    <Container data-testid="error-boundary">
      <Inner>
        <IconError />
        <Header>{localeTitle}</Header>
        <Subheading>{localeBody}</Subheading>

        <Box margin="1rem" maxWidth="18.75rem">
          <ActionButton fullWidth onClick={onReload} data-testid="error-boundary-reload-btn">
            {formatMessage({ id: 'reload' })}
          </ActionButton>
        </Box>
      </Inner>
    </Container>
  );
}

export default ErrorBoundary;
