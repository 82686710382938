import {
  IComboSlot,
  IComboSlotOption,
  IItem,
  IPicker,
  IPickerOption,
  ISanityComboSlot,
  ISanityComboSlotOption,
  ISanityPickerOption,
  MenuObject,
  SanityMenuObject,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { modifiersForItem } from 'utils/menu';

export const remapItemOptions = (
  menuData: SanityMenuObject[] | SanityMenuObject | null
): MenuObject | MenuObject[] | null => {
  if (!menuData) {
    return null;
  }
  const remapPickerOptions = (children: ISanityPickerOption[]): IPickerOption[] => {
    return children.map<IPickerOption>((option: ISanityPickerOption) => {
      return {
        ...option,
        option: remapItemOptions(option.option),
      } as IPickerOption;
    });
  };

  const remapComboSlots = (comboSlots: ISanityComboSlot[]): IComboSlot[] => {
    return comboSlots.map((comboSlot: ISanityComboSlot) => ({
      ...comboSlot,
      options: remapComboSlotOptions(comboSlot.options),
    }));
  };

  const remapComboSlotOptions = (
    comboSlotOptions: ISanityComboSlotOption[]
  ): IComboSlotOption[] => {
    return comboSlotOptions.map<IComboSlotOption>((option: ISanityComboSlotOption) => {
      const { option: comboSlotOption } = option;

      const mappedOption = remapItemOptions(comboSlotOption);

      return {
        ...option,
        option: mappedOption as IItem | IPicker,
      };
    });
  };

  // if an array filter each item in the array for availability
  if (Array.isArray(menuData)) {
    return menuData.map(remapItemOptions) as MenuObject[];
  }

  switch (menuData._type) {
    case MenuObjectTypes.SECTION: {
      const remappedChildren = remapItemOptions(menuData.options!) as MenuObject[];

      return { ...menuData, options: remappedChildren } as MenuObject;
    }
    case MenuObjectTypes.PICKER: {
      const options = remapPickerOptions(menuData.options);

      return {
        ...menuData,
        options,
      };
    }
    case MenuObjectTypes.COMBO: {
      const mainItem = menuData.mainItem ? modifiersForItem(menuData.mainItem) : null;
      return {
        ...menuData,
        mainItem,
        options: remapComboSlots(menuData.options),
      };
    }
    case MenuObjectTypes.ITEM: {
      return modifiersForItem(menuData);
    }

    default:
      return null;
  }
};
