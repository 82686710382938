import React from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isMobile } from 'utils/environment';

import { LoyaltyFab } from './loyalty-fab';
import { MainFab } from './main-fab';

export const FabContainer: React.FC = () => {
  const enableMainFabButton = useFlag(LaunchDarklyFlag.ENABLE_MAIN_FAB_BUTTON);
  const enableLoyaltyFloatingCartButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_FLOATING_CART_BUTTON
  );

  const shouldRenderMainButton = enableMainFabButton && isMobile();
  const shouldRenderLoyaltyButton = enableLoyaltyFloatingCartButton && isMobile();

  return (
    <>
      {shouldRenderMainButton ? <MainFab /> : null}
      {shouldRenderLoyaltyButton ? <LoyaltyFab /> : null}
    </>
  );
};
