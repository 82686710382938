import React, { useCallback, useRef } from 'react';

import { useMatch } from 'react-router-dom';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { EventName, emitEvent } from 'utils/event-hub';

import StyledLink from './styled-link';

interface INavLinkProps extends IBaseProps {
  to: string;
  hidden?: boolean;
  requiresExactMatch?: boolean;
  onClick?: VoidFunction;
  className?: string;
  navBarText?: string;
  componentKey?: string;
}

export const NavLink = ({
  requiresExactMatch,
  to,
  children,
  'data-testid': dataQaId,
  onClick,
  hidden,
  componentKey,
  className = '',
  navBarText = '',
}: INavLinkProps) => {
  const cdp = useCdpContext();
  const ref = useRef<HTMLAnchorElement | null>(null);

  const onClickAndLogToCdp = useCallback(() => {
    const linkText = ref.current?.innerText;

    if (navBarText !== '') {
      cdp.logNavBarClickEvent(navBarText, componentKey);
    }
    cdp.logNavigationClick(CustomEventNames.NAV_CLICK, { linkText, linkTo: to });

    emitEvent(EventName.BOTTOM_TAB_PRESSED, {
      route: to,
    });

    if (onClick) {
      onClick();
    }
  }, [componentKey, navBarText, cdp, onClick, to]);

  const pathToMatch = requiresExactMatch ? to : `${to}/*`;
  const pathMatch = useMatch(pathToMatch);

  const activeClassName = pathMatch ? 'active' : '';

  return (
    <StyledLink
      to={to}
      onClick={onClickAndLogToCdp}
      className={`${className} ${activeClassName}`}
      data-testid={dataQaId}
      ref={ref}
      tabIndex={hidden ? -1 : undefined}
    >
      {children}
    </StyledLink>
  );
};

export const iconSize = 25;
