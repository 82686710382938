import { CardTypes, IPaymentMethod } from 'state/payment/types';

import { IGetPrepaidReloadPaymentMethodId } from '../types';

import { getValidPaymentMethodId } from './getValidPaymentMethodId';

const getValidDefaultReloadMethod = (accounts: IPaymentMethod[], usersPaymentMethodId: string) => {
  const notGiftCardPaymentMethod = accounts.find(
    paymentMethod => paymentMethod.accountIdentifier !== usersPaymentMethodId
  );

  return notGiftCardPaymentMethod?.accountIdentifier;
};

/**
 * Prepaid payment method can be a CC, Apple or Google Pay (i.e. anything but a prepaid card)
 */
export const getPrepaidReloadPaymentMethodId = ({
  paymentMethodHasBeenInit,
  accounts,
  usersPaymentMethodId,
  canUseApplePay,
  canUseGooglePay,
  applePayCardDetails,
  googlePayCardDetails,
  isUsersPaymentMethodGiftCard,
}: IGetPrepaidReloadPaymentMethodId) => {
  const prepaidReloadAccounts = accounts;
  let userPrepaidReloadPaymentMethodId = usersPaymentMethodId;
  if (isUsersPaymentMethodGiftCard || !userPrepaidReloadPaymentMethodId) {
    userPrepaidReloadPaymentMethodId =
      getValidDefaultReloadMethod(accounts, usersPaymentMethodId) ?? '';
  }

  // Add Apple/Google Pay to the accounts list if its enabled & not already there
  if (canUseApplePay) {
    prepaidReloadAccounts.unshift(applePayCardDetails);
    if (!paymentMethodHasBeenInit) {
      // If the payment methods have not been init, set it to apple pay if available
      userPrepaidReloadPaymentMethodId = CardTypes.APPLE_PAY;
    }
  }
  if (canUseGooglePay) {
    prepaidReloadAccounts.unshift(googlePayCardDetails);
    if (!paymentMethodHasBeenInit) {
      // If the payment methods have not been init, set it to google pay if available
      userPrepaidReloadPaymentMethodId = CardTypes.GOOGLE_PAY;
    }
  }
  // Return a valid prepaid payment method (cannot be a prepaid card)
  return getValidPaymentMethodId({
    canUseApplePay,
    canUseGooglePay,
    paymentMethodId: userPrepaidReloadPaymentMethodId,
    paymentMethods: prepaidReloadAccounts.filter(method => !!method.credit),
    returnFirstValid: true,
  });
};
