import { datadogRum } from '@datadog/browser-rum';

import {
  appVersionCode,
  brand,
  env,
  getConfigValue,
  isLocalDev,
  platform,
} from 'utils/environment';
import * as location from 'utils/location';

import { DEFAULT_SITE, sharedContext } from './constants';
import { Context } from './types';
import { rumBeforeSendCallback } from './utils';

export const init = (
  context: Context,
  sessionSampleRate: number,
  sessionReplaySampleRate: number
) => {
  const rumConfig = getConfigValue('datadog').rum;
  const applicationId = rumConfig?.applicationId;
  const clientToken = rumConfig?.clientToken;
  const site = rumConfig?.dataCenter ?? DEFAULT_SITE;

  const awsConfig = getConfigValue('aws');
  const baseUrl = location.get('origin');
  const allowedTracingUrls = [
    awsConfig.gqlApiUrl,
    awsConfig.gqlGatewayApiUrl,
    `${baseUrl}/api/gateway`,
    `${baseUrl}/api/whitelabel`,
  ];

  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service: `${brand()}_${platform()}`,
    env: env(),
    version: appVersionCode(),
    sessionSampleRate: isLocalDev ? 0 : sessionSampleRate,
    sessionReplaySampleRate,
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls,
    beforeSend: rumBeforeSendCallback,
  });

  datadogRum.setGlobalContext({
    ...datadogRum.getGlobalContext(),
    ...sharedContext,
    ...context,
  });
};
