import React, { createContext, useContext, useEffect, useMemo } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import {
  IAllFeatureIdsQuery,
  useAllFeatureIdsQuery,
  useFeatureNutritionLazyQuery,
} from 'generated/sanity-graphql';
import { usePrevious } from 'hooks/use-previous';

interface IFeatureIds {
  featureAccountId?: string;
  featureAccountDeletePageId?: string;
  featureAccountFormId?: string;
  featureAccountRequestInfoPageId?: string;
  featureDisclaimersId?: string;
  featureExternalLinkId?: string;
  featureFooterId?: string;
  featureGeolocationMapMarkersId?: string;
  featureGeolocationTermsModalsId?: string;
  featureGeolocationModalId?: string;
  featureHomePageId?: string;
  featureLayoutId?: string;
  featureLinkPhysicalCardUIId?: string;
  featureMembershipsId?: string;
  featureMenuId?: string;
  featureNavigationId?: string;
  featureOffersId?: string;
  featureSupportDataId?: string;
  featureStaticMenuId?: string;
  featureCateringPageId?: string;
  featureLoyaltyUIId?: string;
  featureClaimPointsUIId?: string;
  featureNutritionId?: string;
  featureLoyaltyRewardListCategoriesId?: string;
  featureLoyaltyExternalOffersId?: string;
  featureLockersId?: string;
  featureBeeperId?: string;
  featureDonationId?: string;
  featureInvitationCodeId?: string;
  featureIFrameId?: string;
  featureQRCodeRefillDrinksId?: string;
  featureAggregatorRedirectButtonsId?: string;
  featureRedeemInRestaurantConfigId?: string;
  featureFeesAndTaxesId?: string;
  featureEventReservationsId?: string;
  // add new feature ids here
}

export interface IFeaturesContext extends IFeatureIds {
  featureIdsLoading: boolean;
}

export const FeaturesContext = createContext<IFeaturesContext>({
  featureIdsLoading: true,
});

export const useFeaturesContext = () => useContext(FeaturesContext);

const processAllFeatureIds = (data: IAllFeatureIdsQuery | undefined): IFeatureIds => {
  if (!data) {
    return {};
  }

  const getSingletonId = (id: keyof Omit<IAllFeatureIdsQuery, '__typename'>) => {
    return data?.[id]?.[0]?._id;
  };

  return {
    featureAccountId: getSingletonId('allFeatureAccount'),
    featureAccountDeletePageId: getSingletonId('allFeatureAccountDeletePage'),
    featureAccountFormId: getSingletonId('allFeatureAccountForm'),
    featureAccountRequestInfoPageId: getSingletonId('allFeatureAccountRequestInfoPage'),
    featureDisclaimersId: getSingletonId('allFeatureDisclaimers'),
    featureExternalLinkId: getSingletonId('allFeatureExternalLinkSection'),
    featureFooterId: getSingletonId('allFeatureFooter'),
    featureGeolocationMapMarkersId: getSingletonId('allFeatureGeolocationMapMarkers'),
    featureGeolocationTermsModalsId: getSingletonId('allFeatureGeolocationTermsModal'),
    featureGeolocationModalId: getSingletonId('allFeatureGeolocationModal'),
    featureHomePageId: 'feature-home-page-singleton',
    featureLoyaltyUIId: getSingletonId('allLoyaltyUI'),
    featureLinkPhysicalCardUIId: getSingletonId('allLinkPhysicalCardUi'),
    featureMembershipsId: getSingletonId('allLiveMemberships'),
    featureClaimPointsUIId: getSingletonId('allClaimPointsUI'),
    featureLayoutId: getSingletonId('allFeatureLayout'),
    featureMenuId: getSingletonId('allFeatureMenu'),
    featureNavigationId: getSingletonId('allFeatureNavigation'),
    featureNutritionId: getSingletonId('allFeatureNutrition'),
    featureOffersId: getSingletonId('allFeatureOffers'),
    featureSupportDataId: getSingletonId('allSupportData'),
    featureStaticMenuId: getSingletonId('allFeatureStaticMenu'),
    featureCateringPageId: getSingletonId('allFeatureCateringPage'),
    featureLoyaltyRewardListCategoriesId: getSingletonId('allRewardList'),
    featureLoyaltyExternalOffersId: getSingletonId('allLoyaltyLiveExternalOffers'),
    featureLockersId: getSingletonId('allFeatureLockers'),
    featureBeeperId: getSingletonId('allFeatureBeeperModal'),
    featureDonationId: getSingletonId('allFeatureDonation'),
    featureInvitationCodeId: getSingletonId('allFeatureInvitationCode'),
    featureIFrameId: getSingletonId('allFeatureIFrame'),
    featureQRCodeRefillDrinksId: getSingletonId('allFeatureQRCodeRefillDrinks'),
    featureAggregatorRedirectButtonsId: getSingletonId('allFeatureAggregatorRedirectButtons'),
    featureRedeemInRestaurantConfigId: getSingletonId('allConfigRedeemInRestaurant'),
    featureFeesAndTaxesId: getSingletonId('allFeatureFeesAndTaxes'),
    featureEventReservationsId: getSingletonId('allFeatureEventReservations'),
  };
};

export const FeaturesProvider: React.FC<IBaseProps> = ({ children }) => {
  const { data: allFeatureIds, loading: featureIdsLoading } = useAllFeatureIdsQuery();
  const featureIds = useMemo(() => processAllFeatureIds(allFeatureIds), [allFeatureIds]);

  const [dispatchFeatureNutritionQuery] = useFeatureNutritionLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { featureNutritionId } = featureIds;
  const previousFeatureNutritionId = usePrevious(featureNutritionId);

  useEffect(() => {
    // pre-fetch critical data after feature nutrition id changes
    if (previousFeatureNutritionId !== featureNutritionId && featureNutritionId) {
      const prefetch = async () => {
        dispatchFeatureNutritionQuery({
          variables: { featureNutritionId },
        });
      };
      prefetch();
    }
  }, [
    dispatchFeatureNutritionQuery,
    featureIdsLoading,
    featureNutritionId,
    previousFeatureNutritionId,
  ]);

  const contextValue = useMemo(() => {
    return { ...featureIds, featureIdsLoading };
  }, [featureIdsLoading, featureIds]);

  return <FeaturesContext.Provider value={contextValue}>{children}</FeaturesContext.Provider>;
};
