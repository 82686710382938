import { LoyaltyOffer } from 'state/loyalty/types';

export enum OfferActivationStatus {
  CAN_ACTIVATE = 'CAN_ACTIVATE',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  ACTIVATED = 'ACTIVATED',
}

export const isMoreInfoDisclaimerAvailable = (offer: LoyaltyOffer): boolean => {
  const moreInfoText = offer?.moreInfo?.localeRaw?.[0]?.children?.[0]?.text;
  return !!moreInfoText;
};
