import { useMarketingCardGroupsQuery } from 'generated/sanity-graphql';

export const useMarketingCardGroups = (ids?: string[]) => {
  const marketingCardGroupIds = ids ?? [];
  const { data, loading } = useMarketingCardGroupsQuery({
    variables: {
      where: {
        _id: { in: marketingCardGroupIds },
      },
    },
    skip: !marketingCardGroupIds?.length,
  });

  const marketingCardGroups = data?.allMarketingCardGroup;

  return {
    marketingCardGroups,
    marketingCardGroupsLoading: loading,
  };
};
