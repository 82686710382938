import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-flex;
  gap: 0.2em;
  align-items: center;
`;

export const PointsDisplayPrefix = styled.span``;
export const PointsDisplaySuffix = styled.span``;
export const PointsDisplayValue = styled.span``;
export const PointsDisplayIcon = styled.div`
  width: 1.25em;
  height: 1.25em;
  margin: 0;

  /* Svg should not be styled directly apart from here. Set style for the Icon should suffice
  If you want to recolor a monochrome icon you can just use the 'color' property on the PointsDisplayIcon
  Multicolored icons (logos, etc.) have the fill attribute specified on elements which is taking the priority on the css. */

  svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke: none !important;
    margin: 0;
    /* Using the parent 'color' property (the text color) as the fallback for fill, useful to recolor
     monochrome icons that have no 'fill' explicitly set on elements. */
    fill: currentColor;
  }
`;
