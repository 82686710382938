import { FC } from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

export type Widget = FC<Omit<IWidgetKey, 'componentKey'>>;

export interface IWidgetElement {
  id: string;
  element: JSX.Element;
}

export type IWidgetMap<TWidgets extends string> = Record<TWidgets, Widget>;

export interface IWidgetKey {
  readonly componentKey?: Maybe<string>;
  readonly _key?: Maybe<string>;
  readonly [key: string]: any;
}

export const hasComponentKey = <T extends Object>(
  widget: Maybe<T & { componentKey?: Maybe<string> }>
): widget is T & { componentKey: string } => {
  return !!widget?.componentKey;
};
