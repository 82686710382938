import { getMessage } from './shared';
import { ILoggerMiddleware } from './types';
/**
 * Error Categories allow us to identify common causes of errors
 * that may have a distinct message. I.e.
 *   - An error occurred loading chunk 36.adwdfef.js
 *   - An error occurred loading chunk 1.awddwdw.js
 * While these error messages are distinct they are both
 * indicative of a common issue.
 */

export enum ErrorCategory {
  Appflow = 'Appflow',
  Authentication = 'Authentication',
  Braze = 'Braze',
  Cache = 'Caching',
  CMS = 'CMS',
  CodeBug = 'Code Bug',
  CommitOrder = 'Commit Order',
  Compatibility = 'Compatibility',
  Cors = 'Cors',
  Delivery = 'Delivery',
  DoNotLog = 'Do not log',
  FirstData = 'First Data',
  GoogleMaps = 'Google Maps',
  GraphQL = 'GraphQL',
  Geolocation = 'Location',
  mParticle = 'mParticle',
  NeedsCategorization = 'Needs Categorization',
  Network = 'Possible Network Issue',
  NotFound = '404',
  Offers = 'Offers',
  POS = 'POS',
  StaticPages = 'Static Pages',
  ValidPaymentDecline = 'Valid Payment Decline',
}

type RegExpMap<K extends string> = { [key in K]: Array<RegExp> };
type CategoryMapping = Partial<RegExpMap<ErrorCategory>>;

const CategoryToMessageRegex: CategoryMapping = {
  [ErrorCategory.Appflow]: [
    /extracting update/i,
    /\(evaluating 'prefs.availableUpdate.state = UpdateState.Pending'\)/i,
    /\/data\/.*/,
    /pro-deploy\.ionicjs\.com\/.*/i,
  ],
  [ErrorCategory.Authentication]: [
    /signing in/i,
    /log in again/i,
    /jwt is invalid/i,
    /cannot read property 'userid' of undefined/i,
  ],
  [ErrorCategory.Braze]: [/error_rate_limit/i],
  [ErrorCategory.Cache]: [/loading chunk \d+/i, /unexpected token/i],
  [ErrorCategory.CMS]: [/customizable content/i, /union type/i],
  [ErrorCategory.CodeBug]: [
    /the string did not match the expected pattern/i,
    /failed to execute 'measure' on 'performance'/i,
    /minified react error/i,
    /object\.entries/i,
    /cannot convert null or undefined to object/i,
    /undefined or null reference/i,
    /undefined is not an object/i,
    /error fetching restaurant info/i,
    /of undefined/i,
  ],
  [ErrorCategory.Compatibility]: [/cannot set property 'state' of undefined/i],
  [ErrorCategory.Cors]: [/preflight/i, /access-control/i, /specified hostname/i],
  [ErrorCategory.CommitOrder]: [/committed/i],
  [ErrorCategory.Delivery]: [/delivery/i],
  [ErrorCategory.DoNotLog]: [
    /An account with the given email already exists/i,
    /user not found/i,
    /address not recognized/i,
    /cancelled/i,
    /mparticle sessionid/i,
    /email not registered/i,
    /request sent to native sdk/i,
    /no current user/i,
    /read error/i,
    /unexpected end of stream/i,
    /connection abort/i,
    /geolocation prompt/i,
  ],
  [ErrorCategory.FirstData]: [
    /encryptiondetails/i,
    /first data/i,
    /error getting accounts/i,
    /failed to create account/i,
    /payment methods/i,
    /nonce/i,
    /tokenid/i,
    /missing fd ca api key/i,
  ],
  [ErrorCategory.GoogleMaps]: [
    /could not load ".*"/i,
    /a\.firstChild/i,
    /cannot read property 'zoom'/i,
    /this\.g\./i,
    /google\.maps/i,
    /latlngbounds/i,
  ],
  [ErrorCategory.GraphQL]: [
    /an error occurred loading (order|offer redemptions)/i,
    /error loading user orders/i,
    /missing auth header/i,
    /graphqlerror occurred: argument/i,
    /quotaexceededcount/i,
  ],
  [ErrorCategory.Geolocation]: [
    /kclerrordomain/i,
    /error get restaurants near me/i,
    /location unavailable/i,
    /location permission/i,
  ],
  [ErrorCategory.mParticle]: [
    /appboy must be initialized before/i,
    /all user identity values must be strings or null/i,
    /method not found/i,
  ],
  [ErrorCategory.NotFound]: [/unsupported item type/i],
  [ErrorCategory.Offers]: [/already redeemed/i, /loading offers/i, /adding offer redemption/i],
  [ErrorCategory.POS]: [
    /price the order/i,
    /price_error/i,
    /heartbeat/i,
    /error pricing order/i,
    /insert error/i,
    /error occurred creating the order/i,
  ],
  [ErrorCategory.Network]: [
    /network/i,
    /end of input/i,
    /roaming is currently off/i,
    /offline/i,
    /connection abort/i,
    /request timed out/i,
  ],
  [ErrorCategory.StaticPages]: [/static page/i],
  [ErrorCategory.ValidPaymentDecline]: [
    /payment failed/i,
    /failed to perform sale/i,
    /credit card details may be incorrect/i,
  ],
};

// this ordering impacts which category
// is chosen - earlier elements in the array
// will be checked for a match first,
// so the most general categories
// should be at the end
const Categories = [
  ErrorCategory.Appflow,
  ErrorCategory.Authentication,
  ErrorCategory.Cache,
  ErrorCategory.CommitOrder,
  ErrorCategory.Cors,
  ErrorCategory.Delivery,
  ErrorCategory.FirstData,
  ErrorCategory.Geolocation,
  ErrorCategory.GoogleMaps,
  ErrorCategory.Geolocation,
  ErrorCategory.mParticle,
  ErrorCategory.Network,
  ErrorCategory.NotFound,
  ErrorCategory.Offers,
  ErrorCategory.POS,
  ErrorCategory.StaticPages,
  ErrorCategory.ValidPaymentDecline,
  ErrorCategory.GraphQL,
  ErrorCategory.DoNotLog,
];

export function categoryForMessageWithCategories<E extends string, C extends RegExpMap<E>>(
  categories: E[],
  categoryRegexMap: C,
  fallback: E
) {
  return function errorCategoryMiddlewareFunction(attributes) {
    const { message } = getMessage(attributes);
    const category = categories.find(errorCategory => {
      const regexes = categoryRegexMap[errorCategory] || [];

      return regexes.some(regex => regex.test(message));
    });

    return { ...attributes, category: category || fallback };
  } as ILoggerMiddleware;
}

export const errorCategoryMiddleware: ILoggerMiddleware = categoryForMessageWithCategories(
  Categories,
  CategoryToMessageRegex as RegExpMap<ErrorCategory>,
  ErrorCategory.NeedsCategorization
);
