import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import { StoreProxy } from 'state/store';

import { StoreCard } from './store-card';
import {
  ExitQueueButtonContainer,
  PositionContainer,
  StyledBlockContent,
  StyledEstimates,
  StyledPosition,
} from './styled';

interface IQueueInformationProps {
  store: StoreProxy;
  position: number;
  firingTimestamp: string | null | undefined;
  estimatedTime: string | null | undefined;
  onQueueExit: VoidFunction;
}

export const QueueInformation: FC<IQueueInformationProps> = ({
  store,
  position,
  firingTimestamp,
  estimatedTime,
  onQueueExit,
}) => {
  const { formatMessage } = useIntl();

  const isOrderInQueue = position > 0;

  if (!isOrderInQueue) {
    return <h2>{formatMessage({ id: 'yourOrderIsNotQueued' })}</h2>;
  }

  const isOrderBeingFired = firingTimestamp
    ? new Date(firingTimestamp).getTime() - Date.now() < 0
    : false;

  return (
    <>
      <PositionContainer>
        <StyledBlockContent> {formatMessage({ id: 'virtualQueuePosition' })} </StyledBlockContent>
        <StyledPosition> {position} </StyledPosition>
        <hr />
        {isOrderBeingFired ? (
          <>
            <StyledBlockContent>
              {formatMessage({ id: 'yourOrderIsBeingSentToTheKitchen' })}
              <LoadingAnimation fillColor={Styles.color.contrastBackground} />
            </StyledBlockContent>
          </>
        ) : (
          <>
            <StyledBlockContent>
              {formatMessage({ id: 'virtualQueueEstimatedTime' })}
            </StyledBlockContent>
            <StyledEstimates>{estimatedTime}</StyledEstimates>
          </>
        )}
      </PositionContainer>
      {store && <StoreCard store={store} />}
      <ExitQueueButtonContainer>
        <ActionButton
          aria-label={formatMessage({ id: 'exitQueue' })}
          data-testid="exit-queue-modal"
          onClick={onQueueExit}
          variant={ActionButtonVariants.BOX_SHADOW_INVERSE}
        >
          {formatMessage({ id: 'exitQueue' })}
        </ActionButton>
      </ExitQueueButtonContainer>
    </>
  );
};
