import { getLocales } from '@rbilabs/intl';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import { FALLBACK_LOCALES } from 'utils/constants';
import { brand } from 'utils/environment';

export function inferSupportedLocale(
  newLanguage: SupportedLanguages,
  newRegion: SupportedRegions,
  useDefault = false
): string {
  const regionSupportedLocales = getLocales(brand(), newRegion) ?? FALLBACK_LOCALES;

  if (useDefault) {
    return regionSupportedLocales[0];
  }

  // Find the first item on the list with the langauge that we care about
  return (
    regionSupportedLocales.find(sortedLocale => sortedLocale.startsWith(`${newLanguage}`)) ||
    regionSupportedLocales[0]
  );
}
