import { Language } from '@rbilabs/intl';
import { LinkProps } from 'react-helmet-async';

import { SupportedRegions } from '@rbi-ctg/frontend';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { useLocale } from 'state/intl';
import { useLocationContext } from 'state/location';
import { usePageManagerContext } from 'state/static-page-manager';
import { getSupportedMarketLanguages } from 'utils/i18n';
import { appUrl } from 'utils/routing';
import { resolvePath } from 'utils/routing/resolve-path';

const getAlternateLinkProperties = (
  language: Language,
  pathname?: string | null
): LinkProps | null => {
  if (!pathname) {
    return null;
  }

  return {
    rel: 'alternate',
    href: appUrl(language)(pathname),
    hrefLang: language,
  };
};

const getApplicationRouteLinks = (pathname: string, region: SupportedRegions) =>
  getSupportedMarketLanguages().map(lang =>
    getAlternateLinkProperties(lang, resolvePath(`${lang}-${region}`)(pathname))
  );

const getStaticPageRouteLinks = (staticPageRoute?: IStaticPageRoute) =>
  getSupportedMarketLanguages().map(lang =>
    getAlternateLinkProperties(lang, staticPageRoute?.localePath[lang]?.current)
  );

export const useAlternateLinks = () => {
  const { region, shouldUseLanguageCodeInUrls } = useLocale();
  const {
    location: { pathname },
  } = useLocationContext();
  const { isStaticPage, getStaticPageRoute } = usePageManagerContext();

  if (!shouldUseLanguageCodeInUrls) {
    return [];
  }

  const alternateLinks = isStaticPage(pathname)
    ? getStaticPageRouteLinks(getStaticPageRoute(pathname))
    : getApplicationRouteLinks(pathname, region);

  return alternateLinks.filter(Boolean) as LinkProps[];
};
