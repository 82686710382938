import styled from 'styled-components';

const UnstyledButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  font-family: ${Styles.fontFamily.base};
`;

export default UnstyledButton;
