import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconCircleMinus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" {...props}>
    <title>Circle Minus Icon</title>
    <path d="M10 0a10 10 0 0 1 10 10 10 10 0 0 1-10 10A10 10 0 0 1 0 10 10 10 0 0 1 10 0z" />
    <path
      fill={Styles.color.white}
      d="M15.19609,8.82488L11.09504,8.82488L9.08272,8.82488L5.00714,8.82488A1.00616,0.99585 0 1 0 5.00714,10.81658L15.19609,10.81658A1.00616,0.99585 0 0 0 15.19609,8.82488z"
    />
  </svg>
);

export default IconCircleMinus;
