import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 0;
  padding-inline: 16px;
  ${Styles.desktop} {
    justify-content: flex-start;
    padding-inline-start: 40px;
    padding-inline-end: 55px;
  }
  height: 32px;
  width: 100%;
  background-color: ${Styles.color.black};
`;

export const Message = styled.p`
  color: ${Styles.color.white};
  margin-inline-end: 8px;
`;

export const Action = styled.span`
  color: ${Styles.color.white};
  cursor: pointer;
  text-decoration: underline;
`;
