export const clamp = (min: number, max: number) => (v: number) =>
  v <= min ? min : v >= max ? max : v;

export const lerp = (from: number, to: number, progress: number) =>
  (1 - progress) * from + to * progress;

type Range = [number, number];
export const interpolateToRange = (rangeOne: Range, rangeTwo: Range) => (v: number) => {
  const rangeOneDelta = rangeOne[1] - rangeOne[0];
  const progress = clamp(0, 1)(v / rangeOneDelta);
  const rangeTwoCurrent = lerp(rangeTwo[0], rangeTwo[1], progress);
  return clamp(rangeTwo[0], rangeTwo[1])(rangeTwoCurrent);
};

export const interpolateToDiscreteRange = (rangeOne: Range, rangeTwo: Range) => (v: number) =>
  Math.floor(interpolateToRange(rangeOne, rangeTwo)(v));

export const squared = (x: number) => Math.pow(x, 2);
