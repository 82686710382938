import { PayloadAction } from '@reduxjs/toolkit';
import { isNumber, omit } from 'lodash';

import { createAppSlice } from 'state/global-state/utils';
import {
  AvailableRewards,
  IAppliedRewards,
  IRemoveAppliedRewardsAction,
  IRewardAction,
  LoyaltyUser,
} from 'state/loyalty/hooks/types';
import { LoyaltyEngineRewards } from 'state/loyalty/types';

import { userSlice } from '../user/user.slice';

import { IRewardsState } from './rewards.types';
import {
  getAppliedRewardsArrayAndTimesApplied,
  getAppliedRewardsFromStorage,
  getStagedCartPoints,
  setStateFromAppliedRewards,
  updateAppliedRewardsInStorage,
} from './rewards.utils';

export const initialState: IRewardsState = {
  appliedLoyaltyRewards: getAppliedRewardsFromStorage(),
  appliedLoyaltyRewardsArray: null,
  availableLoyaltyRewardsMap: {},
  isPricingRewardApplication: false,
  stagedCartPoints: 0,
  totalTimesRewardApplied: {},
  shouldRefetchRewards: false,
  shouldRefetchOffers: false,
};

export const rewardsSlice = createAppSlice({
  name: 'rewards',
  initialState,
  reducers: {
    applyReward: (
      state,
      { payload }: PayloadAction<IRewardAction & { loyaltyUser?: LoyaltyUser }>
    ) => {
      const {
        cartId,
        rewardBenefitId,
        rewardObj,
        quantity = 1,
        minimumSpend,
        minimumTotalSpend,
      } = payload;

      const {
        appliedLoyaltyRewards,
        availableLoyaltyRewardsMap,
        stagedCartPoints,
        totalTimesRewardApplied,
      } = state;
      const mappedReward = rewardObj ?? availableLoyaltyRewardsMap[rewardBenefitId];

      if (!mappedReward) {
        return;
      }

      const { pointCost, id: rewardId, limitPerOrder, sanityId, rewardPrice } = mappedReward;

      const timesApplied = totalTimesRewardApplied[rewardId];
      // safety check for over-redemption
      if (pointCost * quantity > stagedCartPoints || timesApplied === limitPerOrder) {
        return;
      }

      state.stagedCartPoints = stagedCartPoints - pointCost * quantity;

      const curAppliedTimes = appliedLoyaltyRewards[cartId]?.timesApplied || 0;
      const updatedAppliedRewards: IAppliedRewards = {
        ...appliedLoyaltyRewards,
        [cartId]: {
          timesApplied: curAppliedTimes + quantity,
          rewardId,
          pointCost,
          rewardBenefitId,
          minimumSpend,
          minimumTotalSpend,
          ...(sanityId ? { sanityId } : null),
          ...(rewardPrice ? { rewardPrice } : null),
        },
      };
      const { appliedRewardsArray, timesRewardApplied } =
        getAppliedRewardsArrayAndTimesApplied(updatedAppliedRewards);
      updateAppliedRewardsInStorage(updatedAppliedRewards);
      state.appliedLoyaltyRewards = updatedAppliedRewards;
      state.appliedLoyaltyRewardsArray = appliedRewardsArray;
      state.totalTimesRewardApplied = timesRewardApplied;
    },
    createAvailableLoyaltyRewardsMap: (
      state,
      { payload }: PayloadAction<NonNullable<LoyaltyEngineRewards>>
    ) => {
      const availableRewardMap = payload.reduce((acc: AvailableRewards, reward) => {
        let rewardBenefitId = reward?.rewardBenefitId;

        if (!rewardBenefitId) {
          return acc;
        }

        if (reward.rewardPrice) {
          rewardBenefitId += '_' + reward.rewardPrice;
        }

        const rewardInMap = acc[rewardBenefitId];
        // This ensures that the lowest point reward for the benefit item is displayed to the offer in the cart
        const rewardHasLowerPointCost = () => reward.pointCost < rewardInMap.pointCost;

        if (!rewardInMap || rewardHasLowerPointCost()) {
          acc[rewardBenefitId] = reward;
        }

        return acc;
      }, {});

      state.availableLoyaltyRewardsMap = availableRewardMap;
    },
    rehydrateAppliedReward: state => {
      setStateFromAppliedRewards(state, getAppliedRewardsFromStorage());
      const stagedCartPoints = getStagedCartPoints(state, state.stagedCartPoints);
      if (isNumber(stagedCartPoints)) {
        state.stagedCartPoints = stagedCartPoints;
      }
    },
    removeAppliedReward: (state, { payload }: PayloadAction<IRewardAction>) => {
      const { cartId } = payload;
      const { appliedLoyaltyRewards, stagedCartPoints } = state;
      const { pointCost, timesApplied } = appliedLoyaltyRewards[cartId] || {};
      if (pointCost && timesApplied) {
        state.stagedCartPoints = stagedCartPoints + pointCost * timesApplied;
      }
      const updatedAppliedRewards = omit(appliedLoyaltyRewards, cartId);
      setStateFromAppliedRewards(state, updatedAppliedRewards);
    },
    removeAppliedRewards: (state, { payload }: PayloadAction<IRemoveAppliedRewardsAction>) => {
      const { cartIds } = payload;
      if (!cartIds.length) {
        return;
      }

      const { appliedLoyaltyRewards, stagedCartPoints } = state;

      // calculate the total points to be restored
      const restoredPoints = cartIds.reduce((pointsAccum: number, cartId: string) => {
        const { pointCost, timesApplied } = appliedLoyaltyRewards[cartId] || {};

        return pointCost && timesApplied ? pointsAccum + pointCost * timesApplied : pointsAccum;
      }, 0);

      state.stagedCartPoints = stagedCartPoints + restoredPoints;
      const updatedAppliedRewards = omit(appliedLoyaltyRewards, cartIds);
      setStateFromAppliedRewards(state, updatedAppliedRewards);
    },
    resetLoyaltyRewardsState: (state, { payload }: PayloadAction<number>) => {
      updateAppliedRewardsInStorage({});
      state.appliedLoyaltyRewards = {};
      state.appliedLoyaltyRewardsArray = null;
      state.availableLoyaltyRewardsMap = {};
      state.stagedCartPoints = payload;
    },
    setAvailableLoyaltyRewardsMap: (state, { payload }: PayloadAction<AvailableRewards | {}>) => {
      state.availableLoyaltyRewardsMap = payload;
    },
    setIsPricingRewardApplication: (state, { payload }: PayloadAction<boolean>) => {
      state.isPricingRewardApplication = payload;
    },
    setShouldRefetchRewards: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldRefetchRewards = payload;
    },
    setShouldRefetchOffers: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldRefetchOffers = payload;
    },
    setStagedCartPoints: (state, { payload }: PayloadAction<number>) => {
      state.stagedCartPoints = payload;
    },
    unApplyReward: (
      state,
      // Not sure if this is the best way to handle loyalty user data. Maybe
      // there's a way to consume data from other slice
      { payload }: PayloadAction<IRewardAction & { loyaltyUser?: LoyaltyUser }>
    ) => {
      const { cartId, loyaltyUser } = payload;
      const { appliedLoyaltyRewards, stagedCartPoints } = state;
      const mappedReward = appliedLoyaltyRewards[cartId];

      if (!mappedReward) {
        return;
      }

      const { pointCost, rewardId, rewardBenefitId, rewardPrice } = mappedReward;

      // safety check for over subtracting
      const newBalanceExceedsUserBalance =
        stagedCartPoints + pointCost > (loyaltyUser?.points ?? 0);

      if (newBalanceExceedsUserBalance) {
        return;
      }

      state.stagedCartPoints = stagedCartPoints + pointCost;

      const curTimesApplied = appliedLoyaltyRewards[cartId]?.timesApplied || 0;
      const timesApplied = curTimesApplied - 1;
      const updatedAppliedRewards: IAppliedRewards =
        timesApplied > 0
          ? {
              ...appliedLoyaltyRewards,
              [cartId]: {
                timesApplied,
                rewardId,
                pointCost,
                rewardBenefitId,
                ...(rewardPrice ? { rewardPrice } : null),
              },
            }
          : omit(appliedLoyaltyRewards, cartId);
      setStateFromAppliedRewards(state, updatedAppliedRewards);
    },
  },
  extraReducersBuilder: builder => {
    builder.addCase(
      userSlice.actions.setUser,
      (state: IRewardsState, { payload }: PayloadAction<LoyaltyUser | null>) => {
        const loyaltyUserPoints = payload?.points ?? 0;
        const stagedCartPoints = getStagedCartPoints(state, loyaltyUserPoints);
        if (isNumber(stagedCartPoints)) {
          state.stagedCartPoints = stagedCartPoints;
        }
      }
    );
  },
});
