import styled from 'styled-components';

import * as Base from './styled.default';

export { DesktopSignupBanner } from './base';

export const Heading = styled(Base.Heading)`
  font-weight: normal;
`;

export const ButtonSubText = styled(Base.ButtonSubText)``;

export const ButtonWrapper = styled(Base.ButtonWrapper)``;

export const NewLayoutContainer = styled(Base.NewLayoutContainer)``;

export const NewLayoutContainerWrapper = styled(Base.NewLayoutContainerWrapper)``;
