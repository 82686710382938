import { computeCompositeModifierPlu, computeSimpleModifierPlu } from 'utils/vendor-config';

import { IComputeItemOptionModifierPlu } from './types';

export const computePluForItemOptionModifier = ({
  item,
  modifier,
  vendor,
  prices,
}: IComputeItemOptionModifierPlu) => {
  const complexPlu = computeCompositeModifierPlu({ item, modifier, vendor });

  if (!!complexPlu && typeof prices?.[complexPlu] === 'number') {
    return complexPlu;
  }

  const simplePlu = computeSimpleModifierPlu({ modifier, vendor });

  if (!!simplePlu && typeof prices?.[simplePlu] === 'number') {
    return simplePlu;
  }

  return null;
};
