import {
  IRequiredUserAcceptanceQueryVariables,
  useRequiredUserAcceptanceLazyQuery,
} from 'generated/sanity-graphql';
import { IRequiredUserAcceptanceList } from 'utils/user-agreements/types';

export const useGetRequiredUserAcceptance = () => {
  const [toggleGetRequiredUserAcceptance, queryReturn] = useRequiredUserAcceptanceLazyQuery();

  const getRequiredUserAcceptance = async (
    queryOptions?: IRequiredUserAcceptanceQueryVariables
  ): Promise<IRequiredUserAcceptanceList> => {
    const { data } = await toggleGetRequiredUserAcceptance(queryOptions);

    return data?.allStaticPage || [];
  };

  return {
    getRequiredUserAcceptance,
    ...queryReturn,
  };
};
