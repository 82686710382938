import styled from 'styled-components';

import { BasicTileContainer as DefaultBasicTileContainer } from 'components/marketing-basic-tile/styled.default';

export * from './styled.default';

export const BasicTileContainer = styled(DefaultBasicTileContainer)`
  border-radius: 0.563rem;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
`;
