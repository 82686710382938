import styled from 'styled-components';

export const P = styled.p`
  display: none;
  font-family: ${Styles.fontFamily.body};
  color: ${Styles.color.grey.four};
  font-size: 0.75rem;
  margin: 0;
  padding: 0.5rem 0;

  ${Styles.desktop} {
    display: block;
  }
`;

export const Header = styled.h2`
  color: ${Styles.color.white};
  font-size: 1.625rem;
  margin-block-start: 0;
  margin-block-end: 10px;
  text-transform: ${Styles.textTransform.headlines};

  ${Styles.desktop} {
    margin-block-end: auto;
  }
`;

export const Subheader = styled.div`
  color: ${Styles.color.white};
`;
