import styled from 'styled-components';

import UnstyledButton from 'components/unstyled-button';

export const IconsContainer = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 1.125rem;
  display: flex;
  padding-block-start: 0.625rem;
`;

export const StoreIconWrap = styled.div`
  margin-block-start: 0.625rem;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 1rem;
  cursor: pointer;
  line-height: 0;

  &:last-child {
    margin-inline-end: 0;
  }
`;

export const UnstyledButtonNoSpacing = styled(UnstyledButton)`
  margin: 0;
  padding: 0;
  line-height: 0;
`;

export const Wrapper = styled.div`
  margin-block: 0;
  margin-inline-start: 0;
  margin-inline-end: 0.5rem;
`;
