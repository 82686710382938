import React from 'react';

import { useIntl } from 'react-intl';

import { ServiceMode } from 'state/order';

import { Text } from '../text';

import { BoldText } from './bold-text';
import { WE_ARE_PREPARING_YOUR_ORDER_TRANSLATION_ID } from './constants';
import { ISubheadingText } from './types';

const getSubheadingTextOptions = (
  formatMessage: Function,
  {
    isOrderFiredIn,
    pickupTime,
    lastCallToEditOrderTime,
    canOrderBeEdited,
    name,
    isOrderTableService,
  }: {
    isOrderFiredIn: boolean;
    pickupTime: string;
    lastCallToEditOrderTime: string;
    canOrderBeEdited: boolean;
    name: string;
    isOrderTableService?: boolean;
  }
) => {
  const driveThruInstructionsText = formatMessage(
    {
      id: 'driveThruPickupInstructions',
    },
    { name }
  );

  const preparingOrderText = isOrderFiredIn
    ? isOrderTableService
      ? formatMessage({
          id: 'weArePreparingYourOrderTableService',
        })
      : formatMessage({
          id: WE_ARE_PREPARING_YOUR_ORDER_TRANSLATION_ID,
        })
    : '';

  const waitingInstructionsText = formatMessage(
    {
      id: 'weWillCallNameWhenYourOrderIsReady',
    },
    { name }
  );

  const curbsideInstructionsText = formatMessage(
    { id: isOrderFiredIn ? 'curbsideInstructionsTextHere' : 'curbsideInstructionsText' },
    {
      time: pickupTime,
    }
  );

  const orderPlacedText = formatMessage(
    {
      id: !isOrderFiredIn
        ? canOrderBeEdited
          ? 'changesToOrderTime'
          : 'noChangesToOrderTime'
        : 'yourOrderHasBeenPlaced',
    },
    {
      lastCallToEditOrder: lastCallToEditOrderTime,
    }
  );

  const lockerInstructionsText = formatMessage({ id: 'weArePreparingYourOrderLocker' });

  return {
    orderPlacedText,
    curbsideInstructionsText,
    waitingInstructionsText,
    preparingOrderText,
    driveThruInstructionsText,
    lockerInstructionsText,
  };
};

export const SubheadingText = ({
  isOrderFiredIn,
  name,
  pickupTime,
  lastCallToEditOrderTime,
  canOrderBeEdited,
  serviceMode,
  pickupPin,
}: ISubheadingText) => {
  const { formatMessage } = useIntl();
  const isOrderTableService = serviceMode === ServiceMode.TABLE_SERVICE;
  const {
    curbsideInstructionsText,
    driveThruInstructionsText,
    orderPlacedText,
    preparingOrderText,
    waitingInstructionsText,
    lockerInstructionsText,
  } = getSubheadingTextOptions(formatMessage, {
    isOrderFiredIn,
    name,
    pickupTime,
    lastCallToEditOrderTime,
    canOrderBeEdited,
    isOrderTableService,
  });

  if (serviceMode === ServiceMode.DRIVE_THRU) {
    return <Text>{driveThruInstructionsText}</Text>;
  }

  if (serviceMode === ServiceMode.CURBSIDE) {
    return <Text>{curbsideInstructionsText}</Text>;
  }

  if (pickupPin) {
    return <Text>{lockerInstructionsText}</Text>;
  }

  if (preparingOrderText) {
    return <Text>{preparingOrderText}</Text>;
  }

  return (
    <Text>
      <BoldText>{waitingInstructionsText}</BoldText>
      {orderPlacedText}
    </Text>
  );
};
