import React, { ReactNode } from 'react';

import { differenceInYears, isExists, isFuture, isValid } from 'date-fns';
import { IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';

import { formatDateObject } from 'utils/form';
import formatListOfWords from 'utils/intl/formatListOfWords';

import { LONGEST_RECORDED_HUMAN_LIFE_IN_YEARS, MINIMUM_SIGNUP_AGE } from './constants';

const getBelowMinimumMessage = (formatMessage: IntlShape['formatMessage']) => {
  return formatMessage(
    { id: 'dateOfBirthIsBelowMinimum' },
    {
      termsLink: (
        <Link to={formatMessage({ id: 'routes.termsOfService' })}>
          {formatMessage({ id: 'termsOfService' })}
        </Link>
      ),
      privacyPolicyLink: (
        <Link to={formatMessage({ id: 'routes.privacyPolicy' })}>
          {formatMessage({ id: 'privacyPolicy' })}
        </Link>
      ),
    }
  );
};

const checkDob = (
  dobDate: Date,
  dobRawInput: { month: string; day: string; year: string },
  formatMessage: IntlShape['formatMessage'],
  minimumLegalAge?: number | null | undefined
): ReactNode | null => {
  // JS Date object sucks...
  dobDate.setTime(dobDate.getTime() + dobDate.getTimezoneOffset() * 60000);
  const minimumAge = minimumLegalAge || MINIMUM_SIGNUP_AGE;
  if (
    !isValid(dobDate) ||
    !isExists(
      parseInt(dobRawInput.year),
      parseInt(dobRawInput.month) - 1,
      parseInt(dobRawInput.day)
    )
  ) {
    return formatMessage({ id: 'invalidDateOfBirthProvided' });
  }
  if (isFuture(dobDate)) {
    return formatMessage({ id: 'futureDateOfBirthProvided' });
  }
  if (differenceInYears(Date.now(), dobDate) > LONGEST_RECORDED_HUMAN_LIFE_IN_YEARS) {
    return formatMessage({ id: 'invalidDateOfBirthProvided' });
  }
  if (differenceInYears(Date.now(), dobDate) < minimumAge) {
    return getBelowMinimumMessage(formatMessage);
  }

  return null;
};

export const getDobErrors = (
  dob = { month: '', day: '', year: '' },
  formatMessage: IntlShape['formatMessage'],
  minimumLegalAge?: number | null | undefined,
  isRequired?: boolean
): ReactNode | null => {
  // Empty DOB (valid)
  if (!dob.month && !dob.day && !dob.year) {
    const errorMessage = isRequired ? 'DOB is Required' : null;
    return errorMessage;
  }

  // Partial DOB
  if (!dob.month || !dob.day || !dob.year) {
    return getInvalidDateOfBirthMessage(dob, formatMessage);
  }

  return checkDob(new Date(formatDateObject(dob)), dob, formatMessage, minimumLegalAge);
};

export const getLegacyDobErrors = (
  dob: string | undefined,
  formatMessage: IntlShape['formatMessage'],
  minimumLegalAge?: number | null | undefined
): null | ReactNode => {
  // Empty DOB (valid)
  if (!dob) {
    return null; // dob is not required
  }

  const [month, day, year] = dob.split('/');

  return checkDob(new Date(dob), { year, month, day }, formatMessage, minimumLegalAge);
};

function getInvalidDateOfBirthMessage(
  dob = { month: '', day: '', year: '' },
  formatMessage: IntlShape['formatMessage']
) {
  let missingFieldList = [];
  if (!dob.year) {
    missingFieldList.push(formatMessage({ id: 'year' }));
  }
  if (!dob.month) {
    missingFieldList.push(formatMessage({ id: 'month' }));
  }
  if (!dob.day) {
    missingFieldList.push(formatMessage({ id: 'day' }));
  }

  const missingFields = formatListOfWords({ formatMessage, list: missingFieldList });
  return formatMessage(
    { id: 'invalidDateMissingFields' },
    {
      count: missingFieldList.length,
      missingFields,
    }
  );
}
