import { platform } from 'utils/environment';

import { withHeaders } from './with-headers';

const LANGUAGE_HEADER = 'x-ui-language';
const REGION_HEADER = 'x-ui-region';
const UI_PLATFORM_HEADER = 'x-ui-platform';

export const withI18nHeaders = withHeaders(context => {
  const headers = {};

  if (context.language) {
    headers[LANGUAGE_HEADER] = context.language;
  }

  if (context.region) {
    headers[REGION_HEADER] = context.region;
  }

  headers[UI_PLATFORM_HEADER] = platform();

  return headers;
});
