import React from 'react';

import { ExternalLink, IconContainer } from './styled';
import { ISocialIconProps } from './types';

export const SocialIcon: React.FC<ISocialIconProps> = ({ href, children, width, height }) => {
  return (
    <IconContainer width={width} height={height}>
      <ExternalLink href={href ?? ''}>{children}</ExternalLink>
    </IconContainer>
  );
};
