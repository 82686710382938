import { useState } from 'react';

/**
 * Caution - this hook serves as an escape hatch
 * for specific situations where useState, for some
 * reason, cannot be used effectively. It should be
 * avoided in most cases.
 */
export function useForceUpdate() {
  const [, setState] = useState(0);

  const forceUpdate = () =>
    setState(currentState => (currentState + 1 > Number.MAX_SAFE_INTEGER ? 0 : currentState + 1));

  return forceUpdate;
}
