import { LoyaltyOffer } from 'state/loyalty/types';

import { BatteryLevelRule, UserAttributesItem, UserAttributesRule } from './types';

const isBatteryLevelAttributeItem = (item: UserAttributesItem) =>
  item?.attributeItem === 'battery-level';

const isBatteryLevelRule = (rule: UserAttributesRule) =>
  rule?.__typename === 'UserAttributes' &&
  rule.userAttributesItem?.some(isBatteryLevelAttributeItem);

export const extractBatteryLevelRulesFromLoyaltyOffers = (
  offers: LoyaltyOffer[]
): BatteryLevelRule[] => {
  return offers
    .filter(offer => offer.rules?.some(isBatteryLevelRule))
    .map(offer => {
      const batteryLevelRule = offer.rules?.find(isBatteryLevelRule) as UserAttributesRule;
      const batteryLevelAttributeItem = batteryLevelRule?.userAttributesItem?.find(
        isBatteryLevelAttributeItem
      );

      return {
        operator: batteryLevelAttributeItem?.useAttributeBatteryLevelFilter,
        value: batteryLevelAttributeItem?.useAttributeBatteryLevelValue,
      };
    });
};
