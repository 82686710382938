import styled from 'styled-components';

import LockedIcon from 'components/icons/locked';
import { primitive } from 'styles/constants/primitives';

export const LockOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  background: ${primitive.$black};
  opacity: 0.5;
  z-index: ${Styles.zIndex.top};
`;

export const Lock = styled(LockedIcon)`
  position: absolute;
  width: 2rem;
  height: 2rem;
  inset-inline-start: 0;
  inset-inline-end: 0;
  margin-block-start: 2rem;
  margin-block-end: 0;
  margin-inline: auto;
  z-index: ${Styles.zIndex.top};
`;
