import { isString } from 'lodash';

// Matches protocol-relative URLs.
// This is kept for backward-compatibility purposes.
// Refs. https://en.wikipedia.org/wiki/URL#Protocol-relative_URLs
const protocolRelativeRegex = /^\/{2}(?!\/)/;

export const isExternalLink = (url: unknown = '') => {
  if (!isString(url)) {
    return false;
  }

  if (protocolRelativeRegex.test(url)) {
    return true;
  }

  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
