import styled from 'styled-components';

import { View } from 'components/view';

export const SafeAreaView = styled(View)`
  padding-block-start: env(safe-area-inset-top);
  padding-inline-end: env(safe-area-inset-right);
  padding-block-end: env(safe-area-inset-bottom);
  padding-inline-start: env(safe-area-inset-left);
`;
