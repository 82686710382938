import React from 'react';

import styled from 'styled-components';

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Styles.desktop} {
    border-block-end: 1px solid ${Styles.color.grey.four};
    padding-block-start: 20px;
    padding-block-end: 64px;
    padding-inline: 0;

    :first-child {
      padding-block-start: 0;
    }

    :last-child {
      border-block-end: none;
      padding-block-end: 0;
    }
  }

  > * {
    flex-basis: ${props => 100 / React.Children.count(props.children)}%;
  }
`;
