import styled from 'styled-components';

import { BUTTON_CONTAINER_TOP_BORDER } from '../constants';

export const ButtonContainer = styled.div`
  background-color: ${Styles.color.white};
  inset-block-end: 0;
  position: relative;
  width: 100%;
  border-block-start: ${BUTTON_CONTAINER_TOP_BORDER};
`;

export const ButtonContainerShadow = styled.div<{ $boxShadow: boolean }>`
  inset-block-end: 0;
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.1);
  inset-inline-start: 0;
  opacity: ${props => (props.$boxShadow ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  inset-block-start: 0;
  transition: 0.2s opacity linear;
  inset-inline-end: 0;
`;
