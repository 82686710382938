import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';

interface IHeaderLoadingProps extends IBaseProps {
  hasStoreSelection: boolean;
}

// Expecting this to be overrriden with `as=
const StyledLoadingIndicator = styled.div<IHeaderLoadingProps>`
  position: absolute;
  inset-block-end: ${(p: IHeaderLoadingProps) => (p.hasStoreSelection ? '32px' : '0')};
`;

export default StyledLoadingIndicator;
