import { useCallback, useEffect, useReducer } from 'react';

import { IStore } from '@rbi-ctg/store';
import { getRestaurantsByStoreNumber } from 'remote/api/restaurants';
import { useNetworkContext } from 'state/network';

export interface UseFetchStoreOptions {
  storeNumber?: string;
  lazy?: boolean;
}

export interface UseFetchStoreResult {
  data?: IStore;
  loading: boolean;
  error?: unknown;
  refetch: (storeNumber?: string) => Promise<void>;
}

type UseFetchStore = (options?: UseFetchStoreOptions) => UseFetchStoreResult;

type State = Omit<UseFetchStoreResult, 'refetch'>;

const initialState: State = {
  loading: false,
};

interface FetchingAction {
  type: 'loading';
}

interface SuccessAction {
  type: 'success';
  payload: UseFetchStoreResult['data'];
}

interface ErrorAction {
  type: 'error';
  payload: UseFetchStoreResult['error'];
}

type Reducer = React.Reducer<State, FetchingAction | SuccessAction | ErrorAction>;

const reducer: Reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { loading: true };
    case 'success':
      return { data: action.payload, loading: false };
    case 'error':
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const useFetchStore: UseFetchStore = ({
  storeNumber: initialStoreNumber,
  lazy = false,
}: UseFetchStoreOptions = {}) => {
  const { connection, sanityEndpoints } = useNetworkContext();

  const [state, dispatch] = useReducer<Reducer>(reducer, initialState);

  const fetchStore = useCallback(
    async (storeNumber = initialStoreNumber) => {
      if (typeof storeNumber === 'undefined') {
        return;
      }

      try {
        dispatch({ type: 'loading' });

        const [data] = await getRestaurantsByStoreNumber(sanityEndpoints.groq, {
          connection,
          storeNumber,
        });

        dispatch({ type: 'success', payload: data });
      } catch (error) {
        dispatch({ type: 'error', payload: error });
      }
    },
    [initialStoreNumber, sanityEndpoints.groq, connection]
  );

  useEffect(() => {
    if (!lazy) {
      fetchStore();
    }
  }, [lazy, fetchStore]);

  return { ...state, refetch: fetchStore };
};
