import * as React from 'react';
import { FC } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { ServiceMode } from 'state/service-mode';

import {
  ReminderSubtitle,
  ReminderTitle,
  TextWrapper,
  Wrapper,
} from './cash-payment-reminder.styled';

interface ICashPaymentReminderProps {
  serviceMode: ServiceMode;
}

/**
 * Display a short message to remind customers to pay for their order in cash
 */
const CashPaymentReminder: FC<ICashPaymentReminderProps> = ({ serviceMode }) => {
  const { formatMessage } = useIntl();

  const subtitleKey =
    serviceMode === ServiceMode.DELIVERY ? 'uponOrderDelivery' : 'uponOrderPickup';

  return (
    <Wrapper data-testid="cash-payment-message">
      <Icon icon="error" color="text-error" width="24px" aria-hidden />
      <TextWrapper>
        <ReminderTitle>{formatMessage({ id: 'pleaseHaveYourCashReady' })}</ReminderTitle>
        <ReminderSubtitle>{formatMessage({ id: subtitleKey })}</ReminderSubtitle>
      </TextWrapper>
    </Wrapper>
  );
};

export default CashPaymentReminder;
