import { ApolloLink } from '@apollo/client';
import {
  getMainDefinition,
  hasDirectives,
  removeDirectivesFromDocument,
} from '@apollo/client/utilities';
import { DocumentNode } from 'graphql';

export const USECACHE_DIRECTIVE = 'useCache';

export const stripUseCacheDirective = new ApolloLink((operation, forward) => {
  if (!hasDirectives([USECACHE_DIRECTIVE], operation.query)) {
    return forward(operation);
  }
  const strippedQuery = removeDirectivesFromDocument(
    [{ name: USECACHE_DIRECTIVE }],
    operation.query
  );
  if (strippedQuery) {
    operation.query = strippedQuery;
  }
  return forward(operation);
});

export const isCacheRequest = (query: DocumentNode) => {
  const definition = getMainDefinition(query);
  const foundDirective = definition.directives?.find(
    directive => directive.name.value === USECACHE_DIRECTIVE
  );
  return !!foundDirective;
};
