import EventEmitter from 'eventemitter3';

import { EventName } from './types';

type EventListener = (args: object) => void;

export * from './types';

// Emits an event and synchronously calls each event listener one by one
export const emitEvent = (eventName: EventName, args?: object) => {
  EventHub.getInstance().emitEvent(eventName, args);
};

// Add an event listener which will be called everytime the event is emitted
export const addEventListener = (eventName: EventName, listener: EventListener) => {
  EventHub.getInstance().addEventListener(eventName, listener);
};

// This event listener is called only once per event
// i.e even if the event fires multiple time the listener will only be called on the first emission
export const addOnceEventListener = (eventName: EventName, listener: EventListener) => {
  EventHub.getInstance().addOnceEventListener(eventName, listener);
};

class EventHub {
  public emitEvent = (eventName: EventName, args?: object) => {
    this.eventEmitter.emit(eventName, args);
  };

  public addEventListener = (eventName: EventName, listener: EventListener) => {
    this.eventEmitter.addListener(eventName, listener);
  };

  public addOnceEventListener = (eventName: EventName, listener: EventListener) => {
    this.eventEmitter.once(eventName, listener);
  };

  eventEmitter: EventEmitter;
  private constructor() {
    this.eventEmitter = new EventEmitter();
  }

  private static instance: EventHub;
  public static getInstance(): EventHub {
    if (!EventHub.instance) {
      EventHub.instance = new EventHub();
    }
    return EventHub.instance;
  }
}
