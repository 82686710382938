import { normalizedTranslate } from '@rbilabs/components-library';
import styled from 'styled-components';

export const DropdownContainer = styled.div<{ $isOpen: boolean; $insetInlineStart: number }>`
  opacity: ${p => (p.$isOpen ? 1 : 0)};
  position: absolute;
  inset-block-start: 78px;
  inset-inline-start: ${p => p.$insetInlineStart}px;
  width: 320px;
  display: inline-block;
  box-sizing: border-box;
  background: ${Styles.color.white};
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 10%);
  transform: ${p => (p.$isOpen ? 'translate(-50%, 0)' : 'translate(-50%, -1rem)')};
  transition:
    opacity 200ms ease-out,
    transform 200ms ease-out,
    visibility 200ms ease-out;
  visibility: ${p => (p.$isOpen ? 'visible' : 'hidden')};
  z-index: ${Styles.zIndex.top};

  & > div {
    max-height: calc(90vh - 78px);
    padding-block: 0;
    padding-inline: 2rem;

    overflow-y: auto;
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    border: medium solid ${Styles.color.white};
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent ${Styles.color.white} transparent;
    inset-block-start: 1px;
    inset-inline-start: 50%;
    transform: ${normalizedTranslate('-50%', '-100%')};
  }
`;
