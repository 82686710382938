import { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { IDeliveryAddress } from 'generated/rbi-graphql';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

import { StoreLocatorSteps, StoreLocatorViews } from '../types';

export const MPARTICLE_VIEW_EVENT_MAP = {
  [StoreLocatorViews.LIST_VIEW]: 'Selected list view',
  [StoreLocatorViews.MAP_VIEW]: 'Selected map view',
};

export * from '../types';

export default function useStoreLocatorView() {
  const { logNavigationClick } = useCdpContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const [currentView, setCurrentView] = useState(StoreLocatorViews.LIST_VIEW);
  const [currentStep, setCurrentStep] = useState(StoreLocatorSteps.ADDRESS_SELECTOR);
  const [editedAddress, setEditedAddress] = useState<IDeliveryAddress | null>(null);
  const enableDeliverySplitAddressDetails = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_SPLIT_ADDRESS_DETAILS
  );

  const onBackButtonEvent = useCallback(() => {
    setCurrentStep(StoreLocatorSteps.ADDRESS_SELECTOR);
  }, []);

  const onSetCurrentStep = useCallback(
    (step: StoreLocatorSteps) => {
      if (!enableDeliverySplitAddressDetails) {
        return;
      }
      setCurrentStep(step);
    },
    [enableDeliverySplitAddressDetails]
  );

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCurrentView = useCallback(
    (newView: StoreLocatorViews) => {
      // log view switch event
      logNavigationClick(CustomEventNames.BUTTON_CLICK_STORE_LOCATOR_VIEW, {});
      // switch view state
      setCurrentView(newView);

      // If we are on delivery route and select Map view go back to Pick Up
      if (newView === StoreLocatorViews.MAP_VIEW && location.pathname === routes.address) {
        navigate(formatMessage({ id: 'routes.storeLocator' }));
      }
    },
    [formatMessage, location.pathname, logNavigationClick, navigate]
  );

  return {
    editedAddress,
    setEditedAddress,
    currentView,
    currentViewIsList: currentView === StoreLocatorViews.LIST_VIEW,
    currentViewIsMap: currentView === StoreLocatorViews.MAP_VIEW,
    updateStoreLocatorView: updateCurrentView,
    currentStep,
    setCurrentStep: onSetCurrentStep,
  };
}
