import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { ICdpCtx } from 'state/cdp/types';

export const checkLimitReachedEvent = (overLimitTotal: string, cdp: ICdpCtx) => {
  cdp.trackEvent({
    name: CustomEventNames.CHECKOUT_LIMIT_REACHED,
    type: EventTypes.Other,
    attributes: {
      total: overLimitTotal,
    },
  });
};

export const checkMinimumNotReachedEvent = (underLimitTotal: string, cdp: ICdpCtx) => {
  cdp.trackEvent({
    name: CustomEventNames.CHECKOUT_DELIVERY_MINIMUM_NOT_REACHED,
    type: EventTypes.Other,
    attributes: {
      total: underLimitTotal,
    },
  });
};
