import { IFeatureLockersQuery, useFeatureLockersQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureLockers {
  featureLockersLoading: boolean;
  featureLockers: IFeatureLockersQuery['FeatureLockers'];
}

export const useFeatureLockers = (): IUseFeatureLockers => {
  const { featureLockersId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureLockersQuery({
    variables: { featureLockersId },
    skip: !featureLockersId,
  });

  const featureLockers = data?.FeatureLockers ?? null;
  const featureLockersLoading = featureIdsLoading || loading;

  return { featureLockersLoading, featureLockers };
};
