import { AddToCartArgs } from 'components/with-add-incentive-to-cart/types';
import { LoyaltyTierKey } from 'generated/graphql-gateway';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { IIncentiveEvaluationResult } from 'state/loyalty/hooks/types';
import { LoyaltyOffer, LoyaltyReward } from 'state/loyalty/types';

import { parseIncentiveData } from '../incentive-card/parse-incentive-data';

export interface IIncentiveDetailsProps {
  /** Currently selected reward to display */
  selectedIncentive: LoyaltyReward | LoyaltyOffer;
  /** A map for retrieving points for returned sanityRewards */
  engineIncentivesMap: ReturnType<typeof useLoyaltyRewardsList>['engineRewardsMap'];

  addIncentiveToCart(args: AddToCartArgs): void;

  challengeIncentiveSelected?: boolean;
  isWidget?: boolean;
  onGoBack: VoidFunction;
}

export type ParsedRewardData = ReturnType<typeof parseIncentiveData>;

export interface IIncentiveDetailsViewProps extends ParsedRewardData {
  /** redemption cost for reward */
  pointCost?: number;
  locked?: boolean | null;
  tierLocked?: boolean | null;
  requiredLoyaltyTier?: LoyaltyTierKey | null;
  tierPointsNeeded?: number;
  closedOffer?: boolean | null;
  redemptionPercentage?: number | null;
  remainingPointsNeededForRedemption?: number | null;

  handleRedeemRewardClick(offer: any): void;

  handleInRestaurantRedemptionClick(): void;

  canAddToInRestaurantOrder?: boolean;
  canAddToMobileOrder?: boolean;
  errorMessage?: string;
  checkingAvailability: boolean;
  showRemoveRewardButton?: boolean;

  handleRemoveRewardsClick(): void;

  incentiveEvaluationResults: IIncentiveEvaluationResult[];
  offersInCooldown: boolean;
  favorited?: boolean;
  favoriteLoading?: boolean;

  handleToggleFavorite?(incentiveId: string, sanityId: string, name: string): void;

  challengeIncentiveSelected?: boolean;
  isUpcomingOffer?: boolean;
}

export interface IRewardPoints {
  pointCost: number;
  locked: boolean;
}

export interface IMenuItemDescriptor {
  _id: string;
  _type: string;
}

export enum LoyaltyRedemptionTypes {
  MOBILE_ONLY = 'mobileOnly',
  RESTAURANT_ONLY = 'restaurantOnly',
  MOBILEANDRESTAURANT = 'mobileAndRestaurant',
}
