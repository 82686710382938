import { ApolloLink, NextLink, Operation } from '@apollo/client';
import Cookies from 'js-cookie';
import { merge } from 'lodash';

const DEVICE_ID_HEADER = 'x-forter-device-id';
const TOKEN_HEADER = 'x-forter-token';

export class ForterHeadersLink extends ApolloLink {
  public deviceId?: string;
  public enabled?: boolean;
  /**
   * The Token Trigger Integration (TTI) allows the Forter JS integration to work,
   * without having to rely on the ability to write a cookie. This ability is
   * becoming more essential nowadays since certain configurations do not allow
   * writing cookies. In addition, some privacy settings and extensions also
   * block cookies (even 1st party).
   * */
  public ttiForterToken?: string;

  public request(operation: Operation, forward: NextLink) {
    const context = operation.getContext();

    if (this.enabled) {
      // If the TTI Forter token is not available, then default to the cookie integration.
      const cookieForterToken = Cookies.get('forterToken');
      const forterToken = this.ttiForterToken ?? cookieForterToken;

      operation.setContext(
        merge(
          {
            headers: {
              ...(this.deviceId && { [DEVICE_ID_HEADER]: this.deviceId }),
              ...(forterToken && { [TOKEN_HEADER]: forterToken }),
            },
          },
          context
        )
      );
    }
    return forward(operation);
  }

  public setForterToken(token: string) {
    this.ttiForterToken = token;
    return token;
  }

  public setDeviceId(deviceId: string) {
    this.deviceId = deviceId;
    return deviceId;
  }

  public setEnabled(enabled: boolean) {
    this.enabled = enabled;
    return enabled;
  }
}

/**
 * use a singleton instance to share Forter Device ID state
 */
export const withForterHeaders = new ForterHeadersLink();
