import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 1.125rem;
  background-color: ${Styles.color.cardBackground};
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TextWrapper = styled.div`
  margin-inline-start: 1rem;
  text-align: start;
  line-height: 1.2;
`;

export const ReminderTitle = styled.div`
  font-size: 1.125rem;
  font-family: ${Styles.fontFamily.base};
`;

export const ReminderSubtitle = styled.div`
  font-size: 0.72rem;
`;
