import React, { useRef, useState } from 'react';

import ReactDOM from 'react-dom';

import { ListOfLinks, ListOfLinksProps } from 'components/list-of-links';
import { useAuthContext } from 'state/auth';

import { Drawer } from './drawer';
import { StyledAccount } from './styled';
import { ToggleButton } from './toggle-button';

export interface SideNavProps extends Pick<ListOfLinksProps, 'entries'> {}

export const SideNav: React.FC<SideNavProps> = ({ entries }) => {
  const { isAuthenticated } = useAuthContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={toggleSideNav}
        iconColor="icon-header-contrast"
        ref={buttonRef}
      />
      {ReactDOM.createPortal(
        <Drawer
          isOpen={isOpen}
          onDismiss={() => {
            setIsOpen(false);
          }}
          buttonRef={buttonRef}
        >
          {isAuthenticated() && <StyledAccount />}
          {entries && <ListOfLinks entries={entries} />}
        </Drawer>,
        document.body
      )}
    </>
  );
};
