import { roundToOneDecimalIfNecessary } from 'components/nutrition/utils';
import { useFeatureNutrition } from 'hooks/use-feature-nutrition';
import { useLocale } from 'state/intl';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { Joule, RoundingFunction } from './types';

export function useFormatJoules(): (
  cal?: number,
  suffix?: string,
  roundingFunction?: RoundingFunction
) => string {
  const { locale } = useLocale();
  const { roundNutritionValue } = useFeatureNutrition();
  const enableNutritionDecimals = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_DECIMALS);
  const enableFeatureNutritionIntergration = useFlag(
    LaunchDarklyFlag.ENABLE_FEATURE_NUTRITION_INTEGRATION
  );

  const oldRoundingMethod: RoundingFunction = enableNutritionDecimals
    ? roundToOneDecimalIfNecessary
    : Math.floor;

  const defaultRoundingMethod: RoundingFunction = enableFeatureNutritionIntergration
    ? roundNutritionValue
    : oldRoundingMethod;

  return function formatJoules(
    cal?: number,
    suffix?: string,
    roundingFunction: RoundingFunction = defaultRoundingMethod
  ): string {
    if (cal === undefined || isNaN(cal)) {
      return '';
    }

    const jouleStr = suffix !== 'none' ? Joule.UNIT : '';
    return `${roundingFunction(cal * Joule.CONVERSION).toLocaleString(locale)} ${jouleStr}`.trim();
  };
}
