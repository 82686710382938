import { Nullable } from '@rbi-ctg/frontend';
import { ServiceMode } from 'state/service-mode/types';
import { IPreloadedStoreState, Store, StoreState } from 'state/store/hooks';

/**
 * Helper function to initialize store for store selection 1.0
 *
 * @param {StoreState} preloaded Preloaded store
 * @param {Nullable<ServiceMode>} serviceMode Selected service mode
 */
export const initStore = (
  preloaded: IPreloadedStoreState,
  serviceMode: Nullable<ServiceMode>
): StoreState | Store => (!!preloaded.store && serviceMode ? preloaded.store : null);
