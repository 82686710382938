import { useEffect, useState } from 'react';

import { KeyboardKeys } from 'enums/keyboard-keys';

/**
 * Hook that provides a watcher for whther or not
 * the user is using tabs to navigate.
 */
export const useTabNavigationWatcher = () => {
  const [isUsingTabNavigation, setIsUsingTabNavigation] = useState(false);

  useEffect(() => {
    const keyUpHandler = (e: KeyboardEvent) => {
      if (e.key === KeyboardKeys.Tab) {
        setIsUsingTabNavigation(true);
      }
    };

    const clickHandler = () => {
      setIsUsingTabNavigation(false);
    };

    document.body.addEventListener('keyup', keyUpHandler);
    document.body.addEventListener('mousedown', clickHandler);

    return () => {
      document.body.removeEventListener('keyup', keyUpHandler);
      document.body.removeEventListener('mousedown', clickHandler);
    };
  }, [setIsUsingTabNavigation]);

  return { isUsingTabNavigation };
};
