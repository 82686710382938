import { RootState } from 'state/global-state';

export const selectCartEntries = ({ ordering }: RootState) => ordering.cart.cartEntries;
export const selectFavoriteEntries = ({ ordering }: RootState) => ordering.cart.favoriteEntries;
export const selectOfferEntry = ({ ordering }: RootState) => ordering.cart.offerEntry;
export const selectPendingEntry = ({ ordering }: RootState) => ordering.cart.pendingEntry;
export const selectPendingEntryFromCart = ({ ordering }: RootState) =>
  ordering.cart.cartEntries.find(entry => entry.cartId === ordering.cart.pendingEntry?.cartId);
export const selectPendingEntryFromFavorite = ({ ordering }: RootState) =>
  ordering.cart.favoriteEntries.find(entry => entry.cartId === ordering.cart.pendingEntry?.cartId);
export const selectPendingEntryFromOffer = ({ ordering }: RootState) => {
  // If there is no offerEntry then pending could not have come from there
  if (!ordering.cart.offerEntry?.cartId) {
    return undefined;
  }

  // pendingEntry came from the offerEntry or there is no pendingEntry
  return ordering.cart.offerEntry.cartId === ordering.cart.pendingEntry?.cartId
    ? ordering.cart.offerEntry
    : undefined;
};
