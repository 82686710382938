import { IMarketingCardGroupFragment, IMarketingTileGroup } from 'generated/sanity-graphql';

export interface IMarketingTileGroupProps {
  item: IMarketingTileGroup | IMarketingCardGroupFragment;
  className?: string;
}

export enum MarketingTileGroupType {
  MARKETING_TILE = 'MarketingTile',
  MARKETING_TILE_BASIC = 'MarketingTileBasic',
  MARKETING_TILE_PAIR = 'MarketingTilePair',
  MARKETING_TILE_QUAD = 'MarketingTileQuad',
  MARKETING_TILE_LIST = 'MarketingTileList',
}
