import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { SignUpMethodVariations } from 'state/launchdarkly/variations';
import { OTPAuthDeliveryMethod, isOTPEmailEnabled, isOTPEnabled, isOTPSMSEnabled } from 'utils/otp';

/**
 * Centralizes the calculation of which "One Time Password" method we are using
 */

export const signUpMethodToOTPAuthDeliveryMethod = (
  signUpMethod?: SignUpMethodVariations
): OTPAuthDeliveryMethod => {
  if (!signUpMethod || signUpMethod === SignUpMethodVariations.EMAIL) {
    return OTPAuthDeliveryMethod.Email;
  }
  if (
    signUpMethod === SignUpMethodVariations.EMAIL_OR_PHONE ||
    signUpMethod === SignUpMethodVariations.EMAIL_OR_PHONE_WITH_EMAIL
  ) {
    return OTPAuthDeliveryMethod.All;
  }
  return OTPAuthDeliveryMethod.None;
};

export const useOtpFeature = () => {
  const signUpMethodFlagValue = useFlag(LaunchDarklyFlag.SIGN_UP_METHOD) as SignUpMethodVariations;

  const enableOtpFlagValue = signUpMethodToOTPAuthDeliveryMethod(signUpMethodFlagValue);

  const smsOtpEnabled = isOTPSMSEnabled(enableOtpFlagValue);
  const emailOtpEnabled = isOTPEmailEnabled(enableOtpFlagValue);

  return {
    enableOtpFlagValue: enableOtpFlagValue ?? OTPAuthDeliveryMethod.None,
    jwtEmailEnabled: !isOTPEnabled(enableOtpFlagValue),
    smsOtpEnabled,
    emailOtpEnabled,
  };
};
