import React from 'react';

import { ActionButtonSizes } from 'components/action-button';

import { FabContainer, StyledActionButton } from './styled';
import { FabProps } from './types';

export const Fab = ({
  size = ActionButtonSizes.LARGE,
  caption,
  badge,
  onClick,
  ...rest
}: FabProps) => (
  <FabContainer $badgeContent={badge}>
    <StyledActionButton size={size} onClick={onClick} {...rest}>
      {caption}
    </StyledActionButton>
  </FabContainer>
);
