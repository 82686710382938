import { useCallback, useMemo } from 'react';

import { isNil } from 'lodash';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode, ServiceModeStatus } from 'state/service-mode/types';
import { StoreProxy } from 'state/store/hooks';
import {
  isMobileOrderingAvailable,
  isRestaurantOpen,
  useGetRestaurantAvailabilityFn,
  useGetRestaurantFn,
} from 'utils/restaurant';
import {
  PICKUP_SERVICE_MODES,
  SERVICE_MODES,
  isCatering,
  isServiceModeAvailable,
} from 'utils/service-mode';

export const useServiceModeStatusGenerator = () => {
  const isCateringDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_CATERING_DELIVERY);
  const isCateringEnabled = useFlag(LaunchDarklyFlag.ENABLE_CATERING);
  const isCurbsideEnabled = useFlag(LaunchDarklyFlag.ENABLE_CURBSIDE);
  const isDeliveryEnabled = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);
  const isTableServiceEnabled = useFlag(LaunchDarklyFlag.ENABLE_TABLE_SERVICE);
  // Move these up in order to check for null (meaning LD service is down) or undefined
  // FYI - use ENABLE_* flags to avoid this necessity as null values will correctly disable the option
  const driveThruDisabled = useFlag(LaunchDarklyFlag.DISABLE_DRIVE_THRU);
  const dineInDisabled = useFlag(LaunchDarklyFlag.DISABLE_DINE_IN);
  const takeOutDisabled = useFlag(LaunchDarklyFlag.DISABLE_TAKE_OUT);

  // ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  const enableDeliveryOutsideOpeningHours = useFlag(
    LaunchDarklyFlag.ENABLE_DELIVERY_CHECKOUT_OUTSIDE_OPENING_HOURS
  );

  const generateServiceModeStatusForStore = useCallback(
    (store: StoreProxy | IRestaurantNode) => {
      const serviceModeStatus: ServiceModeStatus = {
        CATERING_DELIVERY: {
          capable: !!store.hasDelivery && !!store.hasCatering,
          available: false,
          disabled: !isCateringDeliveryEnabled,
        },
        CATERING_PICKUP: {
          capable: !!store.hasCatering,
          available: false,
          disabled: !isCateringEnabled,
        },
        CURBSIDE: {
          capable: !!store.hasCurbside,
          available: false,
          disabled: !isCurbsideEnabled,
          open: false,
        },
        DELIVERY: {
          capable: !!store.hasDelivery,
          available: false,
          disabled: !isDeliveryEnabled,
          open: false,
        },
        DRIVE_THRU: {
          capable: !!store.hasDriveThru,
          available: false,
          disabled: isNil(driveThruDisabled) || driveThruDisabled,
          open: false,
        },
        EAT_IN: {
          capable: !!store.hasDineIn,
          available: false,
          disabled: isNil(dineInDisabled) || dineInDisabled,
          open: false,
        },
        TAKEOUT: {
          capable: !!store.hasTakeOut,
          available: false,
          disabled: isNil(takeOutDisabled) || takeOutDisabled,
          open: false,
        },
        TABLE_SERVICE: {
          capable: !!store.hasTableService,
          available: false,
          disabled: !isTableServiceEnabled,
          open: false,
        },
      };

      const curbsideOpen = isRestaurantOpen(store.curbsideHours);
      const deliveryOpen =
        isRestaurantOpen(store.deliveryHours) || enableDeliveryOutsideOpeningHours;
      const diningRoomOpen = isRestaurantOpen(store.diningRoomHours);
      const driveThruOpen = isRestaurantOpen(store.driveThruHours);

      /** Need to know if service mode is open for store selection 2.0 */
      serviceModeStatus.DELIVERY.open = deliveryOpen;
      serviceModeStatus.EAT_IN.open = diningRoomOpen;
      serviceModeStatus.TAKEOUT.open = diningRoomOpen;
      serviceModeStatus.CURBSIDE.open = curbsideOpen;
      serviceModeStatus.DRIVE_THRU.open = driveThruOpen;
      serviceModeStatus.TABLE_SERVICE.open = diningRoomOpen;

      serviceModeStatus.CATERING_DELIVERY.available = serviceModeStatus.CATERING_DELIVERY.capable;
      serviceModeStatus.CATERING_PICKUP.available = serviceModeStatus.CATERING_PICKUP.capable;
      serviceModeStatus.DELIVERY.available = serviceModeStatus.DELIVERY.capable && deliveryOpen;
      serviceModeStatus.EAT_IN.available = serviceModeStatus.EAT_IN.capable && diningRoomOpen;
      serviceModeStatus.CURBSIDE.available = serviceModeStatus.CURBSIDE.capable && curbsideOpen;
      serviceModeStatus.TAKEOUT.available = serviceModeStatus.TAKEOUT.capable && diningRoomOpen;
      serviceModeStatus.DRIVE_THRU.available =
        serviceModeStatus.DRIVE_THRU.capable && driveThruOpen;
      serviceModeStatus.TABLE_SERVICE.available =
        serviceModeStatus.TABLE_SERVICE.capable && diningRoomOpen;

      return serviceModeStatus;
    },
    [
      isCateringDeliveryEnabled,
      isCateringEnabled,
      isCurbsideEnabled,
      isDeliveryEnabled,
      driveThruDisabled,
      dineInDisabled,
      takeOutDisabled,
      isTableServiceEnabled,
      enableDeliveryOutsideOpeningHours,
    ]
  );

  return {
    generateServiceModeStatusForStore,
  };
};

const isAvailableAndNotDisabled = ({
  available,
  disabled,
}: ServiceModeStatus[keyof ServiceModeStatus]) => available && !disabled;

export const useServiceModeStatus = (store: StoreProxy | IRestaurantNode) => {
  const { serviceMode } = useServiceModeContext();

  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const storeSelection2_0Enabled = useFlag(LaunchDarklyFlag.ENABLE_STORE_SELECTION_2_0);
  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );

  const hasAvailableProperty = useGetRestaurantAvailabilityFn();
  const getRestaurant = useGetRestaurantFn();
  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const serviceModeStatus = useMemo(
    () => generateServiceModeStatusForStore(store),
    [generateServiceModeStatusForStore, store]
  );

  const allServiceModesUnavailable = Object.values(serviceModeStatus).every(
    ({ available, disabled }) => !available || disabled
  );
  const hideClickAndCollectOrdering = store.hideClickAndCollectOrdering ?? false;
  const availablePickupServiceModes = useMemo(
    () => PICKUP_SERVICE_MODES.filter(value => isAvailableAndNotDisabled(serviceModeStatus[value])),
    [serviceModeStatus]
  );

  const capablePickupServiceModes = useMemo(
    () => PICKUP_SERVICE_MODES.filter(value => serviceModeStatus[value].capable),
    [serviceModeStatus]
  );

  const isRestaurantAvailable = useCallback(
    async (restaurantData: StoreProxy | IRestaurantNode) => {
      const mobileOrderingEnabled = isMobileOrderingAvailable(
        restaurantData,
        isAlphaBetaStoreOrderingEnabled
      );
      const { number: storeId } = restaurantData;

      if (!storeId) {
        return false;
      }

      const rbiRestaurant = await getRestaurant(storeId);

      const isRestaurantPosAvailable = Boolean(rbiRestaurant?.available);

      const status = generateServiceModeStatusForStore(restaurantData);

      const serviceModeToCheck = serviceMode || ServiceMode.DRIVE_THRU;

      const open =
        [ServiceMode.DELIVERY, ServiceMode.CATERING_PICKUP, ServiceMode.CATERING_DELIVERY].includes(
          serviceModeToCheck
        ) ||
        (status[serviceModeToCheck].available && !status[serviceModeToCheck].disabled);

      return open && isRestaurantPosAvailable && mobileOrderingEnabled;
    },
    [generateServiceModeStatusForStore, isAlphaBetaStoreOrderingEnabled, serviceMode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /** Determines whether a restaurant can be selected */
  const restaurantCanBeSelected = useMemo(() => {
    // Store selection 2.0: any store can be selected
    if (storeSelection2_0Enabled && store._id) {
      return true;
    }

    if (!enableOrdering || hideClickAndCollectOrdering) {
      return false;
    }

    /*
     * Catering service mode is preselected and assumed to be available for
     * ordering during selection.
     */
    if (isCatering(serviceMode)) {
      return true;
    }

    // Store selection 1.0: store can only be selected if there is an available service mode
    return (
      hasAvailableProperty(store) &&
      isMobileOrderingAvailable(store, isAlphaBetaStoreOrderingEnabled) &&
      Boolean(availablePickupServiceModes.length)
    );
  }, [
    storeSelection2_0Enabled,
    store,
    enableOrdering,
    serviceMode,
    hasAvailableProperty,
    isAlphaBetaStoreOrderingEnabled,
    availablePickupServiceModes.length,
  ]);

  const availableServiceModes = useMemo(() => {
    return SERVICE_MODES.filter(isServiceModeAvailable(serviceModeStatus));
  }, [serviceModeStatus]);

  return {
    availableServiceModes,
    allServiceModesUnavailable,
    availablePickupServiceModes,
    isRestaurantAvailable,
    restaurantCanBeSelected,
    serviceModeStatus,
    capablePickupServiceModes,
  };
};
