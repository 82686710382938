import { PayloadAction } from '@reduxjs/toolkit';
import { omit, uniqBy } from 'lodash';

import { ICartEntry } from '@rbi-ctg/menu';
import { IOffersFragment, OfferRedemptionType } from 'generated/graphql-gateway';
import { createAppSlice } from 'state/global-state/utils';
import { IncentiveEvaluationMap } from 'state/loyalty/hooks/types';
import { LoyaltyAppliedOffer, LoyaltyOffer } from 'state/loyalty/types';
import { isAppliedOfferSwap } from 'state/loyalty/utils';

import { IEntriesIdsMap, IOffersState } from './offers.types';
import {
  getAppliedOffersFromStorage,
  getSelectedOfferFromStorage,
  removeSelectedOfferInStorage,
  updateAppliedOffersInStorage,
  updateSelectedOfferInStorage,
} from './offers.utils';

export const initialState: IOffersState = {
  appliedOffers: getAppliedOffersFromStorage(),
  cartEntriesIdsMap: {},
  cmsOffers: [],
  incentivesIds: [],
  offers: [],
  offersFeedbackMap: {},
  offersLoading: false,
  offerRedemptionAvailableAfter: null,
  personalizedOffers: [],
  selectedOffer: getSelectedOfferFromStorage(),
  surpriseAvailable: false,
  upsizeAvailable: false,
  userOffers: [],
};

export const offersSlice = createAppSlice({
  name: 'offers',
  initialState,
  reducers: {
    applyOffer: (state, { payload: newOffer }: PayloadAction<LoyaltyAppliedOffer>) => {
      const isSwapOffer = isAppliedOfferSwap(newOffer);
      const isStackableOffer = newOffer.isStackable;
      const updatedOffers = state.appliedOffers
        .filter((appliedOffer: LoyaltyAppliedOffer) => {
          if (isSwapOffer) {
            // filters out already applied swap offer
            return !isAppliedOfferSwap(appliedOffer);
          } else if (isStackableOffer) {
            // Ensures we are not adding the same offer
            return appliedOffer.id !== newOffer.id;
          } else {
            // filters out non-stackable offers
            return isAppliedOfferSwap(appliedOffer) || appliedOffer.isStackable;
          }
        })
        .concat(newOffer);
      updateAppliedOffersInStorage(updatedOffers);
      state.appliedOffers = updatedOffers;
    },
    unshiftPersonalizedOffer: (state, { payload }: PayloadAction<LoyaltyOffer>) => {
      state.personalizedOffers = [payload, ...state.personalizedOffers];
    },
    resetAppliedOffers: state => {
      updateAppliedOffersInStorage([]);
      state.appliedOffers = [];
    },
    resetOfferFeedbackMap: state => {
      state.offersFeedbackMap = {};
    },
    resetSurpriseAvailability: state => {
      state.surpriseAvailable = false;
    },
    resetUpsizeAvailability: state => {
      state.upsizeAvailable = false;
    },
    setAppliedOffers: (state, { payload }: PayloadAction<LoyaltyAppliedOffer[]>) => {
      updateAppliedOffersInStorage(payload);
      state.appliedOffers = payload;
    },
    setCartEntriesIdsMap: (state, { payload }: PayloadAction<IEntriesIdsMap>) => {
      state.cartEntriesIdsMap = payload;
    },
    setCmsOffers: (state, { payload }: PayloadAction<LoyaltyOffer[]>) => {
      state.cmsOffers = uniqBy([...state.cmsOffers, ...payload], '_id');
    },
    setIncentivesIds: (state, { payload }: PayloadAction<string[]>) => {
      state.incentivesIds = payload;
    },
    setOffers: (state, { payload }: PayloadAction<IOffersFragment[]>) => {
      state.offers = payload;
    },
    setOffersFeedbackMap: (state, { payload }: PayloadAction<IncentiveEvaluationMap>) => {
      state.offersFeedbackMap = payload;
    },
    setOffersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.offersLoading = payload;
    },
    // TODO: remove this function and get the value from the user
    setOfferRedemptionAvailableAfter: (state, { payload }: PayloadAction<string | null>) => {
      state.offerRedemptionAvailableAfter = payload;
    },
    setPersonalizedOffers: (state, { payload }: PayloadAction<LoyaltyOffer[]>) => {
      state.personalizedOffers = uniqBy(payload, 'loyaltyEngineId');
    },
    setSelectedOffer: (state, { payload }: PayloadAction<LoyaltyOffer | null>) => {
      updateSelectedOfferInStorage(payload);
      state.selectedOffer = payload;
    },
    setSurpriseOfferIfAvailable: (state, { payload: offer }: PayloadAction<IOffersFragment>) => {
      if (offer.redemptionType === OfferRedemptionType.SURPRISE) {
        state.surpriseAvailable = true;
      }
    },
    setUpsizeAvailable: (state, { payload }: PayloadAction<boolean>) => {
      state.upsizeAvailable = payload;
    },
    setUserOffers: (state, { payload }: PayloadAction<IOffersFragment[]>) => {
      state.userOffers = payload;
    },
    removeAppliedOfferByCartEntry: (state, { payload: cartEntry }: PayloadAction<ICartEntry>) => {
      const updatedOffers = state.appliedOffers.filter(offer => {
        if (
          cartEntry.cartId === offer.cartId &&
          offer.id === state.selectedOffer?.loyaltyEngineId
        ) {
          removeSelectedOfferInStorage();
        }
        if (offer?.id && state.offersFeedbackMap[offer.id]) {
          state.offersFeedbackMap = omit(state.offersFeedbackMap, offer.id);
        }
        // If cart entry has children, cartIds could match
        // otherwise, cartId should check against Swap cartId
        return offer?.swap && !cartEntry.children.length
          ? offer?.swap?.cartId !== cartEntry?.cartId
          : offer?.cartId !== cartEntry?.cartId;
      });

      updateAppliedOffersInStorage(updatedOffers);

      state.appliedOffers = updatedOffers;
    },
    removeAppliedDiscountOffer: state => {
      const updatedOffers = state.appliedOffers.filter(offer => offer.cartId !== 'discount-offer');
      state.appliedOffers = updatedOffers;
    },
    removeCmsOfferByLoyalty: (state, { payload }: PayloadAction<LoyaltyOffer>) => {
      state.cmsOffers = state.cmsOffers.filter(
        offer => offer.loyaltyEngineId !== payload.loyaltyEngineId
      );
    },
    resetAllConfigOffers: state => {
      state.selectedOffer = null;
      state.personalizedOffers = [];
      state.userOffers = [];
      state.offersFeedbackMap = {};
    },
  },
});
