import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ICartEntry } from '@rbi-ctg/menu';
import { useRedeemReward } from 'components/cart-item/redeem-reward/use-redeem-reward/use-redeem-reward';
import { IncrementorActions } from 'components/incrementor';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { booleanToString } from 'state/cdp/utils';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { useOrderContext } from 'state/order';
import { routes } from 'utils/routing';

export const useCartItemEditingTools = ({
  item,
  isOffer = false,
}: {
  item: ICartEntry;
  isOffer?: boolean;
}) => {
  const cdp = useCdpContext();
  const { confirmRemoveFromCart, updateQuantity, editCart } = useOrderContext();
  const navigate = useNavigate();
  const location = useLocation();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();
  const { incentiveNotInMenu } = useRedeemReward(item);
  const dispatch = useAppDispatch();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const handleEditItem = () => {
    // If its an extra take them to the extras modal
    if (item.isExtra) {
      return navigate(`${routes.cart}/extras`);
    }

    editCart(item.cartId);
    // If its a reorder item always take directly to the item. Only allow picker changes if we haven't ordered it before.
    const baseUrl = item.reorder
      ? `/menu/${item.type.toLowerCase()}-${item._id}`
      : item.url.split('?')[0];
    const editUrl = `${baseUrl}?isEdit=true`;
    cdp.trackEvent({
      name: CustomEventNames.EDIT_CART,
      type: EventTypes.Other,
      attributes: {
        'Product Id': item._id,
        'Product Name': item.name,
        'Edit Url': editUrl,
        'Is Reorder': booleanToString(item?.reorder ?? false),
        'Is Offer': booleanToString(isOffer),
      },
    });
    navigate(editUrl, { state: { isFromCart: location.pathname === routes.cart } });
  };

  const handleRemoveItem = useCallback(
    () => confirmRemoveFromCart(item.cartId),
    [item, confirmRemoveFromCart]
  );

  const handleQuantityChange = useCallback(
    (quantity: number, incrementorAction: IncrementorActions) => {
      if (loyaltyEnabled) {
        const { rewardBenefitId } = getAvailableRewardFromCartEntry(item) || {};
        const numberOfAppliedRewards = appliedLoyaltyRewards[item.cartId]?.timesApplied;
        const quantityLessThanAppliedRewards = quantity < numberOfAppliedRewards;
        const { applyReward, unApplyReward } = actions.loyalty;
        if (quantityLessThanAppliedRewards && rewardBenefitId) {
          dispatch(
            unApplyReward({
              rewardBenefitId,
              cartId: item.cartId,
              loyaltyUser,
            })
          );
        }

        // if incentive is not in menu, any quantity update should apply or remove the incentive
        if (
          incentiveNotInMenu &&
          incrementorAction === IncrementorActions.Increment &&
          rewardBenefitId
        ) {
          dispatch(applyReward({ rewardBenefitId, cartId: item.cartId }));
        }
      }

      updateQuantity(item.cartId, quantity);
    },
    [
      appliedLoyaltyRewards,
      dispatch,
      getAvailableRewardFromCartEntry,
      incentiveNotInMenu,
      item,
      loyaltyEnabled,
      loyaltyUser,
      updateQuantity,
    ]
  );

  return {
    handleEditItem,
    handleRemoveItem,
    handleQuantityChange,
  };
};
