import { getDateFormat } from '@rbilabs/intl';
import { Locale as DateFnsLocale, format } from 'date-fns';
import { enGB } from 'date-fns/locale';

import { brand, getCountry } from 'utils/environment';

type TFormatConfig = Parameters<typeof format>[2];

export type TFormatDateFn = (date: Date, formatStyle: string, config?: TFormatConfig) => string;

export const createFormatDate = (initialConfig: TFormatConfig = {}): TFormatDateFn => {
  return (date, formatStyle, config) => format(date, formatStyle, { ...initialConfig, ...config });
};

export const formatDate = createFormatDate({ locale: enGB });

/**
 * Converts a `TDateFnLocale` value from @rbilabs/intl's `Country.dateFormat`
 * field into a locale code date-fns uses in the file system. This is to be used
 * when dynamically importing locales.
 * @param {string} locale a `TDateFnLocale` from @rbilabs/intl.
 * @returns {string} a string representing a date-fns locale path.
 */
export const toDateFnsLocaleDir = (locale: string): string => {
  return locale.replace(/^([a-z][a-z])([A-Z][A-Za-z]+)?$/, (_, ab, CD) =>
    ab && CD ? `${ab}-${CD}` : ab
  );
};

export const getDateFnsLocale = async (locale: string): Promise<DateFnsLocale> => {
  const localeDateFns = getDateFormat(brand(), getCountry(), locale) || 'enGB';
  const localeDateFnsDir = toDateFnsLocaleDir(localeDateFns);

  return await import(`date-fns/locale/${localeDateFnsDir}/index.js`);
};
