import React from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { routes } from 'utils/routing';

import { SignupButtonWrapper, StyledSignupButton } from './styled';

interface SignupButtonProps {
  variant: ActionButtonVariants;
}

export const SignupButton: React.FC<SignupButtonProps> = ({ variant, ...rest }) => {
  const { formatMessage } = useIntl();

  return (
    // TODO(ICFE-1146): Remove the wrapper when action button is fixed
    <SignupButtonWrapper>
      <StyledSignupButton
        variant={variant}
        size={ActionButtonSizes.SMALL}
        to={routes.signUp}
        {...rest}
      >
        {formatMessage({ id: 'signUp' })}
      </StyledSignupButton>
    </SignupButtonWrapper>
  );
};
