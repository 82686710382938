import styled, { css } from 'styled-components';

import {
  Block as BaseBlock,
  Footer as BaseFooter,
  ModalHead as BaseModalHead,
  ModalInner as BaseModalInner,
} from './details-modal.styled.default';

export * from './details-modal.styled.default';

const bgColor = css`
  background-color: ${p => p.theme.token('background-default')};
`;

export const ModalHead = styled(BaseModalHead)`
  ${bgColor}
`;

export const Footer = styled(BaseFooter)`
  ${bgColor}
`;

export const ModalInner = styled(BaseModalInner)`
  ${bgColor}
`;

export const Block = styled(BaseBlock)`
  p {
    color: ${p => p.theme.token('text-default')};
  }
`;
