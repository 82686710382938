import React, { ReactNode, useRef } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';

import { Omit } from '@rbi-ctg/frontend';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog, { IDialogProps } from 'components/dialog';
import useMediaQuery from 'hooks/use-media-query';

export interface IConfirmProps extends Omit<IDialogProps, 'actions'> {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  confirmLabel?: string | ReactNode;
  cancelLabel?: string | ReactNode;
  modalAppearanceEventMessage: string;
}

const ConfirmDialog = ({
  className,
  heading,
  body,
  bodyComponent,
  onDismiss,
  onConfirm,
  onCancel,
  image,
  confirmLabel,
  cancelLabel,
  showCloseButton,
  'data-testid': dataTestId,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
}: IConfirmProps) => {
  const { formatMessage } = useIntl();
  const isDesktop = useMediaQuery('desktop');
  const confirmText = confirmLabel || formatMessage({ id: 'okay' });
  const cancelText = cancelLabel || formatMessage({ id: 'cancel' });
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Dialog
      data-testid={dataTestId}
      body={body}
      bodyComponent={bodyComponent}
      className={className}
      heading={heading}
      image={image}
      onDismiss={onDismiss}
      initialFocusRef={confirmButtonRef}
      actions={
        <>
          <Box width="100%" minWidth="auto">
            <ActionButton
              ref={confirmButtonRef}
              fullWidth
              onlyIcon
              onClick={onConfirm}
              data-testid="dialog-confirm-btn"
              skipLogEvent
            >
              {confirmText}
            </ActionButton>
          </Box>
          <Box margin={isDesktop ? '0 0 0 1rem' : '1rem 0 0'} width="100%" minWidth="auto">
            <ActionButton
              onlyIcon
              fullWidth
              onClick={onCancel}
              data-testid="dialog-cancel-btn"
              skipLogEvent
              variant={ActionButtonVariants.OUTLINE}
            >
              {cancelText}
            </ActionButton>
          </Box>
        </>
      }
      showCloseButton={showCloseButton}
      modalAppearanceEventMessage={modalAppearanceEventMessage}
    />
  );
};

export default ConfirmDialog;
