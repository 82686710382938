import styled from 'styled-components';

// Styles from `react-native-web`
// https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/View/index.js#L146-L164
export const View = styled.div`
  align-items: stretch;
  background-color: transparent;
  border: 0 solid black;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  min-height: 0;
  min-width: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  z-index: 0;
`;
