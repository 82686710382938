import { useCallback } from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

import { ISocialLoginInput, useSocialLoginMutation } from 'generated/graphql-gateway';
import { useLocale } from 'state/intl';

interface ISocialLoginMutateReturn {
  challengeCode: string | undefined;
  sessionId: string | undefined;
  email: Maybe<string> | undefined;
}

export interface ISocialLoginResult {
  socialLoginMutate: (socialLoginInput: ISocialLoginInput) => Promise<ISocialLoginMutateReturn>;
  isLoading: boolean;
}

export const useSocialLogin = (): ISocialLoginResult => {
  const [socialLoginMutation, { loading }] = useSocialLoginMutation();
  const { region } = useLocale();

  const socialLoginMutate = useCallback(
    async ({
      providerToken,
      providerType,
      providerEmail,
    }: ISocialLoginInput): Promise<ISocialLoginMutateReturn> => {
      const { data } = await socialLoginMutation({
        context: {
          headers: {
            region,
          },
        },
        variables: {
          userInfo: {
            providerToken,
            providerType,
            providerEmail,
          },
        },
      });

      const { challengeCode, sessionId, email } = data?.socialLogin || {};

      return { challengeCode, sessionId, email };
    },
    [region, socialLoginMutation]
  );

  return {
    isLoading: loading,
    socialLoginMutate,
  };
};
