import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';

export const Container = styled.button<{ color?: string }>`
  background-color: transparent;
  border: none;
  align-items: center;
  color: ${props => props.color};
  display: inline-flex;
  cursor: pointer;
  ${Styles.mobile} {
    &:focus {
      outline: none;
    }
  }
`;

export const Label = styled.span`
  font: ${brandFont.copyTwo};
  margin-inline-end: 0.625rem;
  text-align: end;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 3.875rem;
  white-space: nowrap;
`;
