import React from 'react';

import { createGlobalStyle } from 'styled-components';

const BRANCH_BANNER_HEIGHT = '76px';

// change the body height when branch download app banner is visible
const BranchBannerCustomStyle = createGlobalStyle`
  body.branch-banner-is-active {
    height: calc(100vh - ${BRANCH_BANNER_HEIGHT});
  }
`;

export const BranchJourneysBannerCssOverride = () => <BranchBannerCustomStyle />;
