import * as React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconQRCodeScanner = props => (
  <svg data-dir="ltr" width={30} height={30} {...props}>
    <title>QR Code Scanner Icon</title>
    <path d="M11.25 7.25v4.5h-4.5v-4.5h4.5ZM13.5 5h-9v9h9V5Zm-2.25 14.25v4.5h-4.5v-4.5h4.5ZM13.5 17h-9v9h9v-9Zm9.75-9.75v4.5h-4.5v-4.5h4.5ZM25.5 5h-9v9h9V5Zm-9 12h2.25v2.25H16.5V17Zm2.25 2.25H21v2.25h-2.25v-2.25ZM21 17h2.25v2.25H21V17Zm-4.5 4.5h2.25v2.25H16.5V21.5Zm2.25 2.25H21V26h-2.25v-2.25ZM21 21.5h2.25v2.25H21V21.5Zm2.25-2.25h2.25v2.25h-2.25v-2.25Zm0 4.5h2.25V26h-2.25v-2.25ZM30 8h-3V3.5h-4.5v-3H30V8Zm0 22.5V23h-3v4.5h-4.5v3H30Zm-30 0h7.5v-3H3V23H0v7.5Zm0-30V8h3V3.5h4.5v-3H0Z" />
  </svg>
);
export default IconQRCodeScanner;
