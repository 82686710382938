import styled from 'styled-components';

import { Accordion, AccordionItem } from 'components/accordion';
import { AccordionActivatorButton, AccordionContentWrapper } from 'components/accordion/styled';
import { ListItem } from 'components/list/list';

export const Wrapper = styled.div`
  border: 1px solid ${Styles.color.grey.four};
  border-radius: ${Styles.borderRadius};
  background-color: ${Styles.color.cardBackground};

  ul {
    margin: 0;
  }
`;

export const InnerAccordionItem = styled.div`
  border-block-start: 1px solid ${Styles.color.grey.four};
`;

export const StyledAccordionItem = styled(AccordionItem)`
  ${AccordionContentWrapper} {
    margin: 0;
  }
`;

export const StyledAccordion = styled(Accordion)`
  ${ListItem} {
    border: none;
  }
  ${AccordionActivatorButton} {
    padding-block: 1rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1.5rem;
  }
`;
