import _ParseError from 'libphonenumber-js/es6/ParseError';
import {
  CountryCode,
  Metadata,
  NationalNumber,
  NumberFormat,
  PhoneNumber,
} from 'libphonenumber-js/types';

import logger from 'utils/logger';

// https://gitlab.com/catamphetamine/libphonenumber-js#customizing-metadata
import metadata from './metadata.custom.json';

// This is how we currently use these functions.
// To integrate other optional arguments see node_modules/libphonenumber-js/index.d.ts
export async function format(
  phone: NationalNumber,
  whichFormat: NumberFormat
): Promise<string | undefined> {
  try {
    const module = await import(
      /* webpackChunkName: "libphonenumber-js/es6/format" */ 'libphonenumber-js/es6/format'
    );
    const _format = module.default;
    return _format(phone, whichFormat, metadata as unknown as Metadata);
  } catch (e) {
    logger.error(e);
    return undefined;
  }
}

export async function parsePhoneNumber(text: string, country: CountryCode): Promise<PhoneNumber> {
  try {
    const module = await import(
      /* webpackChunkName: "libphonenumber-js/es6/parsePhoneNumber" */ 'libphonenumber-js/es6/parsePhoneNumber'
    );
    const _parsePhoneNumber = module.default;
    return _parsePhoneNumber(text, country, metadata as unknown as Metadata);
  } catch (e) {
    logger.error(e);
    throw e;
  }
}

export async function parsePhoneNumberFromString(text: string): Promise<PhoneNumber> {
  try {
    const module = await import(
      /* webpackChunkName: "libphonenumber-js/es6/parsePhoneNumberFromString" */ 'libphonenumber-js/es6/parsePhoneNumberFromString'
    );
    const _parsePhoneNumberFromString = module.default;
    return _parsePhoneNumberFromString(text, metadata as unknown as Metadata);
  } catch (e) {
    logger.error(e);
    throw e;
  }
}

export { _ParseError as ParseError };
