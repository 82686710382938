import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IButtonProps } from '@rbi-ctg/frontend';
import IconClose from 'components/icons/close';

import { NO_BACKGROUND } from './constants';

interface ICloseButtonProps extends IButtonProps {
  inverted?: boolean;
  noBackground?: boolean;
}

const StyledButton = styled.button<ICloseButtonProps>`
  align-items: center;
  appearance: none;
  border: 0;
  background: ${p => (p.inverted ? Styles.color.black : Styles.color.white)};
  ${({ noBackground }) => noBackground && `background: transparent;`}
  border-radius: 50%;
  color: ${p => (p.inverted ? Styles.color.white : Styles.color.black)};
  cursor: pointer;
  display: flex;
  font-size: 1.375rem;
  justify-content: center;
  padding: 0;
  transition: all 0.2s;
  transform: rotate(0deg);

  &:hover {
    transform: rotate(90deg);
  }
`;

const StyledIcon = styled(IconClose)`
  fill: currentColor;
  height: 2.25rem;
`;

const CloseButton = ({
  className,
  onClick,
  inverted = false,
  noBackground = NO_BACKGROUND,
}: ICloseButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledButton
      className={className}
      onClick={onClick}
      inverted={inverted}
      noBackground={noBackground}
      data-testid="close-button"
    >
      <VisuallyHidden>{formatMessage({ id: 'close' })}</VisuallyHidden>
      <StyledIcon />
    </StyledButton>
  );
};

export default CloseButton;
