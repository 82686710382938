import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';

export const defaultModalPadding = '2rem 0 2rem 1.5rem';

export const ModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  inset-inline-start: 0;
  position: fixed;
  inset-block-start: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Styles.zIndex.max - 200};
`;

export const ModalContentAgreements = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  height: auto;
  min-height: 40%;
  min-width: 80%;
  max-height: 90%;
  max-width: 90%;
  padding: ${defaultModalPadding};

  ${Styles.desktop} {
    height: auto;
    min-width: 40%;
    min-height: 30%;
    max-width: 60%;
    max-height: 70%;
  }
`;

export const ContentHeader = styled.div`
  width: 100%;
`;

export const TitleModal = styled.div`
  font: ${brandFont.headerOne};
  font-weight: bold;
  margin-block-end: 0.5rem;
`;

export const ContentDescription = styled.div`
  font: ${brandFont.copyOne};
  width: 100%;
  background: #fff;
`;

export const ContentCheckbox = styled.div`
  font: ${brandFont.copyOne};
  margin-block-start: 1rem;
  margin-block-end: 0;
  margin-inline: 0;
  width: 100%;
`;

export const ContentListLinks = styled.div`
  font: ${brandFont.copyOne};
  line-height: 1.85rem;
  display: block;
  width: auto;
  margin-block-start: 1rem;
  margin-block-end: 0;
  margin-inline-start: 1.75rem;
  margin-inline-end: 0;
`;

export const ContentLink = styled.div`
  font: ${brandFont.copyOne};
  line-height: 1.85rem;
  display: block;
  width: 100%;
  margin-block-start: 1rem;
  margin-block-end: 0;
  margin-inline-start: 1.75rem;
  margin-inline-end: 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const ModalButtons = styled.div`
  margin-block-start: 1rem;
  margin-block-end: 0;
  margin-inline: 0;
  width: 100%;

  button {
    margin-inline-start: 0 !important;
    margin-block-start: 0.5rem !important;
  }
`;

export const ModalWrapper = styled.div<{ hasOverflow?: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  overflow: ${({ hasOverflow }) => (hasOverflow ? 'auto' : 'hidden')};
`;

export const ModalContent = styled.div`
  flex: 1;
  overflow: auto;
  padding-inline-end: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  padding-inline-end: 1.5rem;
`;
