import { useGetRestaurantQuery } from 'generated/rbi-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import logger from 'utils/logger';
import { useGetRestaurantAvailabilityFn } from 'utils/restaurant';

interface IUseRestaurantPosConnectivityStatus {
  storeId: string | null;
  pollInterval?: number;
  skip?: boolean;
}

export const useRestaurantPosConnectivityStatus = ({
  storeId,
  pollInterval,
  skip = false,
}: IUseRestaurantPosConnectivityStatus) => {
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const checkAvailability = useGetRestaurantAvailabilityFn();

  const { data, loading, refetch } = useGetRestaurantQuery({
    fetchPolicy: 'cache-and-network',
    variables: { storeId },
    skip: !storeId || !enableOrdering || skip,
    // skip does not apply to polling calls
    // if storeId is cleared, then we set the interval to 0 which ends the polling
    pollInterval: storeId ? pollInterval : 0,
    onError: err =>
      logger.warn({ err, message: `Failed to retrieve restaurant availability - ${storeId}` }),
  });

  const rbiRestaurant = data?.restaurant;

  const isRestaurantPosOnline = checkAvailability(rbiRestaurant);

  return {
    isRestaurantPosOnline,
    loading,
    refetch,
  };
};
