import { IFee } from '@rbi-ctg/menu';

import { FEES, FEE_TYPE } from './constants';
import { IGetStandardizedName, TFeeKeyStandard } from './types';

export const getStandardizedName = ({ name }: IGetStandardizedName): TFeeKeyStandard =>
  FEES[name] ?? name;

export const getFeesByGroup = (fees: IFee[] | null) =>
  (fees ?? []).reduce((acc: Record<string, number>, val: IFee) => {
    const lowerCaseName = val.name.toLowerCase();
    if (!acc[lowerCaseName]) {
      acc[lowerCaseName] = 0;
    }

    acc[lowerCaseName] += val.totalCents;

    return acc;
  }, {});

export const filterFees = (fees?: IFee[]) =>
  fees?.filter(fee => (Object.keys(FEE_TYPE) as string[]).includes(fee.name)) ?? [];
