import { isBefore, subDays, subMonths } from 'date-fns';

import { COOKIE_VERSION } from 'utils/local-storage';

import {
  getLastAcceptedCookieTimestamp,
  getLastCookieVersion,
  getLastIgnoredCookieTimestamp,
} from './helpers';

export const isRecentCookieTimestamp = () => {
  const acceptedCookieTimestamp = getLastAcceptedCookieTimestamp();
  if (!acceptedCookieTimestamp) {
    return false;
  }
  return isBefore(subMonths(Date.now(), 6), acceptedCookieTimestamp);
};

export const hasIgnoredCookies = () => {
  const ignoredCookieTimestamp = getLastIgnoredCookieTimestamp();
  if (!ignoredCookieTimestamp) {
    return false;
  }
  return isBefore(subDays(Date.now(), 1), ignoredCookieTimestamp);
};

export const hasAcceptedCookies = () => {
  if (getLastCookieVersion() !== COOKIE_VERSION) {
    return false;
  }

  return isRecentCookieTimestamp();
};
