/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, createContext } from 'react';

import { noop } from 'lodash';

import { IBluecodeContext, defaultBluecode } from './types';

export const useBluecodeContext = () => {
  return {
    isBluecodeEnabled: false,
    isBluecodeInitialized: false,
    isBluecodeViewOpen: false,
    userHasBluecodeAccount: false,
    openBluecodeView: (deepLinkUrl?: string) => noop as any,
    requestResetBluecode: () => noop as any,
  };
};

export const BluecodeContext = createContext<IBluecodeContext>(defaultBluecode);

export const BluecodeProvider = ({ children }: { children: ReactNode }) => {
  return <BluecodeContext.Provider value={defaultBluecode}>{children}</BluecodeContext.Provider>;
};
