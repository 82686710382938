import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

export const useOrderHref = ({ forceStoreChange }: { forceStoreChange?: boolean } = {}) => {
  const { isStoreOpenAndAvailable } = useStoreContext();
  const enableStaticMenu = useFlag(LaunchDarklyFlag.ENABLE_STATIC_MENU);

  if (forceStoreChange) {
    return routes.storeLocator;
  }

  return isStoreOpenAndAvailable || enableStaticMenu ? routes.menu : routes.storeLocator;
};
