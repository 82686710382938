import { useEffect, useState } from 'react';

import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getConfigValue } from 'utils/environment';

export const useForterScriptTag = () => {
  const enableForterFraudTracking = useFlag(LaunchDarklyFlag.ENABLE_FORTER_FRAUD);
  const [scriptHasMounted, setScriptHasMounted] = useState(false);

  useEffect(() => {
    const siteId = getConfigValue('forter').siteId;

    if (enableForterFraudTracking && !scriptHasMounted) {
      document.addEventListener('ftr:tokenReady', (event: CustomEvent) => {
        const token = event.detail;
        withForterHeaders.setForterToken(token);
      });
      const script = document.createElement('script');
      script.id = siteId;
      script.src = '/forter.js';
      document.body.appendChild(script);
      setScriptHasMounted(true);
    }
  }, [enableForterFraudTracking, scriptHasMounted]);
};
