import styled from 'styled-components';

import { primitive } from 'styles/constants/primitives';

import { ReceiptDate as BaseReceiptDate } from './receipt-head-info.styled.default';

export * from './receipt-head-info.styled.default';

export const ReceiptDate = styled(BaseReceiptDate)`
  margin: ${primitive.$spacing2} 0;
`;
