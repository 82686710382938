import { Maybe } from 'graphql/jsutils/Maybe';

import {
  IImageFragment,
  IImagesFragment,
  IOffersPageMarketingTileFragment,
} from 'generated/sanity-graphql';

type MaybeLocaleString = Maybe<{ readonly locale: string | null }>;
type MaybeNumber = Maybe<number | null | undefined>;
export type NoNullFields<Ob> = { [K in keyof Ob]: NonNullable<Ob[K]> };

export const maybeLocaleString = (textNode: MaybeLocaleString) => textNode?.locale ?? null;

export const maybeLocaleRawString = (textNode: any) => textNode?.localeRaw ?? null;

export const maybeNumber = (number: MaybeNumber) => number ?? null;

export const maybeLocaleImage = (imageNode: Maybe<{ readonly locale: IImageFragment | null }>) =>
  imageNode?.locale ?? null;

export const maybeLocaleImages = (imageNode: Maybe<{ readonly locale: IImagesFragment | null }>) =>
  imageNode?.locale?.app ?? null;

export const maybeLinkAction = (
  linkActionNode: Maybe<{ readonly route: Maybe<string>; readonly actionText: MaybeLocaleString }>
) => ({
  actionText: linkActionNode?.actionText?.locale ?? null,
  route: linkActionNode?.route ?? null,
});

const maybeOffersPageMarketingTile = (
  offersPageMarketingTile: Maybe<IOffersPageMarketingTileFragment>
): IOffersPageMarketingTileFragment => ({
  customPosition: offersPageMarketingTile?.customPosition ?? null,
  displayPosition: offersPageMarketingTile?.displayPosition ?? null,
  marketingTileReference: offersPageMarketingTile?.marketingTileReference ?? null,
});
export const maybeOffersPageMarketingTiles = (
  offersMageMarketingTiles: Maybe<
    ReadonlyArray<
      Maybe<{ readonly __typename?: 'OffersPageMarketingTile' } & IOffersPageMarketingTileFragment>
    >
  >
) => offersMageMarketingTiles?.map(tile => maybeOffersPageMarketingTile(tile)) ?? [];
