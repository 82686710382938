import React, { FC } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { RotateIcon } from '@rbilabs/components-library/build/components/rotate-icon';
import { noop } from 'lodash';

import List, { ListItem, ListItemTitle } from 'components/list';

import { accordionItemTestIds } from './constants';
import {
  AccordionActivatorButton,
  AccordionContentWrapper,
  SpreadContentContainer,
} from './styled';
import { IAccordionItemProps, IAccordionProps, IconType } from './types';
import { useAccordionItem } from './use-accordion-item';

/**
 * Wraps a list of `AccordionItem`s
 *
 */
export const Accordion: FC<IAccordionProps> = ({ children }) => (
  <List data-testid="accordion">{children}</List>
);

const {
  accordionActivatorButtonTestId,
  accordionItemContentTestId,
  accordionItemTestId,
  accordionItemTitleTestId,
} = accordionItemTestIds;

/**
 * Accordion item shows expandable content when clicked.
 */
export const AccordionItem: FC<IAccordionItemProps> = ({
  contentWrapper: ContentWrapper = AccordionContentWrapper,
  activeHighlight,
  children,
  onInteract = noop,
  title,
  iconType,
  ...props
}) => {
  const { handleToggle, isActive } = useAccordionItem({ onInteract });

  let accordionIcon;
  const iconColor = isActive ? 'primary' : 'icon-default';
  switch (iconType) {
    case IconType.ARROW:
      accordionIcon = (
        <RotateIcon active={isActive} toDeg={90}>
          <Icon icon="next" color="icon-default" width="16px" aria-hidden />
        </RotateIcon>
      );
      break;
    default:
      accordionIcon = (
        <RotateIcon active={isActive} toDeg={45}>
          <Icon icon="add" color={iconColor} width="24px" aria-hidden />
        </RotateIcon>
      );
  }

  return (
    <ListItem
      {...props}
      activeHighlight={activeHighlight && isActive}
      data-testid={accordionItemTestId}
    >
      <AccordionActivatorButton
        onClick={() => handleToggle()}
        data-testid={accordionActivatorButtonTestId}
        type="button"
      >
        <SpreadContentContainer>
          <ListItemTitle
            data-testid={accordionItemTitleTestId}
            activeHighlight={activeHighlight && isActive}
          >
            {title}
          </ListItemTitle>
          {accordionIcon}
        </SpreadContentContainer>
      </AccordionActivatorButton>
      {isActive && (
        <ContentWrapper data-testid={accordionItemContentTestId}>{children}</ContentWrapper>
      )}
    </ListItem>
  );
};
