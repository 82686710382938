import { CardTypes } from 'state/payment/types';

import { ICheckNativePaymentDefaulting, INativePaymentDefaulted } from '../../types';

export const checkNativePayDefaulting = ({
  paymentMethodId,
  cardType,
  canUseNativePayment = false,
}: ICheckNativePaymentDefaulting): boolean => paymentMethodId === cardType && canUseNativePayment;

export const isNativePaymentDefaulted = ({
  paymentMethodId,
  canUseApplePay = false,
  canUseGooglePay = false,
}: INativePaymentDefaulted): boolean => {
  if (
    checkNativePayDefaulting({
      paymentMethodId,
      cardType: CardTypes.APPLE_PAY,
      canUseNativePayment: canUseApplePay,
    }) ||
    checkNativePayDefaulting({
      paymentMethodId,
      cardType: CardTypes.GOOGLE_PAY,
      canUseNativePayment: canUseGooglePay,
    })
  ) {
    return true;
  }

  return false;
};
