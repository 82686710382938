import { createGlobalStyle } from 'styled-components';

import baseGlobalStyles from './base';

const GlobalStyles = createGlobalStyle`
  /* TODO: Add global style reset file? */
  ${baseGlobalStyles}
`;

export default GlobalStyles;
