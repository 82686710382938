import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  margin-block-start: 0;
  margin-block-end: -0.5rem;
  margin-inline: 0;
  border-block-start: 1px solid ${Styles.color.background};
`;
