import { SupportedLanguages } from '@rbi-ctg/frontend';

export interface IFontConfig {
  baseFontSize: number;
}

export const fonts: Partial<Record<SupportedLanguages, IFontConfig>> &
  Record<'default', IFontConfig> = {
  default: {
    baseFontSize: 16,
  },
  ar: {
    baseFontSize: 18,
  },
};
