import { ILocation } from '@rbi-ctg/frontend';

export enum PermissionState {
  DENIED = 1,
  UNAVAILABLE = 2,
  TIMEOUT = 3,
}

export enum PromptState {
  DENIED = 'denied',
  PROMPT = 'prompt',
  GRANTED = 'granted',
}

/**
 * Each of these states encapsulate a permutation of three boolean values.

  GeolocationPermissionStates.GRANTED:
    isGranted: true,
    isKnown: true,
    pending: false
  GeolocationPermissionStates.DENIED:
    isGranted: false,
    isKnown: true,
    pending: false
  GeolocationPermissionStates.CHECKING:
    isGranted: true/false,
    isKnown: false,
    pending: true
  GeolocationPermissionStates.PROMPT:
    isGranted: false,
    isKnown: false,
    pending: false
 */
export enum GeolocationPermissionStates {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
  CHECKING = 'checking',
}

export interface IPlaceAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface IPlaceAddress {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  route?: string;
  state?: string;
  streetNumber?: string;
  zip?: string;
  country?: string;
  shouldSave?: boolean;
  alias?: string;
}

export interface ICoords {
  lat: () => number;
  lng: () => number;
}

export interface IPlaceData {
  coordinates: ILocation;
  address: IPlaceAddress;
}

export interface Coordinates {
  lat: number;
  lng: number;
}
