import { componentsLibraryPrimitives as primitive } from 'styles/constants/primitives';
import { brand } from 'utils/environment';

export const brandFont = {
  hero: 'var(--font-hero)',
  headerOne: 'var(--font-headerOne)',
  headerTwo: 'var(--font-headerTwo)',
  headerThree: 'var(--font-headerThree)',
  headerFour: primitive[brand()].$headerFour,
  copyOne: 'var(--font-copyOne)',
  copyTwo: 'var(--font-copyTwo)',
  formUtility: primitive[brand()].$formUtility,
};
