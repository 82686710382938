import { useMemo } from 'react';

import { CartServiceMode, PosVendor, usePluConfigToVendorConfigAdapter } from '@rbilabs/intl-menu';
import { cloneDeep } from 'lodash';

import { useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import { PosVendors } from 'utils/vendor-config';

import usePosVendor from './menu/use-pos-vendor';

export const useConvertPluConfigs = () => {
  const { store } = useStoreContext();
  const { vendor } = usePosVendor();
  const { serviceMode } = useServiceModeContext();
  const enablePluConfigs = useFlag(LaunchDarklyFlag.ENABLE_PLU_CONFIGS);

  const posIntegration = store?.integration?.partnerGroup?.posIntegration;

  const conversionMethods = useMemo(() => {
    const isPartner = vendor === PosVendors.PARTNER;

    if (!(vendor && serviceMode && enablePluConfigs)) {
      return { convertDeep: undefined, cloneAndConvertDeep: undefined };
    }

    if (isPartner && !posIntegration) {
      return { convertDeep: undefined, cloneAndConvertDeep: undefined };
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const convertDeep = usePluConfigToVendorConfigAdapter(
      {
        posIntegration,
        posVendor: vendor as unknown as PosVendor,
        serviceMode: serviceMode as unknown as CartServiceMode,
      },
      {
        omitVendorConfigsType: true,
      }
    ).convertDeep;

    const cloneAndConvertDeep = <T>(data: T): T => {
      if (!convertDeep) {
        return data;
      }
      const clonedData = cloneDeep(data);
      convertDeep?.(clonedData);
      return clonedData;
    };

    return { convertDeep, cloneAndConvertDeep };
  }, [posIntegration, vendor, serviceMode, enablePluConfigs]);

  return conversionMethods;
};
