import React from 'react';

import styled from 'styled-components';

import { ISocialIconsWithHeaderWidgetFragment } from 'generated/sanity-graphql';

import { SocialIcons } from './social-icons';
import { Header } from './styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type ISocialIconsWithHeaderProps = ISocialIconsWithHeaderWidgetFragment;

export const SocialIconsWithHeader: React.FC<ISocialIconsWithHeaderProps> = ({
  header,
  socialIconsWidget,
}) => {
  const socialIconsProps = {
    instagramUrl: socialIconsWidget?.instagramUrl?.locale,
    facebookUrl: socialIconsWidget?.facebookUrl?.locale,
    youtubeUrl: socialIconsWidget?.youtubeUrl?.locale,
    twitterUrl: socialIconsWidget?.twitterUrl?.locale,
    linkedinUrl: socialIconsWidget?.linkedinUrl?.locale,
    snapchatUrl: socialIconsWidget?.snapchatUrl?.locale,
  };

  return (
    <Container>
      <Header>{header?.locale}</Header>
      <SocialIcons {...socialIconsProps} />
    </Container>
  );
};
