import { checkFlagEnabled } from 'components/mobile-web-navigation-bar/utils-sanity';
import { INavigationLinkFragment } from 'generated/sanity-graphql';
import { LaunchDarklyFlagsObject } from 'utils/launchdarkly';

import { AlternateLDNavigationLink, IValidDesktopLink, IValidNavLink, NavLinks } from './types';

const buildNavLink = (data: IValidDesktopLink) => ({
  key: data._key,
  link: data.navLink?.link?.locale,
  text: data.navLink?.text?.locale,
});

const isValidLink = (
  link: INavigationLinkFragment | AlternateLDNavigationLink | null
): link is IValidDesktopLink => {
  const enabledIfAltTab = link?.__typename !== 'LaunchDarklyNavigationLink' || link?.enabled;
  return !!(link?.navLink?.link?.locale && link?.navLink?.text?.locale && enabledIfAltTab);
};

export const getNavLinks = (navLinks: NavLinks, flags: LaunchDarklyFlagsObject) =>
  navLinks.reduce<IValidNavLink[]>((acc, val) => {
    const hideNavLinkLdFlag = val?.hideLinkBasedOnLdFlag?.launchDarklyFlag;
    const shouldHideNavLink = checkFlagEnabled(flags, hideNavLinkLdFlag);

    if (isValidLink(val) && !shouldHideNavLink) {
      const altLdFlagNavLink = val?.launchDarklyAlternateNavigationLink;
      const LDFlagDependency = altLdFlagNavLink?.launchDarklyFlagDependency?.launchDarklyFlag;
      const shouldShowAlternateLdNavLink = checkFlagEnabled(flags, LDFlagDependency);

      acc.push(
        buildNavLink(
          shouldShowAlternateLdNavLink && isValidLink(altLdFlagNavLink) ? altLdFlagNavLink : val
        )
      );
    }
    return acc;
  }, []);
