import {
  getGuestCredentials,
  getGuestCredentialsFromUrlQuery,
} from 'state/auth-guest/hooks/use-guest-authentication';
import { IGuestCredentials } from 'state/auth-guest/types';

export interface IGuestSessionData {
  AccessToken: string;
}

export class GuestToken {
  payload: string;
  decodedPayload: { [key: string]: any };

  constructor({ token }: { token: string }) {
    this.payload = token;
    this.decode();
  }

  public getJwtToken(): string {
    return this.payload;
  }
  public getExpiration(): number {
    return this.decodedPayload.exp;
  }
  public getGuestId(): string {
    return this.decodedPayload.sub;
  }
  public getIssuedAt(): number {
    return this.decodedPayload.iat;
  }
  public isExpired(): boolean {
    return Date.now() > this.getExpiration() * 1000;
  }
  public getName(): string | null {
    return this.decodedPayload.name || null;
  }
  public getEmail(): string | null {
    return this.decodedPayload.email || null;
  }

  private decode() {
    const payload = this.payload.split('.')[1];
    this.decodedPayload = JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
  }
}

export class GuestSession {
  token: GuestToken;

  constructor(guest_credentials: IGuestCredentials) {
    this.token = new GuestToken({ token: guest_credentials.token });
  }

  public getIdToken(): GuestToken {
    return this.token;
  }

  public getGuestId(): string {
    return this.token.getGuestId();
  }

  public isValid(): boolean {
    return !this.token.isExpired();
  }
}

// return the current guest user session
export const getCurrentGuestSession = (): GuestSession | null => {
  const urlCredentials = getGuestCredentialsFromUrlQuery();
  const guestTokenPayload = urlCredentials || getGuestCredentials();
  if (!guestTokenPayload) {
    return null;
  }
  const guestSession = new GuestSession(guestTokenPayload);

  if (guestSession.isValid()) {
    return guestSession;
  }

  return null;
};
