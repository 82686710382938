// NOTE (gabceb): This file needs to stay as vanilla js file
// because is used by the sitemap generator that uses plain node
// to run the script until we find a way to get ts-node to work

import { IStore } from '@rbi-ctg/store';
import { IRestaurantNode } from 'generated/rbi-graphql';

export const slugify = (input: string) =>
  input
    .toLowerCase()
    .replace(/[*+~.,;()'"!:@]/g, '') // Remove unwanted characters
    .replace(/\s+/g, '-'); // replace spaces with -

export const getStoreSEOSlug = (store: IStore | IRestaurantNode) => {
  if (!store.physicalAddress || !store.physicalAddress.address1) {
    return `${store.number}`;
  }

  const address1 = store.physicalAddress.address1 || '';
  const descriptiveSlug = slugify(
    // The .replace('/', '-') is required to account for Carrols stores
    // (ex.Carrols / Xenial Miami POS Lab).  Otherwise browser can't parse route.
    `${address1.replace('/', '-')}--${store.physicalAddress.city}--${
      store.physicalAddress.stateProvince
    }--${store.physicalAddress.postalCode}`
  );

  return `${store.number}/${descriptiveSlug}`;
};
