import { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { IServerOrder } from '@rbi-ctg/menu';
import { IUserOrderFragment, RbiOrderStatus } from 'generated/rbi-graphql';
import { useUserOrders } from 'hooks/use-user-orders';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderTimedFire } from 'state/order/hooks/use-order-timed-fire';

import { QUEUE_BANNER_POLLING_FREQUENCY } from '../constants';
import { getOrderInProgress } from '../utils';

import { getOrderTrackingMessage, getOrderTrackingStatuses } from './utils';

interface ITrackOrder {
  orderInProgress: IUserOrderFragment | null;
  message: string;
}

export const useTrackOrder = (): ITrackOrder => {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useAuthContext();
  const [orderInProgress, setOrderInProgress] = useState<IUserOrderFragment | null>(null);

  const flags = {
    enableWaitTimeManagement: useFlag(LaunchDarklyFlag.ENABLE_WAIT_TIME_MANAGEMENT),
    enableAsyncPayment: useFlag(LaunchDarklyFlag.ENABLE_ASYNC_PAYMENT),
  };

  const { enableOrderTimedFire, isFireTimeElapsed } = useOrderTimedFire({ serverOrder: null });

  let allowedStatuses = getOrderTrackingStatuses({ flags });

  if (enableOrderTimedFire) {
    allowedStatuses = [...allowedStatuses, RbiOrderStatus.INSERT_SUCCESSFUL];
  }

  const { data } = useUserOrders({
    variables: {
      limit: 1,
      orderStatuses: [
        ...allowedStatuses,
        // Terminal statuses
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
      ],
    },
    pollInterval: QUEUE_BANNER_POLLING_FREQUENCY,
    skipQuery: !isAuthenticated(),
  });

  const lastOrder = data?.userOrders?.orders?.[0];

  useEffect(() => {
    const serverOrder =
      lastOrder?.createdAt && lastOrder?.fireOrderIn
        ? (lastOrder as unknown as IServerOrder)
        : null;

    if (enableOrderTimedFire && isFireTimeElapsed(serverOrder)) {
      return;
    }

    const dateNow = new Date();
    const order = getOrderInProgress({
      dateNow,
      order: lastOrder,
      allowedStatuses: [...allowedStatuses],
    });
    setOrderInProgress(order);
  }, [lastOrder, allowedStatuses, enableOrderTimedFire, isFireTimeElapsed]);

  return {
    orderInProgress,
    message: formatMessage({ id: getOrderTrackingMessage({ order: orderInProgress }) }),
  };
};
