import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IncrementorActions } from 'components/incrementor';
import { useCdpContext } from 'state/cdp';
import { ClickEventComponentNames, CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useOrderContext } from 'state/order';
import { MIN_CART_QUANTITY, getMenuObjectCartQuantity } from 'utils/cart';

import CartItemButton from '../cart-item-button';
import { useRedeemReward } from '../redeem-reward/use-redeem-reward/use-redeem-reward';
import { ICartItemQuantityProps } from '../types';

import Counter from './counter';
import theme from './theme';

const ChangeQuantityButton = styled(CartItemButton)`
  border: 2px solid ${p => (p.disabled ? theme.disabledBorderColor : theme.borderColor)};
  border-radius: 100%;
  width: 1.35rem;
  height: 1.35rem;
  margin: 0.5rem;
  padding: 0;

  background-color: ${Styles.color.cardBackground};
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;

  &[disabled] {
    color: ${Styles.color.grey.four};
    cursor: not-allowed;
  }
`;

const CartItemQuantityContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CartItemQuantity: React.FC<ICartItemQuantityProps> = ({
  item,
  updateQuantity,
  minCartQuantity = MIN_CART_QUANTITY,
}) => {
  const { formatMessage } = useIntl();
  const { hasSufficientBalance, incentiveNotInMenu, limitPerOrderMet } = useRedeemReward(item);
  const { cartEntries } = useOrderContext() ?? {};
  const { maxCartQuantityMet } = getMenuObjectCartQuantity({ menuObject: item, cartEntries });

  const disableForRewardRedemption =
    (incentiveNotInMenu && !hasSufficientBalance) || limitPerOrderMet;

  const isIncrementDisabled = item.isExtra || disableForRewardRedemption || maxCartQuantityMet;

  const cdp = useCdpContext();
  const handleDecrementClick = () => {
    cdp.trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: 'Checkout Product Decrementor',
      },
    });
    updateQuantity(item.quantity - 1, IncrementorActions.Decrement);
  };
  const handleIncrementClick = () => {
    cdp.trackEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: 'Checkout Product Incrementor',
      },
    });
    updateQuantity(item.quantity + 1, IncrementorActions.Increment);
  };

  return (
    <CartItemQuantityContainer>
      <VisuallyHidden>Adjust quantity of {item.name}</VisuallyHidden>

      <ChangeQuantityButton
        data-testid={`decrement-${item.name}`}
        aria-label={`Remove one ${item.name} from your cart`}
        onClick={handleDecrementClick}
        disabled={item.isExtra || item.quantity === minCartQuantity}
      >
        &ndash;
        <VisuallyHidden>
          {formatMessage({ id: 'removeOne' })} {item.name}
        </VisuallyHidden>
      </ChangeQuantityButton>
      <Counter>{item.quantity}</Counter>
      <ChangeQuantityButton
        data-testid={`increment-${item.name}`}
        aria-label={`Add one ${item.name} to your cart`}
        onClick={handleIncrementClick}
        disabled={isIncrementDisabled}
      >
        &#43;
        <VisuallyHidden>Add one {item.name} to your cart</VisuallyHidden>
      </ChangeQuantityButton>
    </CartItemQuantityContainer>
  );
};

export default CartItemQuantity;
