import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { Box } from '@rbilabs/components-library/build/components/layout';
import { Caption } from '@rbilabs/components-library/build/components/typography';

import { StoreCardSeperator } from './store-card-seperator';

interface StoreCardErrorProps {
  message: string;
}

export const StoreCardError: React.FC<StoreCardErrorProps> = ({ message }) => {
  return (
    <Box align="center">
      <Icon height="0.75rem" aria-hidden color="primary" icon="error" width="0.75rem" />
      <StoreCardSeperator />
      <Caption color="primary">{message}</Caption>
    </Box>
  );
};
