import styled from 'styled-components';

export const ChallengeContainer = styled.iframe`
  height: 100%;
  width: 100%;
`;

export const ChallengeForm = styled.form`
  display: none;
`;
