import {
  IFeatureAppHomePageQuery,
  IFeatureWebHomePageQuery,
  useFeatureAppHomePageQuery,
  useFeatureWebHomePageQuery,
} from 'generated/sanity-graphql';
import { isNative } from 'utils/environment';
import { isHuaweiWebview } from 'utils/is-huawei-webview';

export type IFeatureHomePageQuery = IFeatureAppHomePageQuery | IFeatureWebHomePageQuery;

export const useFeatureHomePageQuery =
  isNative || isHuaweiWebview() ? useFeatureAppHomePageQuery : useFeatureWebHomePageQuery;
