export interface IGroqError {
  query: string;
  description: string;
  start: number;
  end: number;
  type: string;
}

export default class GroqError extends Error implements IGroqError {
  readonly description: string;
  readonly end: number;
  readonly query: string;
  readonly type: string;
  readonly start: number;

  constructor({ description, end, query, start, type }: IGroqError) {
    super(`Error executing Sanity query: ${description}`);
    this.description = description;
    this.end = end;
    this.query = query;
    this.start = start;
    this.type = type;
  }
}
