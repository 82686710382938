import React, { FC, useCallback, useRef } from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { REDEMPTION_TITLE_TEST_ID } from 'components/offer-redemption-modal/constants/index';
import {
  ICartEntryDetailsOffer,
  ICartEntryDetailsReward,
  ICartEntryType,
} from 'state/loyalty/in-restaurant-redemption';
import { useLoyaltyContext } from 'state/loyalty/loyalty-context';
import { OfferType } from 'state/loyalty/types';
import { routes } from 'utils/routing';

import { BadgeAndIncrementor } from './badge-and-incrementor';
import {
  OfferItemDescription,
  OfferItemName,
  OfferItemPicture,
  RewardItemDescription,
  RewardItemName,
  RewardItemPicture,
} from './map-components';
import {
  InRestaurantRedemptionEntryContainer,
  InRestaurantRedemptionEntryContent,
  PictureAndDetails,
  RewardDetails,
  StyledIconX,
  ViewDetails,
  WrapperName,
} from './reward-redemption.styled';
import { IRewardRedemptionItemProps, ViewComponentMapping } from './types';

export const componentMapping: ViewComponentMapping = {
  [ICartEntryType.OFFER]: {
    ItemPicture: OfferItemPicture,
    ItemName: OfferItemName,
    ItemDescription: OfferItemDescription,
  },
  [ICartEntryType.REWARD]: {
    ItemPicture: RewardItemPicture,
    ItemName: RewardItemName,
    ItemDescription: RewardItemDescription,
  },
};

export const RewardRedemptionItem: FC<IRewardRedemptionItemProps> = ({
  cartEntry,
  handleUpdateEntry,
  handleRemoveEntry,
}) => {
  const containerRef = useRef<HTMLLIElement>(null);
  const { formatMessage } = useIntl();
  const { rewardLimitePerOrder } = useLoyaltyContext();

  const handleRemove = useCallback(() => {
    handleRemoveEntry(cartEntry);
  }, [cartEntry, handleRemoveEntry]);

  const { details, type } = cartEntry;
  const components = componentMapping[type];

  const { ItemPicture, ItemName } = components;
  const isReward = cartEntry.type === ICartEntryType.REWARD;
  const isSystemwideOffer =
    cartEntry.type === ICartEntryType.OFFER &&
    cartEntry.details.offer._type === OfferType.SYSTEMWIDE;
  const showBadgeAndIncrementor =
    (rewardLimitePerOrder && rewardLimitePerOrder > 1) || isSystemwideOffer;
  return (
    <InRestaurantRedemptionEntryContainer
      ref={containerRef}
      $elementHeight={containerRef.current?.offsetHeight || 0}
    >
      <InRestaurantRedemptionEntryContent>
        <PictureAndDetails>
          <ItemPicture entryCartDetails={details} objectFitContain />

          <RewardDetails>
            <WrapperName data-testid={REDEMPTION_TITLE_TEST_ID}>
              <ItemName entryCartDetails={details} />
            </WrapperName>
            <ViewDetails
              variant={ActionButtonVariants.TEXT_ONLY}
              size={ActionButtonSizes.SMALL}
              to={
                isReward
                  ? `${routes.rewardsList}/${
                      (cartEntry.details as ICartEntryDetailsReward).reward._id
                    }`
                  : `${routes.rewardsOffers}/${
                      (cartEntry.details as ICartEntryDetailsOffer).offer.loyaltyEngineId
                    }`
              }
              data-testid="loyalty-view-reward-details"
            >
              {formatMessage({ id: isReward ? 'viewRewardDetails' : 'viewOfferDetails' })}
            </ViewDetails>
            {showBadgeAndIncrementor && (
              <BadgeAndIncrementor cartEntry={cartEntry} handleUpdateEntry={handleUpdateEntry} />
            )}
          </RewardDetails>
        </PictureAndDetails>
        <StyledIconX data-testid="remove-item" onClick={handleRemove} />
      </InRestaurantRedemptionEntryContent>
    </InRestaurantRedemptionEntryContainer>
  );
};
