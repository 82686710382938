import styled, { css } from 'styled-components';

import { brandFont } from 'components/layout/brand-font';

import { ItemName as BaseItemName, ItemPrice as BaseItemPrice } from './cart-entry.styled.default';

export * from './cart-entry.styled.default';

const itemHeaderCss = css`
  font: ${brandFont.headerThree};
`;

export const ItemPrice = styled(BaseItemPrice)`
  display: flex;
  order: 999;
  width: fit-content;
  ${itemHeaderCss}
`;

export const ItemName = styled(BaseItemName)<{ row?: number }>`
  ${itemHeaderCss}
`;
