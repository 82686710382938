import { useMemo } from 'react';

import {
  IFeatureGeolocationModalQuery,
  useFeatureGeolocationModalQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import { NoNullFields, maybeLocaleString } from 'utils/graphql';

type IFeatureGeolocationModal = NoNullFields<IParsedGeolocationData>;

interface IUseFeatureGeolocationModalValue {
  featureGeolocationModalLoading: boolean;
  featureGeolocationModal: IFeatureGeolocationModal | null;
}

interface IParsedGeolocationData {
  header: string | null;
  body: string | null;
}

const isValidData = (data: IParsedGeolocationData): data is IFeatureGeolocationModal => {
  return Object.values(data).every(Boolean);
};

const parseData = (data: IFeatureGeolocationModalQuery['FeatureGeolocationModal'] | undefined) => {
  if (!data) {
    return null;
  }
  const parsedData = {
    header: maybeLocaleString(data.header),
    body: maybeLocaleString(data.body),
  };
  return isValidData(parsedData) ? parsedData : null;
};

export const useFeatureGeolocationModal = (): IUseFeatureGeolocationModalValue => {
  const { featureGeolocationModalId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureGeolocationModalQuery({
    variables: { featureGeolocationModalId },
    skip: !featureGeolocationModalId,
  });

  const featureGeolocationModalLoading = featureIdsLoading || loading;
  const featureGeolocationModal = useMemo(() => parseData(data?.FeatureGeolocationModal), [data]);

  return {
    featureGeolocationModal,
    featureGeolocationModalLoading,
  };
};
