import { isAfter, isValid } from 'date-fns';

import { ISanityBlockContent } from '@rbi-ctg/menu';
import { IRequiredAcceptanceAgreementInfoInput } from 'generated/graphql-gateway';
import { SwitchUpdateDateAcceptanceAgreement } from 'state/launchdarkly/variations';
import { isLocaleBlockTextWidget } from 'utils/sanity';
import {
  IAcceptanceAgreement,
  IRequiredUserAcceptance,
  IRequiredUserAcceptanceList,
} from 'utils/user-agreements/types';

const isAgreementMissing = (
  userAcceptedAgreements: IRequiredAcceptanceAgreementInfoInput[],
  sanityAcceptanceAgreement: IRequiredUserAcceptance
): boolean => {
  const hasAgreementToUpdate = userAcceptedAgreements.some(
    acceptanceAgreement => sanityAcceptanceAgreement._id === acceptanceAgreement.id
  );

  const hasSanityAgreementToAccept = !!sanityAcceptanceAgreement;

  return !hasAgreementToUpdate && hasSanityAgreementToAccept;
};

const isAgreementDateInvalid = (
  sanityAcceptanceAgreementDate: Date,
  acceptanceAgreementDate: Date
): boolean => {
  const isAgreementOutdated = isAfter(sanityAcceptanceAgreementDate, acceptanceAgreementDate);

  const hasNotAcceptedAgreement = !isValid(acceptanceAgreementDate);

  return hasNotAcceptedAgreement || isAgreementOutdated;
};

const isUserAgreementOutdated = (
  userAcceptedAgreements: IRequiredAcceptanceAgreementInfoInput[],
  sanityAcceptanceAgreement: IRequiredUserAcceptance,
  newUpdatedAtAcceptanceAgreement?: SwitchUpdateDateAcceptanceAgreement
): boolean =>
  userAcceptedAgreements.some(acceptanceAgreement => {
    const newUpdatedAtAcceptanceDate = newUpdatedAtAcceptanceAgreement?.find(
      ({ id }) => id === sanityAcceptanceAgreement._id
    );

    const updatedAt =
      newUpdatedAtAcceptanceDate?.updatedAt ?? sanityAcceptanceAgreement._updatedAt!;

    const sanityAcceptanceAgreementDate = new Date(updatedAt);

    const acceptanceAgreementDate = new Date(acceptanceAgreement.updatedAt);

    const didUserAcceptAgreement = sanityAcceptanceAgreement._id === acceptanceAgreement.id;

    return (
      didUserAcceptAgreement &&
      isAgreementDateInvalid(sanityAcceptanceAgreementDate, acceptanceAgreementDate)
    );
  });

export const requiresUserAgreement =
  (
    userAcceptedAgreements: IRequiredAcceptanceAgreementInfoInput[] | undefined,
    newUpdatedAtAcceptanceAgreement?: SwitchUpdateDateAcceptanceAgreement
  ) =>
  (sanityAcceptanceAgreement: IRequiredUserAcceptance): boolean => {
    if (!userAcceptedAgreements?.length) {
      return true;
    }

    return (
      isAgreementMissing(userAcceptedAgreements, sanityAcceptanceAgreement) ||
      isUserAgreementOutdated(
        userAcceptedAgreements,
        sanityAcceptanceAgreement,
        newUpdatedAtAcceptanceAgreement
      )
    );
  };

export const toAcceptanceAgreementInfoModel = (
  requiredUserAcceptance: IRequiredUserAcceptanceList
) =>
  requiredUserAcceptance.map(pages => ({
    id: pages._id,
    updatedAt: pages._updatedAt,
  }));

export const getHtmlContentFromWidgets = (
  agreementContent: IAcceptanceAgreement
): ISanityBlockContent[] | undefined => {
  const widgets = agreementContent.widgets || [];

  const localeBlockTextContent = widgets
    .map(widget => {
      if (isLocaleBlockTextWidget(widget) && widget.localeBlockTextContent?.localeRaw?.length) {
        return widget.localeBlockTextContent;
      }

      return { localeRaw: null };
    })
    .find(lbtc => lbtc.localeRaw);

  return localeBlockTextContent?.localeRaw;
};
