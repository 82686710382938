import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';

import { RedirectData } from 'state/payment/hooks/types';

const RedirectStyledForm = styled.form`
  display: none;
`;

const RedirectForm: React.FC<{
  data?: RedirectData;
}> = ({ data }) => {
  const redirectFormRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!data || !redirectFormRef) {
      return;
    }

    redirectFormRef.current?.submit();
  }, [data, redirectFormRef]);

  return data?.action ? (
    <RedirectStyledForm action={data.action?.url} method="post" ref={redirectFormRef}>
      {Object.keys(data.action?.data || []).map((key, index) => {
        return <input key={index} type="hidden" name={key} value={data?.action.data[key]} />;
      })}
    </RedirectStyledForm>
  ) : null;
};

export default RedirectForm;
