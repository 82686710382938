import { SupportedLanguages } from '@rbi-ctg/frontend';
import { IStaticPageRoute } from 'remote/queries/static-page';
import { getStaticPagesLocalizedRouteForPath, toRelativePath } from 'utils/routing';

export const getLocalizedRoute =
  (staticRoutes: IStaticPageRoute[], language: SupportedLanguages) =>
  (path: string): string => {
    const localizedStaticPagesPath = getStaticPagesLocalizedRouteForPath(
      path,
      staticRoutes,
      language
    );

    return localizedStaticPagesPath || toRelativePath(path);
  };
