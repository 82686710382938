import { differenceInYears, parse } from 'date-fns';
import delv from 'dlv';
import isEqual from 'lodash/isEqual';

import { IUpdateUserAttributesEventInput } from 'generated/graphql-gateway';
import { getName } from 'utils/attributes';
import * as Braze from 'utils/braze';
import { isNativeIOS } from 'utils/environment';
import { getNativeLocationPermissions } from 'utils/geolocation';

import { CustomEventNames, EventTypes } from '../constants';
import {
  ExtendedCdpAttributes,
  ICdpAttributes,
  ICdpCtx,
  ICdpUser,
  UpdatedUserAttributes,
} from '../types';
import {
  flattenCommunicationPreferences,
  getPushOptions,
  normalizeBooleans,
  sanitizeValues,
} from '../utils';

const getAge = ({
  age,
  dob,
  updatedAttributes,
}: {
  age?: number | null;
  dob?: string | null;
  updatedAttributes?: UpdatedUserAttributes;
}): number | undefined | null => {
  if (updatedAttributes?.dobDeleted === 'True') {
    return null;
  }
  if (dob) {
    return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
  }
  return age ?? null;
};

// safely merge existing and new attributes
export const mergeUserAttributes = (
  {
    $FirstName,
    $LastName,
    $Zip: zipcode,
    $Age: age,
    $Gender: gender,
    $City: city,
    $State: state,
    $Mobile: phoneNumber,
    Tier,
    'Legacy User': legacyUser,
    'Date of Birth': dob,
    ...rest
  }: ICdpAttributes = {},
  updatedAttributes: UpdatedUserAttributes = {},
  enablePushNotificiationsOnSignUp?: boolean
): Partial<ICdpAttributes> => {
  const flattenedCommunicationPreferences = flattenCommunicationPreferences(
    updatedAttributes.communicationPreferences
  );

  const isBrazeForwarderConfigured = window.mParticle
    ?._getActiveForwarders?.()
    ?.find(f => f.name.toLowerCase() === 'braze');

  const getDob = () => {
    if (isBrazeForwarderConfigured && updatedAttributes?.dobDeleted === 'True') {
      //Workaround for removing the DOB directly on Braze (context: ICU-44)
      //see braze-compat.ts l54 & l55 - verify if initialization was successful
      if (Braze?.initialize()) {
        Braze?.getUser?.()?.setDateOfBirth(null, null, null);
      }
      return null;
    }
    return dob !== updatedAttributes?.dob ? updatedAttributes?.dob : dob;
  };

  const getPhoneNumber = () => {
    if (isBrazeForwarderConfigured && !updatedAttributes?.phoneNumber) {
      //Workaround for removing the Phone Number directly on Braze (context: ICU-44)
      //see braze-compat.ts l54 & l55 - verify if initialization was successful
      if (Braze?.initialize()) {
        Braze?.getUser?.()?.setPhoneNumber(null);
      }
      return null;
    }
    return phoneNumber !== updatedAttributes?.phoneNumber
      ? updatedAttributes?.phoneNumber
      : phoneNumber;
  };

  const getTier = () => {
    const updatedTier = (updatedAttributes?.loyaltyUserTier ?? '').replace(/[^0-9]/g, '') || null;
    return Tier !== updatedTier ? updatedTier : Tier;
  };

  const pushOptions = getPushOptions(
    enablePushNotificiationsOnSignUp,
    updatedAttributes,
    updatedAttributes.promotionalEmails
  );

  const sanitizedValues = sanitizeValues({
    ...rest,
    ...getName(updatedAttributes, { $FirstName, $LastName }),
    ...flattenedCommunicationPreferences,
    $Zip: delv(updatedAttributes, 'zipcode', zipcode),
    $Gender: delv(updatedAttributes, 'gender', gender),
    $City: delv(updatedAttributes, 'city', city),
    $State: delv(updatedAttributes, 'state', state),
    'Location Services': delv(updatedAttributes, 'locationServices', ''),
    Locale: delv(updatedAttributes, 'locale', ''),
    Timezone: delv(updatedAttributes, 'timezone', ''),
    'Join Date': delv(updatedAttributes, 'joinDate', ''),
    'Legacy User': delv(updatedAttributes, 'Legacy User', legacyUser || ''),
    'RBI Cognito ID': delv(updatedAttributes, 'rbiCognitoId', ''),
    favoriteStores: delv(updatedAttributes, 'favoriteStores', ''),
    favoriteOffers: delv(updatedAttributes, 'favoriteOffers', ''),
    language: delv(updatedAttributes, 'language', ''),
    'Email Opt In': delv(updatedAttributes, 'promotionalEmails', ''),
    email_subscribe:
      delv(updatedAttributes, 'promotionalEmails', '')?.toLowerCase?.() === 'true'
        ? 'opted_in'
        : 'unsubscribed',
    marketingEmail: delv(updatedAttributes, 'promotionalEmails', ''),
    rewardsEmail: delv(updatedAttributes, 'promotionalEmails', ''),
    'Battery Level': delv(updatedAttributes, 'batteryLevel', ''),
    ...pushOptions,
  });

  let dobDateFormat = null;
  const dobStringFormat = getDob();

  if (dobStringFormat && dob) {
    // Braze requires dates in Date type followed by the pattern MM-dd-YYYY
    dobDateFormat = new Date(dobStringFormat.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1'));
  }

  return {
    ...sanitizedValues,
    //If the user deletes his birthday or Phone Number from the app we need to send null as a value
    'Date of Birth': getDob(), // Custom attribute
    dob: dobDateFormat, // Braze's standard attribute
    $Age: getAge({ age, dob: getDob(), updatedAttributes }), // Cdp mapped attribute for dob
    $Mobile: getPhoneNumber(),
    Tier: getTier(),
  };
};

const mapApiUserAttributes = (
  attributes: ExtendedCdpAttributes
): IUpdateUserAttributesEventInput => {
  return {
    dob: attributes.dob?.toISOString(),
    dateOfBirth: attributes['Date of Birth'],
    age: attributes.$Age,
    mobile: attributes.$Mobile,
    tier: attributes.Tier,
    isGuestUser: attributes['Is Guest User'],
    promotionalEmails: attributes['Promotional Emails'],
    ...sanitizeValues({
      firstName: attributes.$FirstName,
      lastName: attributes.$LastName,
      zip: attributes.$Zip,
      gender: attributes.$Gender,
      city: attributes.$City,
      state: attributes.$State,
      legacyUser: attributes['Legacy User'],
      locationServices: attributes['Location Services'],
      locale: attributes.Locale,
      timezone: attributes.Timezone,
      joinDate: attributes['Join Date'],
      rbiCognitoId: attributes['RBI Cognito ID'],
      favoriteStores: attributes.favoriteStores,
      favoriteOffers: attributes.favoriteOffers,
      batteryLevel: attributes['Battery Level'],
      emailOptIn: attributes['Email Opt In'],
      marketingEmail: attributes.marketingEmail,
      rewardsEmail: attributes.rewardsEmail,
      marketingPush: attributes.marketingPush,
      rewardsPush: attributes.rewardsPush,
      mediaServicesPreferences: attributes.mediaServicesPreferences,
      orderStatus: attributes.orderStatus,
      paybackUserId: attributes.paybackUserId,
      emailSubscribe: attributes.email_subscribe,
      pushSubscribe: attributes.push_subscribe,
      utmSource: attributes['UTM Source'],
      utmMedium: attributes['UTM Medium'],
      utmCampaign: attributes['UTM Campaign'],
      utmTerm: attributes['UTM Term'],
      utmContent: attributes['UTM Content'],
      iosLocationPermissions: attributes['IOS Location Permissions'],
      androidLocationPermissions: attributes['Android Location Permissions'],
      smsSubscribe: attributes.sms_subscribe,
      serviceMode: attributes.serviceMode,
      pickupMode: attributes['Pickup Mode'],
      sourcePage: attributes['Source Page'],
      browserType: attributes.browserType,
      browserVersion: attributes.browserVersion,
      isMobileWeb: attributes.isMobileWeb,
      isSmallScreen: attributes.isSmallScreen,
      layer: attributes.layer,
      enableFlavorFlow: attributes.enableFlavorFlow,
      typePreference: attributes['Type Preference'],
      customerId: attributes.CustomerID,
      currentScreen: attributes.currentScreen,
      appBuild: attributes.appBuild,
      loyalty: attributes.loyalty,
      sizePreference: attributes['Size Preference'],
      timePreference: attributes['Time Preference'],
      snackPreference: attributes['Snack Preference'],
      language: attributes.language,
      currentBuild: attributes.currentBuild,
    }),
  };
};

const setUserAttributes = (
  user: ICdpUser,
  newAttributes: UpdatedUserAttributes,
  trackEvent: ICdpCtx['trackEvent'],
  sendUpdateUserAttributesEvent: (options: {
    variables: { input: IUpdateUserAttributesEventInput };
  }) => void,
  enablePushNotificiationsOnSignUp?: boolean
) => {
  const existingAttributes = user.getAllUserAttributes();
  const updated = mergeUserAttributes(
    existingAttributes,
    newAttributes,
    enablePushNotificiationsOnSignUp
  );

  if (!isEqual(existingAttributes, updated)) {
    const attributes = {
      'Is Guest User': newAttributes.isGuest,
      'Promotional Emails': newAttributes.promotionalEmails,
      ...updated,
    };
    sendUpdateUserAttributesEvent({
      variables: {
        input: mapApiUserAttributes(attributes as ExtendedCdpAttributes),
      },
    });
    trackEvent({
      name: CustomEventNames.UPDATE_USER_ATTRIBUTES,
      type: EventTypes.Other,
      attributes,
    });

    const normalizedUpdated = normalizeBooleans(updated);
    user.setUserAttributes(normalizedUpdated);
  }
};

export const getUtmAttributes = (params: URLSearchParams) => {
  return sanitizeValues({
    'UTM Source': params.get('utm_source') || '',
    'UTM Medium': params.get('utm_medium') || '',
    'UTM Campaign': params.get('utm_campaign') || '',
    'UTM Term': params.get('utm_term') || '',
    'UTM Content': params.get('utm_content') || '',
  });
};

export const setUserUtmAttributes = (user: ICdpUser | undefined, params: URLSearchParams) => {
  const utmAttrs = getUtmAttributes(params);
  if (user?.setUserAttributes) {
    user.setUserAttributes(utmAttrs);
  }
};

export const updateLocationPermissionStatus = async (user: ICdpUser | undefined) => {
  if (!user) {
    return;
  }
  const status = await getNativeLocationPermissions();
  if (!status) {
    return;
  }
  if (isNativeIOS()) {
    user.setUserAttributes({
      'IOS Location Permissions': status,
    });
  } else {
    user.setUserAttributes({
      'Android Location Permissions': status,
    });
  }
};

export default setUserAttributes;
