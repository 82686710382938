import styled from 'styled-components';

import IconCirclePlus from 'components/icons/circle-plus';

const PlusIcon = styled(IconCirclePlus)<{ disabled: boolean }>`
  width: 24px;
  display: inline-block;
  margin: auto;
  fill: transparent;
  border: 2px solid
    ${props => (props.disabled ? Styles.color.disabledBorder : Styles.color.primary)};
  border-radius: 100%;
  path:last-child {
    fill: ${props => (props.disabled ? Styles.color.disabledColor : Styles.color.primary)};
  }
`;

export default PlusIcon;
