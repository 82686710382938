import { ReactNode } from 'react';

export enum InRestaurantScreen {
  /* Displays the QR code */
  QR_CODE = 'QRCode',
  /* Displays the in-restaurant cart list when 1 or more items are added */
  CART_LIST = 'cartList',
  /* Displays the info on how to use the in-restaurant loyalty code */
  HOW_TO_USE = 'howToUse',
}

export type InRestaurantScreenOption = {
  title: string;
  component: NonNullable<ReactNode>;
};

export type InRestaurantScreenVariants = Record<InRestaurantScreen, InRestaurantScreenOption>;
