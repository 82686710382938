import { IntlShape } from 'react-intl';

interface IFormatListOfWords {
  formatMessage: IntlShape['formatMessage'];
  list: string[];
}

// TODO: Replace this function with https://formatjs.io/docs/react-intl/api/#formatlist when it has better support

/**
 * formatListOfWords: This function allows you to join list of words together in a safe way.
 * @param formatMessage intl format function used with listOfWordsLayout id.
 * @param words List of words to be concatenated.
 * @returns Enumaration string composed by words and its separators.
 */
export default function formatListOfWords({ formatMessage, list }: IFormatListOfWords): string {
  return list
    .map((word, index) =>
      formatMessage(
        { id: 'listOfWordsLayout' },
        {
          word,
          position: getWordPositionInList(index + 1, list.length),
        }
      )
    )
    .join('');
}

function getWordPositionInList(wordNumberPosition: number, wordsAmount: number): string {
  if (wordNumberPosition === wordsAmount && wordsAmount > 1) {
    return 'last';
  }

  if (wordNumberPosition === 1) {
    return 'first';
  }

  return 'inner';
}
