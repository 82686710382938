import { ICartEntry } from '@rbi-ctg/menu';
import { useIsEditCartEnabled } from 'hooks/use-is-edit-cart-enabled';
import { useFavoritesContext } from 'state/favorites';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { getOptionsFromParams } from 'utils/wizard';

export const useEditingCartEntry = (): ICartEntry => {
  const { location } = useLocationContext();
  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES);
  const { isOffer } = useMenuContext();
  const { isEdit, isFavorite } = getOptionsFromParams(location.search);
  const enableEditCart = useIsEditCartEnabled({ cartHasIncentive: isOffer });
  const { getCurrentCartEntry, cartIdEditing } = useOrderContext();
  const { currentCartEntry: currentFavoriteEntry, tempFavorite: currentFavorite } =
    useFavoritesContext();
  const isEditing =
    isFavorite && currentFavorite ? enableFavorites : enableEditCart && isEdit && cartIdEditing;
  const editingCartEntry = currentFavorite ? currentFavoriteEntry : getCurrentCartEntry(isOffer);
  return isEditing ? editingCartEntry : null;
};
