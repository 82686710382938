import React from 'react';

import styled from 'styled-components';

import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';

const Wrapper = styled.div`
  padding-block-start: 1rem;
  border-block-start: 1px solid black;
  font-size: 0.625rem;
`;

const Disclaimer = () => {
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { copyrightAndTrademark, reservedRights } = featureDisclaimers || {};

  return (
    <Wrapper data-testid="branding-disclaimer">
      <p>{copyrightAndTrademark?.locale}</p>
      <p>{reservedRights?.locale}</p>
    </Wrapper>
  );
};

export default Disclaimer;
