import type { AppInfo } from '@capacitor/app';
import { SyncResult } from '@capacitor/live-updates';
import { identity } from 'lodash';

import { appVersionCode } from 'utils/environment';

const resolve = () => Promise.resolve();

const alwaysFalse = () => false;

export const askPermissionOnCritialRoute = alwaysFalse;

export const setBuildId = identity<boolean>;

export const getBuildId = alwaysFalse;

export const setShouldReloadApp = identity<boolean>;

export const getShouldReloadApp = alwaysFalse;

export const getCurrentVersion = (): Promise<AppInfo & { appflowBuildId: string }> =>
  Promise.resolve({
    version: 'web',
    build: appVersionCode(),
    id: '',
    name: '',
    appflowBuildId: '',
  });

export const sync = (): Promise<SyncResult> =>
  Promise.resolve({
    activeApplicationPathChanged: false,
    liveUpdate: {
      appId: 'web',
      channel: 'none',
    },
    snapshot: null,
    source: 'download',
  });

export const reload = resolve;

export const forceUpdate = resolve;

export const backgroundUpdate = resolve;
