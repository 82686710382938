import { isCartEntryEqual } from './cart-entry';
import { ICart, ICartEntry } from './types';
import { removeElementAtIndexFromList, replaceElementAtIndex } from './utils';

type ICartEntryUpdates = Omit<ICartEntry, 'referenceId' | 'details' | 'type'>;

/**
 * Helper function to get the default predicate.
 */
const parsePredicateArg = (arg: ICartEntry | ((ce: ICartEntry) => boolean)) =>
  typeof arg === 'function' ? arg : (ce: ICartEntry) => isCartEntryEqual(ce, arg);

/**
 * Finds the cart entry.
 */
export function findCartEntry(cart: ICart, cartEntry: ICartEntry): ICartEntry | undefined;
export function findCartEntry(
  cart: ICart,
  predicate: (ce: ICartEntry) => boolean
): ICartEntry | undefined;
export function findCartEntry(
  cart: ICart,
  arg: ICartEntry | ((ce: ICartEntry) => boolean)
): ICartEntry | undefined {
  return cart.find(parsePredicateArg(arg));
}

export const existCartEntry = (
  cart: ICart,
  predicate: (ce: ICartEntry, idx: number, cart: ICart) => boolean
) => !!cart.find(predicate);

/**
 * Adds cart entries to the cart.
 */
export const addToCart = (cart: ICart, ...cartEntry: ICartEntry[]): ICart => cart.concat(cartEntry);

/**
 * Filter the cart entries if satisfy the predicate.
 */
export const filterCartEntries = (cart: ICart, predicate: (ce: ICartEntry) => boolean): ICart =>
  cart.filter(predicate);

/**
 * Removes the given cart entry from the cart.
 * If no cart entry if found returns the same list.
 */
export function removeCartEntry(cart: ICart, cartEntry: ICartEntry): ICart;
export function removeCartEntry(cart: ICart, predicate: (ce: ICartEntry) => boolean): ICart;
export function removeCartEntry(
  cart: ICart,
  arg: ICartEntry | ((ce: ICartEntry) => boolean)
): ICart {
  const entryIndex = cart.findIndex(parsePredicateArg(arg));

  return removeElementAtIndexFromList(cart, entryIndex);
}

/**
 * Update a cart entry based on a given cart entry to replace or a predicate function.
 */
export function updateCartEntry(
  cart: ICart,
  cartEntry: ICartEntry,
  cartEntryUpdates: ICartEntryUpdates
): ICart;
export function updateCartEntry(
  cart: ICart,
  predicate: (ce: ICartEntry) => boolean,
  cartEntryUpdates: ICartEntryUpdates
): ICart;
export function updateCartEntry(
  cart: ICart,
  arg: ICartEntry | ((ce: ICartEntry) => boolean),
  cartEntryUpdates: ICartEntryUpdates
) {
  const index = cart.findIndex(parsePredicateArg(arg));
  const foundedCartEntry = cart[index];

  // invalid index
  if (!foundedCartEntry) {
    return cart;
  }

  const updatedCartEntry = {
    ...foundedCartEntry,
    ...cartEntryUpdates,
  };

  return replaceElementAtIndex(cart, updatedCartEntry, index);
}
