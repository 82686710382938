import React from 'react';

import { useIntl } from 'react-intl';

import { useLocalizedPath } from 'hooks/use-localized-path';
import { useFeaturesContext } from 'state/features';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';

import { StyledLink } from './styled.default';

const PHONE_NUMBER_REGEX = /\+\d+[\d\s]+/;
const extractPhoneNumber = (message: string) => message.match(PHONE_NUMBER_REGEX)?.[0];

export const SupportLink: React.FC = () => {
  const { formatMessage } = useIntl();
  const { serviceMode } = useServiceModeContext();
  const { featureSupportDataId = '' } = useFeaturesContext();
  const enableCallSupportLink = useFlag(LaunchDarklyFlag.ENABLE_CALL_SUPPORT_LINK_FOR_DELIVERY);
  const showCallSupport = enableCallSupportLink && serviceMode === ServiceMode.DELIVERY;
  const callSupportLabel = formatMessage({ id: 'callSupport' });
  const phoneNumber = extractPhoneNumber(callSupportLabel);
  const supportRoute = featureSupportDataId === '' ? routes.contactUs : routes.support;

  // TODO: ICFE-674 - Implement useLocalizedNavigate Hook for All /support Route Navigations
  const localizedPath = useLocalizedPath(supportRoute);

  return showCallSupport ? (
    <StyledLink to={`tel:${phoneNumber}`}>{callSupportLabel}</StyledLink>
  ) : (
    <StyledLink to={localizedPath}>{formatMessage({ id: 'visitSupport' })}</StyledLink>
  );
};
