import ThemeBase from './theme-base';

export default {
  ...ThemeBase,
  delivery: {
    ...ThemeBase.delivery,
    icon: 'delivery',
  },
  catering: {
    delivery: {
      ...ThemeBase.catering.delivery,
      icon: 'delivery',
    },
    pickup: {
      ...ThemeBase.catering.pickup,
    },
  },
  badge: {
    color: Styles.color.tertiary,
  },
};
