import { PayloadAction } from '@reduxjs/toolkit';

import { ICartEntry } from '@rbi-ctg/menu';
import { createAppSlice } from 'state/global-state/utils';
import { replaceEntryArrayItem } from 'state/order/utils';

import { preloadedOfferCartEntry, preloadedOrder } from '../ordering.utils';

import { ICartState } from './cart.types';

const preloadedState: Partial<ICartState> = {
  cartEntries: preloadedOrder().cartEntries || [],
  offerEntry: preloadedOfferCartEntry() || null,
};

export const initialState: ICartState = {
  cartEntries: [],
  favoriteEntries: [],
  offerEntry: null,
  pendingEntry: null,
  ...preloadedState,
};

export const cartSlice = createAppSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart: state => {
      state.cartEntries = [];
    },
    editCartEntry: (state, { payload }: PayloadAction<{ cartId: string }>) => {
      const { cartId } = payload;
      const entryToEdit = state.cartEntries.find(entry => entry.cartId === cartId);

      if (entryToEdit) {
        state.pendingEntry = entryToEdit;
      }
    },
    // used to remove single and multiple entries
    removeCartEntries: (state, { payload }: PayloadAction<Set<string>>) => {
      state.cartEntries = state.cartEntries.filter(entry => !payload.has(entry.cartId));
    },
    replaceOfferEntry: state => {
      state.offerEntry = state.pendingEntry;
      state.pendingEntry = null;
    },
    replacePendingEntry: (state, { payload }: PayloadAction<ICartEntry>) => {
      state.pendingEntry = payload;
    },
    updateQuantity: (state, { payload }: PayloadAction<{ cartId: string; quantity: number }>) => {
      const { cartId, quantity } = payload;
      state.cartEntries = state.cartEntries.map(entry => {
        if (entry.cartId === cartId) {
          entry.quantity = quantity;
        }
        return entry;
      });
    },
    upsertCartEntry: state => {
      if (state.pendingEntry) {
        state.cartEntries = replaceEntryArrayItem(state.cartEntries, state.pendingEntry);
        state.pendingEntry = null;
      }
    },
    upsertFavoritesEntry: state => {
      if (state.pendingEntry) {
        state.favoriteEntries = replaceEntryArrayItem(state.favoriteEntries, state.pendingEntry);
        state.pendingEntry = null;
      }
    },
  },
});
