import React, { useMemo, useState } from 'react';

import { differenceInMinutes } from 'date-fns';
import { useIntl } from 'react-intl';

import { DeliveryStatus, RbiOrderStatus } from 'generated/rbi-graphql';
import { useUserOrders } from 'hooks/use-user-orders';
import { DetailsModal } from 'pages/account/account-orders/details-modal';
import { MODAL_TYPE } from 'pages/account/account-orders/types';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import DeliveryBanner from './delivery-banner';

interface IDeliveryBannerContainer {
  position?: 'absolute' | 'relative';
}

const DeliveryBannerContainer: React.FC<IDeliveryBannerContainer> = ({ position }) => {
  const { formatMessage } = useIntl();
  const { isAuthenticated } = useAuthContext();
  const deliveryBannerPolling = useFlag(LaunchDarklyFlag.DELIVERY_BANNER_POLLING);
  const [showDetails, setShowDetails] = useState(false);
  /**
   * If the delivery polling interval is falsy or undefined,
   * the banner will be disabled
   */
  const isDisabled = !deliveryBannerPolling;

  // reroute
  const { data } = useUserOrders({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.UPDATE_ERROR, // not a terminal status, the order could cotinue
      ],
    },
    pollInterval: isAuthenticated() ? deliveryBannerPolling * 60 * 1000 : 0,
    skipQuery: isDisabled,
  });

  const order = useMemo(() => {
    const deliveryOrder = data?.userOrders?.orders?.[0];
    const orderStatus = deliveryOrder?.delivery?.status;
    const failedOrderStatus = [
      DeliveryStatus.ORDER_CANCELLED,
      DeliveryStatus.ORDER_ABANDONED,
      DeliveryStatus.ORDER_DROPPED_OFF,
    ];

    if (!deliveryOrder || !orderStatus || failedOrderStatus.includes(orderStatus)) {
      return null;
    }

    const createdAtDate = new Date(deliveryOrder.createdAt);
    const now = new Date();
    const maxMinutesOrderCheck = 180; // 3 hours

    if (differenceInMinutes(now, createdAtDate) > maxMinutesOrderCheck) {
      return null;
    }

    return deliveryOrder;
  }, [data]);

  const message = useMemo(() => {
    if (!order || !order.delivery) {
      return null;
    }
    switch (order.delivery.status) {
      case DeliveryStatus.ORDER_CREATED:
      case DeliveryStatus.DRIVER_ASSIGNED:
      case DeliveryStatus.DRIVER_UNASSIGNED:
      case DeliveryStatus.DRIVER_STARTING:
      case DeliveryStatus.DRIVER_AT_STORE:
        return formatMessage({ id: 'deliveryPrepared' });
      case DeliveryStatus.ORDER_PICKED_UP:
      case DeliveryStatus.DRIVER_AT_CUSTOMER:
        return formatMessage({ id: 'deliveryDriveEnroute' });
      default:
        return formatMessage({ id: 'deliveryPlaced' });
    }
  }, [order, formatMessage]);

  if (isDisabled || !order || !message) {
    return null;
  }

  return (
    <>
      <DeliveryBanner message={message} onClick={() => setShowDetails(true)} position={position} />
      {showDetails && (
        <DetailsModal
          rbiOrderId={order.rbiOrderId}
          onModalDismiss={() => setShowDetails(false)}
          type={MODAL_TYPE.TRACK_ORDER}
        />
      )}
    </>
  );
};

export default DeliveryBannerContainer;
