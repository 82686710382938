import styled from 'styled-components';

export const LOGO_TITLE_KEY = 'bk_logo_title';

export const StyledLogo = styled.div`
  width: 38px;
  /** Safari/WkWebView doesn't display the image without the height */
  height: 41.64px;
  display: flex;

  ${Styles.desktopLarge} {
    height: 60px;
    width: 55px;
  }
`;
