/**
 * filterMap combines the filtering and mapping steps into one function
 * @param filter A filter function applied to each item of the input array
 * @param map A transform (map) function applied to each item of the input array that passes the filter
 * @returns A function that applies the filter and map to an input array
 */
export function filterMap<A, B extends A, C>(
  filter: (item: A) => boolean,
  map: (item: B) => C
): (items: ReadonlyArray<A>) => C[];
export function filterMap<A, B extends A, C>(
  filter: (item: A) => boolean,
  map: (item: B) => C
): (items: A[]) => C[];
export function filterMap<A, B>(
  filter: (item: A) => boolean,
  map: (item: A) => B
): (items: ReadonlyArray<A>) => B[];
export function filterMap<A, B>(
  filter: (item: A) => boolean,
  map: (item: A) => B
): (items: A[]) => B[];
export function filterMap<A, B>(filter: (item: A) => boolean, map: (item: A) => B) {
  return (items: A[]): B[] =>
    items.reduce<B[]>((partialArray, item) => {
      const passesFilter = filter(item);
      if (passesFilter) {
        const mappedItem = map(item);
        return [...partialArray, mappedItem];
      }
      return partialArray;
    }, []);
}
