import { TRouteOverrides } from './types';

export const routeOverrides: TRouteOverrides = {
  'en-GB': {
    '/support': '/get-in-touch',
  },
  'de-DE': {
    '/support': '/kontakt',
  },
};
