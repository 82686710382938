import { MParticleAdapter } from 'state/cdp/mParticle/mparticle-adapter';
import { getApiKey } from 'utils/environment';

export const MPARTICLE = 'mparticle';
export const BRAZE = 'braze';
export const BRANCH = 'branch';
export const LAUNCHDARKLY = 'launchdarkly';
export const COOKIEBOT_CONSENT_CONFIG = {
  marketing: [],
  necessary: [LAUNCHDARKLY],
  preferences: [],
  statistics: [MPARTICLE, BRAZE, BRANCH],
};

export type ConsentGroups = {
  marketing: boolean;
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  stamp: string;
};

export const COOKIEBOT_EVENTS = ['CookiebotOnAccept', 'CookiebotOnDecline'];
export const addEventListener = (callback: any) => {
  COOKIEBOT_EVENTS.forEach((event: string) =>
    window.addEventListener(event, () => {
      callback();
    })
  );
};

export const removeEventListeners = (callback: any) => {
  COOKIEBOT_EVENTS.forEach((event: string) =>
    window.removeEventListener(event, () => {
      callback();
    })
  );
};

export const isStatisticsConsent = (sdk: string, consent: ConsentGroups) => {
  return consent.statistics && COOKIEBOT_CONSENT_CONFIG.statistics.includes(sdk);
};

export const isBranchUnavailable = (): boolean => {
  return !getApiKey(BRANCH);
};

export const handleMparticleConsent = (consentGroups: ConsentGroups) => {
  if (isStatisticsConsent(MPARTICLE, consentGroups)) {
    MParticleAdapter.setOptOut(false);
  } else {
    MParticleAdapter.setOptOut(true);
  }
};

export const handleBranchConsent = (consentGroups: ConsentGroups, branchUnavailable: boolean) => {
  if (isStatisticsConsent(BRANCH, consentGroups) && !branchUnavailable) {
    window.branch.disableTracking(false);
  } else if (!branchUnavailable) {
    window.branch.disableTracking(true);
  }
};
