import React, { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { routes } from 'utils/routing';

import { useTrackOrder } from './hooks/use-track-order';
import { OrderTrackingBanner } from './order-tracking-banner';

export const OrderTrackingBannerContainer: FC = () => {
  const navigate = useNavigate();

  const { orderInProgress, message } = useTrackOrder();

  if (!orderInProgress) {
    return null;
  }

  return (
    <>
      <OrderTrackingBanner
        message={message}
        onClick={() => navigate(`${routes.orderConfirmation}/${orderInProgress.rbiOrderId}`)}
      />
    </>
  );
};
