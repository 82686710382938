import React from 'react';

import { IBackendCartEntries, ICartEntry } from '@rbi-ctg/menu';
import { useComposeDescription } from 'hooks/use-compose-description';
import { CartEntryType } from 'utils/cart';
import priceForCartEntry from 'utils/menu/price-for-cart-entry';

import {
  EntryDescriptionRow,
  Item,
  ItemHeader,
  ItemName,
  ItemPrice,
  StyledCurrency,
} from './cart-entry.styled';

interface ICartEntryProps {
  entry: IBackendCartEntries;
  index?: number;
}

const CartEntry = ({ entry }: ICartEntryProps) => {
  const quantity = entry.quantity || 1;
  const amount = priceForCartEntry(entry);

  const description = useComposeDescription(entry as unknown as ICartEntry, {
    includeModifiers: true,
    returnTypeArray: true,
    includeQuantity: false,
  });

  return (
    <Item>
      <ItemHeader>
        <ItemName>
          {quantity > 1 && `${quantity}x `}
          {entry.name}
        </ItemName>
        <ItemPrice>
          <StyledCurrency
            $zeroAmount={amount === 0}
            amount={entry.type === CartEntryType.offerCombo ? entry.price : amount}
          />
        </ItemPrice>
      </ItemHeader>

      {description.map((descriptionItem, index) => (
        <EntryDescriptionRow key={`descriptionItem-${index}`}>
          {descriptionItem}
          <br />
        </EntryDescriptionRow>
      ))}
    </Item>
  );
};

export default CartEntry;
