import type { PluginListenerHandle } from '@capacitor/core';
import type { ConnectionStatus, ConnectionStatusChangeListener } from '@capacitor/network';

declare var window: any;

export interface NetworkPlugin {
  /**
   * Query the current network status
   */
  getStatus(): Promise<ConnectionStatus>;

  /**
   * Listen for network status change events
   */
  addListener(
    eventName: 'networkStatusChange',
    listenerFunc: (status: ConnectionStatus) => void
  ): Promise<PluginListenerHandle>;
}

export class NetworkPluginWeb implements NetworkPlugin {
  getStatus(): Promise<ConnectionStatus> {
    return new Promise((resolve, reject) => {
      if (!window.navigator) {
        reject('Network info not available');
        return;
      }

      const connected = window.navigator.onLine;
      const connection =
        window.navigator.connection ||
        window.navigator.mozConnection ||
        window.navigator.webkitConnection;
      const connectionType = connection ? connection.type || connection.effectiveType : 'wifi';

      resolve({
        connected,
        connectionType: connected ? connectionType : 'none',
      });
    });
  }

  async addListener(
    eventName: 'networkStatusChange',
    listenerFunc: ConnectionStatusChangeListener
  ) {
    const getConnectionStatus = async () => {
      const networkStatus = await this.getStatus();
      listenerFunc(networkStatus);
    };
    window.addEventListener('online', getConnectionStatus);
    window.addEventListener('offline', getConnectionStatus);

    getConnectionStatus();

    return {
      remove: async () => {
        window.removeEventListener('online', getConnectionStatus);
        window.removeEventListener('offline', getConnectionStatus);
      },
    };
  }
}

const Network = new NetworkPluginWeb();

export { Network };
