import React, { FC } from 'react';

import { useFlag } from 'state/launchdarkly';
import { useOrderTimedFire } from 'state/order/hooks/use-order-timed-fire';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { OrderTrackingBannerContainer } from './order-tracking-banner-container';
import { OrderTrackingBannerModalContainer } from './order-tracking-banner-modal-container';

export const OrderTrackingBannerContainerMaybe: FC = () => {
  const orderTrackingFlags = {
    enableWaitTimeManagement: useFlag(LaunchDarklyFlag.ENABLE_WAIT_TIME_MANAGEMENT),
    enableAsyncPayment: useFlag(LaunchDarklyFlag.ENABLE_ASYNC_PAYMENT),
  };

  const { enableOrderTimedFire } = useOrderTimedFire({ serverOrder: null });

  const enableTrackingBanner = Object.keys(orderTrackingFlags).some(
    flag => orderTrackingFlags[flag]
  );

  if (enableOrderTimedFire) {
    return <OrderTrackingBannerModalContainer />;
  }

  return enableTrackingBanner ? <OrderTrackingBannerContainer /> : null;
};
