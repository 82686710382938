import { Loggable } from '@rbi-ctg/frontend';

/**
 * Retrieve the "message" property from attributes.
 *
 * Prioritize `error.message` over `message` when present.
 *
 * Returns original `message` property as `originalDeveloperMessage`
 */
export function getMessage<O extends Loggable>(attributes: {
  [key: string]: O | any;
}): { message: string; originalDeveloperMessage: string | undefined } {
  const errorMessage = attributes?.error?.message;
  const originalDeveloperMessage = attributes?.message;
  const message = errorMessage || originalDeveloperMessage || '';
  return { message, originalDeveloperMessage };
}
