import styled, { css } from 'styled-components';

import { ReceiptDetail as BaseReceiptDetail } from './receipt-details.styled.default';

export * from './receipt-details.styled.default';

export const ReceiptDetail = styled(BaseReceiptDetail)`
  ${p =>
    p.$total &&
    css`
      font-size: 1.25rem;
      font-family: ${Styles.fontFamily.brand};
    `}
`;
