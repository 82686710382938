import { useCallback, useMemo } from 'react';

import { useLocale } from 'state/intl';
import { usePageManagerContext } from 'state/static-page-manager';

import { getLocalizedRoute } from './utils';

export const useRoutes = () => {
  const { language } = useLocale();
  const { routes: staticRoutes, loadRoutes } = usePageManagerContext();

  const getLocalizedRouteForPath = useMemo(
    () => getLocalizedRoute(staticRoutes, language),
    [language, staticRoutes]
  );

  const doesPageExist = useCallback(
    (path: string): boolean => {
      loadRoutes();
      return staticRoutes.some(
        route =>
          route.path.current === path ||
          route.localePath?.[language]?.current === getLocalizedRouteForPath(path)
      );
    },
    [getLocalizedRouteForPath, language, loadRoutes, staticRoutes]
  );

  return {
    getLocalizedRouteForPath,
    doesPageExist,
  };
};
