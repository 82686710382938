import { IGetValidPaymentMethodId } from '../types';

import { isNativePaymentDefaulted } from './utils';

/**
 * This function check if the paymentMethodId is part of the paymentMethods array and return a valid one
 */
export const getValidPaymentMethodId = ({
  paymentMethodId,
  canUseGooglePay,
  canUseApplePay,
  paymentMethods,
  returnFirstValid,
}: IGetValidPaymentMethodId) => {
  // Check if the paymentId is part of the filtered list

  if (isNativePaymentDefaulted({ paymentMethodId, canUseApplePay, canUseGooglePay })) {
    return paymentMethodId;
  }

  let validPaymentMethod = paymentMethods.find(method => {
    const accountIdentifier = method.accountIdentifier ?? method.fdAccountId ?? '';
    return accountIdentifier === paymentMethodId;
  });

  if (!validPaymentMethod && returnFirstValid) {
    // if there is not a `returnFirstValid` then just return the first payment method in the list.
    // The list will already have prepaid cards filtered out
    validPaymentMethod = paymentMethods.length ? paymentMethods[0] : undefined;
  }

  const validPaymentMethodId =
    validPaymentMethod?.accountIdentifier ?? validPaymentMethod?.fdAccountId ?? '';

  // If it's a valid payment method, return it. Otherwise, ask the user to select a new default payment method
  return validPaymentMethodId;
};
