import { ICombo, IComboSlot, PickerSelection } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';

export const computeComboSlotCalories = (comboSlot: IComboSlot) => {
  return (comboSlot.options || []).reduce((sum, comboSlotOption) => {
    const comboSlotOptionCalories = comboSlotOption.option?.nutritionWithModifiers?.calories || 0;
    const comboSlotOptionQuantity = comboSlotOption.defaultAmount ?? comboSlotOption.minAmount ?? 0;
    return sum + comboSlotOptionCalories * comboSlotOptionQuantity;
  }, 0);
};

const computeComboCalories = (combo: ICombo) => {
  let totalCalories = 0;

  (combo.options || []).forEach(comboSlot => {
    totalCalories += computeComboSlotCalories(comboSlot);
  });

  if (combo.mainItem) {
    totalCalories += combo.mainItem.nutritionWithModifiers?.calories || 0;
  }

  return totalCalories;
};

export const computeProductCalories = (selectedProduct: PickerSelection) => {
  if (selectedProduct._type === MenuObjectTypes.COMBO) {
    return computeComboCalories(selectedProduct);
  } else {
    return selectedProduct.nutritionWithModifiers?.calories || 0;
  }
};
