import React, { RefObject, useRef } from 'react';

import { useOnClickOutside } from 'hooks/use-on-click-outside';

import { DropdownContainer } from './styled';

interface DropdownContentProps extends React.PropsWithChildren {
  id: string;
  isOpen: boolean;
  onDismiss: VoidFunction;
  triggerRef: RefObject<HTMLDivElement>;
  insetInlineStart: number;
}

export const DropdownContent: React.FC<DropdownContentProps> = ({
  onDismiss,
  triggerRef,
  id,
  isOpen,
  insetInlineStart,
  children,
}) => {
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, onDismiss, triggerRef);

  return (
    <DropdownContainer
      aria-expanded={isOpen}
      id={id}
      ref={dropdownRef}
      $isOpen={isOpen}
      $insetInlineStart={insetInlineStart}
    >
      {children}
    </DropdownContainer>
  );
};
