import { Language, getDefaultLanguage, getLanguages } from '@rbilabs/intl';

import { FALLBACK_LANGUAGE } from 'utils/constants';
import { brand, getCountry } from 'utils/environment';

const LOCALE_SEPARATOR = '-';
const LOCALE_LANGUAGE_CODE_RE = new RegExp(`^([a-zA-Z]{2})(${LOCALE_SEPARATOR}[a-zA-Z]{2})?$`);

const NATIVE_LANGUAGE_NAMES: Record<Language, string> = {
  ar: 'العربية',
  ca: 'Català',
  cs: 'Čeština',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  eu: 'Euskara',
  fr: 'Français',
  gl: 'Galego',
  it: 'Italiano',
  ko: '한국어',
  ms: 'Melayu',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Română',
  ta: 'தமிழ்',
  tl: 'ᜆᜄᜎᜓᜄ᜔',
  th: 'ไทย',
  uk: 'українська',
  zh: '中文 (Zhōngwén), 汉语, 漢語',
};

/**
 * Gets a language's native name.
 *
 * @param code ISO-639-2 language code
 * @returns string
 */
export const getNativeLanguageName = (code: Language): string => {
  return NATIVE_LANGUAGE_NAMES[code] || code;
};

/**
 * Retrieves the default market language.
 *
 * @returns Language
 */
export const getDefaultMarketLanguage = (): Language => {
  return getDefaultLanguage(brand(), getCountry().toUpperCase()) || FALLBACK_LANGUAGE;
};

export const getSupportedMarketLanguages = (): Language[] => {
  return getLanguages(brand(), getCountry().toUpperCase()) || [FALLBACK_LANGUAGE];
};

/**
 * Determines whether a language is supported by the active market or not.
 *
 * NOTE: this method exclusively relies on market data provided by
 *  @rbilabs/intl, and it ignores any LD flags that may play a role in the app
 *  behavior (i.e. `disableLocalization`).
 *
 * @param code language code
 * @returns boolean
 */
export const isSupportedMarketLanguage = (code: Language): boolean => {
  const marketLanguages = getSupportedMarketLanguages();
  return marketLanguages.includes(code);
};

/**
 * Given a language, it returns a valid, supported market language.
 *
 * When the passed language is supported, it's returned as-is; otherwise, the
 * market default language is returned.
 *
 * NOTE: at the time of writing (2024.04), whether a market supports a language
 *  or not is additionally governed by the `disableLocalization` LD flag, and
 *  that's the reason the function depends on an additional `useDefault`
 *  parameter.
 *  Ideally, in the future we can get rid of the flag and solely rely on the list
 *  of market languages in order to determine a language's validity within a
 *  market.
 *
 * @param code language code
 * @param useDefault whether to short-circuit the logic and use the default
 *  language directly.
 * @returns
 */
export const getSupportedMarketLanguage = (code: Language, useDefault = false): Language => {
  const defaultLanguage = getDefaultMarketLanguage();
  if (useDefault) {
    return defaultLanguage;
  }

  return isSupportedMarketLanguage(code) ? code : defaultLanguage;
};

/**
 * Attempts to extract an ISO-639-2 language code from a locale-like string.
 *
 * Refs. https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
 * @param maybeLocale a string in a locale-like format, e.g. "ab-CD"
 * @returns language code
 */
export const extractLanguageCode = (maybeLocale: string): string =>
  LOCALE_LANGUAGE_CODE_RE.exec(maybeLocale)?.[1] || '';
