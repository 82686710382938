import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { hasAcceptedCookies, isRecentCookieTimestamp } from 'utils/cookies';

export const useHasAcceptedCookies = () => {
  const isCookieVersioningEnabled = useFlag(LaunchDarklyFlag.ENABLE_COOKIE_VERSIONING);
  if (isCookieVersioningEnabled) {
    return hasAcceptedCookies();
  } else {
    return isRecentCookieTimestamp();
  }
};
