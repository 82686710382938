import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import { useEffectOnUrlChange } from 'hooks/use-effect-on-url-change';
import useMediaQuery from 'hooks/use-media-query';

type IFooterComponent = null | (() => JSX.Element);
type IFooterComponentInterface<P> = [ComponentType<P>, P] | [];

export interface IMobileFooterContext {
  FooterComponent: IFooterComponent;
  __setFooterComponent: Dispatch<SetStateAction<IFooterComponentInterface<any>>>;
}

export const MobileFooterContext = createContext<IMobileFooterContext>({
  FooterComponent: null,
  __setFooterComponent: () => null,
});

export function portalToFooter<Props>(Component: ComponentType<Props>) {
  return memo<ComponentType<Props>>(function PortalToFooter(props: Props) {
    const { __setFooterComponent } = useContext(MobileFooterContext);

    useEffect(() => {
      __setFooterComponent(() => [Component, props]);
    }, [props, __setFooterComponent]);

    useEffectOnUnmount(() => {
      __setFooterComponent([]);
    });

    return null;
  });
}

export const MobileFooterProvider = ({ children }: IBaseProps) => {
  const [FooterComponentInterface, __setFooterComponent] = useState<IFooterComponentInterface<any>>(
    []
  );

  const isMobile = useMediaQuery('headerMobile');

  const FooterComponent = useMemo(
    () =>
      FooterComponentInterface.length === 0
        ? null
        : (function FooterComponentContainer() {
            const [Component, props] = FooterComponentInterface;

            if (Component) {
              return <Component {...props} />;
            }

            return null;
          } as IFooterComponent),
    [FooterComponentInterface]
  );

  useEffectOnUrlChange(() => {
    __setFooterComponent([]);
  });

  useEffect(() => {
    if (!isMobile) {
      __setFooterComponent([]);
    }
  }, [isMobile]);

  return (
    <MobileFooterContext.Provider
      value={{
        FooterComponent,
        __setFooterComponent,
      }}
    >
      {children}
    </MobileFooterContext.Provider>
  );
};

export const useMobileFooterComponent = () => useContext(MobileFooterContext).FooterComponent;
