import React, { FC, useEffect } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useStoreContext } from 'state/store';

import { BottomServiceModeView } from './bottom-service-mode.view';
import { useBottomServiceModeDetails } from './hooks/use-bottom-service-details';
import { useRoutesDisplayBottomService } from './hooks/use-routes-display-bottom-service';
import { IBottomServiceModeProps } from './types';

const StickyBottomServiceMode = styled(BottomServiceModeView)`
  z-index: ${Styles.zIndex.overlay + 100};
  width: 100%;
`;

/**
 *
 * BottomServiceMode purpose
 *
 */
const BottomServiceMode: FC<IBottomServiceModeProps> = () => {
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableLoyaltyFloatingCartButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_FLOATING_CART_BUTTON
  );
  const bottomServiceModeDetails = useBottomServiceModeDetails();
  const { inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();
  const { CancellationModal, ConfirmationModal } = bottomServiceModeDetails;
  const showBottomServiceOnRoutes = useRoutesDisplayBottomService();
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const { resetStore } = useStoreContext();

  const { formatMessage } = useIntl();

  const shouldDisplay =
    enableBottomServiceMode &&
    showBottomServiceOnRoutes &&
    !(enableLoyaltyFloatingCartButton && inRestaurantRedemptionCart?.length);

  useEffect(() => {
    if (!enableOrdering) {
      resetStore();
    }
  }, [enableOrdering, resetStore]);

  return shouldDisplay ? (
    <>
      <StickyBottomServiceMode {...bottomServiceModeDetails} />
      <CancellationModal />

      <ConfirmationModal
        heading={formatMessage({ id: 'bsmConfirmationModalHeading' })}
        body={formatMessage({ id: 'bsmConfirmationModalBody' })}
      />
    </>
  ) : null;
};

export default BottomServiceMode;
