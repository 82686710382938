export enum OTPAuthDeliveryMethod {
  None = 'None',
  Email = 'Email',
  SMS = 'SMS',
  All = 'All',
}

export function isOTPEmailEnabled(
  flagValue?: OTPAuthDeliveryMethod
): flagValue is OTPAuthDeliveryMethod.All | OTPAuthDeliveryMethod.Email {
  return flagValue === OTPAuthDeliveryMethod.Email || flagValue === OTPAuthDeliveryMethod.All;
}

export function isOTPSMSEnabled(
  flagValue?: OTPAuthDeliveryMethod
): flagValue is OTPAuthDeliveryMethod.All | OTPAuthDeliveryMethod.SMS {
  return flagValue === OTPAuthDeliveryMethod.SMS || flagValue === OTPAuthDeliveryMethod.All;
}

export function isOTPEnabled(
  flagValue?: OTPAuthDeliveryMethod
): flagValue is
  | OTPAuthDeliveryMethod.All
  | OTPAuthDeliveryMethod.Email
  | OTPAuthDeliveryMethod.SMS {
  return isOTPEmailEnabled(flagValue) || isOTPSMSEnabled(flagValue);
}
