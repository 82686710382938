import { itemIsAvailable, pluIsAvailable } from 'utils/availability';

import { PluTypes, computeCompositeModifierPlu, getVendorConfig } from '../vendor-config';

import { IItemOptionModifierPluIsAvailable } from './types';

export const itemOptionModifierPluIsAvailable = ({
  item,
  itemOptionModifier,
  vendor,
  prices,
}: IItemOptionModifierPluIsAvailable): boolean => {
  // If itemPlu-modifierPlu is available or modifierPlu is available the product is available
  const vendorConfig = getVendorConfig(itemOptionModifier, vendor);

  if (vendorConfig && vendorConfig.pluType === PluTypes.IGNORE) {
    return true;
  }

  const compositePlu = computeCompositeModifierPlu({
    item,
    modifier: itemOptionModifier,
    vendor,
  });

  return (
    pluIsAvailable(compositePlu, prices) || itemIsAvailable(itemOptionModifier, vendor, prices)
  );
};
