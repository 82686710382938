import delv from 'dlv';

import {
  IItemOption,
  IItemOptionModifier,
  IModifierMultiplier,
  IModifierSelection,
  IQuickConfig,
  ISanityItem,
  ISanityItemOption,
  ISanityItemOptionModifier,
  ISanityTextNode,
  ISanityVendorConfigs,
  IVendorConfig,
} from '@rbi-ctg/menu';

import { PosVendors } from '../vendor-config';

export enum ModifierComponentStyle {
  Stepper = 'stepper',
  Boolean = 'boolean',
  Selector = 'selector',
}

interface IChooseConfigArgs {
  modifierConfig?: IVendorConfig | null;
  modifierMultiplierConfig?: IVendorConfig | null;
  itemOptionModifierConfig?: IVendorConfig | null;
}

const configForModifier = ({
  modifierConfig,
  modifierMultiplierConfig,
  itemOptionModifierConfig,
}: IChooseConfigArgs): IVendorConfig | null => {
  return [
    itemOptionModifierConfig,
    modifierMultiplierConfig,
    modifierConfig,
  ].reduce<IVendorConfig | null>(
    (acc: IVendorConfig | null, vendorConfigObj?: IVendorConfig | null) => {
      if (acc) {
        return acc;
      }
      if (vendorConfigObj) {
        return vendorConfigObj;
      }
      return null;
    },
    null
  );
};

const getModifierMultiplierVendorConfigs = (
  vendorConfigs: ISanityVendorConfigs | undefined,
  modifierMultiplier: IModifierMultiplier
): ISanityVendorConfigs => {
  const modifierMultiplierConfigs: ISanityVendorConfigs =
    delv(modifierMultiplier, 'vendorConfigs') || {};
  const modifierConfigs: ISanityVendorConfigs =
    delv(modifierMultiplier, 'modifier.vendorConfigs') || {};

  return Object.values(PosVendors).reduce<ISanityVendorConfigs>(
    (acc: ISanityVendorConfigs, vendor: PosVendors) => ({
      ...acc,
      [vendor]: configForModifier({
        itemOptionModifierConfig: (vendorConfigs || {})[vendor],
        modifierMultiplierConfig: modifierMultiplierConfigs[vendor],
        modifierConfig: modifierConfigs[vendor],
      }),
    }),
    {}
  );
};

const composeName = (modifierMultiplier: IModifierMultiplier): ISanityTextNode => {
  return {
    locale: `${delv(modifierMultiplier, 'prefix.locale', '')} ${delv(
      modifierMultiplier,
      'modifier.name.locale',
      ''
    )}`,
  };
};

export function modifiersForItem(item: ISanityItem) {
  const itemModifiers = (item.options || []).reduce(
    (acc: object, itemOption: ISanityItemOption) => {
      return {
        ...acc,
        [itemOption._key]: {
          ...itemOption,
          options: [
            ...(itemOption.options || []).map((option: ISanityItemOptionModifier) =>
              makeItemOptionModifier(option)
            ),
          ],
        },
      };
    },
    {}
  );

  const options = Object.keys(itemModifiers).map((_key: string) => {
    return itemModifiers[_key];
  });

  const newItem = {
    ...item,
    options,
  };
  return newItem;
}

export function makeItemOptionModifier(option: ISanityItemOptionModifier) {
  const { _key, name, _type, type, modifierMultiplier, vendorConfigs } = option;
  const image = delv(modifierMultiplier, 'modifier.image', null);
  const imageDescription = delv(modifierMultiplier, 'modifier.imageDescription', null);

  return {
    _key,
    _type: _type || type,
    image,
    imageDescription,
    default: option.default,
    name: name?.locale ? name : composeName(modifierMultiplier),
    nutrition: option.nutrition || {},
    multiplier: delv(modifierMultiplier, 'multiplier', null),
    quantity: 1,
    modifierMultiplier,
    vendorConfigs: getModifierMultiplierVendorConfigs(vendorConfigs, modifierMultiplier),
  };
}

export interface IGroupedItemOption {
  title: string;
  options: ISanityItemOption[];
}

// @todo refactor
const findModifierSelectionInSelections = (
  modifier: ISanityItemOption | ISanityItemOptionModifier,
  modifierSelections: IModifierSelection[],
  comboSlotId?: string,
  itemOption?: ISanityItemOption
) => {
  return modifierSelections.find(
    ({ _key: itemOptionKey, comboSlotId: selectedComboSlotId, modifier: { _key: modifierKey } }) =>
      (modifier._key === modifierKey || modifier._key === itemOptionKey) &&
      (itemOption ? itemOption._key === itemOptionKey : true) &&
      comboSlotId === selectedComboSlotId
  );
};

export function modifierSelectedQuantity(
  option: ISanityItemOption | ISanityItemOptionModifier,
  modifierSelections: IModifierSelection[],
  comboSlotId?: string
) {
  const selection = findModifierSelectionInSelections(option, modifierSelections, comboSlotId);

  if (!selection) {
    return 0;
  }

  return selection.modifier.quantity;
}

export function isDefaultOrLess(
  itemOption: ISanityItemOption | IItemOption,
  modifier: IItemOptionModifier & { quantity?: number }
): boolean {
  if (modifier.default) {
    return true;
  }

  const defaultModifier = (itemOption as IItemOption).options.find(
    ({ default: isDefault }) => isDefault
  );
  const modMultiplier = delv(modifier, 'multiplier') || 0;
  const defaultModMultiplier = delv(defaultModifier!, 'multiplier') || 0;
  return modMultiplier <= defaultModMultiplier;
}

const isQuickConfigActive = (
  modifierSelections: Array<{ modifier: { _key: string } }>,
  quickConfigs?: IQuickConfig[]
) =>
  quickConfigs?.some(config =>
    config.rules.every(rule =>
      modifierSelections.some(selection => selection.modifier._key === rule.modifier.value)
    )
  );

export const isSelectionGovernedByConfigRules = (
  selection: { _key: string },
  modifierSelections: IModifierSelection[],
  quickConfigs?: IQuickConfig[]
) =>
  !isQuickConfigActive(modifierSelections, quickConfigs) &&
  quickConfigs?.some(({ rules }) =>
    rules.some(({ itemOptions }) => itemOptions.some(({ value }) => value === selection._key))
  );

export { getDefaultModifiersForMenuItem } from './get-default-modifiers';
export { onlyDefaultModifierIsAvailable } from './only-default-modifier-is-available';
