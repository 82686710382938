export enum RBIEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
}

export enum GraphQLEnv {
  DEV = 'dev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
  LOCAL = 'local',
}

export enum RBIPlatform {
  APP = 'app',
  KIOSK = 'kiosk',
  WEB = 'web',
}

// Only use for logging universal attributes in mParticle
export enum RBIExpandedPlatform {
  IOS = 'iOS',
  ANDROID = 'Android',
  WEB = 'Web',
}

export enum RBIBrand {
  BK = 'bk',
  PLK = 'plk',
  FHS = 'fhs',
}

export enum RBIFullBrandName {
  Popeyes = 'Popeyes Louisiana Kitchen',
  BurgerKing = 'Burger King',
  FirehouseSubs = 'Firehouse Subs',
}

export enum RBIAppUriScheme {
  Popeyes = 'popeyes://',
  BurgerKing = 'burgerking://',
  FirehouseSubs = 'fhs://',
}
