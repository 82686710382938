import React from 'react';

import { ILinkWidgetFragment, IVerticalLinkListWidgetFragment } from 'generated/sanity-graphql';

import { Links } from './links';
import { PaddedExternalLink, PaddedInternalLink, VerticalLinksContainer } from './styled';

type IVerticalLinkListProps = IVerticalLinkListWidgetFragment;

export const VerticalLinkList: React.FC<IVerticalLinkListProps> = props => {
  return (
    <VerticalLinksContainer>
      <Links
        links={props.linkWidgets as ILinkWidgetFragment[]}
        InternalLink={PaddedInternalLink}
        ExternalLink={PaddedExternalLink}
      />
    </VerticalLinksContainer>
  );
};
