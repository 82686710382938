import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import {
  BottomDrawer,
  useBottomDrawerUIState,
} from '@rbilabs/components-library/build/components/bottom-drawer';
import { noop } from 'lodash';

import { ClickableTextWithPencil, Label } from 'components/clickable-text-with-pencil';
import { RadioCheckList } from 'components/radio-check-list';
import { MenuObjectTypes } from 'enums/menu';
import useMediaQuery, { MediaQuery } from 'hooks/use-media-query';
import { useCdpContext } from 'state/cdp';
import { useMenuContext } from 'state/menu';
import { useProductWizardContext } from 'state/product-wizard';
import { useUIContext } from 'state/ui';

import { ModifierListItem } from './modifier-list-item';
import { OptionsContainer } from './modifier.styled';
import { IModifierTypeUIProps } from './types';
import {
  getModifierImage,
  getModifierNonNumericOptionLabel,
  getModifierOptionMultiplier,
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedOptionToSelections,
} from './utils';

export const ModifierNonNumeric: FC<IModifierTypeUIProps> = ({
  modifier,
  selectedItem,
  selections,
  onSelectionsChange,
}) => {
  const { formatCurrencyForLocale } = useUIContext();
  const { priceForItemOptionModifier } = useMenuContext();
  const { selectedProduct } = useProductWizardContext();
  const selectedOption = useMemo(() => getSingleSelectedOption(selections), [selections]);
  const image = useMemo(() => getModifierImage(modifier), [modifier]);
  const allOptionsHavePrefix = useMemo(
    () => modifier.options.every(opt => !!opt.modifierMultiplier?.prefix),
    [modifier.options]
  );

  const editModifierButtonRef = useRef<HTMLButtonElement>(null);
  const [isDesktopExpanded, setIsDesktopExpanded] = useState(false);
  const isMobile = useMediaQuery(MediaQuery.Mobile);
  const dialogState = useBottomDrawerUIState();

  const cdp = useCdpContext();
  const handleSelect = useCallback(
    (val: string) => {
      const newSelectedOption = modifier.options.find(opt => opt._key === val) || selectedOption;
      setIsDesktopExpanded(false);
      dialogState.hide();
      editModifierButtonRef.current?.focus();
      onSelectionsChange(transformSelectedOptionToSelections(newSelectedOption));
      logProductModifierCustomization(
        newSelectedOption.name?.locale ?? '',
        modifier.name.locale,
        val,
        cdp
      );
    },
    [dialogState, cdp, modifier.name.locale, modifier.options, onSelectionsChange, selectedOption]
  );
  const handleExpandOptionsClick = useCallback(() => {
    if (isMobile) {
      dialogState.show();
    } else {
      setIsDesktopExpanded(!isDesktopExpanded);
    }
  }, [dialogState, isDesktopExpanded, isMobile]);

  const selectedProductItem = useMemo(() => {
    if (selectedProduct._type === MenuObjectTypes.COMBO) {
      if (selectedProduct.mainItem && !selectedProduct.mainItem.isDummyItem) {
        return selectedProduct.mainItem;
      } else if (selectedItem) {
        return selectedItem;
      }
    }
    return selectedProduct;
  }, [selectedItem, selectedProduct]);

  const radioListOptions = useMemo(() => {
    return modifier.options.map(opt => {
      const label = getModifierNonNumericOptionLabel(opt, allOptionsHavePrefix);
      const upchargePrice = priceForItemOptionModifier({
        item: selectedProductItem,
        itemOption: modifier,
        modifier: opt,
      });
      return {
        label: label || '',
        labelSuffix: upchargePrice > 0 ? `+${formatCurrencyForLocale(upchargePrice)}` : '',
        value: opt._key,
      };
    });
  }, [
    allOptionsHavePrefix,
    formatCurrencyForLocale,
    modifier,
    priceForItemOptionModifier,
    selectedProductItem,
  ]);
  const optionsSection = (
    <OptionsContainer data-testid="modifier-options" hasImage={!!image}>
      <RadioCheckList
        options={radioListOptions}
        selectedValue={selectedOption._key}
        onChange={handleSelect}
      />
    </OptionsContainer>
  );

  const pencilIconLabel = allOptionsHavePrefix
    ? selectedOption.modifierMultiplier?.prefix?.locale
    : getModifierOptionMultiplier(selectedOption).toString();

  const isEditEnabled = modifier.options.length > 1;
  const listItemClickAction = isEditEnabled ? handleExpandOptionsClick : noop;

  return (
    <>
      <ModifierListItem
        isListItemClickable={isEditEnabled}
        content={modifier}
        selectedOption={selectedOption}
        onListItemClick={listItemClickAction}
        controls={
          isEditEnabled ? (
            <ClickableTextWithPencil
              ref={editModifierButtonRef}
              arialLabel={`Edit modifier`}
              label={pencilIconLabel || ''}
            />
          ) : (
            <Label style={{ marginRight: '30px' }}>{pencilIconLabel}</Label>
          )
        }
      ></ModifierListItem>
      {isMobile && (
        <BottomDrawer
          data-testid="modifier-bottom-drawer"
          dialogState={dialogState}
          header={modifier.name.locale}
          aria-label={modifier.name.locale}
        >
          {optionsSection}
        </BottomDrawer>
      )}
      {!isMobile && isDesktopExpanded && optionsSection}
    </>
  );
};
