import { offersSlice } from './offers/offers.slice';
import { rewardsSlice } from './rewards/rewards.slice';
import { userSlice } from './user/user.slice';

type LoyaltyActions = typeof offersSlice.actions &
  typeof userSlice.actions &
  typeof rewardsSlice.actions;

// Exporting all actions from slices in one file mimics exported variables from a context and encourages more explicit naming
// As an added benefit, we are able to see which actions come from which slice in a central location
const { setUser, setLoyaltyLoading } = userSlice.actions;

const {
  applyOffer,
  removeAppliedOfferByCartEntry,
  removeAppliedDiscountOffer,
  removeCmsOfferByLoyalty,
  resetAppliedOffers,
  resetOfferFeedbackMap,
  resetSurpriseAvailability,
  resetUpsizeAvailability,
  resetAllConfigOffers,
  setAppliedOffers,
  setCartEntriesIdsMap,
  setCmsOffers,
  setIncentivesIds,
  setOffers,
  setOffersFeedbackMap,
  setOffersLoading,
  setOfferRedemptionAvailableAfter,
  setPersonalizedOffers,
  setSelectedOffer,
  setSurpriseOfferIfAvailable,
  setUpsizeAvailable,
  setUserOffers,
  unshiftPersonalizedOffer,
} = offersSlice.actions;

const {
  applyReward,
  createAvailableLoyaltyRewardsMap,
  rehydrateAppliedReward,
  removeAppliedReward,
  removeAppliedRewards,
  resetLoyaltyRewardsState,
  setAvailableLoyaltyRewardsMap,
  setIsPricingRewardApplication,
  setShouldRefetchRewards,
  setShouldRefetchOffers,
  setStagedCartPoints,
  unApplyReward,
} = rewardsSlice.actions;

export const loyaltyActions: LoyaltyActions = {
  // Offers slice actions
  applyOffer,
  removeAppliedOfferByCartEntry,
  removeAppliedDiscountOffer,
  removeCmsOfferByLoyalty,
  resetAppliedOffers,
  resetOfferFeedbackMap,
  resetSurpriseAvailability,
  resetUpsizeAvailability,
  resetAllConfigOffers,
  setAppliedOffers,
  setCartEntriesIdsMap,
  setCmsOffers,
  setIncentivesIds,
  setOffers,
  setOffersFeedbackMap,
  setOffersLoading,
  setOfferRedemptionAvailableAfter,
  setPersonalizedOffers,
  setSelectedOffer,
  setSurpriseOfferIfAvailable,
  setUpsizeAvailable,
  setUserOffers,
  unshiftPersonalizedOffer,
  // Rewards slice actions
  applyReward,
  createAvailableLoyaltyRewardsMap,
  rehydrateAppliedReward,
  removeAppliedReward,
  removeAppliedRewards,
  resetLoyaltyRewardsState,
  setAvailableLoyaltyRewardsMap,
  setIsPricingRewardApplication,
  setShouldRefetchRewards,
  setShouldRefetchOffers,
  setStagedCartPoints,
  unApplyReward,
  // User slice actions
  setUser,
  setLoyaltyLoading,
};
