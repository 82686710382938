import { useAbTestFlagsQuery } from 'generated/rbi-graphql';
import logger from 'utils/logger';

export const useAbTestFlags = () => {
  const { data, error, loading } = useAbTestFlagsQuery();

  const flags = data?.abTestFlags;

  if (error) {
    logger.error({ error, message: 'Error in useAbTestFlags' });
  }

  return { error, loading, flags };
};
