import { ApolloError, isApolloError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';
import { camelCase } from 'lodash';
import { IntlShape } from 'react-intl';

import { NonNullableObject } from '@rbi-ctg/frontend';
import { messageIdMappingForDialogs } from 'pages/cart/payment/get-error-dialog-options';
import { CardTypes } from 'state/payment/types';
import { TLocalizationKey } from 'types/i18n';
import { filterMap } from 'utils/array';
import { GraphQLError } from 'utils/network';

import {
  GraphQLErrorCodes,
  IGraphqlErrorMap,
  IRbiError,
  IRbiErrorTranslation,
  RbiErrorTranslation,
  paymentErrorModalMaps,
} from './types';

/**
 * @deprecated
 *
 * Maps GQLError objects to a simplified error format containing the error code and message.
 *
 * This method should not be used if the format of extension.code is DOMAIN.XXX.XXX.
 * If error handling is needed, please use the mapRbiErrors method.
 *
 * @param {GraphQLFormattedError} err - The GraphQL error object to be mapped.
 * @returns {IGQLParsedErrorCode | undefined} - An object containing the error code and message,
 * or undefined if the error does not have a valid code.
 */
const mapErrorsWithCodes = filterMap<
  GraphQLFormattedError,
  NonNullableObject<GraphQLFormattedError>,
  IGQLParsedErrorCode
>(
  err => !!err.extensions?.code,
  err => ({ errorCode: err.extensions?.code as GraphQLErrorCodes, message: err.message })
);

/**
 * @deprecated
 *
 * Parses GraphQL error codes from a given error object.
 *
 * This method should not be used if the return type is IRbiError.
 * If error handling is needed, please use the parseGraphQLRbiError method.
 *
 * @param error - The GraphQL error object, which can be either a GraphQLError or an ApolloError.
 * @returns An array of parsed error codes.
 */
export function parseGraphQLErrorCodes(error: GraphQLError | ApolloError): IGQLParsedErrorCode[] {
  if (isApolloError(error)) {
    return mapErrorsWithCodes(error.graphQLErrors);
  }
  return mapErrorsWithCodes(error.originalError || []);
}

/**
 * Given a GraphQL error, return the first GraphQL error code.
 */
export function getGraphQLErrorCode(error: GraphQLError | ApolloError): GraphQLErrorCodes {
  return parseGraphQLErrorCodes(error)[0]?.errorCode;
}

/**
 * Given an array of graphql errors, do any errors have an extension with the given code
 *
 * @param graphQLErrors {GraphQLError[]} An array of Apollo GraphQL errors
 * @param code {GraphQLErrorCodes} The code
 */
export const graphQLErrorsIncludeExtensionCode = (
  graphQLErrors: ApolloError['graphQLErrors'] = [],
  code: GraphQLErrorCodes
) => graphQLErrors.some(graphQLError => graphQLError?.extensions?.code === code);

export interface IErrorDialog {
  title?: string;
  body?: string;
  link?: {
    key: TLocalizationKey;
    to: string;
    text: string;
  };
  message: string;
}

export interface IGQLParsedErrorCode {
  errorCode: GraphQLErrorCodes;
  message?: string;
}

/**
 * Given an error code from the CTG GQL api, return localized error dialog content.
 *
 * Returns `undefined` if we have no dialog defined for the given error code.
 * @param parsedError RBI CTG GraphQL error code
 * @param staticStrings Defined static strings
 */
export function getErrorDialogForGraphQLCode(
  parsedError: IGQLParsedErrorCode,
  formatMessage: IntlShape['formatMessage']
): IErrorDialog | undefined {
  const { errorCode, message } = parsedError;

  const paymentModalKey = paymentErrorModalMaps[errorCode];
  const errorDialog = messageIdMappingForDialogs[paymentModalKey];
  if (!errorDialog) {
    return;
  }
  const { title: titleId, body, message: messageId } = errorDialog;
  if (errorCode === GraphQLErrorCodes.GRAPHQL_VALIDATION_FAILED && message) {
    return {
      title: formatMessage({ id: titleId }),
      body: message,
      message,
    };
  }

  return {
    title: formatMessage({ id: titleId }),
    body: formatMessage({ id: body }),
    message: formatMessage({ id: messageId }),
  };
}

/**
 * Digital Wallet payment error modal key
 * Returns the key for the digital wallet payment error modal
 * @param fdAccountId
 * @returns
 */
export const getAppleOrGooglePayError = (fdAccountId: string): TLocalizationKey | undefined => {
  const messageMapping = {
    [CardTypes.APPLE_PAY]: 'orderFailureMessageForApplePay',
    [CardTypes.GOOGLE_PAY]: 'orderFailureMessageForGooglePay',
  };

  return messageMapping[fdAccountId];
};

/**
 * Creates a dictionary from an array of gql errors using code as the lookup index
 * If a code is not supplied the key will be `GQL_ERROR_$index`
 *
 * @param {GraphQLFormattedError[]} gqlErrors
 *
 * @returns {IGraphqlErrorMap}
 */
export const createGqlErrorMap = (gqlErrors: GraphQLFormattedError[]): IGraphqlErrorMap =>
  gqlErrors.reduce((acc, error, idx) => {
    const key = `${error.extensions?.code || 'GQL_ERROR'}_${idx}`;
    acc[key] = error;
    return acc;
  }, {});

/**
 * Checks if the first error in the GraphQL error list is related to a blocked user.
 *
 * @param {GraphQLFormattedError[]} gqlErrors GraphQL errors
 * @returns {boolean} Returns `true` if the first error code is `BLOCKED_USER_SIGN_IN_ERROR`, otherwise `false`.
 */
export const isBlockedUserSignInError = (gqlError: GraphQLFormattedError[]): boolean => {
  return gqlError?.[0]?.extensions?.code === GraphQLErrorCodes.BLOCKED_USER_SIGN_IN_ERROR;
};

/**
 * Extracts error codes from a given error code string, returning an array of hierarchical error codes.
 *
 * @param errorCode - The error code string to be split.
 * @returns An array of error codes representing the hierarchy.
 */
export const extractRbiErrorCodes = (errorCode: string): string[] => {
  const errorCodes = errorCode.split('.');
  return errorCodes.map((_, index) => errorCodes.slice(0, index + 1).join('.')).reverse();
};

/**
 * Checks if the provided error code is an RBI error.
 *
 * @param {IRbiError} error - The error code to be evaluated. Can be of any type.
 * @returns {boolean} - Returns true if the error code is considered an RBI error,
 * otherwise returns false.
 */
export const isRbiError = (error: IRbiError): boolean =>
  !!error?.rbiErrorCode && !!error?.rbiErrorDomain;

/**
 * Maps GraphQL errors to a specific RBI error format.
 *
 * This method should be used only if the error format is DOMAIN.XXX.XXX.
 * Otherwise, please use the mapErrorsWithCodes method.
 *
 * @param {GraphQLFormattedError} err - A GraphQL error object.
 * @returns {IRbiError | undefined} - Returns a formatted IRbiError object if all necessary fields are present; otherwise, returns undefined.
 */
export const mapRbiErrors = filterMap<
  GraphQLFormattedError,
  NonNullableObject<GraphQLFormattedError>,
  IRbiError
>(
  err =>
    !!err.extensions?.code &&
    !!err.extensions?.rbiErrorCode &&
    !!err.extensions?.rbiErrorDomain &&
    !!err?.message,
  err =>
    ({
      errorCode: err.extensions?.code,
      rbiErrorCode: err.extensions?.rbiErrorCode,
      rbiErrorDomain: err.extensions?.rbiErrorDomain,
      message: err.message,
    }) as IRbiError
);

/**
 * Parses a GraphQL or Apollo error, returning an array of IRbiError objects.
 * If the error is an ApolloError, it uses the graphQLErrors; otherwise, it falls back on originalError.
 *
 * This method should be used only if the return type is IRbiError.
 * Otherwise, please use the parseGraphQLErrorCodes method.
 *
 * The GraphQLError cases occur due to errors returned from src/utils/network/index.ts
 *
 * @param error - The GraphQL or Apollo error to parse.
 * @returns An array of IRbiError objects.
 */
export function parseGraphQLRbiError(error: GraphQLError | ApolloError): IRbiError[] {
  return isApolloError(error)
    ? mapRbiErrors(error.graphQLErrors)
    : mapRbiErrors(error.originalError || []);
}

/**
 * Retrieves the translation for an error based on the provided error code and translation mappings.
 * It attempts to find a specific translation for the given error code; if none exists, it falls back to a default translation.
 *
 * @param errorCode - Error code to translate.
 * @param errorTranslation - Optional specific translation key.
 * @param defaultTranslationId - Default translation identifier if no specific translation is found.
 * @param formatMessage - Function to format messages.
 * @returns The translated error message.
 */
export const getRbiErrorTranslation = ({
  errorCode,
  errorTranslation,
  defaultTranslationId,
  formatMessage,
}: IRbiErrorTranslation) => {
  const parsedErrorCodes = errorCode ? extractRbiErrorCodes(errorCode) : [];

  const foundTranslation = parsedErrorCodes.find(code => {
    const key = errorTranslation ? `error.${code}.${errorTranslation}` : `error.${code}`;
    return formatMessage({ id: key as TLocalizationKey }) !== key;
  });

  const getFormattedMessage = (translation: string) =>
    formatMessage({
      id: errorTranslation
        ? (`error.${translation}.${errorTranslation}` as TLocalizationKey)
        : (`error.${translation}` as TLocalizationKey),
    });

  return foundTranslation
    ? getFormattedMessage(foundTranslation)
    : formatMessage({ id: defaultTranslationId as TLocalizationKey });
};

/**
 * Fetches details for a given RBI error, including its translated title and body.
 *
 * @param {IRbiError} rbiError - The RBI error object to fetch details for.
 * @param {IntlShape['formatMessage']} formatMessage - The function used to format messages based on localization.
 *
 * @returns {Object} An object containing the title, body, message, and the original error.
 */
export const fetchRbiErrorDetails = ({
  rbiError,
  formatMessage,
}: {
  rbiError: IRbiError;
  formatMessage: IntlShape['formatMessage'];
}) => {
  const errorDomain = camelCase(rbiError.rbiErrorDomain);
  const errorCode = `${errorDomain}.${rbiError.rbiErrorCode}`;

  const body = getRbiErrorTranslation({
    errorCode,
    defaultTranslationId: `error.${errorDomain}.${RbiErrorTranslation.DEFAULT}`,
    formatMessage,
  });

  const title = getRbiErrorTranslation({
    errorCode,
    errorTranslation: RbiErrorTranslation.TITLE,
    defaultTranslationId: `error.${errorDomain}.${RbiErrorTranslation.TITLE}`,
    formatMessage,
  });

  const cta = getRbiErrorTranslation({
    errorCode,
    errorTranslation: RbiErrorTranslation.CTA,
    defaultTranslationId: `error.${errorDomain}.${RbiErrorTranslation.CTA}`,
    formatMessage,
  });

  return {
    title,
    body,
    cta,
    message: rbiError.message,
    error: rbiError,
  };
};

export * from './types';
