import { NavigateFunction } from 'react-router-dom';

import { isExternalLink } from 'utils/is-external-link';

import { appUrl } from './path';
import { routes } from './routes';

export function goToMenu({ navigate }: { navigate: NavigateFunction }) {
  navigate(routes.menu);
}

export function goToBrowseMenu({ navigate }: { navigate: NavigateFunction }) {
  navigate(routes.browseMenu);
}

export function editFavorite({
  navigate,
  favoriteId,
}: {
  navigate: NavigateFunction;
  favoriteId: string;
}) {
  navigate(`${routes.favorites}/${favoriteId}/edit`);
}

/**
 * Creates a function to redirect the client to a given target path.
 * Target paths are automatically made basepath-aware.
 * @modifies {window.location} causing a redirect
 */
export const createRedirectHandler =
  (basepath: string = '') =>
  (pathOrUrl: string, windowInstance: Window = window) => {
    const target = isExternalLink(pathOrUrl) ? pathOrUrl : appUrl(basepath)(pathOrUrl);
    windowInstance.location.assign(target);
  };
