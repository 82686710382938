import {
  selectCartEntries,
  selectFavoriteEntries,
  selectOfferEntry,
  selectPendingEntry,
  selectPendingEntryFromCart,
  selectPendingEntryFromFavorite,
  selectPendingEntryFromOffer,
} from './cart/cart.selectors';

export const orderingSelectors = {
  selectCartEntries,
  selectFavoriteEntries,
  selectOfferEntry,
  selectPendingEntry,
  selectPendingEntryFromCart,
  selectPendingEntryFromFavorite,
  selectPendingEntryFromOffer,
};
