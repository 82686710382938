import { EffectCallback, useEffect } from 'react';

const useEffectOnUnmount = (effect: EffectCallback) => {
  useEffect(() => {
    return () => {
      effect();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useEffectOnUnmount;
