import React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import { toast } from 'utils/toast';

interface AppInfoItemProps {
  label: string;
  value?: string;
}

const AppInfoItemWrapper = styled.div`
  margin-block-end: 29px;
  text-align: center;
`;

const AppInfoItemLabel = styled.h4`
  font: ${brandFont.headerFour};
  margin: 0;
`;

const AppInfoItemValue = styled.span`
  font: ${brandFont.copyOne};
`;

export const AppInfoItem: React.FC<AppInfoItemProps> = ({ label, value }) => {
  const { formatMessage } = useIntl();
  return (
    <AppInfoItemWrapper>
      <AppInfoItemLabel>{label}</AppInfoItemLabel>
      {value ? (
        <CopyToClipboard
          text={value}
          onCopy={() => toast.dark(formatMessage({ id: 'aboutApp.copiedToClipboard' }, { label }))}
        >
          <AppInfoItemValue>{value}</AppInfoItemValue>
        </CopyToClipboard>
      ) : (
        <AppInfoItemValue>{formatMessage({ id: 'aboutApp.notAvailable' })}</AppInfoItemValue>
      )}
    </AppInfoItemWrapper>
  );
};
