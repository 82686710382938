import { ActionButtonVariants } from 'components/action-button';

export default {
  itemNameFont: Styles.fontFamily.brand,
  itemContainerMarginTop: '0.25rem',
  itemBackground: Styles.color.white,
  itemBorderRadius: 0,
  itemImageBackgroundColor: Styles.color.grey.five,
  itemBorder: `2px solid ${Styles.color.grey.five}`,
  itemPadding: '0.65rem 0.5rem',
  contentWidth: '300px',
  contentPadding: '4rem 0 calc(3.5rem + env(safe-area-inset-bottom))',
  emptyCartBtnVariant: ActionButtonVariants.OUTLINE,
  buttonContainerMargin: '1.5rem 0.5rem',
};
