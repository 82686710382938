import {
  ISanityCombo,
  ISanityItem,
  ISanityPicker,
  ISanitySection,
  SanityMenuObject,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';

type SectionOption = ISanityPicker | ISanityCombo | ISanityItem | ISanitySection;

/**
 * Get section options one or two levels deep since sections can have nested sections.
 *
 * @param menuObject {SanityMenuObject} the sanity menu object to flatten
 */
export const flattenSectionOptions = (options: SectionOption[]): SectionOption[] =>
  options.flatMap(child => {
    if (child._type === MenuObjectTypes.SECTION) {
      return child.options.length ? child.options : [child];
    }

    return [child];
  });

export const filterForStaticMenu = (menuObject: SanityMenuObject) => {
  if (menuObject._type !== MenuObjectTypes.SECTION) {
    return menuObject;
  }
  const options = flattenSectionOptions(menuObject?.options);
  const filteredOptions = options.filter(option => option?.showInStaticMenu);

  const filteredSection = { ...menuObject, options: filteredOptions };
  return filteredSection;
};
