import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import Picture from 'components/picture';
import { primitive } from 'styles/constants/primitives';

export const OverviewContainer = styled.div`
  position: relative;
`;

export const BackgroundImageColor = styled.div`
  background-color: ${p => p.theme.token('background-menu-customization-image')};
  width: 100%;
  height: 80%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
`;

export const StyledProductPicture = styled(Picture)`
  padding: 0 1rem;
  flex-shrink: 0;
  max-height: 50vh;
  max-width: 50vh;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font: ${brandFont.headerOne};
  text-align: center;
  margin: ${primitive.$spacing2} 0;
`;

export const ImageDisclaimerText = styled.span`
  text-align: center;
  font: ${brandFont.copyTwo};
`;
