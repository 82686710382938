import { IDiscount } from '@rbi-ctg/menu';
import { DiscountTypes } from 'enums/menu';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { OrderStatus, useOrderContext } from 'state/order';
import {
  computeOtherDiscountAmount,
  computeSavings,
  getLoyaltyRewardsDiscount,
} from 'utils/cart/helper';
import { isDelivery } from 'utils/service-mode';

import { ITotals } from '.';

export const useTotalTipCalculations = ({ serverOrder, serviceMode }: ITotals) => {
  const orderContext = useOrderContext();
  const { calculateCartTotal } = orderContext;

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { sanityRewardsMap } = useLoyaltyRewardsList();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const isPricingRewardApplication = useAppSelector(
    selectors.loyalty.selectIsPricingRewardApplication
  );

  const loading = !serverOrder || serverOrder.status === OrderStatus.PRICE_REQUESTED;
  const isTwoStepCheckout = useFlag(LaunchDarklyFlag.TWO_STEP_CHECKOUT);
  const hideTipAmount = useFlag(LaunchDarklyFlag.HIDE_TIP_AMOUNT_CHECKOUT);
  const isAmountOfferDiscountValueAsCents = useFlag(
    LaunchDarklyFlag.ENABLE_AMOUNT_OFFER_DISCOUNT_VALUE_AS_CENTS
  );
  const isDeliveryOrder = isDelivery(serviceMode);

  const { cart, delivery } = serverOrder || {};
  const deliveryFee = delivery ? delivery.feeCents : null;
  const deliveryFeeDiscount = delivery ? delivery.feeDiscountCents : null;
  const allDiscounts = cart?.discounts || [];
  const posRewardDiscount = () => {
    return allDiscounts.find(
      (discount: IDiscount) => discount.name === DiscountTypes.REWARDS_DISCOUNTS
    )?.value;
  };

  // delivery.tipCents is null until the user advances to PAYMENT_REQUESTED
  // at the same time, totalCents increases by the tip amount
  // we needed to do this to render the correct grand total/tip as user advances
  const serverOrderTip = Number(delivery?.tipCents || 0);

  // currently not all POS vendors return discount amount in cart response when offers are applied
  // for loyalty rewards with loyaltyEnabled = true, the discount amount is calculated in the FE
  // otherwise it is returned from the POS system
  const rewardDiscountAmount =
    loyaltyEnabled && !isPricingRewardApplication
      ? sanityRewardsMap
        ? (posRewardDiscount() ??
          getLoyaltyRewardsDiscount({
            cartEntries: orderContext.cartEntries,
            loyaltyEnabled,
            appliedLoyaltyRewards,
            sanityRewardsMap,
            isAmountOfferDiscountValueAsCents,
          }))
        : 0
      : posRewardDiscount();

  // We need to use the cartEntries from the server order if the orderContext.cartEntries is empty (e.g after delivery confirmation)
  const orderCartEntries =
    orderContext.cartEntries.length > 0 ? orderContext.cartEntries : serverOrder?.cart.cartEntries;
  const otherDiscountAmount = Math.max(
    computeOtherDiscountAmount(allDiscounts),
    computeSavings({
      cartEntries: orderCartEntries,
      rewardDiscountAmount,
      subTotalCents: calculateCartTotal() || serverOrder?.cart.subTotalCents,
    })
  );

  const { subTotalCents, taxCents } = cart || {};

  return {
    hideTipAmount,
    subTotalCents,
    taxCents,
    rewardDiscountAmount,
    serverOrderTip,
    additionalDiscountAmount: otherDiscountAmount,
    deliveryFee,
    deliveryFeeDiscount,
    loading,
    isTwoStepCheckout,
    isDeliveryOrder,
    cart,
    delivery,
  };
};
