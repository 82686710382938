import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  height: 44px;
  width: 44px;
  padding: 8px;
`;

export const FallbackWrapper = styled.div`
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  height: 44px;
  width: 44px;
`;
