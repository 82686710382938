import { useCallback, useEffect, useMemo, useState } from 'react';

import { IPlusDataQuery, PlusDataDocument, PosDataServiceMode } from 'generated/graphql-gateway';
import { useApolloClient } from 'state/graphql/hooks/use-sanity-query';
import { useServiceModeContext } from 'state/service-mode';
import { useSanityGqlEndpoint } from 'utils/network';

type PosDataResults = {
  posData: Record<string, number> | null;
};

type PosDataIds = (ids?: {
  restaurantPosDataId: string | null;
  storeNumber: string | null;
}) => Promise<PosDataResults | null>;

interface IUsePosData {
  data: PosDataResults;
  loading: boolean;
  refetch: PosDataIds;
}

// create PLU object to keep same logic
const transformToPluObject = (plus: IPlusDataQuery['plus']): PosDataResults => {
  return {
    posData: (plus || []).reduce((acc, plu) => {
      acc[plu.plu] = Number(plu.price);
      return acc;
    }, {}),
  };
};

export const usePosDataQuery = ({
  restaurantPosDataId,
  storeNumber,
  lazy = false,
}: {
  restaurantPosDataId: string | null;
  storeNumber: string | null;
  lazy?: boolean;
}): IUsePosData => {
  const { isDelivery } = useServiceModeContext();
  const sanityUri = useSanityGqlEndpoint();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<PosDataResults>({
    posData: null,
  });

  const fetchPosData: PosDataIds = useCallback(
    async ids => {
      setIsLoading(true);

      const id = ids?.restaurantPosDataId ?? restaurantPosDataId;
      const storeId = ids?.storeNumber ?? storeNumber;

      if (!id) {
        setIsLoading(false);
        return Promise.resolve({ posData: null });
      }

      let transformedResponse: PosDataResults;

      const response = await client.query({
        query: PlusDataDocument,
        variables: {
          storeId,
          serviceMode: isDelivery ? PosDataServiceMode.DELIVERY : PosDataServiceMode.PICKUP,
        },
      });

      transformedResponse = transformToPluObject(response.data?.plus);

      setData(transformedResponse);
      setIsLoading(false);
      return transformedResponse;
    },
    [client, isDelivery, restaurantPosDataId, sanityUri, storeNumber]
  );

  useEffect(() => {
    if (!lazy) {
      fetchPosData({
        restaurantPosDataId,
        storeNumber,
      });
    }
  }, [fetchPosData, lazy, restaurantPosDataId, storeNumber]);

  return useMemo(
    () => ({
      data,
      loading: isLoading,
      refetch: fetchPosData,
    }),
    [data, isLoading, fetchPosData]
  );
};
