import styled, { css } from 'styled-components';

import { ClickableContainer } from 'components/clickable-container';
import { primitive } from 'styles/constants/primitives';

const listItemCss = css`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.token('background-pattern')};
  color: ${({ theme }) => theme.token('text-default')};
  padding: ${primitive.$spacing4};
`;

// Will eventually use ListItemButton & ListItemContent from component lib
export const ListItemContent = styled.div`
  ${listItemCss}
`;

export const ListItemButton = styled(ClickableContainer)`
  ${listItemCss}
`;

export const ProductControl = styled.div`
  display: flex;
  align-items: center;
  gap: ${primitive.$spacing4};
  padding: ${primitive.$spacing2};
  & label {
    margin: 0;
  }
`;
