import { withForterHeaders } from 'state/graphql/links/with-forter-headers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { useForterScriptTag } from './use-forter-script-tag';

export const useForter = () => {
  useForterScriptTag();

  withForterHeaders.setEnabled(useFlag(LaunchDarklyFlag.ENABLE_FORTER_FRAUD));
};
