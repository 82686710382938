import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';

export const ServiceModeContainer = styled.div<IBaseProps>`
  & a {
    font-weight: 900;
  }

  & a:link,
  & a:visited,
  & a:focus,
  & a:hover,
  & a:active {
    color: ${Styles.color.white};
    margin-inline-start: 1rem;
    text-decoration: none;
  }

  & a:hover {
    color: ${Styles.color.white};
  }

  ${Styles.desktop} {
    font-size: 0.9375rem;
    border-end-start-radius: 0;
    border-end-end-radius: 0;
  }
`;

export const LeftTextContainer = styled.div`
  position: absolute;
  inset-inline-start: 2rem;
  font-size: 0.75rem;
  ${Styles.mobile} {
    display: none;
  }
`;

export const AdditionalTextContainer = styled.span`
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
`;
