import { useMemo } from 'react';

import {
  IFeatureQrCodeRefillDrinksQuery,
  useAllFeatureIdsQuery,
  useFeatureQrCodeRefillDrinksQuery,
} from 'generated/sanity-graphql';

interface IUseQRCodeRefillDrinks {
  featureQrCodeRefillDrinks: IFeatureQrCodeRefillDrinksQuery['FeatureQRCodeRefillDrinks'];
  featureQrCodeRefillDrinksLoading: boolean;
}

export const useFeatureQRCodeRefillDrinks = (): IUseQRCodeRefillDrinks => {
  const { data: dataAllFeatureIds, loading: featureIdsLoading } = useAllFeatureIdsQuery();

  const featureQRCodeRefillDrinksId = useMemo(
    () => dataAllFeatureIds?.allFeatureQRCodeRefillDrinks[0]?._id || '',
    [dataAllFeatureIds]
  );

  const { data, loading } = useFeatureQrCodeRefillDrinksQuery({
    variables: { featureQRCodeRefillDrinksId },
    skip: featureIdsLoading || !featureQRCodeRefillDrinksId,
  });

  const featureQrCodeRefillDrinks = data?.FeatureQRCodeRefillDrinks ?? null;

  return {
    featureQrCodeRefillDrinks,
    featureQrCodeRefillDrinksLoading: loading,
  };
};
