import styled from 'styled-components';

const ModalHeading = styled.h2`
  margin-block-start: 0;
  margin-block-end: 50px;
  max-width: 100%;
  font-family: ${Styles.fontFamily.base};
  letter-spacing: -0.42px;
  line-height: 1.1;
  text-transform: ${Styles.textTransform.headlines};
  text-align: center;
  font-weight: normal;
  font-size: 2rem;

  ${Styles.mobileLandscape} {
    font-size: 2rem;
    margin: 1.25rem 0;
  }

  ${Styles.desktop} {
    max-width: 500px;
  }

  ${Styles.mobile} {
    margin-block-start: 2.5rem;
  }
`;

export default ModalHeading;
