import React, { FC, useCallback } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';

import { ListItemGroup } from 'components/list-item-group';

import { RadioCheckListContainer, RadioCheckListItem } from './radio-check-list.styled';
import { IRadioCheckListProps } from './types';

const RadioCheckList: FC<IRadioCheckListProps> = ({ options, selectedValue, onChange }) => {
  const handleClick = useCallback(
    (val: string) => {
      if (onChange) {
        onChange(val);
      }
    },
    [onChange]
  );
  return (
    <RadioCheckListContainer data-testid="radio-check-list">
      <ListItemGroup
        items={options.map((opt, i) => (
          <RadioCheckListItem
            aria-label={`${opt.label} ${opt.labelSuffix ? opt.labelSuffix : ''}`}
            data-testid="radio-check-list-item"
            onClick={() => handleClick(opt.value)}
            key={i}
          >
            <span>
              {opt.label}
              {opt.labelSuffix && <b> {opt.labelSuffix}</b>}
            </span>
            {opt.value === selectedValue && (
              <Icon
                data-testid="radio-check-list-icon"
                icon="check"
                title="check"
                color="icon-default"
                width="12px"
              />
            )}
          </RadioCheckListItem>
        ))}
      />
    </RadioCheckListContainer>
  );
};

export default RadioCheckList;
