import React from 'react';

import styled from 'styled-components';

export const MoreCircle = styled.div`
  height: 4px;
  width: 4px;
  margin-inline-start: 4px;
  border-radius: 9999rem;
  background-color: ${Styles.color.mobileNavButtonColor};

  &:first-of-type {
    margin-inline-start: 0;
  }
`;

const More = () => (
  <>
    <MoreCircle />
    <MoreCircle />
    <MoreCircle />
  </>
);

export default More;
