import { useCallback, useEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl';

import { OstType, useCreateOrderSelectionByTypeMutation } from 'generated/graphql-gateway';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useLoyaltyOffersEvaluation } from 'state/loyalty/hooks/use-loyalty-offers-evaluation';
import { StatusType, dataDogLogger } from 'utils/datadog';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';

import useStaticIdentifier from '../use-static-identifier';

import { IGetNewShortCodeParams, IShortCodeData, IUserShortCode, ShortCodeState } from './types';
import { differenceMsFromNow, isExpired } from './utils';

/**
 * useShortCode holds otp short-code and its state.
 */
export const useShortCode = (): IUserShortCode => {
  const { isStaticIdentifierEnabled } = useStaticIdentifier();
  const { formatMessage } = useIntl();
  const { trackEvent } = useCdpContext();

  const { evaluateLoyaltyOffers } = useLoyaltyOffersEvaluation();
  const generateCodeOtpTimeoutRef = useRef<NodeJS.Timeout | null>();
  const abortControllerRef = useRef<AbortController>();
  const [shortCodeLoading, setShortCodeLoading] = useState<boolean>(false);
  const [{ shortCode, expiry }, setShortCodeData, clearShortCodeData] =
    useLocalStorageState<IShortCodeData>({
      key: StorageKeys.IN_RESTAURANT_REDEMPTION_SHORT_CODE,
      defaultReturnValue: {},
    });
  const [shortCodeState, setShortCodeState] = useState<ShortCodeState>(() =>
    shortCode ? ShortCodeState.Pending : ShortCodeState.None
  );
  const shouldEvaluateIncentivesRef = useRef<boolean>();
  // evaluate incentives only if the short code is expired to avoid unnecessary requests
  shouldEvaluateIncentivesRef.current = shortCodeState === ShortCodeState.Expired;

  const setRequestError = useCallback(() => {
    clearShortCodeData();
    setShortCodeState(ShortCodeState.RequestError);
  }, [clearShortCodeData]);

  const [generateOrderSelection] = useCreateOrderSelectionByTypeMutation({
    onCompleted: ({ createOrderSelectionByType }) => {
      const { key: newShortCode, expirationTime } = createOrderSelectionByType;

      setShortCodeData({
        shortCode: newShortCode,
        expiry: expirationTime,
      });
      setShortCodeState(ShortCodeState.Pending);
    },
    onError: err => {
      const { networkError } = err;

      setRequestError();
      dataDogLogger({
        message: formatMessage({ id: 'qrCodeLoyaltyCreationError' }),
        status: StatusType.error,
        context: {
          error: err.message,
        },
      });

      trackEvent({
        name: CustomEventNames.QR_AND_SHORT_CODE_ERROR,
        type: EventTypes.Other,
        attributes: {
          Message: formatMessage({ id: 'qrCodeLoyaltyCreationError' }),
          rootCause: err.message,
          isNetworkError: !!networkError,
        },
      });
    },
  });

  const setShortCodeClaimed = useCallback(() => {
    setShortCodeState(ShortCodeState.Claimed);
  }, []);

  const setShortCodeExpired = useCallback(() => {
    setShortCodeState(ShortCodeState.Expired);
  }, []);

  const resetShortCode = useCallback(() => {
    clearShortCodeData();
    setShortCodeLoading(false);
  }, [clearShortCodeData]);

  const setIncentiveValidationError = useCallback(() => {
    setShortCodeState(ShortCodeState.OfferValidationError);
  }, [setShortCodeState]);

  /**
   * getNewShortCode works as a debounce function in order to prevent running
   * multiple mutations at the same time
   */
  const getNewShortCode = useCallback(
    ({
      appliedOffers,
      inRestaurantOrder,
      loyaltyId,
      restaurantId,
      serviceMode,
    }: IGetNewShortCodeParams) => {
      if (generateCodeOtpTimeoutRef.current) {
        clearTimeout(generateCodeOtpTimeoutRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      setShortCodeLoading(true);
      generateCodeOtpTimeoutRef.current = global.setTimeout(async () => {
        const controller = window.AbortController ? new window.AbortController() : undefined;
        abortControllerRef.current = controller;

        // flag to avoid set the state if the request is aborted
        let isRequestAborted = false;
        if (controller?.signal) {
          controller.signal.onabort = () => {
            isRequestAborted = true;
          };
        }

        try {
          // Incentive validations
          let hasValidationErrors = false;
          if (shouldEvaluateIncentivesRef.current && appliedOffers.length) {
            const evaluationResult = await evaluateLoyaltyOffers({
              appliedOffers,
              loyaltyId,
              storeId: restaurantId,
              serviceMode,
            });
            hasValidationErrors = (appliedOffers || []).some(
              ({ id }: { id: string }) => evaluationResult && !!evaluationResult[id]
            );
          }

          // skip validation if the request was aborted to prevent set state
          if (!isRequestAborted && hasValidationErrors) {
            setIncentiveValidationError();
          }

          if (!hasValidationErrors) {
            await generateOrderSelection({
              variables: {
                inRestaurantOrder,
                restaurantId,
                loyaltyId,
                ostType: isStaticIdentifierEnabled ? OstType.LOYALTY_ID : OstType.OTP,
              },
              context: {
                fetchOptions: {
                  signal: controller?.signal,
                },
              },
            });
          }
        } catch (e) {
          logger.error(`Error generating a short code: ${e}`);
        } finally {
          generateCodeOtpTimeoutRef.current = null;
          setShortCodeLoading(false);
        }
      }, 250);
    },
    [
      evaluateLoyaltyOffers,
      generateOrderSelection,
      isStaticIdentifierEnabled,
      setIncentiveValidationError,
    ]
  );

  useEffect(() => {
    if (!expiry) {
      return;
    } else if (isExpired(expiry)) {
      setShortCodeExpired();
      return;
    }

    const codeDurationMilliseconds = differenceMsFromNow(expiry);
    const timeoutId = setTimeout(setShortCodeExpired, codeDurationMilliseconds);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [expiry, setShortCodeExpired]);

  return {
    resetShortCode,
    getNewShortCode,
    setShortCodeClaimed,
    setShortCodeExpired,
    setRequestError,
    shortCode,
    shortCodeLoading,
    shortCodeState,
  };
};
