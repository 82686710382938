import { useOurServicesQuery } from 'generated/sanity-graphql';

export const useOurServices = (id?: string) => {
  const ourServicesId = id ?? '';
  const { data, loading } = useOurServicesQuery({
    variables: {
      ourServicesId,
    },
    skip: !ourServicesId,
  });

  const ourServices = data?.OurServices;

  return {
    ourServices,
    ourServicesLoading: loading,
  };
};
