import { getIso3 } from '@rbilabs/intl';
import uuidv4 from 'uuid/v4';

import { IsoCountryCode } from 'generated/rbi-graphql';
import { getFdProxyAndUrl } from 'remote/api/get-fd-proxy-and-url';
import { encryptCredit } from 'utils/encryption';
import { brand, getConfigValue, getCountry, isLocalDev } from 'utils/environment';
import { getCountryAndCurrencyCodes } from 'utils/form/constants';
import { IPaymentPayload } from 'utils/payment';

import { FirstDataError } from '../exceptions';

/**
 * The First Data endpoint doesn't support CORS for localhost,
 * so during local dev we hit a proxy on our dev server.
 * Stable environments (dev, staging, prod) can hit the API directly from the browser.
 */
export const getFirstDataBaseUrl = (country?: IsoCountryCode) => {
  const [proxy, firstData] = getFdProxyAndUrl(country);
  // NOTE: cypress-v2 test suite requirement
  // Use the same proxy as local development when Cypress is running.
  // Allows Cypress in CI to mimic the recorded request patterns.
  if (window.Cypress) {
    return firstData;
  }
  if (isLocalDev) {
    return proxy;
  }
  return firstData;
};

interface IGetNonce {
  storeId?: string;
  currencyCode?: {
    code: string;
    number: number;
  };
  account: {
    // @todo validate type
    credit: {
      cardNumber: string;
      expiryDate: {
        month: string;
        year: string;
      };
      securityCode: string;
    };
    type: string;
  };
  deviceInfo: {
    id: string;
    kind: string;
  };
  referenceToken: {
    tokenType: string;
  };
  fdCustomerId?: string;
}

export const getNonce = async (
  credit: IPaymentPayload,
  fdPublicKey: string,
  fdApiKey: string,
  fdAccessToken: string,
  fdCustomerId: string | null,
  isoCountryCode?: IsoCountryCode,
  onlySendPostalCode?: boolean
) => {
  const encryptedCredit = await encryptCredit({
    credit,
    fdPublicKey,
  });
  // billing address sent to First Data may only require postal code
  const postalCode = credit.billingAddress.postalCode;
  const billingAddress = onlySendPostalCode ? { postalCode } : credit.billingAddress;

  const encryptedCreditWithCorrectBilling = {
    ...encryptedCredit,
    billingAddress,
  };

  const headers = {
    Authorization: `Bearer ${fdAccessToken}`,
    'Content-Type': 'application/json',
    Timestamp: new Date().getTime() as unknown as string,
    'Client-Request-Id': uuidv4(),
    'Api-Key': fdApiKey,
    'Sub-Merchant-Id': `${brand().toUpperCase()}-${getCountry().toUpperCase()}`,
  };

  // The parameter storeId here refers to the corporate store id,
  // a store chosen by Fiserv to represent the market (e.g. storeId: 1234 is PLK FR).
  const corporateStoreId = getConfigValue('firstData')?.corporateStoreId;

  const regionSaved = getCountry().toUpperCase();
  const region = getIso3({ iso2: regionSaved }) as IsoCountryCode;
  const currency = getCountryAndCurrencyCodes(region);

  const body: IGetNonce = {
    account: {
      credit: encryptedCreditWithCorrectBilling,
      type: 'CREDIT',
    },
    deviceInfo: {
      id: uuidv4(),
      kind: 'web',
    },
    referenceToken: {
      tokenType: 'CLAIM_CHECK_NONCE',
    },
  };

  if (fdCustomerId) {
    body.fdCustomerId = fdCustomerId;
  }

  if (corporateStoreId && currency.currencyCode && currency.currencyNumber) {
    body.storeId = corporateStoreId;
    body.currencyCode = {
      code: currency.currencyCode,
      number: currency.currencyNumber,
    };
  }

  const firstDataBaseUrl = getFirstDataBaseUrl(isoCountryCode);
  const response = await fetch(`${firstDataBaseUrl}/ucom/v1/account-tokens`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  if (response.ok) {
    return response;
  }

  throw new FirstDataError('Error getting nonce', new Error(response.statusText));
};
