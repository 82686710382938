import {
  IFeatureGeolocationMapMarkersQuery,
  useFeatureGeolocationMapMarkersQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureGeolocationMapMarkersValue {
  featureGeolocationMapMarkers: IFeatureGeolocationMapMarkersQuery['FeatureGeolocationMapMarkers'];
  featureGeolocationMapMarkersLoading: boolean;
}

export const useFeatureGeolocationMapMarkers = (): IUseFeatureGeolocationMapMarkersValue => {
  const { featureGeolocationMapMarkersId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureGeolocationMapMarkersQuery({
    variables: { featureGeolocationMapMarkersId },
    skip: !featureGeolocationMapMarkersId,
  });

  const featureGeolocationMapMarkers = data?.FeatureGeolocationMapMarkers ?? null;

  const featureGeolocationMapMarkersLoading = featureIdsLoading || loading;

  return {
    featureGeolocationMapMarkers,
    featureGeolocationMapMarkersLoading,
  };
};
