import React from 'react';

import styled from 'styled-components';

import {
  ICopyrightAndLanguageSelectorFragment,
  IHighlightedVerticalLinkListWidgetFragment,
  IHorizontalLinkListWidgetFragment,
  IImageWidgetFragment,
  ISocialIconsWithHeaderWidgetFragment,
  ITextWidgetFragment,
  ITextWidgetWithUrlFragment,
  IVerticalLinkListWidgetFragment,
  IVerticalLinkListWithHeaderWidgetFragment,
  IWordmarkAndSocialIconsFragment,
} from 'generated/sanity-graphql';
import logger from 'utils/logger';

import { BrandLogo } from './brand-logo';
import { CopyrightAndLanguageSelector } from './copyright-and-language-selector';
import { HighlightedVerticalLinkList } from './highlighted-vertical-link-list';
import { HorizontalLinkList } from './horizontal-link-list';
import { SocialIconsWithHeader } from './social-icons-with-header';
import { P } from './styled';
import { TextWithUrl } from './text-with-url';
import { IColumn as IColumnProps, IOption, OptionTypes } from './types';
import { VerticalLinkList } from './vertical-link-list';
import { VerticalLinkListWithHeader } from './vertical-link-list-with-header';
import { WordmarkAndSocialIcons } from './wordmark-and-social-icons';

const brandLogoHeight = 7;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledBrandLogo = styled(BrandLogo)`
  max-height: ${brandLogoHeight}rem;
`;

export const getComponentForColumnOption = (option: IOption) => {
  switch (option._type) {
    case OptionTypes.CopyrightAndLanguageSelector:
      return (
        <CopyrightAndLanguageSelector {...(option as ICopyrightAndLanguageSelectorFragment)} />
      );
    case OptionTypes.HighlightedVerticalLinkListWidget:
      return (
        <HighlightedVerticalLinkList {...(option as IHighlightedVerticalLinkListWidgetFragment)} />
      );
    case OptionTypes.HorizontalLinkListWidget:
      return <HorizontalLinkList {...(option as IHorizontalLinkListWidgetFragment)} />;
    case OptionTypes.ImageWidget:
      return <StyledBrandLogo {...(option as IImageWidgetFragment)} />;
    case OptionTypes.SocialIconsWithHeaderWidget:
      return <SocialIconsWithHeader {...(option as ISocialIconsWithHeaderWidgetFragment)} />;
    case OptionTypes.TextWidget:
      return <P>{(option as ITextWidgetFragment).text?.locale}</P>;
    case OptionTypes.TextWidgetWithUrl:
      return <TextWithUrl {...(option as ITextWidgetWithUrlFragment)} />;
    case OptionTypes.VerticalLinkListWidget:
      return <VerticalLinkList {...(option as IVerticalLinkListWidgetFragment)} />;
    case OptionTypes.VerticalLinkListWithHeaderWidget:
      return (
        <VerticalLinkListWithHeader {...(option as IVerticalLinkListWithHeaderWidgetFragment)} />
      );
    case OptionTypes.WordmarkAndSocialIcons:
      return <WordmarkAndSocialIcons {...(option as IWordmarkAndSocialIconsFragment)} />;
    default:
      logger.warn(`Unknown column option: ${option._type}`);
      return null;
  }
};

export const Column: React.FC<IColumnProps> = props => {
  return (
    <Container>
      {(props.options ?? []).map(
        (option, index) =>
          // these don't change position between renders, so `index` as key here is fine
          option && (
            <React.Fragment key={index}>{getComponentForColumnOption(option)}</React.Fragment>
          )
      )}
    </Container>
  );
};
