import React, { FC, memo } from 'react';

import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { useFeatureGeolocationModal } from 'hooks/use-feature-geolocation-modal';
import { isMobile } from 'utils/environment';

import {
  Body,
  ButtonContainer,
  Header,
  StyledCloseIcon,
  TextContainer,
  Wrapper,
} from './styled.bk';
import { IGeolocationModalComponentProps, IGeolocationModalViewProps } from './types';

const MobileView = ({
  header,
  body,
  handleDismissModal,
  actions,
}: IGeolocationModalComponentProps) => (
  <Wrapper>
    <StyledCloseIcon data-testid="close-icon" onClick={handleDismissModal} />
    <TextContainer>
      <Header>{header}</Header>
      <Body>{body}</Body>
    </TextContainer>
    {actions}
  </Wrapper>
);

const DesktopView = ({
  header,
  body,
  handleDismissModal,
  actions,
}: IGeolocationModalComponentProps) => (
  <Dialog
    modalAppearanceEventMessage="geolocation-prompt"
    heading={header}
    body={body}
    onDismiss={handleDismissModal}
    actions={actions}
    showCloseButton
  />
);

export const GeolocationModalView: FC<IGeolocationModalViewProps> = memo(
  ({ handleDismissModal, handleGeolocationPrompt }) => {
    const { formatMessage } = useIntl();
    const { featureGeolocationModal } = useFeatureGeolocationModal();
    const isMobileSize = isMobile();

    if (!featureGeolocationModal) {
      return null;
    }

    const { header, body } = featureGeolocationModal;

    const actions = (
      <ButtonContainer>
        <ActionButton
          onClick={handleGeolocationPrompt}
          size={ActionButtonSizes.SMALL}
          variant={ActionButtonVariants.INVERSE}
        >
          {formatMessage({ id: 'shareMyLocation' })}
        </ActionButton>
      </ButtonContainer>
    );

    return isMobileSize ? (
      <MobileView
        header={header}
        body={body}
        handleDismissModal={handleDismissModal}
        actions={actions}
      />
    ) : (
      <DesktopView
        header={header}
        body={body}
        handleDismissModal={handleDismissModal}
        actions={actions}
      />
    );
  }
);
