import { getConfigValue } from 'utils/environment';

import { IEncryptOrbitalCard } from './types';

export interface IEncryptionConfig {
  baseUrl: string;
  pieFormat: string;
  subscriberId: string;
  mode: string;
}

declare global {
  interface Window {
    ProtectPANandCVV: Function;
    ValidatePANChecksum: Function;
    PIE: {
      key_id: string;
      phase: string;
    };
  }
}

export const loadScript = (url: string) => {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    head.appendChild(script);
  });
};

export const encryptOrbitalCard: IEncryptOrbitalCard = async (cardNumber, securityCode) => {
  // Get Orbital configs from Sanity and identify script urls
  const orbitalConfig = getConfigValue('orbital');
  const { baseUrl, pieFormat, subscriberId, mode } = orbitalConfig;
  const embed = mode === 'eFPE' ? true : false;

  if (!orbitalConfig || !orbitalConfig.baseUrl) {
    throw new Error('Safetech encryption config not available');
  }
  const getKeyScriptUrl: string = `${baseUrl}/pie/v1/${pieFormat}${subscriberId}/getkey.js`;
  const encryptionScriptUrl: string = `${baseUrl}/pie/v1/encryption.js`;

  // Load scripts
  await loadScript(getKeyScriptUrl);
  await loadScript(encryptionScriptUrl);

  const hasValidChecksum = await window.ValidatePANChecksum(cardNumber);
  if (!hasValidChecksum) {
    throw new Error('PAN has invalid checksum');
  }
  const result = await window.ProtectPANandCVV(cardNumber, securityCode, !embed);
  if (!result) {
    throw new Error('Encryption failed');
  }

  const [cryptCard, cryptCvv, integrityCheck] = result;

  const response = {
    cryptCard,
    cryptCvv,
    integrityCheck,
    pieFormat,
    subscriberId,
    mode,
    keyId: window.PIE.key_id,
    phase: window.PIE.phase,
  };

  // Return encrypted card details and orbital configs
  return response;
};
