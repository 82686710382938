import { IServerOrder } from '@rbi-ctg/menu';
import { POSVendor } from 'state/store/hooks/enums';

export const getOrderNumber = (serverOrder: IServerOrder): string | null => {
  // QDI / NCR does not display the order number on the receipt.
  // To avoid confusion from the customer, we will omit the line
  //
  // This vendor check can be removed once the backend has been updated
  // to return `undefined` for NCR orders
  const vendorsWithoutOrderNumber = [POSVendor.NCR, POSVendor.QDI];

  const orderNumber = serverOrder.cart.ticketNumber;
  return !vendorsWithoutOrderNumber.includes(serverOrder.cart.posVendor) && orderNumber
    ? orderNumber
    : null;
};
