import { ApolloLink } from '@apollo/client';
import { checkDocument, getOperationName, isField } from '@apollo/client/utilities';
import { DocumentNode, FieldNode, OperationDefinitionNode } from 'graphql';
import { visit } from 'graphql/language/visitor';

import {
  GetComboAvailabilityDocument,
  GetItemAvailabilityDocument,
  GetPickerAvailabilityDocument,
} from 'generated/sanity-graphql';

const TYPENAME_KEY = '__typename';
const OPERATION_DEFINITION_KEY = 'OperationDefinition';

export function stripTypename<O>(data: O): O {
  if (!data) {
    return data;
  }
  // In Apollo v3 the cache is now readonly, directly mutating the cache would often result in buggy behavior.
  return JSON.parse(JSON.stringify(data, (k, v) => (k === TYPENAME_KEY ? undefined : v)));
}

// removes the "__typename" field from data being sent as graphql
// input to avoid errors when sending graphql data as an input to
// another mutation
export const stripTypenameLink = new ApolloLink((request, forward) => {
  if (request.variables) {
    request.variables = stripTypename(request.variables);
  }
  return forward ? forward(request) : null;
});

// removes the "__typename" field from nested fields in the
// menu operations as a temporary remedy for issues with
// fetching too much data from sanity
const MenuQueryOperationNames = [
  GetPickerAvailabilityDocument,
  GetItemAvailabilityDocument,
  GetComboAvailabilityDocument,
].map(doc => doc && getOperationName(doc));

const isMenuQuery = (query: DocumentNode): boolean =>
  MenuQueryOperationNames.includes(getOperationName(query) || '');

export const removeNestedTypenameFromMenuQueriesLink = new ApolloLink((request, forward) => {
  if (request.query && isMenuQuery(request.query)) {
    request.query = removeNestedTypename(request.query);
  }

  return forward ? forward(request) : null;
});

export const removeNestedTypename = (doc: DocumentNode): DocumentNode =>
  // depth first traversal of deeply nested documents that will call it's `enter` callback upon visiting each document node
  visit(checkDocument(doc), {
    SelectionSet: {
      enter(node, _key, parent) {
        // Ignore OperationDefinitions.
        if (parent && (parent as OperationDefinitionNode).kind === OPERATION_DEFINITION_KEY) {
          return;
        }

        // No changes if no selections.
        const { selections } = node;

        if (!selections) {
          return;
        }

        // If this SelectionSet is @export-ed as an input variable, it should
        // not have a __typename field (see issue #4691).
        const field = parent as FieldNode;
        if (
          isField(field) &&
          (field?.directives || []).some(({ name }) => name.value === 'export')
        ) {
          return;
        }

        const selectionsWithoutTypename = selections.filter(
          ({ name }: any) => name?.value !== TYPENAME_KEY
        );

        // Create and return a new SelectionSet without a __typename Field.
        return {
          ...node,
          selections: selectionsWithoutTypename,
        };
      },
    },
  });
