import { useEffect } from 'react';

import { IUseAlertOrderDeliveryMinimum } from './types';

const useAlertOrderDeliveryMinimum = ({
  cartSubtotal,
  checkoutDeliveryPriceMinimum,
  setCartPriceTooLow,
  isDelivery,
}: IUseAlertOrderDeliveryMinimum) => {
  useEffect(() => {
    if (!isDelivery) {
      setCartPriceTooLow(false);
      return;
    }

    setCartPriceTooLow(cartSubtotal < checkoutDeliveryPriceMinimum);
  }, [cartSubtotal, checkoutDeliveryPriceMinimum, isDelivery, setCartPriceTooLow]);
};

export default useAlertOrderDeliveryMinimum;
