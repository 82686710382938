import { useMainHeroQuery } from 'generated/sanity-graphql';

export const useMainHero = (id?: string) => {
  const mainHeroId = id ?? '';
  const { data, loading } = useMainHeroQuery({
    variables: {
      mainHeroId,
    },
    skip: !mainHeroId,
  });

  const mainHero = data?.MainHero;

  return {
    mainHero,
    mainHeroLoading: loading,
  };
};
