import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
`;

export const Section = styled.div<{ $borderColor?: string }>`
  &:not(:first-of-type) {
    border-block-start: 1px solid ${p => p.$borderColor || Styles.color.grey.five};
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 1rem 0;
  margin: 0;
`;

export const ListItem = styled.li`
  margin: 0.5rem 0;
  padding: 0;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${Styles.color.black};

  &:hover,
  &:focus {
    border-block-end: 2px solid ${Styles.color.black};
  }
`;

export const SectionTitle = styled.h3`
  font-family: ${Styles.fontFamily.brand};
  font-weight: ${Styles.fontWeight.normal};
  text-transform: ${Styles.textTransform.headlines};
  font-size: 1rem;
  margin-block-start: 1rem;
  margin-block-end: 0;

  & + ul {
    padding: 0;
  }
`;
