import React from 'react';

import { ICartEntry } from '@rbi-ctg/menu';
import CartItemQuantity from 'components/cart-item/cart-item-quantity';
import { useCartItemEditingTools } from 'hooks/use-cart-item-editing-tools';

import { UpsellCartItemQuantityContainer } from './styles';

interface UpsellModalQuantityChangeProps {
  item: ICartEntry;
}

export const UpsellModalQuantityChange: React.FC<UpsellModalQuantityChangeProps> = ({ item }) => {
  const { handleQuantityChange } = useCartItemEditingTools({ item });

  return (
    <UpsellCartItemQuantityContainer>
      <CartItemQuantity minCartQuantity={0} item={item} updateQuantity={handleQuantityChange} />
    </UpsellCartItemQuantityContainer>
  );
};
