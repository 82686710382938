interface ITryParseStringOptions<V, D extends V> {
  value: string;
  defaultValue?: D;
  onError?(error: Error): void;
}

const tryParsing =
  <V>(parseFn: (value: string) => V) =>
  <D extends V>(options: ITryParseStringOptions<V, D>) => {
    const { value, defaultValue = null, onError } = options;

    try {
      return parseFn(value);
    } catch (err) {
      // catch function is merely used for outputting a custom message
      if (onError) {
        onError(err);
      }

      return defaultValue;
    }
  };

export const decodeBase64String = tryParsing(window.atob);
export const encodeBase64String = tryParsing(window.btoa);
export const parseStringifiedJSON = tryParsing(JSON.parse);
