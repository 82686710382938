import { setContext } from '@apollo/client/link/context';
import { merge } from 'lodash';

import { getCurrentGuestSession } from 'remote/auth';

import { GUEST_AUTHORIZED_KEY } from './auth-required-directive';

export const withAuthGuestToken = setContext((_, previousContext) => {
  const guestSession = getCurrentGuestSession();
  if (guestSession) {
    return merge(
      {
        headers: {
          authorization: `Bearer ${guestSession.getIdToken().getJwtToken()}`,
        },
        [GUEST_AUTHORIZED_KEY]: true,
      },
      previousContext
    );
  }
  return previousContext;
});
