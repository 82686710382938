import { toRem } from 'utils/number';

/**
 * Creates a function for converting pixels to rem values based on a base font
 * size.
 */
export const pxToRem = (baseFontSize: number) => {
  /**
   * Given a number N or a string `Npx` return the corresponding rem string value (multiplied).
   * As this is used on some dynamic data, it has to be tolerant:
   * it will return the original value for any other value (with no multiplications)
   */
  return (value: string | number | null | undefined) => {
    const isString = typeof value === 'string';
    const isNumber = typeof value === 'number';

    // nil values
    if (!(isString || isNumber) || Number.isNaN(value)) {
      return value;
    }

    // string that does not match `N` or `Npx` patterns
    if (isString && !value.match(/^\d*(\.\d*)?(px)?$/)) {
      return value;
    }

    // converted value
    const numeric = isString ? parseInt(value, 10) : value;
    return toRem(numeric / baseFontSize);
  };
};
