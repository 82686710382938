import * as React from 'react';
import { FC } from 'react';

import styled from 'styled-components';

import { useRoutesDisplayBottomService as useRoutesDisplayServiceNotification } from 'components/bottom-service-mode/hooks/use-routes-display-bottom-service';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { useServiceModeNotification } from './hooks/use-service-mode-notification';
import { TopServiceModeNotificationView } from './top-service-mode-notification.view';
import { ITopServiceModeNotificationProps } from './types';

const StickyServiceNotification = styled(TopServiceModeNotificationView)`
  z-index: ${Styles.zIndex.overlay + 100};
  width: 100%;
`;

/**
 *
 * TopServiceModeNotification purpose
 *
 */
const TopServiceModeNotification: FC<ITopServiceModeNotificationProps> = () => {
  const enableTopServiceMode = useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE);
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const showServiceNotificationOnRoutes = useRoutesDisplayServiceNotification();
  const topServiceModeNotificationDetails = useServiceModeNotification();

  const shouldDisplay =
    enableTopServiceMode &&
    !enableBottomServiceMode &&
    showServiceNotificationOnRoutes &&
    topServiceModeNotificationDetails;

  return shouldDisplay ? (
    <StickyServiceNotification {...topServiceModeNotificationDetails} />
  ) : null;
};

export default TopServiceModeNotification;
