import styled from 'styled-components';

import { IIconContainerProps } from './types';

export const IconContainer = styled.div<IIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;

  > * {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  }

  svg {
    fill: ${Styles.color.grey.one};

    ${Styles.desktop} {
      fill: ${Styles.color.white};
    }
  }

  &:hover {
    background-color: ${Styles.color.primaryHover};
  }
`;
