import { relativeRoutes, routes } from './routes';

// These are routes that are safe for appUpdates
export const UPDATE_ALLOWED_ROUTES = [routes.menu, routes.about];
export const PERMISSION_TO_UPDATE_ROUTES = [routes.cart];

// brand-specific routes used to determine whether to display service mode header
export const routesForBottomServiceModeDisplay = [
  { route: routes.base, exact: true },
  { route: `${routes.menu}/${relativeRoutes.recentItems}`, exact: true },
  { route: `${routes.browseMenu}/${relativeRoutes.recentItems}`, exact: true },
  { route: routes.favorites, exact: true },
  { route: routes.menu, exact: false },
  { route: routes.browseMenu, exact: false },
  { route: routes.orders, exact: false },
  { route: routes.offers, exact: false },
  { route: routes.rewardsOffers, exact: false },
];

// brand-specific routes used to determine whether to display top service mode header
export const routesForTopServiceModeDisplay = [
  { route: routes.base, exact: true },
  { route: `${routes.menu}/${relativeRoutes.recentItems}`, exact: true },
  { route: `${routes.browseMenu}/${relativeRoutes.recentItems}`, exact: true },
  { route: routes.favorites, exact: true },
  { route: routes.menu, exact: false },
  { route: routes.browseMenu, exact: false },
  { route: routes.orders, exact: false },
  { route: routes.offers, exact: false },
  { route: routes.redeem, exact: false },
  { route: routes.rewardsList, exact: false },
  { route: routes.rewardsOffers, exact: false },
];
