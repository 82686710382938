import React, { FC, useCallback, useState } from 'react';

import { StyledModal } from 'pages/account/account-orders/details-modal/details-modal.styled.default';
import { OrderConfirmationPageContent } from 'pages/order-confirmation/order-confirmation-page-content';
import { useOrderContext } from 'state/order';

import { useTrackOrder } from './hooks/use-track-order';
import { OrderTrackingBanner } from './order-tracking-banner';

export const OrderTrackingBannerModalContainer: FC = () => {
  const [showModal, setShowModal] = useState(false);

  const { orderInProgress, message } = useTrackOrder();

  const { serverOrder, query } = useOrderContext();

  const addOrderIntoContextAndShowModal = useCallback(async () => {
    await query(orderInProgress?.rbiOrderId);
    setShowModal(true);
  }, [orderInProgress, query]);

  if (!orderInProgress) {
    return null;
  }

  return (
    <>
      {showModal && (
        <StyledModal
          onDismiss={() => setShowModal(false)}
          eventData={{
            modalAppearanceEventMessage: 'Order Details',
          }}
        >
          {serverOrder && (
            <OrderConfirmationPageContent loading={false} serverOrder={serverOrder} />
          )}
        </StyledModal>
      )}
      <OrderTrackingBanner message={message} onClick={addOrderIntoContextAndShowModal} />
    </>
  );
};
