import React, { useEffect, useMemo } from 'react';

import { ApolloProvider } from '@apollo/client';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useCdpContext } from 'state/cdp';
import { useLocale } from 'state/intl';
import { useGatewayFlags } from 'state/launchdarkly';

import { getClient } from './client';
import { withI18nContext } from './links/with-i18n-context';
import { withMParticle } from './links/with-mparticle';
import { withSessionId } from './links/with-session-id';

export const GraphQLProvider = ({ children }: IBaseProps) => {
  const { language, region } = useLocale();
  const gatewayFlags = useGatewayFlags();
  const client = useMemo(() => getClient(language, region), [gatewayFlags]); // eslint-disable-line react-hooks/exhaustive-deps
  const mparticle = useCdpContext();

  // update stateful links
  useEffect(() => {
    withI18nContext.setLocale(language, region);
  }, [client, language, region]);

  useEffect(() => {
    withSessionId.setSessionId(mparticle.sessionId);
  }, [mparticle.sessionId]);

  useEffect(() => {
    withMParticle.setMParticle(mparticle);
  }, [mparticle]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { useLazySanityQuery, useSanityQuery } from './hooks/use-sanity-query';
