import { Maybe } from 'graphql/jsutils/Maybe';

import {
  IFeatureInvitationCodeQuery,
  useFeatureInvitationCodeQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureInvitationCode {
  featureInvitationCodeLoading: boolean;
  featureInvitationCode: IFeatureInvitationCodeQuery['FeatureInvitationCode'];
  enableInvitationCode: Maybe<boolean>;
}

export const useFeatureInvitationCode = (): IUseFeatureInvitationCode => {
  const { featureInvitationCodeId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureInvitationCodeQuery({
    variables: { featureInvitationCodeId },
    skip: !featureInvitationCodeId,
  });

  const featureInvitationCode = data?.FeatureInvitationCode ?? null;
  const featureInvitationCodeLoading = featureIdsLoading || loading;
  const enableInvitationCode = featureInvitationCode?.enableInvitationCode;

  return {
    featureInvitationCodeLoading,
    featureInvitationCode,
    enableInvitationCode,
  };
};
