import React from 'react';

import { Meta } from 'react-meta-elements';

interface WithMetaRobotsRouteWrapperProps {
  children?: React.ReactNode;
  content: string;
}

export const WithMetaRobotsRouteWrapper: React.FC<WithMetaRobotsRouteWrapperProps> = ({
  children,
  content,
}) => (
  <>
    <Meta name="robots" content={content} />
    {children}
  </>
);
