import * as React from 'react';

import { CustomEventNames } from 'state/cdp/constants';

import RewardsLink from './rewards-link.base';

const RewardsLinkBK = () => (
  <RewardsLink onClickEventName={CustomEventNames.BUTTON_CLICK_BK_REWARDS} />
);

export default RewardsLinkBK;
