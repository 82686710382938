import delve from 'dlv';
import { noop } from 'lodash';

import { routes } from 'utils/routing';

import { AnyNode, LinkClickHandlerOptions, ListOfLinksProps } from './types';

export const getNodes = (
  entries: ListOfLinksProps['entries'],
  enableNutritionExplorerLink: boolean
): AnyNode[] => {
  if (!entries) {
    return [];
  }

  return entries.reduce((acc: AnyNode[], entry) => {
    if (!entry) {
      return acc;
    }

    const path = delve(entry, 'localePath.locale.current') || delve(entry, 'path.current', '');

    if (!enableNutritionExplorerLink && path.includes(routes.nutritionExplorer.slice(1))) {
      return acc;
    }

    const item: AnyNode =
      entry.__typename === 'NavigationSection' && Array.isArray(entry.pages)
        ? {
            type: 'array',
            title: delve(entry, 'name.locale', ''),
            pages: getNodes(entry.pages, enableNutritionExplorerLink),
          }
        : {
            type: 'single',
            title: delve(entry, 'localeTitle.locale') || delve(entry, 'title', ''),
            path: delve(entry, 'redirectUrl.locale') || path,
          };

    return [...acc, item];
  }, []);
};

export const createLinkClickHandler =
  ({ isExternal, onDismiss = noop }: LinkClickHandlerOptions) =>
  () => {
    if (isExternal) {
      onDismiss();
    }
  };
