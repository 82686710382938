import React, { FC, ReactNode, memo, useCallback, useState } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import delve from 'dlv';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';

import { Nullable } from '@rbi-ctg/frontend';
import ActionButton from 'components/action-button';
import ConfirmDialog, { IConfirmProps } from 'components/confirm-dialog';
import Dialog, { IDialogProps } from 'components/dialog';

export type DialogCb<T> = (data?: Nullable<T>) => void;

interface IUseDialogBaseArgs<T> {
  onConfirm?: DialogCb<T>;
  onCancel?: DialogCb<T>;
  onDismiss?: DialogCb<T>;
  onOpen?: DialogCb<T>;
  type?: string;
  showCancel?: boolean;
  showActionsButton?: boolean;
  init?: boolean;
  allowDismiss?: boolean;
}

type UseDialogComponentRequired<P> = {
  Component: React.ComponentType<Props<P>>;
  modalAppearanceEventMessage?: string;
};
type UseDialogMessageRequired<P> = {
  Component?: React.ComponentType<Props<P>>;
  modalAppearanceEventMessage: string;
};

type UseDialogArgs<T, P = {}> = IUseDialogBaseArgs<T> &
  (UseDialogComponentRequired<P> | UseDialogMessageRequired<P>);

export interface IUseDialogProps {
  heading?: string;
  body?: ReactNode;
  bodyComponent?: ReactNode;
  buttonLabel?: string;
  image?: ReactNode;
}

export interface IUseDialogState {
  message?: string;
  title?: string;
}

export interface IUseDialogComponentProps {
  onDismiss: VoidFunction;
  onConfirm: VoidFunction;
}

export type Props<P> = IUseDialogProps &
  Partial<IDialogProps> &
  Partial<IConfirmProps> &
  Omit<P, keyof IUseDialogComponentProps>;

export type UseDialogHook<T, P = {}> = [FC<Props<P>>, DialogCb<T>, Nullable<T>, VoidFunction];

export default function useDialogModal<T extends object, P = {}>({
  Component,
  onConfirm = noop,
  onCancel = noop,
  onDismiss = onCancel,
  onOpen = noop,
  showCancel = false,
  showActionsButton = true,
  init = false,
  allowDismiss = true,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
}: UseDialogArgs<T, P>): UseDialogHook<T, P> {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(init);
  const [pendingData, setPending] = useState<Nullable<T>>(null);
  const openDialog = useCallback(
    (data: Nullable<T>) => {
      onOpen(data);
      setPending(data);
      setOpen(true);
    },
    [onOpen]
  );
  const cancelDialog = useCallback(() => {
    onCancel(pendingData);
    setPending(null);
    setOpen(false);
  }, [onCancel, pendingData]);
  const dismissDialog = useCallback(() => {
    onDismiss(pendingData);
    setPending(null);
    if (allowDismiss) {
      setOpen(false);
    }
  }, [allowDismiss, onDismiss, pendingData]);
  const confirmDialog = useCallback(() => {
    onConfirm(pendingData);
    setPending(null);
    setOpen(false);
  }, [onConfirm, pendingData]);

  const DialogComponent: FC<Props<P>> = useCallback(
    ({
      body = formatMessage({ id: 'errorProcessingRequest' }),
      buttonLabel = formatMessage({ id: 'okay' }),
      heading = formatMessage({ id: 'somethingWrong' }),
      image,
      /**
       * The DialogComponent can be rendered using different props
       * than the `useDialogComponent` hook is called with.
       */
      modalAppearanceEventMessage: modalAppearanceEventMessageOverride,
      ...rest
    }) => {
      if (!open) {
        return null;
      }
      // dlv returns the provided default if first arg is null,
      // so cast to T to shut the compiler up about the TypeError
      const message = delve(pendingData as T, 'message', null);
      const title = delve(pendingData as T, 'title', null);

      if (Component) {
        return (
          <Component
            onDismiss={dismissDialog}
            onConfirm={confirmDialog}
            modalAppearanceEventMessage={modalAppearanceEventMessageOverride}
            heading={title! || heading}
            {...(rest as P)}
          />
        );
      }

      return showCancel ? (
        <ConfirmDialog
          heading={title! || heading}
          body={message || body}
          image={image}
          confirmLabel={buttonLabel}
          onConfirm={confirmDialog}
          onCancel={cancelDialog}
          onDismiss={dismissDialog}
          modalAppearanceEventMessage={
            modalAppearanceEventMessageOverride || modalAppearanceEventMessage!
          }
          {...rest}
        />
      ) : (
        <Dialog
          heading={title! || heading}
          body={message || body}
          image={image}
          onDismiss={dismissDialog}
          actions={
            showActionsButton ? (
              <Box margin="1rem" width="100%" minWidth="auto">
                <ActionButton
                  onlyIcon
                  fullWidth
                  onClick={confirmDialog}
                  data-testid="dialog-confirm-btn"
                >
                  {buttonLabel}
                </ActionButton>
              </Box>
            ) : null
          }
          modalAppearanceEventMessage={
            modalAppearanceEventMessageOverride || modalAppearanceEventMessage!
          }
          {...rest}
        />
      );
    },
    [
      formatMessage,
      open,
      pendingData,
      Component,
      showCancel,
      confirmDialog,
      cancelDialog,
      dismissDialog,
      modalAppearanceEventMessage,
      showActionsButton,
    ]
  );

  return [memo(DialogComponent), openDialog, pendingData, dismissDialog];
}
