import React, { useState } from 'react';

import { PrimaryButton } from '@rbilabs/components-library';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { IServerOrder } from '@rbi-ctg/menu';
import ModalRating from 'components/modal-rating';
import QRCode from 'components/qrcode';
import { RefillDrinksQRCodeButton } from 'components/refill-drinks-qr-code-button';
import { AddressHeader, AddressSubheader } from 'components/store-card/styled';
import useDialogModal from 'hooks/use-dialog-modal';
import useOrderNumber from 'hooks/use-order-number';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/order';
import { useOrderEdit } from 'state/order/hooks/use-order-edit';
import { useOrderTimedFire } from 'state/order/hooks/use-order-timed-fire';
import { CartPaymentType, OrderStatus } from 'state/order/types';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { primitive } from 'styles/constants/primitives';
import { TLocalizationKey } from 'types/i18n';
import { fullBrandName } from 'utils/environment';
import { checkIfDateIsWithinCloseTimeAndMinutes, readableCloseHourToday } from 'utils/restaurant';
import { routes } from 'utils/routing';

import IconRedirect from '../../components/icons/redirect';

import { CartConfirmation } from './cart-confirmation';
import { CashPaymentReminder } from './cash-payment-reminder';
import CheckoutIcon from './checkout-icon';
import {
  LogoContainer,
  NarrowSection,
  OrderId,
  OrderIssues,
  PickupPinCode,
  PickupPinMessage,
  PickupPinWrapper,
  QRContainer,
  QRWrapper,
  verticalCenteringStyles,
} from './common';
import PickupCard from './pickup-card';
import PickupDialog from './pickup-dialog';
import { PrepareNowButton } from './prepare-now';
import { ButtonWrapper, Heading, NewLayoutContainer, NewLayoutContainerWrapper } from './styled';
import { SubheadingText } from './subheading-text';
import { Text } from './text';

const Container = styled.div`
  background-color: ${Styles.color.background};
  padding-block-start: 2.5rem;
  padding-block-end: 0.5rem;
  padding-inline: 0;
  padding-block-end: max(0.5rem, env(safe-area-inset-bottom));
  position: relative;
  flex: 2;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  align-items: center;
  width: 100%;
  margin: 0 auto;
  ${verticalCenteringStyles}
  position: absolute;
`;

const Content = styled.div`
  width: 100%;
  /* Fix for iOS Scroll */
  ${Styles.mobile} {
    padding-block-end: 2rem;
  }
`;

const InfoLine = styled.p`
  text-transform: capitalize;
  margin: 0;
`;

const InfoWrapper = styled.div`
  border: 1px solid ${Styles.color.grey.five};
  padding: 1.25rem;
  border-radius: ${Styles.borderRadius};
  margin: 1rem 0;
`;

const getServiceModeTextKey = (serviceMode: ServiceMode): TLocalizationKey => {
  switch (serviceMode) {
    case ServiceMode.EAT_IN:
      return 'dineIn';
    case ServiceMode.TAKEOUT:
      return 'pickUp';
    case ServiceMode.CURBSIDE:
      return 'curbside';
    case ServiceMode.DRIVE_THRU:
      return 'driveThru';
    case ServiceMode.TABLE_SERVICE:
      return 'tableService';
    default:
      return 'dineIn';
  }
};

const ANONYMOUS_CUSTOMER = 'ANONYMOUS_CUSTOMER';

const getCustomerName = (serverOrder: IServerOrder, formatMessage: Function): string => {
  const orderCustomerName = serverOrder.cart.customerName;

  if (orderCustomerName === ANONYMOUS_CUSTOMER) {
    return formatMessage({
      id: 'yourName',
    });
  }

  return orderCustomerName;
};

const qrCodeOptions = {
  margin: 1,
  scale: 4,
  color: {
    dark: primitive.$black,
    light: primitive.$white,
  },
};

function PickupConfirmed({ serverOrder }: { serverOrder: IServerOrder }) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { store } = useStoreContext();
  const { serviceMode } = useServiceModeContext();
  const isShowingOrderingIssue = useFlag(LaunchDarklyFlag.SHOW_ORDERING_ISSUE);
  const enableClosingTimeDialog = useFlag(LaunchDarklyFlag.ENABLE_CLOSING_TIME_DIALOG);
  const enablePickupTimeModification = useFlag(
    LaunchDarklyFlag.ENABLE_SCHEDULED_PICKUP_TIME_MODIFICATION
  );

  // Tech Debit to improve this component -> https://rbictg.atlassian.net/browse/TRX-895
  const newLayout = useFlag(LaunchDarklyFlag.CHANGE_PICKUP_TIME_IN_ORDER_CONFIRMATION);
  const { pickupDate } = useOrderTimedFire({ serverOrder });
  const { canOrderBeEdited, lastCallToEditOrderTime } = useOrderEdit({ pickupDate });
  const [isOrderRefunded, setIsOrderRefunded] = useState(false);
  const { formatCurrencyForLocale } = useUIContext();

  const serverOrderServiceMode = serverOrder.cart.serviceMode;
  const paidWithCash = serverOrder.cart.payment?.cardType === CartPaymentType.CASH;
  const checkPickUpDriveThruSM =
    serviceMode === ServiceMode.TAKEOUT || serviceMode === ServiceMode.DRIVE_THRU;
  const [PickupConfirmationModal] = useDialogModal({
    Component: PickupDialog,
    init:
      checkPickUpDriveThruSM && !!store.driveThruHours && enableClosingTimeDialog
        ? checkIfDateIsWithinCloseTimeAndMinutes(store.driveThruHours, new Date(Date.now()), 60)
        : false,
    showCancel: false,
  });

  const fireOrderInTimeSeconds = serverOrder.fireOrderIn || 0;
  const isOrderTableService = serverOrderServiceMode === ServiceMode.TABLE_SERVICE;
  const isOrderFiredIn = fireOrderInTimeSeconds === 0;
  const shouldShowAppRating = serverOrder.status === OrderStatus.INSERT_SUCCESSFUL;

  // String.fromCharCode(160) puts a non-breaking space in to prevent "10:03" and "pm" from breaking onto different lines in this heading
  const pickupTime = format(pickupDate, 'h:mm aaaa').replace(' ', String.fromCharCode(160));

  const serviceModeText = formatMessage({ id: getServiceModeTextKey(serverOrderServiceMode) });

  const orderNumber = useOrderNumber(serverOrder);

  const name = getCustomerName(serverOrder, formatMessage);

  const { fulfillmentDetails } = serverOrder;

  const headingText = isOrderFiredIn
    ? isOrderTableService
      ? formatMessage(
          { id: 'orderXWillBeComingSoon' },
          { orderNumber: orderNumber ? `#${orderNumber}` : '', linebreak: <br /> }
        )
      : formatMessage(
          { id: 'orderXWillBeReadySoon' },
          { orderNumber: orderNumber ? `#${orderNumber}` : '', linebreak: <br /> }
        )
    : formatMessage(
        { id: 'orderXWillBeReadyAtY' },
        {
          orderNumber: orderNumber ? `#${orderNumber}` : '',
          readyAtTime: pickupTime,
          linebreak: <br />,
        }
      );

  const pickupAddress = [
    serverOrder.cart.storeAddress.addressLine1,
    serverOrder.cart.storeAddress.addressLine2,
    serverOrder.cart.storeAddress.city,
    serverOrder.cart.storeAddress.state,
    serverOrder.cart.storeAddress.zip,
  ]
    .filter(Boolean)
    .join(', ');

  const orderNumberText = formatMessage({
    id: 'orderNumber',
  });

  const method = formatMessage(
    {
      id: 'method',
    },
    {
      serviceMode: <strong>{serviceModeText}</strong>,
    }
  );

  const location = formatMessage({
    id: 'location',
  });

  const pickupOrderAlert = formatMessage(
    { id: 'pickUpOrderSuccessfulAlert' },
    {
      orderNumber,
      brand: fullBrandName(),
      address: `${serverOrder.cart.storeAddress.addressLine1} ${serverOrder.cart.storeAddress.addressLine2}`,
    }
  );

  const showCashPaymentMessage = paidWithCash && serverOrder.cart.totalCents > 0;
  const hasOrderNumberAndOrderNotRefunded = orderNumber && !isOrderRefunded;
  const noNewLayoutAndOrderNotRefunded = !newLayout && !isOrderRefunded;
  const isDriveThru = serverOrderServiceMode === ServiceMode.DRIVE_THRU;

  return (
    <Container>
      <ContentContainer>
        <Content>
          <LogoContainer>
            <CheckoutIcon />
          </LogoContainer>
          <NarrowSection>
            {!newLayout && (
              <Heading data-testid="order-preparation-confirmation">{headingText}</Heading>
            )}
            {newLayout && !isOrderRefunded && canOrderBeEdited() && (
              <Heading data-testid="order-preparation-confirmation">
                {formatMessage({ id: 'orderPlaced' })}
              </Heading>
            )}
            {newLayout && !isOrderRefunded && !canOrderBeEdited() && (
              <Heading data-testid="order-preparation-confirmation">
                {formatMessage({ id: 'orderReadySoon' })}
              </Heading>
            )}
            {newLayout && isOrderRefunded && (
              <Heading data-testid="order-preparation-confirmation">
                {formatMessage({ id: 'orderCanceled' })}
              </Heading>
            )}
          </NarrowSection>
          {showCashPaymentMessage && <CashPaymentReminder serviceMode={serverOrderServiceMode} />}

          {fulfillmentDetails?.pickupQrCode && (
            <QRContainer>
              <QRWrapper>
                <QRCode
                  barcode={fulfillmentDetails.pickupQrCode}
                  options={qrCodeOptions}
                  data-testid="qr-code"
                />
              </QRWrapper>
            </QRContainer>
          )}
          <NarrowSection>
            {newLayout && !isOrderRefunded && canOrderBeEdited() && !isDriveThru && (
              <Text>
                {formatMessage({ id: 'weArePreparingYourOrderWithTime' }, { time: pickupTime })}
              </Text>
            )}
            {newLayout && !isOrderRefunded && !canOrderBeEdited() && !isDriveThru && (
              <Text>{formatMessage({ id: 'weArePreparingYourOrder' })}</Text>
            )}
            {newLayout && isOrderRefunded && (
              <Text>
                {formatMessage(
                  { id: 'cancelOrderSubtitle' },
                  { price: formatCurrencyForLocale(serverOrder.cart.totalCents ?? 0) }
                )}
              </Text>
            )}
            {newLayout && !isOrderRefunded && isDriveThru && (
              <SubheadingText
                isOrderFiredIn={isOrderFiredIn}
                name={name}
                pickupTime={pickupTime}
                lastCallToEditOrderTime={lastCallToEditOrderTime}
                canOrderBeEdited={canOrderBeEdited() && enablePickupTimeModification}
                pickupPin={fulfillmentDetails?.pickupPin}
                serviceMode={serverOrderServiceMode}
              />
            )}
            {!newLayout && !isOrderRefunded && (
              <SubheadingText
                isOrderFiredIn={isOrderFiredIn}
                name={name}
                pickupTime={pickupTime}
                lastCallToEditOrderTime={lastCallToEditOrderTime}
                canOrderBeEdited={canOrderBeEdited() && enablePickupTimeModification}
                pickupPin={fulfillmentDetails?.pickupPin}
                serviceMode={serverOrderServiceMode}
              />
            )}
            {fulfillmentDetails?.pickupPin && !isOrderRefunded && (
              <PickupPinWrapper>
                <PickupPinCode>{fulfillmentDetails.pickupPin}</PickupPinCode>
                <PickupPinMessage>{formatMessage({ id: 'lockerCode' })}</PickupPinMessage>
              </PickupPinWrapper>
            )}
            {(hasOrderNumberAndOrderNotRefunded || noNewLayoutAndOrderNotRefunded) && (
              <InfoWrapper>
                {hasOrderNumberAndOrderNotRefunded && (
                  <InfoLine data-testid="order-number">
                    {orderNumberText}: <strong>{orderNumber}</strong>
                  </InfoLine>
                )}
                {noNewLayoutAndOrderNotRefunded && (
                  <>
                    <InfoLine data-testid="order-method">{method}</InfoLine>
                    <InfoLine data-testid="order-store-location">
                      {location}:{' '}
                      <strong>{store.customerFacingAddress?.locale || pickupAddress}</strong>
                    </InfoLine>
                  </>
                )}
              </InfoWrapper>
            )}
            <PrepareNowButton serverOrder={serverOrder} />
          </NarrowSection>

          {!isOrderRefunded && (
            <NarrowSection data-testid="store-details">
              <PickupCard
                confirmed
                serverOrder={serverOrder}
                newLayout={newLayout}
                onOrderRefunded={() => setIsOrderRefunded(true)}
              />
            </NarrowSection>
          )}
          <NarrowSection>
            <Text as="div">
              <CartConfirmation
                rbiOrderId={serverOrder.rbiOrderId}
                totalPrice={serverOrder.cart.totalCents}
                entries={serverOrder.cart.cartEntries}
              />
            </Text>
            <ButtonWrapper>
              <RefillDrinksQRCodeButton posOrderId={serverOrder?.posOrderId} />
            </ButtonWrapper>
          </NarrowSection>
        </Content>
        {!newLayout && isShowingOrderingIssue && <OrderIssues />}
        {newLayout && isShowingOrderingIssue && (
          <NarrowSection data-testid="help" style={{ width: '100%', cursor: 'pointer' }}>
            <NewLayoutContainer onClick={() => navigate(routes.support)}>
              <NewLayoutContainerWrapper>
                <div>
                  <AddressHeader>
                    <h2 data-private data-dd-privacy="mask" data-testid="restaurant-address">
                      {formatMessage({ id: 'needHelp' })}
                    </h2>
                  </AddressHeader>
                  <AddressSubheader>
                    {formatMessage({ id: 'needHelpVisitSupport' })}
                  </AddressSubheader>
                </div>
                <div>
                  <IconRedirect />
                </div>
              </NewLayoutContainerWrapper>
            </NewLayoutContainer>
          </NarrowSection>
        )}
        {isOrderRefunded && (
          <NarrowSection data-testid="menu" style={{ width: '100%', marginTop: '24px' }}>
            <PrimaryButton
              size={'large'}
              fullWidth={true}
              aria-label={formatMessage({ id: 'backToMenu' })}
              data-testid="back-menu"
              onClick={() => navigate('/')}
            >
              {formatMessage({ id: 'backToMenu' })}
            </PrimaryButton>
          </NarrowSection>
        )}
        <OrderId orderId={serverOrder.rbiOrderId} />
      </ContentContainer>
      <VisuallyHidden role="alert">{pickupOrderAlert}</VisuallyHidden>
      <PickupConfirmationModal data-testid="store-closing-hour-dialog">
        {formatMessage(
          { id: 'dontForgetToPickYourOrder' },
          { time: readableCloseHourToday(store.driveThruHours) }
        )}
      </PickupConfirmationModal>
      {shouldShowAppRating && <ModalRating />}
    </Container>
  );
}

export default React.memo(PickupConfirmed);
