import React from 'react';

import { useIntl } from 'react-intl';

import { AppInfoItem } from './app-info-item';
import { useAppInfo } from './use-app-info';

export const AppInfo: React.FC = () => {
  const { formatMessage } = useIntl();
  const { appflowBuildId, build, deviceId, version, releaseTagRef } = useAppInfo();

  return (
    <>
      <AppInfoItem label={formatMessage({ id: 'aboutApp.versionName' })} value={version} />
      <AppInfoItem label={formatMessage({ id: 'aboutApp.buildNumber' })} value={build} />
      <AppInfoItem label={formatMessage({ id: 'aboutApp.deviceId' })} value={deviceId} />
      <AppInfoItem
        label={formatMessage({ id: 'aboutApp.appflowBuildId' })}
        value={appflowBuildId}
      />
      <AppInfoItem
        label={formatMessage({ id: 'aboutApp.releaseTagReference' })}
        value={releaseTagRef}
      />
    </>
  );
};
