import styled from 'styled-components';

export const Scroller = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  overflow-y: auto;
  padding-block-start: 52px;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ${Styles.mobileLandscape} {
    padding-block-start: 0;
  }

  ${Styles.desktop} {
    padding-block-start: 70px;
  }
`;
