import { useEffect } from 'react';

import { PREVENT_FOCUS_CLASS } from './constants';
import { FocusToggleStyles } from './styled';

/**
 * Toggles whether or not `:focus` styling is enabled on the webpage.
 *
 * To use this hook, you must include the `FocusToggleStyles` component.
 */
export const useFocusToggle = (allowFocus: boolean = true, rootElementSelector = 'body') => {
  useEffect(() => {
    if (allowFocus) {
      document.querySelectorAll(rootElementSelector).forEach(element => {
        element.classList.remove(PREVENT_FOCUS_CLASS);
      });
    } else {
      document.querySelectorAll(rootElementSelector).forEach(element => {
        element.classList.add(PREVENT_FOCUS_CLASS);
      });
    }
  }, [allowFocus, rootElementSelector]);

  return { FocusToggleStyles };
};
