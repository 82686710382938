import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';

type IconProps = React.ComponentProps<typeof Icon>;

type StoreCardIconProps = Pick<IconProps, 'icon' | 'color'>;

export const StoreCardIcon: React.FC<StoreCardIconProps> = ({ icon, color }) => {
  return <Icon aria-hidden color={color} height="1.25rem" icon={icon} width="1.25rem" />;
};
