import { Button } from '@rbilabs/components-library';
import styled from 'styled-components';

import LoyaltyMyCodeIcon from 'components/icons/loyalty-my-code';

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.125rem;
  height: 2.125rem;
  margin-inline-start: 0.5rem;
  padding: 0;
`;

export const StyledMyCodeIcon = styled(LoyaltyMyCodeIcon)`
  width: 0.95rem;
  height: 0.95rem;
`;
