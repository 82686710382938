import { IRoundingRulesFragment } from 'generated/sanity-graphql';

export enum RoundingType {
  standard = 'standard',
  ceiling = 'ceiling',
  floor = 'floor',
}

const validRoundingTypes: string[] = [
  RoundingType.standard,
  RoundingType.ceiling,
  RoundingType.floor,
];

type RoundingRules = NonNullable<IRoundingRulesFragment>;
export const applyRounding = ({
  value,
  roundingRules,
}: {
  value: number;
  roundingRules: RoundingRules;
}) => {
  const { enabled, roundingType, decimalPlaces } = roundingRules;
  if (!enabled || !roundingType || typeof decimalPlaces !== 'number') {
    return value;
  }
  if (!validRoundingTypes.includes(roundingType)) {
    return value;
  }

  // shift decimal `decimalPlaces` times to the right
  let workingValue = value * Math.pow(10, decimalPlaces);
  // apply rounding
  switch (roundingType) {
    case RoundingType.standard:
      workingValue = Math.round(workingValue);
      break;
    case RoundingType.ceiling:
      workingValue = Math.ceil(workingValue);
      break;
    case RoundingType.floor:
      workingValue = Math.floor(workingValue);
      break;
    default:
      break;
  }
  // shift decimal `decimalPlaces` times to the left
  workingValue = workingValue * Math.pow(10, -decimalPlaces);
  // eliminate FP errors
  workingValue = +workingValue.toFixed(decimalPlaces);
  return workingValue;
};
