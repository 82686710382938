import type * as BrazeNS from '@braze/web-sdk';

import { getApiKey, isWeb } from 'utils/environment';

type BrazeSDK = typeof BrazeNS;

declare global {
  interface Window {
    braze: BrazeSDK;
  }
}

export const getSdk = (): BrazeSDK => window.braze;

// Because the Braze web SDK update is done in mParticle UI we need to support both 3.x and 4.x versions
// TODO: Remove support for Braze web SDK 3.x https://rbictg.atlassian.net/browse/ICFE-207
export const enableSDK = () => {
  return window.braze.enableSDK();
};

export const disableSDK = () => {
  return window.braze.disableSDK();
};

export const toggleContentCards = (parentNode: Element) => {
  return window.braze.toggleContentCards(parentNode);
};

export const automaticallyShowInAppMessages = () => {
  return window.braze.automaticallyShowInAppMessages();
};

export const initialize = () => {
  const apiKey = getApiKey('braze');
  const cluster = getApiKey('brazeCluster');
  return isWeb ? window?.braze?.initialize(apiKey, { baseUrl: cluster }) : false;
};

/**
 * The function `wipeBrazeUserData` checks for the existence of `window.braze`
 * and calls the `wipeData` method accordingly to wipe user data.
 */
export const wipeBrazeUserData = () => {
  return isWeb ? window?.braze?.wipeData() : false;
};

export const openSession = () => getSdk().openSession();

export const changeUser = (userId: string) => getSdk().changeUser(userId);

export const requestContentCardsRefresh = () => getSdk().requestContentCardsRefresh();

export const getCachedContentCards = () => getSdk().getCachedContentCards();

export const requestImmediateDataFlush = () => getSdk().requestImmediateDataFlush();

export const getUser = () => getSdk().getUser();
