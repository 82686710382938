import styled, { css } from 'styled-components';

import { HorizontalPosition, IColorable, IHorizontalPositionable } from './types';

const horizontalPositionToTextAlignMap = {
  [HorizontalPosition.LEFT]: 'left',
  [HorizontalPosition.CENTER]: 'center',
  [HorizontalPosition.RIGHT]: 'right',
};

const BaseHeadlineStyles = css<IColorable & IHorizontalPositionable>`
  font-size: 3rem;
  line-height: 110%;
  font-family: ${Styles.fontFamily.base};
  color: ${({ color }) => color};
  text-align: ${({ horizontalPosition }) => horizontalPositionToTextAlignMap[horizontalPosition]};
`;

export const Headline1 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseHeadlineStyles}
  font-size: 6rem;
`;

export const Headline2 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseHeadlineStyles}
  font-size: 3.75rem;
`;

export const Headline3 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseHeadlineStyles}
  font-size: 3rem;
`;

export const Headline4 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseHeadlineStyles}
  font-size: 2.125rem;
`;

export const Headline5 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseHeadlineStyles}
  font-size: 1.5rem;
`;

export const Headline6 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseHeadlineStyles}
  font-size: 1.25rem;
`;

const BaseSubtitleStyles = css<IColorable & IHorizontalPositionable>`
  color: ${({ color }) => color};
  text-align: ${({ horizontalPosition }) => horizontalPositionToTextAlignMap[horizontalPosition]};
  line-height: 125%;
`;

export const Subtitle1 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseSubtitleStyles}
  font-size: 1.125rem;
`;

export const Subtitle2 = styled.div<IColorable & IHorizontalPositionable>`
  ${BaseSubtitleStyles}
  font-size: 0.875rem;
`;

const BaseBodyStyles = css<IColorable & IHorizontalPositionable>`
  line-height: 140%;
  color: ${({ color }) => color};
  text-align: ${({ horizontalPosition }) => horizontalPositionToTextAlignMap[horizontalPosition]};
`;

export const Body1 = styled.p<IColorable & IHorizontalPositionable>`
  ${BaseBodyStyles}
  font-size: 1rem;
`;
export const Body2 = styled.p<IColorable & IHorizontalPositionable>`
  ${BaseBodyStyles}
  font-size: 0.875rem;
`;

export const Caption = styled.p<IColorable & IHorizontalPositionable>`
  ${BaseBodyStyles}
  font-size: 0.813rem;
  line-height: 140%;
`;
