import React, { FC } from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { parseStringifiedJSON } from 'utils/parse-string';

import { PriceAndCalories } from './price-and-calories';
import { ProductSkeleton } from './product.skeleton';
import {
  DetailsContainer,
  ImageContainer as ImageContainerBase,
  Layout,
  ProductDescription,
  ProductName,
  QuantityBadge,
  Wrapper,
} from './product.styled';
import SelectionsList from './selections-list';
import { ProductProps } from './types';

/**
 *
 * Product is used to display a compact product to guests
 * Anywhere we need to show a menu item outside the menu we should use this component
 *
 */
const Product: FC<ProductProps> = ({
  image,
  fallbackImage,
  name,
  price,
  calories,
  note,
  selections,
  expandable = true,
  quantity,
  loading = false,
  displayMode = 'list',
  imageSize,
  isProductNameBold = false,
  description,
}) => {
  const { formatMessage } = useIntl();

  // Should not render the product if the name is empty and is not loading
  if (!name && !loading) {
    return null;
  }

  const isInlineLayout = displayMode === 'list' || !!selections?.length;

  return (
    <Wrapper data-testid="product">
      {loading ? (
        <ProductSkeleton $inline={isInlineLayout} />
      ) : (
        <Layout $inline={isInlineLayout}>
          {(image || fallbackImage) && (
            <ImageContainerBase $size={imageSize} data-testid="product-image">
              {image && (
                <Picture
                  objectFitContain={true}
                  image={parseStringifiedJSON({ value: image })}
                  alt={`${formatMessage({ id: 'product' })} ${name}`}
                />
              )}
              {!image &&
                fallbackImage &&
                fallbackImage({
                  width: 55,
                  height: 55,
                })}
              {quantity && (
                <QuantityBadge>
                  <span aria-hidden>{quantity}x</span>
                  <VisuallyHidden>
                    {formatMessage({ id: 'xItems' }, { x: quantity })}
                  </VisuallyHidden>
                </QuantityBadge>
              )}
            </ImageContainerBase>
          )}
          <DetailsContainer>
            {note}
            <ProductName
              htmlFor={`radio-${name?.replace(/\s+/g, '')}`}
              data-testid="product-name"
              isProductNameBold={isProductNameBold}
            >
              {name}
            </ProductName>
            <ProductDescription>{description}</ProductDescription>
            <PriceAndCalories price={price} calories={calories} />
            {selections && (
              <SelectionsList selections={selections} id={name || ''} expandable={expandable} />
            )}
          </DetailsContainer>
        </Layout>
      )}
    </Wrapper>
  );
};

export default Product;
