import { useEffect } from 'react';

import { usePrevious } from 'hooks/use-previous';
import { useLocationContext } from 'state/location';

export const useEffectOnUrlChange = (callback: VoidFunction) => {
  const {
    location: { pathname },
  } = useLocationContext();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    if (previousPathname !== pathname) {
      callback();
    }
    // disabling the line to avoid unnecessary executions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, previousPathname]);
};
