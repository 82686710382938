import { formatDistanceToNowStrict as formatDistanceToNowStrictOrig } from 'date-fns';
import { enGB } from 'date-fns/locale';

type TFormatDistanceToNowConfig = Parameters<typeof formatDistanceToNowStrictOrig>[1];

export type TFormatDistanceToNowFn = (
  date: Date | number,
  config?: TFormatDistanceToNowConfig
) => string;

export const createFormatDistanceToNow = (
  initialConfig: TFormatDistanceToNowConfig = {}
): TFormatDistanceToNowFn => {
  return (date, config) => formatDistanceToNowStrictOrig(date, { ...initialConfig, ...config });
};

export const formatDistanceToNow = createFormatDistanceToNow({ locale: enGB });
