import { useEffect } from 'react';

import { IUseAlertOrderLimit } from './types';

const useAlertOrderLimit = ({
  appliedLoyaltyRewards,
  calculateCartTotal,
  alertOrderLimit,
  checkoutPriceLimit,
  setCartPriceLimitExceeded,
  setIsCartPriceAboveMinimumSpend,
  setMinimumSpendAmount,
  isCatering,
  checkoutCateringPriceLimit = 0,
}: IUseAlertOrderLimit) => {
  useEffect(() => {
    const limit = isCatering ? checkoutCateringPriceLimit : checkoutPriceLimit;
    const priceLimitExceeded = calculateCartTotal() > limit;
    if (Object.keys(appliedLoyaltyRewards).length > 0) {
      let minimumSpendAmount: number[] = [];
      const minSpend = Object.keys(appliedLoyaltyRewards).map(reward => {
        if (
          appliedLoyaltyRewards[reward].minimumSpend != null &&
          appliedLoyaltyRewards[reward].minimumSpend != undefined
        ) {
          const minimumSpend: number = appliedLoyaltyRewards[reward].minimumSpend as number;
          minimumSpendAmount.push(minimumSpend);
          return calculateCartTotal() > minimumSpend;
        } else {
          return true;
        }
      });
      setIsCartPriceAboveMinimumSpend(!minSpend.includes(false));
      setMinimumSpendAmount([...minimumSpendAmount]);
    } else {
      setIsCartPriceAboveMinimumSpend(true);
    }

    if (priceLimitExceeded) {
      alertOrderLimit();
    }

    setCartPriceLimitExceeded(priceLimitExceeded);
  }, [
    calculateCartTotal,
    alertOrderLimit,
    checkoutPriceLimit,
    isCatering,
    checkoutCateringPriceLimit,
    setCartPriceLimitExceeded,
  ]);
};

export default useAlertOrderLimit;
