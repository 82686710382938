/**
 * Removes the element at the given index from the list.
 */
export const removeElementAtIndexFromList = (list: Array<any>, index: number) => {
  if (index < 0) {
    return list;
  }

  return list.slice(0, index).concat(list.slice(index + 1, list.length));
};

/**
 * Replace the element
 */
export const replaceElementAtIndex = <T>(list: Array<T>, element: T, index: number) => {
  // invalid index
  if (index < 0 || index > list.length - 1) {
    return list;
  }

  return list.slice(0, index).concat(element, list.slice(index + 1, list.length));
};
