import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${p => p.theme.token('background-hero-light')};
  color: ${p => p.theme.token('text-default')};
  flex-grow: 1;
  container-type: inline-size;
`;

export const ClickableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  ${Styles.desktopLarge} {
    justify-content: flex-end;
    flex-grow: 1;
    font-size: 0.983rem;
  }
`;

export const Icon = styled.div`
  margin-inline-end: 0.5rem;
`;

export const DetailsContainer = styled.div`
  display: flex;
  align-items: baseline;
  column-gap: 0.5rem;

  ${Styles.desktopLarge} {
    @container (width < 400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const Heading = styled.div`
  font-family: ${Styles.fontFamily.base};
  white-space: nowrap;
  font-size: 1rem;

  ${Styles.desktopLarge} {
    font-size: 1.125rem;

    @container (width < 290px) {
      white-space: normal;
      line-height: 1.1em;
      text-align: start;
    }
  }
`;

export const Details = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 57vw;
  font-size: 0.937rem;
  width: 9.5rem;
`;

export const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 1;
`;
