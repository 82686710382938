import { useMemo } from 'react';

import { IBaseItem, ISanityItem } from '@rbi-ctg/menu';
import { ServiceMode } from 'generated/rbi-graphql';
import { useFeatureMenu } from 'hooks/use-feature-menu';
import { useImagesByChannels } from 'hooks/use-images-by-channels';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';

import { existsInEntries } from './utils';

const useFeatureMenuUpsell = () => {
  const { changeImageByChannel } = useImagesByChannels();
  const { serviceMode } = useServiceModeContext();
  const { filterForAvailability } = useMenuContext();
  const { cartEntries } = useOrderContext();

  const { featureMenu, featureMenuLoading } = useFeatureMenu();

  const upsellType: string = useMemo(() => {
    switch (serviceMode) {
      case ServiceMode.DELIVERY:
        return 'upsellItemsAtCheckoutDelivery';
      case ServiceMode.CATERING_PICKUP:
      case ServiceMode.CATERING_DELIVERY:
        return 'upsellItemsAtCheckoutCatering';
      default:
        return 'upsellItemsAtCheckoutRestaurant';
    }
  }, [serviceMode]);

  const filteredItems: IBaseItem[] = useMemo(() => {
    const _filtered = (featureMenu?.[upsellType] || []).filter(
      (item: IBaseItem) =>
        // Check if doesn't exists in the cart
        !existsInEntries(item, cartEntries) &&
        // Check if is available
        Boolean(filterForAvailability(item as ISanityItem))
    );

    return changeImageByChannel(_filtered);
  }, [cartEntries, featureMenu, filterForAvailability, upsellType, changeImageByChannel]);

  return {
    items: filteredItems,
    loading: featureMenuLoading,
  };
};

export default useFeatureMenuUpsell;
