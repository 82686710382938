import { CartEntryObject } from './types';

const reduceCartEntryChildren = (
  accumulatedPrice: number,
  { children = [], price = 0 }: CartEntryObject
): number => accumulatedPrice + children.reduce(reduceCartEntryChildren, price);

export const priceForCartEntry = (item: CartEntryObject): number =>
  (item.children || []).reduce(reduceCartEntryChildren, 0) * (item.quantity || 1) +
  (item.price || 0);

export default priceForCartEntry;
