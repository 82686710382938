import { HandlerType, StatusType, datadogLogs } from '@datadog/browser-logs';

import LocalStorage from 'utils/cognito/storage';
import {
  appVersionCode,
  brand,
  env,
  getConfigValue,
  isLocalDev,
  platform,
} from 'utils/environment';
import { StorageKeys } from 'utils/local-storage';

import { DEFAULT_SITE, sharedContext } from './constants';
import { Context } from './types';
import { createLogsBeforeSendCallback } from './utils';

export { StatusType } from '@datadog/browser-logs';

export const getHandler = (isLocalDev: boolean): HandlerType =>
  isLocalDev ? HandlerType.console : HandlerType.http;

export const init = (
  context: Context,
  configuredSampleRate?: number,
  ddSampleRateMparticleEvents?: number
) => {
  const defaultRate = isLocalDev ? 100 : 1;
  const sampleRate = typeof configuredSampleRate === 'number' ? configuredSampleRate : defaultRate;
  const sampleRateMParticle =
    typeof ddSampleRateMparticleEvents === 'number' ? ddSampleRateMparticleEvents : sampleRate;

  const logsConfig = getConfigValue('datadog').logs;
  const clientToken = logsConfig?.clientToken;
  const site = logsConfig?.dataCenter ?? DEFAULT_SITE;

  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    env: env(),
    service: `${brand()}_${platform()}`,
    version: appVersionCode(),
    beforeSend: createLogsBeforeSendCallback(sampleRate, sampleRateMParticle),
  });

  datadogLogs.setGlobalContext({
    ...datadogLogs.getGlobalContext(),
    ...sharedContext,
    ...context,
  });

  datadogLogs.logger.setHandler(getHandler(isLocalDev));
};

const UNKNOWN_ERROR_MESSAGE: string = 'Unknown application error. Unable to parse error message.';

interface DataDogLogger {
  context?: Context;
  message: string | Error;
  status: StatusType;
}

export function dataDogLogger({ message, context = {}, status }: DataDogLogger): void {
  const ctx = {
    userId: LocalStorage?.getItem(StorageKeys.USER)?.cognitoId,
    ...context,
  };

  const strMessage: string =
    typeof message === 'string' ? message : message?.message || UNKNOWN_ERROR_MESSAGE;

  datadogLogs.logger.log(strMessage, ctx, status);
}

export function setContextProperty(key: string, value: string) {
  datadogLogs.logger.setContextProperty(key, value);
}
