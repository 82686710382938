import logger from 'utils/logger';

// performance marks can be used to
// measure the duration an event takes
// via the window.performance api
export enum PerformanceMarks {
  COMMIT_END = 'marks.commit_order.end',
  COMMIT_START = 'marks.commit_order.start',
  PRICE_END = 'marks.price_order.end',
  PRICE_START = 'marks.price_order.start',
}

export enum Measures {
  COMMIT = 'measure.commit_order',
  PRICE = 'measure.price_order',
}

export const setMark = (mark: PerformanceMarks) => {
  try {
    window.performance.mark(mark);
  } catch (error) {
    logger.error({ error, mark, message: 'Performance.mark error' });
  }
};

export const clearMark = (mark?: PerformanceMarks) => {
  try {
    window.performance.clearMarks(mark);
  } catch (error) {
    logger.error({ error, mark, message: 'Performance.clearMarks error' });
  }
};

export const clearMeasures = (measure?: Measures) => {
  try {
    window.performance.clearMeasures(measure);
  } catch (error) {
    logger.error({ error, measure, message: 'Performance.clearMeasures error' });
  }
};

export const getMeasureAndClearMarks = (
  measure: Measures,
  ...marks: PerformanceMarks[]
): number | undefined => {
  try {
    window.performance.measure(measure, ...marks);
  } catch (error) {
    logger.error({ error, marks, measure, message: 'Performance.measure error' });
    return;
  }

  marks.forEach(clearMark);

  try {
    const entries = window.performance.getEntriesByName(measure);

    clearMeasures(measure);

    if (!entries.length) {
      return;
    }

    const [{ duration }] = entries;

    return duration;
  } catch (error) {
    logger.error({ error, marks, measure, message: 'Performance.getEntriesByName error' });
    return;
  }
};
