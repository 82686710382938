import { mediaQuery } from '@rbilabs/components-library/build/globalStyles';
import { RuleSet } from 'styled-components';

import { isNative } from 'utils/environment';

export type MediaQueries = {
  headerMobile: string;
  mobile: string;
  mobileTiny: string;
  mobileSmall: string;
  mobileLandscape: string;
  desktop: string;
  desktopLarge: string;
  mobileFullscreen: string;
};

const addNativeOnlyCss = (cssObject: RuleSet<object>) => (isNative ? cssObject : []);

const mediaQueries = {
  mobile: `@media ${mediaQuery.mobile}`,
  headerMobile: `@media screen and (max-width: 1199px)`,
  mobileTiny: `@media ${mediaQuery.mobileTiny}`,
  mobileSmall: `@media ${mediaQuery.mobileSmall}`,
  mobileLandscape: `@media ${mediaQuery.mobileLandscape}`,
  desktop: `@media ${mediaQuery.desktop}`,
  desktopLarge: `@media ${mediaQuery.desktopLarge}`,
  mobileFullscreen: `@media ${mediaQuery.mobileFullScreen}`,
  native: addNativeOnlyCss,
};

export default mediaQueries;
