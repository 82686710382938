import { EffectCallback, useEffect, useRef } from 'react';

const useEffectOnUpdates = (callback: EffectCallback, deps: any[] = []) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    return callback();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useEffectOnUpdates;
