import React from 'react';

import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import LoadingAnimation from 'components/loading-animation';

interface ILoadingIndicatorProps extends IBaseProps {
  ariaLabel?: string;
}

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
`;

export default function LoadingIndicator({ ariaLabel }: ILoadingIndicatorProps) {
  return (
    <LoadingContainer data-testid="store-locator-loading">
      <LoadingAnimation fillColor={Styles.color.primary} ariaLabel={ariaLabel} />
    </LoadingContainer>
  );
}
