import { normalizedTranslate } from '@rbilabs/components-library';
import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
`;

export const plopDown = keyframes`
  0% {
    opacity: 0;
    transform: ${normalizedTranslate(0, '-10px')};
  }
  50% {
    transform: ${normalizedTranslate(0, '5px')};
  }
  100% {
    transform: ${normalizedTranslate(0)};
    opacity: 1;
  }
`;

export const plopUp = keyframes`
  0% {
    opacity: 1;
    transform: ${normalizedTranslate(0)};
  }
  50% {
    transform: ${normalizedTranslate(0, '5px')};
  }
  100% {
    transform: ${normalizedTranslate(0, '-10px')};
    opacity: 0;
  }
`;

export const popIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95)
  }
  50% {
    opacity: 1;
    transform: scale(1.10)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`;

export const shake = keyframes`
  10%, 90% {
    transform: ${normalizedTranslate('-1px', 0, 0)};
  }
  20%, 80% {
    transform: ${normalizedTranslate('2px', 0, 0)};
  }
  30%, 50%, 70% {
    transform: ${normalizedTranslate('-4px', 0, 0)};
  }
  40%, 60% {
    transform: ${normalizedTranslate('4px', 0, 0)};
  }`;

export const slideIn = keyframes`
  from {
    transform: ${normalizedTranslate(0, '-12px')};
    opacity: 0;
  }
  to {
    transform: ${normalizedTranslate(0)};
    opacity: 1;
  }
`;
