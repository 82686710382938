import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import {
  QRCodeContainer,
  QRCodeSubtitle,
  QRCodeTitle,
  StyledQRCode,
} from 'pages/loyalty/loyalty-in-restaurant-redemption/loyalty-in-restaurant-redemption.styled.base';
import { primitive } from 'styles/constants/primitives';

import { LoyaltyQrWidget } from '.';

export const StyledLoyaltyQrWidget = styled(LoyaltyQrWidget)`
  background: none;
  padding: 20px;
  ${QRCodeContainer} {
    margin-block-start: 42px;
  }
  ${QRCodeSubtitle} {
    font: ${brandFont.headerThree};
    color: ${Styles.color.black};
  }
  ${StyledQRCode} {
    margin-block-start: 16px;
  }
`;

export const LoyaltyQrWidgetV3 = styled(LoyaltyQrWidget)`
  background: none;
  padding: 1rem;

  ${QRCodeContainer} {
    background: ${primitive.$white};
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-end-radius: 0;
    border-end-start-radius: 0;
    min-height: unset;
    padding: 2rem;

    & > button {
      display: flex;
      font-size: 1.125rem;
      margin: 1rem auto;
      padding: 0.7rem 3rem;
      background-color: ${primitive.$white};
      color: ${Styles.color.primary};
      border: 1px solid ${Styles.color.primary};

      svg {
        fill: ${Styles.color.primary};
      }
    }
  }

  ${QRCodeTitle} {
    font: ${brandFont.headerThree};
    font-size: 1.2rem;
    text-align: center;
    padding: 0 1rem;
    color: ${Styles.color.black};
    line-height: 1.5rem;
  }

  ${QRCodeSubtitle} {
    font: ${brandFont.headerThree};
    font-size: 1.2rem;
    color: ${Styles.color.black};
  }

  ${StyledQRCode} {
    margin-block-start: 16px;
  }
`;
