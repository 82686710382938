import React, { FC } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';

import { IconContainer } from './toast.styled';
import { ToastType } from './types';

export const ToastTypeLogo: FC<{ type: ToastType }> = ({ type }) => {
  switch (type) {
    case 'success':
      return (
        <IconContainer>
          <Icon color="white" icon="check" width="1rem" height="1rem" aria-hidden />
        </IconContainer>
      );
    case 'info':
    case 'default':
      return (
        <IconContainer>
          <Icon color="white" icon="info" width="1rem" height="1rem" aria-hidden />
        </IconContainer>
      );
    case 'warning':
    case 'error':
      return (
        <IconContainer>
          <Icon color="white" icon="error" width="1rem" height="1rem" aria-hidden />
        </IconContainer>
      );
    case 'dark':
      return (
        <IconContainer>
          <Icon color="white" icon="checkCircle" width="1.2rem" height="1.2rem" aria-hidden />
        </IconContainer>
      );
    default:
      return null;
  }
};
