import { ReactNode } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

export interface IBottomModalProps extends IBaseProps {
  title: string;
  open: boolean;
  eventData?: IEventData;
  children: NonNullable<ReactNode>;
  onCloseModal: VoidFunction;
  hideOnClickOutside?: boolean;
}

export enum ModalState {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
}
