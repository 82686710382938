import { IOptionSettings } from './types';

export const MINUTE_IN_MS = 60000;

export const OPTION_NOW: IOptionSettings = {
  id: 'now',
  inMinutes: 0,
  inSeconds: 0,
  labelId: 'now',
  ariaLabel: 'prepareNowLabel',
};
