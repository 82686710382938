import React from 'react';

const IconHeart = props => (
  <svg viewBox="0 0 22 19.56" {...props}>
    <title>Heart</title>
    <path d="M11 19.56L1.66 9.94A6 6 0 0 1 .6 3.21 5.58 5.58 0 0 1 5.63 0a5.42 5.42 0 0 1 3.25 1.08l1.53 1.12.59.44.59-.44 1.54-1.12A5.41 5.41 0 0 1 16.37 0a5.57 5.57 0 0 1 5 3.21 6 6 0 0 1-1 6.73z" />
  </svg>
);

export default IconHeart;
