import { ReactNode } from 'react';

import {
  IFeatureFooterQuery,
  ISocialIconsWidgetFragment,
  IWordmarkAndSocialIconsFragment,
} from 'generated/sanity-graphql';

/**
 * We want to use the types as generated from the feature footer query (feature-footer.graphql)
 *
 * Every field is optional and so is "wrapped" in `Maybe`, and that makes them difficult to use
 * So we "unwrap" with `Exclude`, and this gives us the "raw" type definition of each object
 * we're querying for
 */

export type IFeatureFooter = Exclude<Exclude<IFeatureFooterQuery, null>['FeatureFooter'], null>;
export type IRows = Exclude<IFeatureFooter['rows'], null>;
export type IRow = Exclude<IRows[number], null>;
export type IColumns = Exclude<IRow['columns'], null>;
export type IColumn = Exclude<IColumns[number], null>;
export type IOptions = Exclude<IColumn['options'], null>[number];
export type IOption = Exclude<IOptions, null>;

export enum OptionTypes {
  BoldHorizontalLinkListWidget = 'boldHorizontalLinkListWidget',
  ColumnHeaderWidget = 'columnHeaderWidget',
  CopyrightAndLanguageSelector = 'copyrightAndLanguageSelector',
  HighlightedVerticalLinkListWidget = 'highlightedVerticalLinkListWidget',
  HorizontalLinkListWidget = 'horizontalLinkListWidget',
  HorizontalTextWithLinkWidget = 'horizontalTextWithLinkWidget',
  ImageWidget = 'imageWidget',
  LinkWidget = 'linkWidget',
  SocialIconsWidget = 'socialIconsWidget',
  SocialIconsWithHeaderWidget = 'socialIconsWithHeaderWidget',
  TextWidget = 'textWidget',
  TextWidgetWithUrl = 'textWidgetWithUrl',
  VerticalLinkListWidget = 'verticalLinkListWidget',
  VerticalLinkListWithHeaderWidget = 'verticalLinkListWithHeaderWidget',
  WordmarkAndSocialIcons = 'wordmarkAndSocialIcons',
}

export interface ISocialIconProps {
  href?: string | null;
  width?: number;
  height?: number;
  children?: ReactNode;
}

export type IWordmarkAndSocialIcons = IWordmarkAndSocialIconsFragment;
export type ISocialIcons = ISocialIconsWidgetFragment;

export interface ISocialIconsProps {
  instagramUrl?: string | null;
  facebookUrl?: string | null;
  youtubeUrl?: string | null;
  twitterUrl?: string | null;
  tiktokUrl?: string | null;
  linkedinUrl?: string | null;
  snapchatUrl?: string | null;
}

export interface ISocialCompProps {
  item: string;
  href?: string | null;
}
