import { css } from 'styled-components';

const baseGlobalStyles = css`
  /* TODO: Add global style reset file? */

  /* Expose safe are insets to make it accessible from JS */
  /* Based on https://benfrain.com/how-to-get-the-value-of-phone-notches-environment-variables-env-in-javascript-from-css/ */
  :root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }

  html {
    box-sizing: border-box;
    font: 100%/1.5 ${Styles.fontFamily.body};
    color: ${Styles.color.black};
    -webkit-font-smoothing: antialiased;
  }

  /* hide google stuff - might be a better way to hide copyright, terms of use, and report map errors buttons */
  .gm-style-cc {
    display: none;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .no-scroll {
    overflow: hidden;
  }

  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    background-color: ${Styles.color.white};
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
    scroll-margin-block-end: 16px;
  }

  /* work around for Edge not supporting outline-style: auto */
  @supports (-ms-ime-align: auto) {
    &:focus {
      outline-color: invert;
      outline-width: 3px;
      outline-style: dotted;
    }
  }

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
  }

  // Resets default browser styles
  p {
    font-weight: normal;
  }

  // Override iOS 15 -apple-system-blue on buttons
  button {
    color: inherit;
  }
`;

export default baseGlobalStyles;
