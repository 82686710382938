import { useState } from 'react';

import { useDayPartContext } from 'state/day-part';

import { DAY_PART_SELECTIONS, IGetDayPart, IUseDayPart } from './types';

export const useDayPartSelector = (): IUseDayPart => {
  const { activeDayParts, dayParts } = useDayPartContext();
  const [selectedDayPart, setSelectedDayPart] = useState<DAY_PART_SELECTIONS>();

  const daypartItems = dayParts.filter(item => item && item.key !== DAY_PART_SELECTIONS.ALL_KEY);

  const breakfastInterval = activeDayParts.find(
    item => item?.key === DAY_PART_SELECTIONS.BREAKFAST_KEY
  );

  const mainMenuInterval = activeDayParts.find(
    item => item?.key === DAY_PART_SELECTIONS.DAYTIME_KEY
  );

  const calculateInitialDaypart = (showStaticMenu: boolean) => {
    if (!showStaticMenu) {
      return null;
    }
    return breakfastInterval ? DAY_PART_SELECTIONS.BREAKFAST_KEY : DAY_PART_SELECTIONS.DAYTIME_KEY;
  };

  const getDayPart = ({ showStaticMenu }: { showStaticMenu: boolean }): IGetDayPart => {
    return {
      currentDayPart: selectedDayPart ? selectedDayPart : calculateInitialDaypart(showStaticMenu),
      dayParts: daypartItems,
    };
  };

  return {
    breakfastInterval,
    mainMenuInterval,
    setDayPart: setSelectedDayPart,
    getDayPart,
  };
};
