import React, { useState } from 'react';

import { times } from 'lodash';

import { IconRating, IconRatingFilled } from 'components/icons/rating';

import { IconStyled } from './styled';

interface IRatingStars {
  onRate: (stars: number) => void;
}

const RatingStars = ({ onRate }: IRatingStars): JSX.Element => {
  const [stars, setStars] = useState(0);

  const amountStars = 5;

  const handleClick = (starClickedKey: number) => {
    setStars(starClickedKey);
    onRate(starClickedKey);
  };

  return (
    <>
      {times(amountStars, i => (
        <IconStyled onClick={() => handleClick(i)} key={i}>
          {++i && i <= stars ? (
            <IconRatingFilled fill={Styles.color.black} data-testid={`icon-rating-filled-${i}`} />
          ) : (
            <IconRating fill={Styles.color.black} data-testid={`icon-rating-${i}`} />
          )}
        </IconStyled>
      ))}
    </>
  );
};

export default RatingStars;
