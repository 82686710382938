import { RBIExpandedPlatform } from './environment';

export function determinePlatformFromNavigator(): RBIExpandedPlatform {
  const regexPatterns = {
    androidRegex: /.*?Android.*?/i,
    iOSRegex: /^iP.*$/i,
  };
  const androidMatch = regexPatterns.androidRegex.test(navigator.userAgent);
  const iOSMAtch = regexPatterns.iOSRegex.test(navigator.platform);

  if (androidMatch) {
    return RBIExpandedPlatform.ANDROID;
  } else if (iOSMAtch) {
    return RBIExpandedPlatform.IOS;
  }
  // default to Web if none of the above are matched
  return RBIExpandedPlatform.WEB;
}

export const getBrowserType = (): string => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (userAgent.match(/safari/i)) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else if (userAgent.match(/android/i)) {
    browserName = 'Android';
  } else if (userAgent.match(/iphone/i)) {
    browserName = 'iPhone';
  } else {
    browserName = 'Unknown';
  }
  return browserName as string;
};

export const getBrowserVersion = (): string => {
  const userAgent = navigator.userAgent;
  const browserType = getBrowserType();
  const browserSentence = userAgent.substring(userAgent.indexOf(browserType));
  const containsSpace = /\s/.test(browserSentence);
  const indexFirstNumber = browserSentence.search(/\d/);
  const indexFirstSpace = browserSentence.search(/\s/);

  const isAndroid = userAgent.match(/([A-Za-z0-9]+(\.[A-Za-z0-9]+)+);/);
  const isIoS = userAgent.match(/([A-Za-z0-9]+(_[A-Za-z0-9]+)+)/);
  // returns a Semver of the browser's current version, such as 1.2.3
  if (determinePlatformFromNavigator() === 'iOS') {
    const browserVersion = isIoS && isIoS[0];
    return browserVersion as string;
  } else if (determinePlatformFromNavigator() === 'Android') {
    const browserVersion = isAndroid && isAndroid[0];
    return browserVersion as string;
  } else if (containsSpace) {
    const browserVersion = browserSentence.substring(indexFirstNumber, indexFirstSpace);
    return browserVersion as string;
  } else {
    const browserVersion = browserSentence.substring(indexFirstNumber);
    return browserVersion as string;
  }
};

export const getIsMobileWeb = (): string => {
  const userAgent = navigator.userAgent;
  if (determinePlatformFromNavigator() === 'Web') {
    return 'FALSE' as string;
  } else if (
    userAgent.match(/chrome|chromium|crios/i) ||
    userAgent.match(/firefox|fxios/i) ||
    userAgent.match(/safari/i) ||
    userAgent.match(/opr\//i) ||
    userAgent.match(/edg/i)
  ) {
    return 'TRUE' as string;
  }
  return 'FALSE';
};
