import React, { ComponentType } from 'react';

import { ILinkWidgetFragment } from 'generated/sanity-graphql';
import { getCountry } from 'utils/environment';
import { isExternalLink } from 'utils/is-external-link';
import { routes } from 'utils/routing';

import {
  ExternalLink as DefaultExternalLink,
  InternalLink as DefaultInternalLink,
  KoreaPolicyLink as DefaultPolicyLink,
} from './styled';

type ILinksProps = {
  links: ILinkWidgetFragment[];
  InternalLink?: typeof DefaultInternalLink;
  ExternalLink?: typeof DefaultExternalLink;
  KoreaPolicyLink?: typeof DefaultPolicyLink;
};

export const Links: React.FC<ILinksProps> = ({
  links,
  InternalLink = DefaultInternalLink,
  ExternalLink = DefaultExternalLink,
  KoreaPolicyLink = DefaultPolicyLink,
}) => {
  return (
    <>
      {links.map((widget, index) => {
        const isExternal = isExternalLink(widget?.link?.locale);
        let Link: ComponentType = isExternal ? ExternalLink : InternalLink;
        /**
         * The KR govt. has issued a mandate that all website footers' privacy policy should be in the color yellow.
         * https://rbictg.atlassian.net/browse/INMS-634
         */
        if (getCountry().toLocaleLowerCase() === 'kr') {
          Link = widget?.link?.locale?.includes('privacy-policy') ? KoreaPolicyLink : Link;
        }
        const linkProps = {
          ...(isExternal
            ? { href: widget?.link?.locale ?? undefined }
            : { to: widget?.link?.locale ?? routes.base }),
          children: widget?.text?.locale,
        };

        return <Link key={widget?._key ?? index} {...linkProps} />;
      })}
    </>
  );
};
