import { useEffect } from 'react';

import { useGetSupportDataQuery } from 'generated/sanity-graphql';
import { useErrorContext } from 'state/errors';
import { useFeaturesContext } from 'state/features';

export const useSupportData = () => {
  const { featureSupportDataId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useGetSupportDataQuery({
    variables: { supportDataId: featureSupportDataId },
    skip: !featureSupportDataId,
  });

  const { setSanityDataRef } = useErrorContext();
  useEffect(() => {
    if (!loading && data) {
      setSanityDataRef({ data });
    }
  }, [loading, data, setSanityDataRef]);

  const {
    deliveryMissingItemsSubcategory,
    deliveryNeverArriveSubcategory,
    orderingIssuesCategoryGroup,
    supportCategoryGroups,
    supportDataFaqs,
    supportPolicyCover,
  } = data?.SupportData ?? {};
  const isSupportDataLoading = featureIdsLoading || loading;

  return {
    deliveryMissingItemsSubcategory,
    deliveryNeverArriveSubcategory,
    isSupportDataLoading,
    orderingIssuesCategoryGroup,
    supportCategoryGroups,
    supportDataFaqs,
    supportPolicyCover,
  };
};
