import { IPriceOrderParams } from 'hooks/price-order/types';
import { IPriceOrderInput } from 'remote/queries/order';

import { buildCartInput } from './build-cart-input';

export function buildPriceOrderInput(params: IPriceOrderParams): IPriceOrderInput {
  return {
    ...buildCartInput(params),
    rewardsApplied: params.rewardsApplied,
    cartVersion: params.cartVersion,
    customerLocale: params.customerLocale,
    customerName: params.customerName,
    paymentMethod: params.cardType,
    appliedOffers: params.appliedOffers,
    requestedAmountCents: params.requestedAmountCents,
    vatNumber: params.store.vatNumber,
  };
}
