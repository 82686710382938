import React from 'react';

import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';
import { useFeatureLoyaltyNotAuthenticatedQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

import {
  CellDescription,
  CellDetails,
  CellTitle,
  Container,
  Disclaimer,
  Header,
  SignupButton,
  StyledPicture,
  StyledRewardsLogo,
  Tile,
  TitleDescription,
} from './styled';

const LoyaltyInRestaurantRedemptionSignupV3 = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useLocationContext();

  const { featureLoyaltyUIId = '' } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyNotAuthenticatedQuery({
    variables: { id: featureLoyaltyUIId },
  });

  // If Not Authenticated screen hasn't been configured simply redirect
  if (!data && !loading) {
    navigate(routes.signIn);
    return null;
  }

  if (!data || loading) {
    return (
      <LoadingContainer>
        <LoadingAnimation fillColor={Styles.color.primaryHover} />
      </LoadingContainer>
    );
  }

  const legalDisclaimer = data?.LoyaltyUI?.loyaltyNotAuthenticated?.loyaltySignupLegalText?.locale;

  return (
    <div data-testid="loyalty-in-restaurant-redemption-signup">
      <Header>
        <StyledRewardsLogo data-testid="rewards-logo" />
        <TitleDescription>
          {data?.LoyaltyUI?.loyaltyNotAuthenticated?.loyaltySignupTitle?.locale}
        </TitleDescription>
      </Header>
      <Container>
        {data?.LoyaltyUI?.loyaltyNotAuthenticated?.loyaltySignupCells?.map((tile, id) => (
          <Tile key={id}>
            <StyledPicture image={tile?.loyaltySignupCellImage?.locale} alt="" objectFitContain />
            <CellDetails>
              <CellTitle>{tile?.loyaltySignupCellTitle?.locale}</CellTitle>
              <CellDescription>{tile?.loyaltySignupCellDescription?.locale}</CellDescription>
            </CellDetails>
          </Tile>
        ))}
        <SignupButton
          variant={ActionButtonVariants.PRIMARY}
          data-testid="loyalty-in-restaurant-redemption-signup-button"
          size={ActionButtonSizes.LARGE}
          perceptible
          to={routes.signUp}
        >
          {formatMessage({ id: 'signUp' })}
        </SignupButton>
      </Container>
      {legalDisclaimer ? <Disclaimer>{legalDisclaimer}</Disclaimer> : null}
    </div>
  );
};

export default LoyaltyInRestaurantRedemptionSignupV3;
