import { routes } from 'utils/routing';

export const useIsBrowseMenu = (currentUrl: string) => {
  const menuUrlSection = currentUrl.split('/')[1];

  /* 
  There is a simpler way to do this 
  but we need to ensure that the only other value
  to be returned here is the {routes.browseMenu} */

  const isBrowseMenu = `/${menuUrlSection}` === routes.browseMenu;

  return { isBrowseMenu };
};
