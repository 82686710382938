import { useFanFavoritesQuery } from 'generated/sanity-graphql';

export const useFanFavorites = (id?: string) => {
  const fanFavoritesId = id ?? '';
  const { data, loading } = useFanFavoritesQuery({
    variables: {
      fanFavoritesId,
    },
    skip: !fanFavoritesId,
  });

  const fanFavorites = data?.FanFavorites;

  return {
    fanFavorites,
    fanFavoritesLoading: loading,
  };
};
