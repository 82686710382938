import styled from 'styled-components';

export const CartHoverContainer = styled.div`
  box-shadow: ${Styles.boxShadow};
  background: ${Styles.color.white};
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 4.5rem;
  transition: all 200ms ease-out;
  ${Styles.desktop} {
    border-radius: 10px;
    max-height: calc(100vh - 10rem);
    overflow: hidden;
    z-index: ${Styles.zIndex.normal};
  }
`;

export const CartScrollContainer = styled.div`
  ${Styles.desktop} {
    max-height: calc(100vh - 10rem - 203px);
    overflow-y: auto;
    position: relative;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${Styles.color.black};
  margin-block-end: 0.5rem;
  > p {
    font-size: 1rem;
    margin: 0;
  }
`;
