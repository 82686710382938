import { primitive } from './primitives';

export const sharedDesignTokens = {
  'border-color-input': primitive.$blackOpacity30,
  'border-color-input-default': primitive.$blackOpacity30,
  'border-color-options': primitive.$blackOpacity10,
  'border-color-reversed': primitive.$whiteOpacity10,
  'border-radius-button': '100%',
  'border-radius-chip': '4px',
  'border-radius-input': '4px',
  'border-radius-input-icon-hover-focus': '100%',
  'border-radius-pattern': '20px',
  'border-radius-pattern-interactive': '12px',
  'border-radius-toggle': '4px',
  'border-radius-toggle-default': '0',
  'border-radius-toggle-end': '0 4px 4px 0',
  'border-radius-toggle-start': '4px 0 0 4px',
  'border-width-button-secondary': '1px',
  'border-width-input': '1px',
  'border-width-tabs': '1px',
  'box-shadow-button': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-component': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-component-reversed': `0 0 0 4px ${primitive.$whiteOpacity30}`,
  'box-shadow-pattern': `0 0 0 4px ${primitive.$blackOpacity10}`,
  'box-shadow-pattern-reversed': `0 0 0 4px ${primitive.$whiteOpacity30}`,
  'box-shadow-toggle': `inset 0 0 0 4px ${primitive.$blackOpacity10}`,
  'drop-shadow-bottom-nav': `0 -1px 1px ${primitive.$blackOpacity10}`,
  'drop-shadow-component': `0 4px 4px ${primitive.$blackOpacity10}`,
  'drop-shadow-top-nav': `0 1px 1px ${primitive.$blackOpacity10}`,
  'height-button-large': '48px',
  'height-button-small': '32px',
  'height-checkbox-radio': '20px',
  'height-chip': '24px',
  'height-icon': '24px',
  'height-icon-small': '16px',
  'height-input': '48px',
  'height-tabs': '56px',
};
