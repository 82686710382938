import { IntlShape } from 'react-intl';

import { ServiceMode } from 'state/service-mode/types';

import { CATERING_SERVICE_MODES, DELIVERY_SERVICE_MODES } from './service-mode';

export enum ServiceModeCategory {
  CATERING = 'CATERING',
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
}

/**
 * @name determineServiceModeCategory
 * @description gives a category to the provided SM
 *     need this bc pickup & catering have multiple
 *     SM values that fall into one of the categories
 */
export const determineServiceModeCategory = (serviceMode: ServiceMode) => {
  let smType = ServiceModeCategory.PICKUP;
  if (DELIVERY_SERVICE_MODES.includes(serviceMode)) {
    smType = ServiceModeCategory.DELIVERY;
  }
  if (CATERING_SERVICE_MODES.includes(serviceMode)) {
    smType = ServiceModeCategory.CATERING;
  }
  return smType;
};

export const getLocalizedServiceModeCategory = (
  serviceMode: ServiceMode,
  formatMessage: IntlShape['formatMessage']
): string => {
  const serviceModeCategory = determineServiceModeCategory(serviceMode);
  switch (serviceModeCategory) {
    case ServiceModeCategory.CATERING:
      return formatMessage({ id: 'catering' });
    case ServiceModeCategory.DELIVERY:
      return formatMessage({ id: 'delivery' });
    case ServiceModeCategory.PICKUP:
      return formatMessage({ id: 'pickUp' });
    default:
      return '';
  }
};
