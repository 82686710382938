import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import { useFeatureLoyaltyNotAvailableAtThisRestaurantQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';
import { useUIContext } from 'state/ui';
import { isMobile } from 'utils/environment';

const NotAvailableContainer = styled.div`
  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
  max-width: ${Styles.layout.sectionMaxWidth};
  padding-block-end: 0;
  padding-block-start: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: ${Styles.color.black};

  img {
    ${Styles.desktop} {
      margin-block-start: 3.1rem;
      width: 25%;
    }
    ${Styles.mobile} {
      width: 80%;
    }
  }

  h1 {
    margin-inline-start: 2.7rem;
    margin-inline-end: 2.7rem;
    margin-block-start: 0.7rem;
    margin-block-end: 0.7rem;
    font-family: ${Styles.fontFamily.base};
    font-size: 1.5rem;
    line-height: 1.7rem;
    text-transform: capitalize;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0.9rem;
    margin-inline: 0;
    font-size: 0.8rem;
    line-height: 1.1rem;
  }

  button {
    width: 16rem;
    height: 2.5rem;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1rem;
    margin-block-end: 1rem;
  }
`;

const LoyaltyCtaBar = React.lazy(() =>
  import('pages/loyalty/loyalty-cta-bar').then(module => ({
    default: module.LoyaltyCtaBar,
  }))
);

const LoyaltyNotAvailableAtThisRestaurant: React.FC = () => {
  const { formatMessage } = useIntl();
  const { buildImageUrl } = useUIContext();
  const { featureLoyaltyUIId = '' } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyNotAvailableAtThisRestaurantQuery({
    variables: { id: featureLoyaltyUIId },
  });

  if (loading) {
    return <LoadingAnimation fillColor={Styles.color.primaryHover} />;
  }
  const loyaltyNotAvailableData = data?.LoyaltyUI?.loyaltyNotAvailableAtThisRestaurant;
  const imageUrl = buildImageUrl(loyaltyNotAvailableData?.image?.locale?.app?.asset?._id || '');
  const imageAlt = loyaltyNotAvailableData?.image?.locale?.imageDescription || '';
  const title = loyaltyNotAvailableData?.title?.locale;
  const description = loyaltyNotAvailableData?.description?.locale || '';
  const firstRedirectPath = loyaltyNotAvailableData?.firstRedirectPath?.locale || '';
  const firstButtonText = loyaltyNotAvailableData?.firstButtonText?.locale || '';
  const secondRedirectPath = loyaltyNotAvailableData?.secondRedirectPath?.locale || '';
  const secondButtonText = loyaltyNotAvailableData?.secondButtonText?.locale || '';

  return (
    <div data-testid="loyalty-not-available-at-this-restaurant">
      <Helmet title={formatMessage({ id: 'loyaltyNotAvailableAtThisRestaurant' })} />
      {isMobile() && <LoyaltyCtaBar />}
      <NotAvailableContainer>
        <img alt={imageAlt} src={imageUrl} />
        <h1>{title}</h1>
        <p>{description}</p>
        <ActionButton to={firstRedirectPath}>{firstButtonText}</ActionButton>
        <ActionButton to={secondRedirectPath} variant={ActionButtonVariants.OUTLINE}>
          {secondButtonText}
        </ActionButton>
      </NotAvailableContainer>
    </div>
  );
};

export default LoyaltyNotAvailableAtThisRestaurant;
