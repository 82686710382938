import { ICartEntry } from '@rbi-ctg/menu';
import { IPriceOrderInput } from 'remote/queries/order';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { buildStoreAddress, remappedCartForBackEnd } from 'utils/cart';
import { brand, platform } from 'utils/environment';
import { priceForCartEntry } from 'utils/menu/price';

export interface ICartInput {
  cartEntries: ICartEntry[];
  serviceMode: ServiceMode;
  store: StoreProxy;
}

export function buildCartInput(cartInput: ICartInput): IPriceOrderInput {
  const { cartEntries, serviceMode, store } = cartInput;
  return {
    storeAddress: buildStoreAddress(store),
    storeId: String(store.number),
    storePosId: store.posRestaurantId,
    brand: brand().toUpperCase(),
    platform: platform(),
    posVendor: store.pos?.vendor ?? null,
    serviceMode,
    requestedAmountCents: cartEntries.reduce((price, entry) => price + priceForCartEntry(entry), 0),
    cartEntries: remappedCartForBackEnd(cartEntries),
    vatNumber: String(store.vatNumber),
  };
}
