import {
  Country,
  IsoCountryCode2,
  IsoCountryCode3,
  allMarkets,
  asIsoCountryCode2,
  asIsoCountryCode3,
} from '@rbilabs/intl';

import { FALLBACK_ISO2 } from 'utils/constants';
import { getCountry } from 'utils/environment';

const findCountryByIso3 = (iso3: IsoCountryCode3): Country | undefined => {
  for (const [, markets] of Object.entries(allMarkets)) {
    const match = markets.find(market => market.country.iso3 === iso3);
    if (match) {
      return match.country;
    }
  }
  return;
};

const findCountryByName = (name: string): Country | undefined => {
  for (const [, markets] of Object.entries(allMarkets)) {
    const match = markets.find(market => market.country.name.toUpperCase() === name);
    if (match) {
      return match.country;
    }
  }
  return;
};

// TODO: maybe we can just use the function from the @rbilabs/intl packaeg
export function convertCountryStringToIsoCountryCode2(
  country: string
): IsoCountryCode2 | undefined {
  const normalizedCountry = country.toUpperCase().trim();

  // Country is in format "CH"
  const iso2 = asIsoCountryCode2(normalizedCountry);
  if (iso2) {
    return iso2;
  }

  // Country is in format "CHE"
  const iso3 = asIsoCountryCode3(normalizedCountry);
  if (iso3) {
    return findCountryByIso3(iso3)?.iso2;
  }

  // Country is in format "SWITZERLAND"
  return findCountryByName(normalizedCountry)?.iso2;
}

export const getAppCountry = () => asIsoCountryCode2(getCountry()) || FALLBACK_ISO2;
