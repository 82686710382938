import React from 'react';

import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { IconWrapBase } from './sanity-nav-mobile-tab';
import { NavLinkText } from './styled';
import { NavLinkStyles } from './styled-link';

const NavStyles = styled.div`
  ${NavLinkStyles}
  height: auto;
`;
const NavItem = styled.li`
  height: 50px;
  list-style-type: none;
`;

const NavOption = ({ textWidth = '35px' }: { textWidth?: string }) => (
  <NavItem>
    <NavStyles>
      <IconWrapBase>
        <Skeleton height="23px" width="25px" />
      </IconWrapBase>
      <NavLinkText>
        <Skeleton width={textWidth} />
      </NavLinkText>
    </NavStyles>
  </NavItem>
);

export const MobileWebNavigationBarSkeleton = () => {
  return (
    <>
      <NavOption />
      <NavOption />
      <NavOption />
      <NavOption textWidth="48px" />
      <NavOption textWidth="48px" />
    </>
  );
};
