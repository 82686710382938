import React, { FC, ReactNode, createContext, useContext, useState } from 'react';

import { noop } from 'lodash';

import { useFocusToggle } from './use-focus-toggle';
import { useTabNavigationWatcher } from './use-tab-navigation-watcher';

interface IAccessibilityContext {
  /**
   * Whether or not a user is using tabs for navigating the page
   */
  isUsingTabNavigation: boolean;

  /**
   * Whether or not a full page modal is open
   * Used to determine if elements are no longer visible
   * since they are covered by the modal and thus should
   * not announce their presence
   */
  isFullPageModalActive: boolean;

  /**
   * Set value for isFullPageModalActive
   */
  setFullPageModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AccessibilityContext = createContext<IAccessibilityContext>({
  isUsingTabNavigation: false,
  isFullPageModalActive: false,
  setFullPageModalActive: noop,
});

/**
 * Provides accessibility utilities
 */
export const useAccessibility = () => useContext(AccessibilityContext);

/**
 * Provides accessibility utilities
 */
export const AccessibilityProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isFullPageModalActive, setFullPageModalActive] = useState(false);
  const { isUsingTabNavigation } = useTabNavigationWatcher();

  const { FocusToggleStyles } = useFocusToggle(isUsingTabNavigation);

  return (
    <AccessibilityContext.Provider
      value={{
        isUsingTabNavigation,
        isFullPageModalActive,
        setFullPageModalActive,
      }}
    >
      <FocusToggleStyles />
      {children}
    </AccessibilityContext.Provider>
  );
};
