import { TLocalizationMessages } from 'types/i18n';
import { brand, releaseTagRef } from 'utils/environment';
import logger from 'utils/logger';

import { BASE_LOCALE } from './constants';

const BASE_URL = `https://intl-prod-${brand()}-cdn.rbictg.com/locales`;

const getLocaleUrl = (locale: string) => {
  return `${BASE_URL}/${locale}.${releaseTagRef}.json`;
};

const fetchLocale = async (locale: string): Promise<TLocalizationMessages> => {
  const url = getLocaleUrl(locale);
  const response = await fetch(url);
  return await response.json();
};

interface IGetMessagesForLanguageArgs {
  language: string;
  country: string;
}

export const getMessagesForLanguage = async ({
  language,
  country,
}: IGetMessagesForLanguageArgs): Promise<TLocalizationMessages> => {
  const locales = [`${language}-${country}`, BASE_LOCALE];

  for (const locale of locales) {
    try {
      return await fetchLocale(locale);
    } catch (e) {
      logger.warn(e);
    }
  }

  return await import(`state/translations/${BASE_LOCALE}.json`);
};
