import { useFeatureLoyaltyOverrideShortCodeModalContentQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

export const useShortCodeOverrideModalContent = () => {
  const { featureLoyaltyUIId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureLoyaltyOverrideShortCodeModalContentQuery({
    variables: { featureLoyaltyUIId },
    skip: !featureLoyaltyUIId,
  });

  return {
    data: data?.LoyaltyUI?.overrideShortCodeModalContent,
    loading: featureIdsLoading || loading,
  };
};
