import { appVersionCode, brand, env, getCountry, platform } from 'utils/environment';

export const DEFAULT_SITE = 'datadoghq.eu';

/**
 * Shared context for all logs and RUM events.
 */
export const sharedContext = {
  appVersionCode: appVersionCode(),
  stage: env(),
  brand: brand(),
  country: getCountry(),
  platform: platform(),
};
