import { useIntl } from 'react-intl';

import { roundToOneDecimalIfNecessaryLocale } from 'components/nutrition/utils';
import { useFeatureNutrition } from 'hooks/use-feature-nutrition';
import { useLocale } from 'state/intl';
import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { CaloriesSuffix, IUseFormatCalories } from './types';

export function useFormatCalories(): IUseFormatCalories {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const { roundNutritionValue } = useFeatureNutrition();

  const enableNutritionDecimals = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_DECIMALS);
  const enableFeatureNutritionIntegration = useFlag(
    LaunchDarklyFlag.ENABLE_FEATURE_NUTRITION_INTEGRATION
  );

  return function formatCalories(calories: number | null, suffix: CaloriesSuffix = 'cal'): string {
    const calsString = suffix !== 'none' ? formatMessage({ id: suffix }) : '';

    if ((!calories && calories !== 0) || isNaN(calories)) {
      return '';
    }

    // New rounding for Nutrition 2.0
    if (enableFeatureNutritionIntegration) {
      return `${roundNutritionValue(calories).toLocaleString(locale)} ${calsString}`;
    }

    // Old rounding
    const calorieDisplay = enableNutritionDecimals
      ? roundToOneDecimalIfNecessaryLocale(calories, locale)
      : Math.floor(calories).toLocaleString(locale);

    return `${calorieDisplay} ${calsString}`.trim();
  };
}
