import React, {
  ComponentType,
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import useMediaQuery from 'hooks/use-media-query';

type PortaledComponent = null | (() => JSX.Element);

export interface IMobileHeaderNavContext {
  BackButtonComponent: PortaledComponent;
  __setBackButtonComponent: Dispatch<SetStateAction<PortaledComponent>>;
  HeaderComponent: PortaledComponent;
  __setHeaderComponent: Dispatch<SetStateAction<PortaledComponent>>;
}

export const MobileHeaderNavContext = createContext<IMobileHeaderNavContext>({
  BackButtonComponent: null,
  __setBackButtonComponent: () => null,
  HeaderComponent: null,
  __setHeaderComponent: () => null,
});

export function portalToHeader<Props extends {}>(Component: ComponentType<Props>) {
  return memo<ComponentType<Props>>(function PortalToHeader(props: Props) {
    const { __setBackButtonComponent } = useContext(MobileHeaderNavContext);

    useEffect(() => {
      __setBackButtonComponent(() => () => <Component {...props} />);
    }, [props, __setBackButtonComponent]);

    useEffectOnUnmount(() => {
      __setBackButtonComponent(null);
    });

    return null;
  });
}

export function portalToReplaceHeader<Props extends {}>(Component: ComponentType<Props>) {
  return memo<ComponentType<Props>>(function PortalToHeader(props: Props) {
    const { __setHeaderComponent } = useContext(MobileHeaderNavContext);

    useEffect(() => {
      __setHeaderComponent(() => () => <Component {...props} />);
    }, [props, __setHeaderComponent]);

    useEffectOnUnmount(() => {
      __setHeaderComponent(null);
    });

    return null;
  });
}

export const MobileHeaderNavProvider = ({ children }: IBaseProps) => {
  const isBackButtonVisible = useMediaQuery('mobile');
  const [BackButtonComponent, __setBackButtonComponent] = useState<PortaledComponent>(null);
  const [HeaderComponent, __setHeaderComponent] = useState<PortaledComponent>(null);

  // When the viewport is no longer mobile, we need to clear the back button from memory
  // as it should not be rendered any longer.
  useEffect(() => {
    if (!isBackButtonVisible) {
      __setBackButtonComponent(null);
    }
  }, [isBackButtonVisible]);

  return (
    <MobileHeaderNavContext.Provider
      value={{
        BackButtonComponent,
        __setBackButtonComponent,
        HeaderComponent,
        __setHeaderComponent,
      }}
    >
      {children}
    </MobileHeaderNavContext.Provider>
  );
};

export const useMobileHeaderBackButton = () =>
  useContext(MobileHeaderNavContext).BackButtonComponent;

export const useMobileHeaderComponent = () => useContext(MobileHeaderNavContext).HeaderComponent;
