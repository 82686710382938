import { routes } from 'utils/routing';

export const shouldDisplayFab = (pathname: string, allowedRoutes: string[] = []) => {
  const isComboOrPickerPage = pathname.includes('picker') || pathname.includes('combo');
  const isMenuPage = pathname.includes(routes.menu);
  const isItemMenu = pathname.search(/item\b/) >= 0;

  const shouldHideFabButton =
    (isMenuPage && (isComboOrPickerPage || isItemMenu)) ||
    (!isMenuPage && !allowedRoutes.includes(pathname));

  return !shouldHideFabButton;
};
