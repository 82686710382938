import React from 'react';

import { useIntl } from 'react-intl';

export interface IDefaultHoursTextProps {
  closeHourToday: string;
  isOpen: boolean;
  nextOpenHour: string;
}
const DefaultHoursText = ({ isOpen, nextOpenHour, closeHourToday }: IDefaultHoursTextProps) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <span>
        {isOpen ? formatMessage({ id: 'open' }) : formatMessage({ id: 'closed' })}
        &nbsp;
      </span>
      {formatMessage({ id: 'until' })} {isOpen ? closeHourToday : nextOpenHour}
    </>
  );
};
export default DefaultHoursText;
