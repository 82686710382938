import styled from 'styled-components';

export const CartItemName = styled.span`
  color: ${Styles.color.black};
  display: flex;
  font-family: ${Styles.fontFamily.base};
  font-weight: ${Styles.fontWeight.normal};
  letter-spacing: normal;
  line-height: 1.25rem;
  font-size: 1.25rem;
  font-stretch: condensed;
  text-transform: ${Styles.textTransform.headlines};
  margin: 0.5rem 0;
`;

export default CartItemName;
