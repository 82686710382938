import React from 'react';

/* A note when using this component - you will need to pass a fill color if you want anything other than black. */

const IconInstagram = props => (
  <svg data-dir="ltr" viewBox="0 0 17 16" {...props}>
    <title lang="en">Follow us on Instagram</title>
    <path
      d="M8.082 0C5.91 0 5.637.01 4.784.048c-.852.04-1.433.174-1.942.372-.534.2-1.018.516-1.417.923-.407.4-.722.883-.923 1.417C.304 3.268.169 3.85.13 4.701c-.038.854-.048 1.126-.048 3.3 0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372.854.039 1.126.048 3.299.048s2.444-.01 3.298-.048c.851-.04 1.433-.174 1.942-.372a3.914 3.914 0 0 0 1.417-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942.038-.853.048-1.125.048-3.298s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.91 3.91 0 0 0 13.322.42c-.51-.198-1.092-.333-1.943-.372C10.525.01 10.254 0 8.08 0h.003-.001zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.452.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.705.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0-.128 8.217 4.109 4.109 0 0 0 .128-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
      fillRule="nonzero"
    />
  </svg>
);

export default IconInstagram;
