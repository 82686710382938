import { normalizedTranslate } from '@rbilabs/components-library';
import styled from 'styled-components';

import Account from 'pages/account';

export const IconContainer = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

export const DrawerContainer = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${Styles.color.white};
  position: absolute;
  z-index: ${Styles.zIndex.top};
  inset-block-start: ${Styles.layout.navHeight.desktop};
  inset-inline-start: 0;
  height: calc(100vh - ${Styles.layout.navHeight.desktop});
  box-shadow: 0 2px 8px rgba(0, 0, 0, 10%);
  transform: ${({ $isOpen }) => normalizedTranslate($isOpen ? '0' : '-100%')};
  transition: transform 0.3s ease-in-out;
  padding: 2rem;
  overflow-y: auto;
`;

export const StyledAccount = styled(Account)`
  margin: 0;
  padding: 0;
  a {
    color: ${Styles.color.tertiary};
  }
`;
