import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

export default function useStaticIdentifier() {
  const loyaltyUser = useAppSelector(selectors.loyalty.selectUser);
  const isStaticIdentifierEnabled = Boolean(
    useFlag(LaunchDarklyFlag.ENABLE_STATIC_IN_STORE_IDENTIFICATION)
  );
  const loyaltyUserId = loyaltyUser?.id;

  // The static identifier is the loyalty id but this can be changed depending on biz requirements
  const staticIdentifier = isStaticIdentifierEnabled && loyaltyUserId ? loyaltyUserId : null;
  return { staticIdentifier, isStaticIdentifierEnabled };
}
