import React from 'react';

import { IStoreDiagnosticData } from 'components/store-diagnostic-modal';

import DiagnosticCogIcon from './diagnostic-cog-icon';
import { FavoriteStoreIcon } from './favorite-store-icon';
import { IconsContainer } from './styled';

const StoreCardIcons: React.FC<{
  storeNumber: string;
  storeId: string;
  diagnosticsEnabled: boolean;
  storeDiagnosticData: IStoreDiagnosticData | undefined;
}> = ({ storeId, diagnosticsEnabled, storeDiagnosticData, storeNumber }) => (
  <IconsContainer>
    <FavoriteStoreIcon storeId={storeId} storeNumber={storeNumber} />
    {diagnosticsEnabled && <DiagnosticCogIcon storeDiagnosticData={storeDiagnosticData} />}
  </IconsContainer>
);
export default StoreCardIcons;
