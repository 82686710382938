import * as React from 'react';
import { FC, memo, useCallback } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA } from 'pages/loyalty/loyalty-incentives-components/incentive-details/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyIdentificationCodeTypeVariation } from 'state/launchdarkly/variations';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import {
  ICartEntryOffer,
  ICartEntryReward,
} from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';
import { routes } from 'utils/routing';

import { RewardRedemptionItem } from './reward-redemption-item';
import {
  AddButton,
  EXIT_ANIMATION_DURATION_SECONDS,
  EmptyCartWidgetStyled,
  EmptyStateV3,
  InRestaurantRedemptionList,
  YourOrderDescription,
  YourOrderTitle,
} from './reward-redemption.styled';
import { IRewardRedemptionListProps } from './types';

const EXIT_ANIMATION_DURATION_MS = EXIT_ANIMATION_DURATION_SECONDS * 1000;
const animationTimeout = {
  exit: EXIT_ANIMATION_DURATION_MS,
} as const;

const RewardRedemptionList: FC<IRewardRedemptionListProps> = props => {
  const {
    inRestaurantRedemptionCart,
    updateInRestaurantRedemptionEntryQuantity,
    removeInRestaurantRedemptionEntry,
  } = useInRestaurantRedemptionContext();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const enableLoyaltyInApps = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_IN_APPS);
  const loyaltyIdentificationCodeType = useFlag<LoyaltyIdentificationCodeTypeVariation>(
    LaunchDarklyFlag.LOYALTY_IDENTIFICATION_CODE_TYPE
  );
  const enableLoyaltyFloatingCartButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_FLOATING_CART_BUTTON
  );

  const handleUpdateEntry = useCallback(
    (cartEntry: ICartEntryReward | ICartEntryOffer, { quantity }: { quantity: number }) => {
      updateInRestaurantRedemptionEntryQuantity(cartEntry, quantity);
      hapticNotification({ type: HapticsNotificationType.SUCCESS });
    },
    [updateInRestaurantRedemptionEntryQuantity]
  );

  const handleEmptyState = (route: string) => navigate(route);

  if (!inRestaurantRedemptionCart.length) {
    const emptyCartWidget = props?.loyaltyEmptyCartTileWidget;

    if (!!emptyCartWidget) {
      const emptyCartWidgetLinkParams = ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA
        ? `?${ONLY_SHOW_REDEEM_REWARD_IN_RESTAURANT_CTA}=true`
        : '';
      const emptyCartWidgetLink = emptyCartWidget.link?.locale
        ? `${emptyCartWidget.link?.locale}${emptyCartWidgetLinkParams}`
        : '';
      return (
        <EmptyCartWidgetStyled
          title={emptyCartWidget.marketingBasicTileTitle?.locale ?? ''}
          description={emptyCartWidget.marketingBasicTileDescription?.localeRaw ?? ''}
          link={emptyCartWidgetLink}
          linkLabel={emptyCartWidget.linkLabel?.locale ?? ''}
        />
      );
    }
  }

  return (
    <InRestaurantRedemptionList {...props}>
      {enableLoyaltyFloatingCartButton ? (
        <YourOrderTitle>{formatMessage({ id: 'yourInRestaurantOrderTitle' })}</YourOrderTitle>
      ) : null}
      {enableLoyaltyFloatingCartButton && !inRestaurantRedemptionCart?.length ? (
        <YourOrderDescription>
          {formatMessage({ id: 'yourInRestaurantOrderDescription' })}
        </YourOrderDescription>
      ) : null}
      <TransitionGroup>
        {inRestaurantRedemptionCart.map(cartEntry => (
          <CSSTransition key={cartEntry.referenceId} timeout={animationTimeout}>
            <RewardRedemptionItem
              cartEntry={cartEntry}
              handleUpdateEntry={handleUpdateEntry}
              handleRemoveEntry={removeInRestaurantRedemptionEntry}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      <EmptyStateV3 data-testid="loyalty-reward-redemption-list-empty-state">
        {enableLoyaltyInApps && (
          <AddButton
            variant={ActionButtonVariants.OUTLINE}
            data-testid="redemption-add-rewards-button"
            size={ActionButtonSizes.LARGE}
            perceptible
            startIcon={
              enableLoyaltyFloatingCartButton ? (
                <Icon icon="add" color="primary" aria-hidden />
              ) : null
            }
            onClick={() => handleEmptyState(routes.rewardsList)}
          >
            {formatMessage({ id: 'loyaltyAddRewards' })}
          </AddButton>
        )}
        {loyaltyIdentificationCodeType === LoyaltyIdentificationCodeTypeVariation.DYNAMIC && (
          <AddButton
            variant={ActionButtonVariants.OUTLINE}
            data-testid="redemption-add-offers-button"
            size={ActionButtonSizes.LARGE}
            perceptible
            startIcon={
              enableLoyaltyFloatingCartButton ? (
                <Icon icon="add" color="primary" aria-hidden />
              ) : null
            }
            onClick={() => handleEmptyState(routes.rewardsOffers)}
          >
            {formatMessage({ id: 'loyaltyAddOffers' })}
          </AddButton>
        )}
      </EmptyStateV3>
    </InRestaurantRedemptionList>
  );
};

export default memo(RewardRedemptionList);
