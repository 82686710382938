import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl';

import useInterval from 'hooks/use-interval';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { TimedFireOptionsVariation } from 'state/launchdarkly/variations';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import {
  isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours,
  isRestaurantOpen,
} from 'utils/restaurant';

import { MINUTE_IN_MS } from './constants';
import { deliveryOptions, pickupOptions } from './options';
import { Container, Fieldset, Group, Label, Radio, Scrollable } from './styled';
import { IOptionSettings, IPickupTimeSelectorProps } from './types';
import {
  calculateNextAvailableOptions,
  deriveDefaultOptionFromValue,
  generateOptions,
  getNearestTimeSlot,
} from './utils';

const PickupTimeSelector = ({
  onChange,
  onDeliveryStatusChange,
  value,
  showAsTime,
  isPickupTimeAvailable,
  scheduledTime,
  createdAt,
  serverOrder,
}: IPickupTimeSelectorProps) => {
  const { store } = useStoreContext();
  const { isDelivery, serviceMode } = useServiceModeContext();
  const timedFireOptions = useFlag<TimedFireOptionsVariation>(LaunchDarklyFlag.TIMED_FIRE_OPTIONS);
  const fireTimeScheduleBeforeClosedStore = useFlag(
    LaunchDarklyFlag.FIRE_TIME_SCHEDULE_ORDER_BEFORE_CLOSED_STORE
  );
  const defaultTimedFireGenerateOptions = isDelivery ? deliveryOptions : pickupOptions;
  const actualTimedFireGenerateOptions =
    timedFireOptions?.generateOptions ?? defaultTimedFireGenerateOptions;
  actualTimedFireGenerateOptions.startNow = !scheduledTime;

  const checkDeliveryOpen = useCallback(() => {
    const { deliveryHours } = store;
    return isRestaurantOpen(deliveryHours);
  }, [store]);

  const [isDeliveryOpen, setIsDeliveryOpen] = useState(checkDeliveryOpen);

  // created fixed timestamp for to use for delivery orders when the store is closed
  const timestamp = useRef(new Date());
  const nearestTimeSlot = getNearestTimeSlot();

  const getOptionDateObject = useCallback(
    (minutes: number) => {
      const timeSlot =
        isDelivery && !isDeliveryOpen ? timestamp.current.getTime() : nearestTimeSlot;
      const date = createdAt ? new Date(createdAt).getTime() : timeSlot;
      return new Date(date + minutes * MINUTE_IN_MS);
    },
    [createdAt, isDelivery, isDeliveryOpen, nearestTimeSlot]
  );

  const getOptions = useCallback(() => {
    const { diningRoomHours, deliveryHours } = store;
    const hours = isDelivery ? deliveryHours : diningRoomHours;

    if (!hours) {
      return [];
    }

    const filterOptions = (option: IOptionSettings): boolean =>
      isOpenIfNotWithinMinutesOfCloseTodayAndNot24hours(
        hours,
        getOptionDateObject(option.inMinutes),
        fireTimeScheduleBeforeClosedStore
      );

    // Use the checkDeliveryOpen instead of the isDeliveryOpen state
    // to avoid state out of sync when the calculation is triggered by
    // a change of the state
    if (isDelivery && !checkDeliveryOpen()) {
      return calculateNextAvailableOptions({
        deliveryOptions: actualTimedFireGenerateOptions,
        deliveryHours: hours,
        now: timestamp.current,
      }).filter(filterOptions);
    }

    const filterIfOrderHasAlreadyBeenInserted = (time: IOptionSettings) => {
      if (!createdAt) {
        return time;
      }
      const dateAfter = new Date(date);
      dateAfter.setMinutes(dateAfter.getMinutes() + time.inMinutes);

      return dateAfter > new Date() ? time : undefined;
    };

    const date: number = createdAt ? new Date(createdAt).getTime() : 0;

    return generateOptions(actualTimedFireGenerateOptions)
      .filter(filterIfOrderHasAlreadyBeenInserted)
      .filter(filterOptions);
  }, [
    store,
    isDelivery,
    checkDeliveryOpen,
    actualTimedFireGenerateOptions,
    createdAt,
    getOptionDateObject,
    fireTimeScheduleBeforeClosedStore,
  ]);

  const [options, setOptions] = useState<IOptionSettings[]>(getOptions);

  const [selectedOption, setSelectedOption] = useState<IOptionSettings | null>(() =>
    deriveDefaultOptionFromValue(value, options)
  );

  const { formatMessage, formatTime } = useIntl();
  const { trackEvent } = useCdpContext();
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);

  const getFireOrderInValue = useCallback(
    (selectedOptionInSeconds = 0) => {
      const orderCreationTime = serverOrder?.createdAt
        ? new Date(serverOrder?.createdAt).getTime()
        : new Date().getTime();
      const differenceWithNearestTimeSlotInSeconds = Math.round(
        (getNearestTimeSlot() - orderCreationTime) / 1000
      );
      const adjustedFireOrderIn =
        differenceWithNearestTimeSlotInSeconds + selectedOptionInSeconds - fireOrderAhead;
      return selectedOptionInSeconds === 0 ? 0 : adjustedFireOrderIn;
    },
    [nearestTimeSlot, fireOrderAhead]
  );

  const getTimeString = (minutes: number) => {
    const formattedTime = formatTime(getOptionDateObject(minutes));
    return `${formattedTime}`;
  };

  const handleChange = useCallback(
    (option: IOptionSettings) => {
      const adjustedValue = getFireOrderInValue(option.inSeconds);
      onChange(adjustedValue);
      setSelectedOption(option);

      trackEvent({
        name: CustomEventNames.SELECT_TIMED_FIRE_OPTION,
        type: EventTypes.Other,
        attributes: {
          'Timed Fire Minutes': Math.round(adjustedValue / 60),
        },
      });
    },
    [trackEvent, onChange, getFireOrderInValue]
  );

  /**
   * If set fireOrderIn value deviates from the selected option for more than 60 seconds
   * then we should update the value.
   * The reason is that the user selects the specific time (9:15, 9:30, 9:45...),
   * while we persist the number of seconds until that selected timed at the time he selects it.
   */
  const updateFireOrderIn = useCallback(() => {
    const adjustedValue = getFireOrderInValue(selectedOption?.inSeconds);
    if (value && Math.abs(value - adjustedValue) >= 60) {
      onChange(adjustedValue);
    }
    if (isDelivery && !isDeliveryOpen) {
      checkDeliveryOpen();
    }
  }, [
    isDelivery,
    isDeliveryOpen,
    getFireOrderInValue,
    selectedOption,
    value,
    checkDeliveryOpen,
    onChange,
  ]);

  useInterval(updateFireOrderIn, MINUTE_IN_MS / 2, [updateFireOrderIn]);
  // In case components re-renders sooner than the set interval
  useEffect(() => updateFireOrderIn, [updateFireOrderIn]);

  // Create a reference to store the previous value of serviceMode
  const prevServiceModeRef = useRef<ServiceMode | null>(serviceMode);

  useEffect(() => {
    // check if serviceMode has changed
    if (prevServiceModeRef.current !== serviceMode) {
      // If changed, update the order context with the new value
      const adjustedValue = getFireOrderInValue(selectedOption?.inSeconds);
      onChange(adjustedValue);
    }

    // Update the previous value of serviceMode
    prevServiceModeRef.current = serviceMode;
  }, [getFireOrderInValue, onChange, selectedOption, serviceMode]);

  /**
   * Recalculate options if the delivey status changes
   * This is needed cause the user might enter really close
   * to open the delivery or very close to close it
   */
  const updateDeliveryOptions = useCallback(() => {
    if (checkDeliveryOpen() !== isDeliveryOpen) {
      setIsDeliveryOpen(checkDeliveryOpen);

      timestamp.current = new Date();
      setOptions(getOptions);
    }
  }, [checkDeliveryOpen, isDeliveryOpen, getOptions]);

  useEffect(() => {
    if (onDeliveryStatusChange) {
      const hasInvalidDeliveryOptions = !options.length;
      onDeliveryStatusChange(hasInvalidDeliveryOptions);
    }
  }, [onDeliveryStatusChange, options.length]);

  useInterval(updateDeliveryOptions, MINUTE_IN_MS / 2, [updateDeliveryOptions]);

  // Make the first option selected any time we set the options
  // and set the fireOrderIn attr in the order context by calling onChange with
  // selected option.inSeconds attribute
  useEffect(() => {
    if (options) {
      setSelectedOption(options[0]);
      const adjustedValue = getFireOrderInValue(options[0]?.inSeconds);
      onChange(adjustedValue);
    }
    // Adding onChange causes the effect tu re-run after every run
    // breaking the functionality
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  // Output something nice when for any reason there're not available options
  if (!options?.length) {
    return (
      <p>
        {formatMessage({
          id: isDelivery ? 'noDeliveryOptionsAvailable' : 'noPickupOptionsAvailable',
        })}
      </p>
    );
  }

  return (
    <Scrollable>
      <Fieldset data-testid="pickup-time-selector">
        <Container>
          {options.map(option => (
            <Group key={option.id}>
              <Radio
                id={option.id}
                data-testid={option.id}
                name="pickUpTime"
                type="radio"
                aria-label={formatMessage({ id: option.ariaLabel }, { minutes: option.inMinutes })}
                value={option.inSeconds}
                checked={selectedOption?.id === option.id}
                onChange={() => handleChange(option)}
                disabled={
                  option.id !== 'now' &&
                  isPickupTimeAvailable &&
                  !isPickupTimeAvailable(getOptionDateObject(option.inMinutes))
                }
              />
              <Label htmlFor={option.id}>
                {showAsTime && option.id !== 'now'
                  ? getTimeString(option.inMinutes)
                  : formatMessage({ id: option.labelId })}
              </Label>
            </Group>
          ))}
        </Container>
      </Fieldset>
    </Scrollable>
  );
};

export default PickupTimeSelector;
