import styled from 'styled-components';

import theme from '../theme';

export const Text = styled.p`
  color: ${theme.textCartConfirmationColor};
  font-family: ${Styles.fontFamily.body};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.43;
  text-align: center;
`;
