import { differenceInMinutes } from 'date-fns';

export const postOrderTimeout = (currentOrderTime: string, timeoutInMinutes: number): boolean =>
  Math.abs(differenceInMinutes(new Date(currentOrderTime), new Date())) < timeoutInMinutes;

export const deliveryCompleteTimeout = (
  currentOrderTime: string,
  timeoutInMinutes: number
): boolean =>
  Math.abs(differenceInMinutes(new Date(currentOrderTime), new Date())) > timeoutInMinutes;
