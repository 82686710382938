import * as React from 'react';
import { FC } from 'react';

import { Footer, Group, HeaderText, HeaderWrapper, SubHeaderText } from './list-item-group.styled';
import { IListItemGroupProps } from './types';

/**
 *
 * ListItemGroup provides a consistent layout to wrap list item rows in a header and footer
 *
 */
const ListItemGroup: FC<IListItemGroupProps> = ({
  items,
  header,
  headerAction,
  subHeader,
  footer,
  isNested,
}) => {
  if (!items?.length) {
    return null;
  }

  return (
    <Group
      role="group"
      aria-labelledby={`legend-${header?.replace(/\s+/g, '')}`}
      data-testid="list-item-group"
    >
      {(header || headerAction) && (
        <HeaderWrapper>
          <HeaderText
            as={isNested ? 'h4' : 'h3'}
            $isNested={isNested}
            data-testid="list-item-group-header"
            id={`legend-${header?.replace(/\s+/g, '')}`}
          >
            {header}
          </HeaderText>
          {headerAction}
        </HeaderWrapper>
      )}
      {subHeader && <SubHeaderText>{subHeader}</SubHeaderText>}
      <ul data-testid="list-item-group-items">
        {items.map(item => (
          <li>{item}</li>
        ))}
      </ul>
      {footer && <Footer data-testid="list-item-group-footer">{footer}</Footer>}
    </Group>
  );
};

export default ListItemGroup;
