import { IFeatureMenuQuery } from 'generated/sanity-graphql';
import { IDayPartBoundary } from 'state/day-part/hooks/use-active-day-parts';

export enum DAY_PART_SELECTIONS {
  DAYTIME_KEY = 'Main Menu',
  BREAKFAST_KEY = 'Breakfast',
  ALL_KEY = 'all',
  BURGERS_FOR_BREAKFAST = 'Burgers for Breakfast',
}

// TODO
// find a better way to type dayParts
export interface IGetDayPart {
  currentDayPart: DAY_PART_SELECTIONS | null;
  dayParts: IFeatureMenuQuery['FeatureMenu'] extends object
    ? IFeatureMenuQuery['FeatureMenu']['dayParts']
    : Array<any>;
}

export interface IUseDayPart {
  breakfastInterval: IDayPartBoundary | undefined;
  mainMenuInterval: IDayPartBoundary | undefined;
  setDayPart: (newDayPart: DAY_PART_SELECTIONS) => void;
  getDayPart: ({ showStaticMenu }: { showStaticMenu: boolean }) => IGetDayPart;
}
