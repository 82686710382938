import { useEffect } from 'react';

import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';

export const useShortCodeRefresh = () => {
  const { shortCodeState, generateShortCode } = useInRestaurantRedemptionContext();

  useEffect(() => {
    if (shortCodeState === ShortCodeState.Expired || shortCodeState === ShortCodeState.None) {
      generateShortCode();
    }
  }, [generateShortCode, shortCodeState]);
};
