import { IStaticPageRoute } from 'remote/queries/static-page';

import { toRelativePath } from './path';

export const getStaticPagesLocalizedRouteForPath = (
  path: string,
  staticRoutes: IStaticPageRoute[] | null,
  language: string
) => {
  const matchingRoute = staticRoutes?.find(route => route.path.current === path);
  if (!matchingRoute) {
    return null;
  }

  const localizedPathWithFallback =
    matchingRoute.localePath?.[language]?.current ||
    matchingRoute.redirectUrl?.locale ||
    matchingRoute.path.current ||
    path;

  return toRelativePath(localizedPathWithFallback);
};
