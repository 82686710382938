import React from 'react';

import { useIntl } from 'react-intl';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isExternalLink } from 'utils/is-external-link';
import { normalize, routes } from 'utils/routing';

import { Container, List, ListItem, Section, SectionTitle, StyledLink } from './styled';
import { ListOfLinksProps, SingleNode } from './types';
import { createLinkClickHandler, getNodes } from './utils';

export const ListOfLinks: React.FC<ListOfLinksProps> = ({
  borderColor,
  entries,
  onDismiss,
  showAboutAppLink,
  showSectionHeadings,
}) => {
  const { formatMessage } = useIntl();
  const enableNutritionExplorerLink = useFlag(LaunchDarklyFlag.ENABLE_NUTRITION_EXPLORER);
  const aboutAppNode: SingleNode[] = showAboutAppLink
    ? [
        {
          type: 'single',
          title: formatMessage({ id: 'aboutApp' }),
          path: routes.aboutApp,
        },
      ]
    : [];
  const nodes = getNodes(entries, enableNutritionExplorerLink).concat(aboutAppNode);

  const renderSingleNode = ({ title, path }: SingleNode) => {
    // Fix relative paths not having a leading slash
    const isExternal = isExternalLink(path);
    const to = isExternal ? path : normalize(path);

    return (
      <ListItem key={`${title}:${to}`}>
        <StyledLink to={to} onClick={createLinkClickHandler({ isExternal, onDismiss })}>
          {title}
        </StyledLink>
      </ListItem>
    );
  };

  return (
    <Container data-testid="links-list">
      {nodes.map((node, i) => {
        const isSection = node.type === 'array';
        return (
          <Section $borderColor={borderColor} key={`path-group-${i}`}>
            {isSection && showSectionHeadings && <SectionTitle>{node.title}</SectionTitle>}
            <List>{isSection ? node.pages.map(renderSingleNode) : renderSingleNode(node)}</List>
          </Section>
        );
      })}
    </Container>
  );
};
