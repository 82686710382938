import { cartSlice } from './cart/cart.slice';

type OrderingActions = typeof cartSlice.actions;

// Exporting all actions from slices in one file mimics exported variables from a context and encourages more explicit naming
// As an added benefit, we are able to see which actions come from which slice in a central location
const {
  clearCart,
  editCartEntry,
  removeCartEntries,
  replaceOfferEntry,
  replacePendingEntry,
  updateQuantity,
  upsertCartEntry,
  upsertFavoritesEntry,
} = cartSlice.actions;

export const orderingActions: OrderingActions = {
  // Cart actions
  clearCart,
  editCartEntry,
  removeCartEntries,
  replaceOfferEntry,
  replacePendingEntry,
  updateQuantity,
  upsertCartEntry,
  upsertFavoritesEntry,
};
