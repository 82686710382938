import React, { useCallback } from 'react';

import { QRCodeRenderersOptions, toCanvas } from 'qrcode';

import logger from 'utils/logger';

interface IQRCodeProps {
  barcode: string;
  className?: string;
  options: QRCodeRenderersOptions;
}
const QRCode = ({ barcode, options, ...canvasProps }: IQRCodeProps) => {
  const canvasRef = useCallback(
    (canvasNode: HTMLCanvasElement | null) => {
      if (canvasNode && barcode) {
        toCanvas(canvasNode, barcode, options, (err: Error) => {
          if (err) {
            logger.error({ error: err, message: 'Unable to render/decoded barcode string' });
          }
        });
      }
    },
    [barcode, logger, options]
  );

  return <canvas {...canvasProps} ref={canvasRef} />;
};

export default QRCode;
