import * as React from 'react';
import { FC } from 'react';

import { maybeLocaleImage } from 'utils/graphql';

import { StyledPicture } from './styled';
import { IItemPictureProps } from './types';

export const OfferItemPicture: FC<IItemPictureProps<'offer'>> = ({
  entryCartDetails: { offer },
  ...props
}) => (
  <StyledPicture
    {...props}
    alt={offer?.localizedImage?.locale?.imageDescription || ''}
    image={offer?.localizedImage?.locale?.app}
  />
);

export const RewardItemPicture: FC<IItemPictureProps<'reward'>> = ({
  entryCartDetails: { reward },
  ...props
}) => (
  <StyledPicture
    {...props}
    alt={reward?.name?.locale || ''}
    image={maybeLocaleImage(reward.image)}
  />
);
