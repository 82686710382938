import React from 'react';

import { normalizedTranslate } from '@rbilabs/components-library';
import styled, { keyframes } from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';

const loadingIn = keyframes`
  0% {
    transform: ${normalizedTranslate('-105%')};
    opacity: 1;
  }
  44.999% {
    opacity: 1;
  }
  45% {
    transform: ${normalizedTranslate('10%')};
    opacity: 0;
  }
  90% {
    transform: ${normalizedTranslate('10%')};
    opacity: 0;
  }
  100% {
    transform: ${normalizedTranslate('10%')};
    opacity: 0;
  }
`;

const loadingOut = keyframes`
  0% {
    transform: ${normalizedTranslate('10%')};
    opacity: 0;
  }
  44.999% {
    opacity: 0;
  }
  45% {
    transform: ${normalizedTranslate('10%')};
    opacity: 1;
  }
  90% {
    transform: ${normalizedTranslate('105%')};
    opacity: 1;
  }
  100% {
    transform: ${normalizedTranslate('105%')};
    opacity: 1;
  }
`;

const LoadingContainer = styled.div<ILoadingContainerProps>`
  height: 0.3rem;
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: ${Styles.zIndex.overlay};

  &::before,
  &::after {
    display: block;
    position: fixed;
    content: '';
    inset-inline-start: 0;
    width: 100%;
    height: 0.3rem;
    will-change: transform;
  }

  &::before {
    background-color: ${p => p.fill};
    animation: ${loadingIn} 2.75s cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite;
    transform: ${normalizedTranslate('-100%')};
  }

  &::after {
    background-color: ${p => p.fill};
    animation: ${loadingOut} 2.75s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    transform: ${normalizedTranslate('100%')};
  }
`;

interface ILoadingContainerProps {
  fill: string;
}

interface ILoadingIndicatorProps extends IBaseProps {
  show: boolean;
  fill?: string;
}

const LoadingIndicatorBar = ({
  fill = Styles.color.primary,
  show,
  className,
}: ILoadingIndicatorProps) => {
  return show ? (
    <LoadingContainer fill={fill} className={className} data-testid="loading-indicator" />
  ) : null;
};

export default LoadingIndicatorBar;
