import { normalizedTranslate } from '@rbilabs/components-library';
import styled, { css } from 'styled-components';

export const NAVIGATION_DRAWER_ANIMATION_TIME = 200;

export const DrawerContainer = styled.div<{
  $isSideViewActive: boolean;
}>`
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  .drawer-main-panel {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    height: 100%;

    ${({ $isSideViewActive }) =>
      $isSideViewActive &&
      css`
        pointer-events: none;
        z-index: -1;
      `}
  }

  .drawer-side-panel {
    width: 100%;
    height: 100%;
    background: ${Styles.color.background};
    z-index: ${Styles.zIndex.overlay + 10};

    ${Styles.desktop} {
      position: absolute;
      inset-inline-start: 0;
      inset-block-start: 0;
      background: ${Styles.color.cardBackground};
    }

    transition: transform ${NAVIGATION_DRAWER_ANIMATION_TIME}ms cubic-bezier(0, 0, 0.3, 1);
    will-change: transform;
    transform: ${normalizedTranslate('100%')};
    ${({ $isSideViewActive }) =>
      $isSideViewActive &&
      css`
        transform: ${normalizedTranslate(0)};
      `}
  }
`;
