import React from 'react';

import { useAccessibility } from 'state/accessibility';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';

import { AppHeaderContainer } from '../app-header-container';
import { Logo } from '../logo';
import { PrimaryNav } from '../primary-nav';
import { SecondaryNav } from '../secondary-nav';

export const DesktopHeader: React.FC = () => {
  const enableSignUp = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING) ?? false;
  const enableBottomServiceMode = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableTopServiceMode =
    useFlag(LaunchDarklyFlag.ENABLE_TOP_SERVICE_MODE) && !enableBottomServiceMode;
  const enableDesktopSideNavigation = useFlag(LaunchDarklyFlag.ENABLE_DESKTOP_SIDE_NAVIGATION);

  const cdp = useCdpContext();
  const { isStoreOpenAndAvailable, noStoreSelected } = useStoreContext();
  const { isFullPageModalActive } = useAccessibility();

  // enable cart if a selected store is open and and available or no store is selected
  const enableCart = enableOrdering && (isStoreOpenAndAvailable || noStoreSelected);

  return (
    <AppHeaderContainer data-mediaquery="desktop" aria-hidden={isFullPageModalActive}>
      <PrimaryNav enableDesktopSideNavigation={enableDesktopSideNavigation} />

      <Logo />

      {enableSignUp && (
        <SecondaryNav
          enableOrdering={enableOrdering}
          enableCart={enableCart}
          enableTopServiceMode={enableTopServiceMode}
          onMyAccountClick={() => cdp.logNavigationClick(CustomEventNames.BUTTON_CLICK_MY_ACCOUNT)}
        />
      )}
    </AppHeaderContainer>
  );
};
