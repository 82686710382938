import React from 'react';

import styled from 'styled-components';

import { IButtonProps } from '@rbi-ctg/frontend';

interface ITextButton extends IButtonProps {
  color?: string;
}

const TextButton = styled.button<ITextButton>`
  background-color: transparent;
  color: ${props => props.color || Styles.color.black};
  border: 0;
  cursor: pointer;
  font-family: ${Styles.fontFamily.body};
  font-size: 0.875rem;
  padding: 20px 0;
  text-decoration: underline;
  user-select: none;

  &:focus {
    outline: 1px solid ${props => props.color || Styles.color.black};
  }
`;

export default (props: ITextButton) => <TextButton type="button" {...props} />;
