import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { isNil } from 'lodash';

import {
  GetStoreOrderQuery,
  IGetStoreOrderQueryResponse,
  IGetStoreOrderQueryVariables,
  IServerCateringOrder,
} from 'remote/queries/order';

import { IUseStoreOrderStatusParams } from './types';

export const useStoreOrderStatus = ({ storeOrderJwt }: IUseStoreOrderStatusParams) => {
  const queryResult = useQuery<IGetStoreOrderQueryResponse, IGetStoreOrderQueryVariables>(
    GetStoreOrderQuery,
    {
      variables: { storeOrderJwt },
      skip: isNil(storeOrderJwt),
      fetchPolicy: 'network-only',
    }
  );

  const { data } = queryResult;
  const serverOrder = useMemo<IServerCateringOrder | null>(
    () => (data && data.cateringOrder ? data.cateringOrder : null),
    [data]
  );

  return {
    ...queryResult,
    serverOrder,
  };
};
