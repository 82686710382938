import { ComponentType, ReactNode } from 'react';

export interface IAccordionItemProps {
  contentWrapper?: ComponentType;
  /** Gets rendered at the title content. */
  title: ReactNode;
  /** callback that triggers when the accordion is expanded or collapsed */
  onInteract?: (isExpanded: boolean, isFirstInteraction: boolean) => void;
  /** Allows for a colored background when the item is expanded */
  activeHighlight?: boolean;
  /** Select the icon type that you want to display */
  iconType?: IconType; // replace it with an enum
  children: ReactNode;
}

export interface IUseAccordionItemProps {
  onInteract?: (isExpanded: boolean, isFirstInteraction: boolean) => void;
}

export interface IAccordionProps {
  children: ReactNode;
}

export enum IconType {
  ARROW = 'arrow',
}
