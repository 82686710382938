import React, { useState } from 'react';

import styled, { CSSProp } from 'styled-components';

const Container = styled.div<{ activeCss: any }>`
  cursor: pointer;

  ${p => p.activeCss}
`;

type OnActiveCss = (active: boolean) => CSSProp;
interface IClickableContainer extends React.HTMLAttributes<HTMLDivElement> {
  onActiveCss?: OnActiveCss;
}

export const ClickableContainer = React.forwardRef<HTMLDivElement, IClickableContainer>(
  (props, ref) => {
    const [isActive, setIsActive] = useState(false);

    const { onActiveCss } = props;

    return (
      <Container
        role="button"
        tabIndex={0}
        ref={ref}
        {...props}
        activeCss={onActiveCss && onActiveCss(isActive)}
        onTouchStart={() => setIsActive(true)}
        onTouchCancel={() => setIsActive(false)}
        onTouchEnd={() => setIsActive(false)}
      >
        {props.children}
      </Container>
    );
  }
);
