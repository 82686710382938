import React, { useRef } from 'react';

import { useIntl } from 'react-intl';

import { IStore } from '@rbi-ctg/store';
import DirectionsLink from 'components/store-card/directions-link';
import StoreCardIcons from 'components/store-card-icons';
import { IStoreDiagnosticData } from 'components/store-diagnostic-modal';
import { useStoreCard } from 'hooks/store-card';
import { StoreCardError } from 'pages/store-locator/new-ui/store-card/components';
import { useGeolocation } from 'state/geolocation';
import { StoreProxy } from 'state/store';

import { Address, StreetAddress, StyledStoreCard } from './styled';

export interface IStoreCardOptions {
  store: StoreProxy;
}

export const StoreCard: React.FC<IStoreCardOptions> = ({ store }) => {
  const address = store.customerFacingAddress?.locale || store.physicalAddress?.address1;
  const formattedCity = store.physicalAddress?.city ?? '';
  const state = store.physicalAddress?.stateProvince ?? '';
  const postalCode = store.physicalAddress?.postalCode ?? '';
  const storeLatLong = store.latitude &&
    store.longitude && { lat: store.latitude, lng: store.longitude };

  const {
    diningRoomOpen,
    driveThruOpen,
    isMobileOrderingAvailable,
    isRestaurantPosOnline,
    areDiagnosticToolsEnabled,
  } = useStoreCard({ restaurant: store as IStore });

  const storeDiagnosticData: IStoreDiagnosticData = {
    driveThruOpen,
    diningRoomOpen,
    isRestaurantOpen: true,
    isMobileOrderingAvailable,
    isRestaurantPosOnline,
    restaurantName: store.name || '',
    restaurantHasMobileOrdering: store.hasMobileOrdering || false,
    restaurantCountry: store?.physicalAddress?.country || '',
    restaurantStreetAddress: address || '',
  };

  const { current: position } = useRef(storeLatLong);
  const { activeCoordinates } = useGeolocation();
  const { formatMessage } = useIntl();

  return (
    <StyledStoreCard>
      <StreetAddress id="street-address">{address}</StreetAddress>
      <Address id="city-state-zip-address">
        {formattedCity} {state} {postalCode}
      </Address>
      {activeCoordinates && position && (
        <DirectionsLink activeCoordinates={activeCoordinates} position={position} />
      )}
      <StoreCardError message={formatMessage({ id: 'virtualQueueCurrentlyBusyWarning' })} />
      <StoreCardIcons
        storeNumber={store.number ?? ''}
        storeId={store._id ?? ''}
        diagnosticsEnabled={areDiagnosticToolsEnabled}
        storeDiagnosticData={storeDiagnosticData}
      />
    </StyledStoreCard>
  );
};
