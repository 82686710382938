import { useMemo } from 'react';

import { selectors, useAppSelector } from 'state/global-state';

export const useRewardQuantity = () => {
  const appliedLoyaltyRewardsArray = useAppSelector(
    selectors.loyalty.selectAppliedLoyaltyRewardsArray
  );
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);

  return useMemo(() => {
    const rewardQuantity: number =
      appliedLoyaltyRewardsArray?.reduce(
        (accAppliedReward, appliedReward) => appliedReward.timesApplied + accAppliedReward,
        0
      ) || 0;
    const offerQuantity: number = appliedOffers?.length || 0;

    return offerQuantity + rewardQuantity;
  }, [appliedLoyaltyRewardsArray, appliedOffers]);
};
