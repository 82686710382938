import styled from 'styled-components';

const CartItemButton = styled.button`
  color: ${Styles.color.primary};
  font-family: ${Styles.fontFamily.brand};
  border: 0;
  padding: 0.75rem;
`;

export default CartItemButton;
