import styled from 'styled-components';

import { primitive } from 'styles/constants/primitives';

type QRCodeTipProps = {
  isFHSBrand: boolean;
};

export const QRCodeWrapper = styled.div`
  text-align: center;

  & > canvas {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

export const QRCodeTip = styled.p<QRCodeTipProps>`
  font: var(--font-copyTwo);
  margin-block-start: 0;
  margin-block-end: ${primitive.$spacing4};
  margin-inline: 0;
  color: ${({ isFHSBrand }) => isFHSBrand && primitive.fhs.$houseNavy};
`;
