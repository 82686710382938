import React, { useCallback, useState } from 'react';

import { useGeolocation } from 'state/geolocation';
import { isMobile } from 'utils/environment';

import { GeolocationModalView } from './geolocation-modal-view';

export const AutoGeolocationModal = () => {
  const { isPermissionPrompt, setPermissionChecking, setPermissionPrompt, isPermissionKnown } =
    useGeolocation();
  // Auto geolocation modal should only show on mobile sized devices (including mobile on web app)
  const openModalOnMount = isMobile() && isPermissionPrompt;

  const [modalOpen, setModalOpen] = useState(openModalOnMount);

  const handleGeolocationPrompt = useCallback(() => {
    setModalOpen(false);
    setPermissionChecking();
  }, [setPermissionChecking]);

  const handleDismissModal = useCallback(() => {
    setModalOpen(false);
    setPermissionPrompt();
  }, [setPermissionPrompt]);

  const showModal = modalOpen && !isPermissionKnown;

  return showModal ? (
    <GeolocationModalView
      handleDismissModal={handleDismissModal}
      handleGeolocationPrompt={handleGeolocationPrompt}
    />
  ) : null;
};
