import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { noop } from 'lodash';
import { useIntl } from 'react-intl';

import ActionButton, {
  ActionButtonSizes,
  ActionButtonVariants,
  ActionLink,
} from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import { useOrderStatus } from 'hooks/order-status';
import { useRefundEligibility } from 'hooks/refund-eligibility';
import { useOrderDetails } from 'hooks/use-order-details';
import { useSupportData } from 'hooks/use-support-data';
import LoadingIndicator from 'pages/store-locator/loading-indicator';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';
import { OrderSuccessFailureStatuses } from 'state/order/constants';
import { useReorder } from 'state/order/hooks/use-reorder';
import { isHistoricalMigratedOrder } from 'state/order/utils';
import { everyCartEntriesInMenu } from 'utils/cart';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { MODAL_TYPE } from '../types';

import { CartEntry } from './cart-entry';
import {
  Block,
  Container,
  Footer,
  LoadingContainer,
  ModalHead,
  ModalInner,
  NeedHelpButtonLoadingIndicatorWrapper,
  OrderDetailsScrollContainer,
  StyledCartEntries,
  StyledModal,
} from './details-modal.styled';
import getModalHeading from './get-modal-heading';
import IsEnRouteModal from './is-en-route-modal';
import { PointsEarnedBlock } from './points-earned';
import { ReceiptDetails } from './receipt-details';
import { ReceiptHeadInfo } from './receipt-head-info';
import { useHelpLinkUrlGenerator } from './use-help-link-url-generator';

const DetailsModal: React.FC<{
  rbiOrderId: string;
  onModalDismiss: VoidFunction;
  type: string;
  setModalType?: Dispatch<SetStateAction<MODAL_TYPE>>;
  setDetailsModalId?: (orderId: string) => void;
}> = ({ rbiOrderId, onModalDismiss, type, setModalType = noop, setDetailsModalId = noop }) => {
  const { formatMessage } = useIntl();
  const { order } = useOrderDetails(rbiOrderId);

  const { handleReorderClick, disabled } = useReorder(order);
  const {
    reorder: { reordering },
  } = useOrderContext();
  const enableOrderSupport = useFlag(LaunchDarklyFlag.ENABLE_ORDER_SUPPORT);

  const { logNavigationClick } = useCdpContext();
  const { isDeliveryActive } = useRefundEligibility({
    orderId: rbiOrderId,
  });
  const { isSupportDataLoading } = useSupportData();

  const helpLink = useHelpLinkUrlGenerator(rbiOrderId);
  const disableReorder = useMemo(
    () => disabled || !everyCartEntriesInMenu(order?.cart?.reorderCartEntries || []),
    [disabled, order]
  );

  const { serverOrder } = useOrderStatus({
    failureStatuses: OrderSuccessFailureStatuses.failures,
    orderStatusPollInterval: 20000,
    rbiOrderId,
    successStatuses: OrderSuccessFailureStatuses.success,
    skip: false,
  });

  if (isDeliveryActive && type !== MODAL_TYPE.SHOW_DETAILS && serverOrder) {
    return <IsEnRouteModal serverOrder={serverOrder} onModalDismiss={() => onModalDismiss()} />;
  }

  const loyaltyTransaction = order?.loyaltyTransaction;
  const handleNeedHelpClick = () => {
    logNavigationClick(CustomEventNames.BUTTON_CLICK_RECENT_ORDER_NEED_HELP);
  };

  const handleTrackOrder = () => {
    setDetailsModalId(order.rbiOrderId);
    setModalType(MODAL_TYPE.TRACK_ORDER);
  };

  // We will want to hide missing information from migrated orders
  const isMigratedOrderWithoutCart = isHistoricalMigratedOrder(order);

  return !order ? null : (
    <StyledModal
      onDismiss={() => onModalDismiss()}
      eventData={{
        modalAppearanceEventMessage: 'Order Details',
      }}
    >
      <ModalInner>
        <ModalHead>
          <h2 id="modal-heading">{getModalHeading(formatMessage)}</h2>
        </ModalHead>
        <OrderDetailsScrollContainer>
          <Container>
            {order ? (
              <>
                <Block>
                  <ReceiptHeadInfo order={order} />
                </Block>
                {!isMigratedOrderWithoutCart && loyaltyTransaction && (
                  <PointsEarnedBlock lylTransaction={loyaltyTransaction} />
                )}
                {!isMigratedOrderWithoutCart && (
                  <StyledCartEntries>
                    {order.cart.cartEntries.map((entry, i) => (
                      <CartEntry entry={entry} key={entry.name! + i} index={i} />
                    ))}
                  </StyledCartEntries>
                )}
                <Block>
                  <ReceiptDetails order={order} />
                </Block>
              </>
            ) : (
              <LoadingContainer>
                <LoadingAnimation fillColor={Styles.color.black} />
              </LoadingContainer>
            )}
          </Container>
        </OrderDetailsScrollContainer>
        <Footer>
          <Container>
            {isDeliveryActive ? (
              <ActionButton fullWidth onClick={handleTrackOrder}>
                {formatMessage({ id: 'trackOrder' })}
              </ActionButton>
            ) : (
              <ActionButton
                fullWidth
                onClick={handleReorderClick}
                isLoading={reordering}
                disabled={disableReorder}
              >
                {formatMessage({ id: 'reorder' })}
              </ActionButton>
            )}
            <p>
              {formatMessage({
                id: 'pricesDiscountsAndAvailabilityAreSbujectToChangeWhenYouReorder',
              })}
            </p>

            {isSupportDataLoading && enableOrderSupport && (
              <NeedHelpButtonLoadingIndicatorWrapper>
                <LoadingIndicator />
              </NeedHelpButtonLoadingIndicatorWrapper>
            )}

            {/*
             * isSupportDataLoading becomes false quickly, so aria-live="polite" on this button is distracting.
             * This will silently appear for screen readers.
             */}
            {!isMigratedOrderWithoutCart && !isSupportDataLoading && enableOrderSupport && (
              <Box margin="auto">
                <ActionLink
                  data-testid="recent-order-need-help"
                  fullWidth
                  variant={ActionButtonVariants.OUTLINE}
                  size={ActionButtonSizes.SMALL}
                  onClick={handleNeedHelpClick}
                  to={helpLink}
                >
                  {formatMessage({ id: 'needHelp' })}
                </ActionLink>
              </Box>
            )}
          </Container>
        </Footer>
      </ModalInner>
    </StyledModal>
  );
};

export default DetailsModal;
