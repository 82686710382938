import {
  GraphQLEnv,
  getConfigValue,
  graphqlEnv,
  graphqlGatewayEnv,
  isNative,
} from 'utils/environment';

export const getGraphqlApiUrl = () => {
  const awsConfig = getConfigValue('aws');
  return graphqlEnv() === GraphQLEnv.TEST || graphqlEnv() === GraphQLEnv.LOCAL
    ? 'http://localhost:3001/graphql'
    : isNative
      ? awsConfig.gqlApiUrl
      : '/api/whitelabel';
};

export enum QUERY_TYPE {
  LambdaGraphqlQuery,
  SanityGroqQuery,
  SanityGraphqlQuery,
}

export const getGraphqlGatewayApiUrl = () => {
  const awsConfig = getConfigValue('aws');
  switch (graphqlGatewayEnv()) {
    case GraphQLEnv.TEST:
      return 'https://use1-dev-bk-gateway.rbictg.com/graphql';
    case GraphQLEnv.LOCAL:
      return 'http://localhost:3002/graphql';
    default:
      return isNative ? awsConfig.gqlGatewayApiUrl : '/api/gateway';
  }
};

export enum HttpErrorCodes {
  BadRequest = 400,
  InternalServerError = 500,
  TooManyRequests = 429,
  Unauthorized = 401,
}
