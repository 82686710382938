import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Picture from 'components/picture';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';

const DeliveryDisclaimerContainer = styled.div`
  text-align: center;
  margin-block-start: 1rem;
  font-size: 0.65rem;
`;

const DeliveryLogoContainer = styled.div`
  width: 10rem;
  margin-block-start: 1rem;
  margin-block-end: 0;
  margin-inline: auto;
`;

const DeliveryDisclaimer: React.FC = () => {
  const { formatMessage } = useIntl();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { deliveryDisclaimer, deliveryLogo } = featureDisclaimers || {};

  // The second condition is a afeguard against a Sanity bug with `localeString`
  // Refs. https://rbidigital.slack.com/archives/CCHFM51A8/p1620400033200600
  if (!deliveryDisclaimer || !deliveryDisclaimer.locale?.trim()) {
    return null;
  }

  return (
    <DeliveryDisclaimerContainer>
      {deliveryDisclaimer.locale}
      {deliveryLogo && (
        <DeliveryLogoContainer>
          <Picture
            image={deliveryLogo.locale}
            alt={formatMessage({ id: 'deliveryLogoAlt' })}
            objectFitContain
          />
        </DeliveryLogoContainer>
      )}
    </DeliveryDisclaimerContainer>
  );
};

export default DeliveryDisclaimer;
