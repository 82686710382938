import styled from 'styled-components';

import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import Picture from 'components/picture';
import SanityBlockRenderer from 'components/sanity-block-renderer';

import theme from './theme';

// item picture
export const StyledPicture = styled(Picture)`
  max-width: 6.25rem;
  height: 4.25rem;
`;

// item name
export const Name = styled.h2`
  font-family: ${Styles.fontFamily.brand};
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
`;

// item description
export const StyledDescriptionSanityBlockRenderer = styled(SanityBlockRenderer)`
  font-family: ${Styles.fontFamily.body};
  font-size: 0.8125rem;
  line-height: 0.875rem;
`;

export const StyledLoyaltyPointsIcon = styled(LoyaltyPointsIcon)`
  width: 1.11rem;
  path {
    fill: ${theme.itemDescriptionDefaultColor};
  }
`;

export const RewardItemDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const RewardItemDescriptionDetail = styled.div`
  font-family: ${Styles.fontFamily.brand};
  text-transform: capitalize;
  font-size: ${theme.itemDescriptionFontSize};
  line-height: ${theme.itemDescriptionLineHeight};
  font-weight: ${theme.itemDescriptionFontWeight};
  margin-inline-start: 0.125rem;
  padding-block-start: 0.3rem;
  color: ${theme.itemDescriptionDefaultColor};
`;
