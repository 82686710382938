import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { merge } from 'lodash';

import { ICdpCtx } from 'state/cdp/types';

export class WithMParticleLink extends ApolloLink {
  public cdp: ICdpCtx;

  public request(operation: Operation, forward: NextLink) {
    const context = operation.getContext();

    operation.setContext(merge({ mParticle: this.cdp }, context));

    return forward(operation);
  }

  public setMParticle(mParticle: ICdpCtx): ICdpCtx {
    this.cdp = mParticle;
    return mParticle;
  }
}

/**
 * use a singleton instance to share mParticle across requests
 */
export const withMParticle = new WithMParticleLink();
