import React, { useState } from 'react';

import { Icon } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import { ISanityBlockContent } from '@rbi-ctg/menu';
import LoadingAnimation from 'components/loading-animation';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import { useAcceptanceAgremeentsByIdsLazyQuery } from 'generated/sanity-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import { IAcceptanceAgreement, IRequiredUserAcceptance } from 'utils/user-agreements/types';
import { getHtmlContentFromWidgets } from 'utils/user-agreements/user-agreements';

import { ContentDescription, ContentHeader, TitleModal } from '../styled';

import {
  ButtonBack,
  Container,
  HeaderContainer,
  ModalContainerDetail,
  WrapperCenter,
} from './styled';

interface ImodalPolicies {
  termsObject: IRequiredUserAcceptance;
  onBack: VoidFunction;
}

const ModalAgreementDetail = ({ termsObject, onBack }: ImodalPolicies) => {
  const { formatMessage } = useIntl();
  const [sanityContent, setSanityContent] = useState([] as ISanityBlockContent[] | undefined);

  const [sanityContentHtml, setSanityContentHtml] = useState('');

  const hasExternalContent = !!sanityContentHtml;

  const [isLoading, setIsLoading] = useState(true);

  const [getAcceptanceAgreementsByIds, { error: getAcceptanceAgreementInfoFailed }] =
    useAcceptanceAgremeentsByIdsLazyQuery();

  const setAgreementContent = (agreementContent: IAcceptanceAgreement) => {
    if (agreementContent.pageHtml?.code) {
      setSanityContentHtml(agreementContent.pageHtml?.code);
    } else {
      const content = getHtmlContentFromWidgets(agreementContent);
      setSanityContent(content);
    }

    setIsLoading(false);
  };

  const loadAgreementData = async (): Promise<void> => {
    const { data } = await getAcceptanceAgreementsByIds({
      variables: {
        ids: [termsObject._id],
      },
    });

    const { allStaticPage: requiredAcceptanceList = [] } = data || {};

    const [requiredAcceptance] = requiredAcceptanceList;

    setAgreementContent(requiredAcceptance);
  };

  useEffectOnce(() => {
    loadAgreementData();
  });

  return (
    <ModalContainerDetail hasExternalContent={hasExternalContent}>
      {isLoading ? (
        <WrapperCenter>
          <LoadingAnimation fillColor={Styles.color.primary} />
        </WrapperCenter>
      ) : (
        <>
          <ButtonBack data-testid="details-back-button" onClick={onBack}>
            <Icon icon="back" color="black" aria-hidden width="20px" />
          </ButtonBack>

          {getAcceptanceAgreementInfoFailed ? (
            <WrapperCenter>
              {formatMessage({ id: 'looksLikeWereExperiencingIssues' })}
            </WrapperCenter>
          ) : (
            <Container>
              <HeaderContainer hasExternalContent={hasExternalContent}>
                {!hasExternalContent && (
                  <ContentHeader>
                    <TitleModal>{termsObject.localeTitle?.locale || termsObject.title}</TitleModal>
                  </ContentHeader>
                )}
              </HeaderContainer>
              <ContentDescription data-testid="details-content-description">
                {sanityContentHtml ? (
                  <div dangerouslySetInnerHTML={{ __html: sanityContentHtml }} />
                ) : (
                  sanityContent?.length && (
                    <SanityBlockRenderer withMargins content={sanityContent} />
                  )
                )}
              </ContentDescription>
            </Container>
          )}
        </>
      )}
    </ModalContainerDetail>
  );
};

export default ModalAgreementDetail;
