import { rgba } from 'polished';
import styled from 'styled-components';

export const Scrollable = styled.div`
  overflow-x: auto;
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
`;

export const Container = styled.ul`
  display: flex;
  padding: 0.75rem;
  margin-block-start: 0;
  background-color: ${rgba(Styles.color.black, 0.04)};
  overflow-y: auto;
`;

export const Label = styled.label`
  display: block;
  background: ${({ theme }) => theme.token('pickup-timed-fire-selector-button-background')};
  border-radius: ${Styles.borderRadius};
  color: ${Styles.color.black};
  font-size: 0.9375rem;
  padding: 0.75rem 1rem;
  margin: 0 0.5rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: ${Styles.color.primaryHover};
    color: ${Styles.color.white};
    z-index: ${Styles.zIndex.below};
  }
`;

export const Group = styled.li`
  flex: 0 0 auto;
  list-style: none;
  display: 'block';
  min-width: 130px;
`;

export const Radio = styled.input`
  position: absolute;
  inset-inline-start: -9999px;
  height: 1px;
  opacity: 0;
  font-family: ${Styles.fontFamily.base};

  &:checked + label {
    background: ${Styles.color.primary};
    color: ${Styles.color.white};
    z-index: ${Styles.zIndex.below};
  }

  &:focus + label {
    outline: -webkit-focus-ring-color auto 5px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 5px;
  }

  &:disabled + label {
    background: ${Styles.color.grey.two};
    color: ${Styles.color.grey.one};
  }

  /* work around for Edge not supporting outline-style: auto */
  @supports (-ms-ime-align: auto) {
    &:focus + label {
      outline-color: invert;
      outline-width: 3px;
      outline-style: dotted;
    }
  }
`;
