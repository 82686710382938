import { IsoCountryCode2, getIso2, getZipCodeRegex } from '@rbilabs/intl';
import { IntlShape } from 'react-intl';

import { getDobErrors, getLegacyDobErrors } from 'pages/authentication/sign-up/dobValidation';
import { FALLBACK_ISO2, FALLBACK_ZIPCODE_REGEX } from 'utils/constants';
import { brand } from 'utils/environment';
import {
  getPhoneNumberErrorMessage,
  isEmailValid,
  isNameInputValid,
  isPhoneNumberValid,
} from 'utils/form';
import { COUNTRY_CODES } from 'utils/form/constants';

import { IUserFormData, IValuesValidationOverrides } from './types';

/*  ======== Form Validation ======== */
export interface INameAndEmailParams {
  formData: IUserFormData;
  formatMessage: IntlShape['formatMessage'];
}

export function validateEmail({ formData, formatMessage }: INameAndEmailParams) {
  if (!formData.email.trim()) {
    return { email: formatMessage({ id: 'emailRequiredError' }) };
  }

  return validateEmailPattern({ formData, formatMessage });
}

export function validateEmailPattern({ formData, formatMessage }: INameAndEmailParams) {
  if (!isEmailValid(formData.email)) {
    return { email: formatMessage({ id: 'notValidEmailError' }) };
  }

  return {};
}

export function validateName(nameParams: INameAndEmailParams) {
  if (!nameParams.formData.name.trim()) {
    return {
      name: nameParams.formatMessage({ id: 'nameRequiredError' }),
    };
  } else if (!isNameInputValid(nameParams.formData.name)) {
    return {
      name: nameParams.formatMessage({ id: 'notValidNameError' }),
    };
  }

  return {};
}

export interface IPhoneDobZipParams extends INameAndEmailParams {
  region: IsoCountryCode2;
  simpleValidation?: boolean;
}
export async function validatePhoneNumber({
  formData,
  region,
  formatMessage,
  simpleValidation,
}: IPhoneDobZipParams) {
  const phoneNumberValidation = formData.phoneNumber
    ? await isPhoneNumberValid({
        phoneNumber: formData.phoneNumber,
        country: region,
        simpleValidation,
      })
    : { valid: true };

  if (!phoneNumberValidation.valid) {
    return {
      phoneNumber: getPhoneNumberErrorMessage(phoneNumberValidation.error, formatMessage),
    };
  }

  return {};
}

export function validateDateOfBirth(
  dobParams: IPhoneDobZipParams,
  valuesValidationOverrides?: IValuesValidationOverrides
) {
  let dobError;
  // ! The typeof === object conditional may be able to be removed at this point
  if (typeof dobParams.formData.dob === 'object') {
    dobError =
      getDobErrors(
        dobParams.formData.dob,
        dobParams.formatMessage,
        valuesValidationOverrides?.minimumSignUpAge
      ) ?? undefined;
  } else {
    dobError =
      getLegacyDobErrors(
        dobParams.formData.dob,
        dobParams.formatMessage,
        valuesValidationOverrides?.minimumSignUpAge
      ) ?? undefined;
  }

  return dobError ? { dob: dobError } : {};
}

export function validateZipCode({ formData, formatMessage }: IPhoneDobZipParams) {
  let zipCodeError;

  const iso2 = getIso2({ iso3: formData.isoCountryCode }) || FALLBACK_ISO2;

  const regex = getZipCodeRegex(brand().toUpperCase(), iso2) || FALLBACK_ZIPCODE_REGEX;

  if (formData.zipcode && formData.isoCountryCode && !regex.test(formData.zipcode)) {
    zipCodeError = formatMessage({ id: 'postalCodeInvalid' });
  }

  return zipCodeError ? { zipcode: zipCodeError } : {};
}

export function parsePhoneNumberAndCountryCode(phoneNumber: string) {
  const matchingCountry = COUNTRY_CODES.find(country =>
    phoneNumber.startsWith(country.countryCode)
  );
  if (matchingCountry) {
    const remainingNumber = phoneNumber.slice(matchingCountry.countryCode.length);
    return {
      phoneNumberDialCode: matchingCountry.countryCode,
      country: matchingCountry.value,
      phoneNumber: remainingNumber,
    };
  } else {
    return {
      phoneNumberDialCode: '',
      country: '',
      phoneNumber,
    };
  }
}

export const allValidationMethods = {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validateDateOfBirth,
  validateZipCode,
};
//
