import React from 'react';

import { PrimaryButton, SecondaryButton } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';

import {
  AddressContainer,
  ButtonsContainer,
  Container,
  CustomDialog,
  PrimaryButtonWrapper,
  Text,
  Title,
} from './styled';
import { buttonLabels, titleMessages, warningConfig } from './types';

export interface IDistanceWarningModalProps {
  city: string;
  onConfirm: VoidFunction;
  onDismiss: VoidFunction;
  streetName: string;
  isGeolocationEnabled: boolean;
  isDelivery?: boolean;
  isOnConfirmLoading?: boolean;
}

export const DistanceWarningModal = ({
  city,
  onConfirm,
  onDismiss,
  streetName,
  isOnConfirmLoading,
  isGeolocationEnabled,
  isDelivery = false,
}: IDistanceWarningModalProps) => {
  const { formatMessage } = useIntl();
  const { trackEvent } = useCdpContext();
  const { pathname } = useLocation();

  const permissionKey = isGeolocationEnabled ? 'permissionGranted' : 'permissionDenied';
  const deliveryKey = isDelivery ? 'delivery' : 'pickup';

  const distanceWarningType = warningConfig[permissionKey][deliveryKey].type;

  const { onConfirmLabel, onCancelLabel } = buttonLabels[distanceWarningType];

  const titleMessage = titleMessages[distanceWarningType];

  const { name: eventMessage, onCancelName } = warningConfig[permissionKey][deliveryKey].analytics;

  const handleOnConfirm = () => {
    trackEvent({
      name: CustomEventNames.BUTTON_CLICK,
      type: EventTypes.Other,
      attributes: {
        Name: 'Yes, continue',
        Path: pathname,
      },
    });

    onConfirm();
  };

  const handleOnDismiss = () => {
    trackEvent({
      name: CustomEventNames.BUTTON_CLICK,
      type: EventTypes.Other,
      attributes: {
        Name: onCancelName,
        Path: pathname,
      },
    });

    onDismiss();
  };

  return (
    <CustomDialog
      bodyComponent={
        <AddressContainer>
          <Text>{streetName}</Text>
          <Text>{city}</Text>
        </AddressContainer>
      }
      headingComponent={
        <Container>
          <Title>{formatMessage({ id: titleMessage })}</Title>
        </Container>
      }
      heading={formatMessage({ id: titleMessage })}
      body={`${streetName} ${city}`}
      actions={
        <ButtonsContainer>
          <PrimaryButtonWrapper>
            <PrimaryButton
              fullWidth
              disabled={false}
              loading={isOnConfirmLoading}
              aria-label={onConfirmLabel}
              onClick={handleOnConfirm}
            >
              {formatMessage({ id: onConfirmLabel })}
            </PrimaryButton>
          </PrimaryButtonWrapper>
          <SecondaryButton fullWidth onClick={handleOnDismiss} aria-label={onCancelLabel}>
            {formatMessage({ id: onCancelLabel })}
          </SecondaryButton>
        </ButtonsContainer>
      }
      modalAppearanceEventMessage={eventMessage}
    />
  );
};
