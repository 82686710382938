import { noop } from 'lodash';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { IUseGooglePay } from '../types';

import { googlePayCardDetails } from './google-pay-payment-details';

export const googlePayStub = {
  requestingPayment: false,
  googlePayCardDetails,
  requestGooglePayPayment: noop as any,
};

// eslint-disable-next-line
export default function useGooglePay(args?: IUseGooglePay) {
  const enableTestGooglePayUi = useFlag(LaunchDarklyFlag.ENABLE_TEST_GOOGLE_PAY_WEB);
  const canMakeGooglePayPayments = async () => enableTestGooglePayUi;

  return {
    ...googlePayStub,
    canUseGooglePay: enableTestGooglePayUi,
    canMakeGooglePayPayments,
    googleDeviceCanMakePayments: canMakeGooglePayPayments,
  };
}
