import React from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { IBaseProps, OnClick } from '@rbi-ctg/frontend';
import Modal, { ModalContent, ModalSize } from 'components/modal';
import UnstyledButton from 'components/unstyled-button';

const StyledModal = styled(Modal)`
  & .modal-scroller {
    padding: 0;
    display: flex;
  }
  ${Styles.desktop} {
    width: 35vw !important;
    height: 12vh !important;

    & p {
      margin: 0;
    }
  }
`;

const StyledModalContent = styled(ModalContent)`
  ${Styles.mobileSmall} {
    height: 25vh;
    width: 90vw;
    inset-inline-start: 5vw;
    inset-block-start: 40vh;
    border-radius: 20px;
  }
`;

const StyledDesc = styled.p`
  ${Styles.mobileSmall} {
    margin: 0;
    margin-block-end: 0.5rem;
  }
`;

export interface IPickupDialogProps extends IBaseProps {
  onDismiss?: OnClick;
}

const PickupDialog = ({ onDismiss, children }: IPickupDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledModal
      eventData={{
        modalAppearanceEventMessage: 'Store closing hour dialog',
      }}
      size={ModalSize.SMALL}
      allowsDismiss={false}
      backgroundColor={'transparent'}
    >
      <StyledModalContent>
        <StyledDesc>{children}</StyledDesc>
        <Box margin="0.5rem 0 0 0" width="100%" justify="flex-end">
          <UnstyledButton onClick={onDismiss} data-testid="store-closing-hour-okay-button">
            {formatMessage({ id: 'okayButtonText' })}
          </UnstyledButton>
        </Box>
      </StyledModalContent>
    </StyledModal>
  );
};

export default PickupDialog;
