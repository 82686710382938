import styled from 'styled-components';

export const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-block-start: 4px;

  > * + * {
    margin-inline-start: 12px;
  }
`;
