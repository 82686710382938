import styled from 'styled-components';

export const StyledText = styled.div`
  font-size: 0.875rem;
  line-height: 1rem;
  margin-block-start: 0;
  margin-block-end: 1rem;
  margin-inline: auto;
  max-width: 17.25rem;
  text-align: center;
`;
