import { formatDuration as formatDurationOrig } from 'date-fns';
import { enGB } from 'date-fns/locale';

type TFormatDurationConfig = Parameters<typeof formatDurationOrig>[1];

export type TFormatDurationFn = (duration: Duration, config?: TFormatDurationConfig) => string;

export const createFormatDuration = (
  initialConfig: TFormatDurationConfig = {}
): TFormatDurationFn => {
  return config => formatDurationOrig({ ...initialConfig, ...config });
};

export const formatDuration = createFormatDuration({ locale: enGB });
