import React, { FC, useMemo } from 'react';

import { IconFallbackImage } from 'components/icons/fallback-image';
import { ProductListItem } from 'components/product-list-item';
import { MenuObjectTypes } from 'enums/menu';
import { useMenuCalories } from 'hooks/menu/use-menu-calories';

import { StyledControls } from './modifier.styled';
import { IModifierListItemProps } from './types';
import { getModifierImage, getModifierOptionImage } from './utils';

export const ModifierListItem: FC<IModifierListItemProps> = ({
  controls,
  content,
  isListItemClickable = false,
  onListItemClick,
  selectedOption,
}) => {
  const { formatCalories } = useMenuCalories();

  const image = useMemo(
    () =>
      content._type === MenuObjectTypes.ITEM_OPTION
        ? getModifierImage(content)
        : getModifierOptionImage(content),
    [content]
  );

  const calories = useMemo(
    () =>
      content._type === MenuObjectTypes.ITEM_OPTION
        ? formatCalories(selectedOption?.nutrition?.calories)
        : formatCalories(content.nutrition?.calories),
    [content, formatCalories, selectedOption]
  );

  return (
    <ProductListItem
      isClickable={isListItemClickable}
      onProductClick={onListItemClick}
      productProps={{
        name: content.name?.locale,
        image,
        fallbackImage: IconFallbackImage,
        imageSize: 'small',
        calories,
      }}
      controls={
        <StyledControls $hasListItemClickListener={isListItemClickable}>{controls}</StyledControls>
      }
    />
  );
};
