import { IsoCountryCode2 } from '@rbilabs/intl';
import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import uuidv4 from 'uuid/v4';

import { Cognito } from 'utils/cognito';
import { RBIBrand, brand, getCountry } from 'utils/environment';

import { getCurrentCognitoUser, normalizeString } from './util';

export enum CustomCognitoAttribute {
  ReferralCode = 'custom:referralCode',
  EmailSubscribe = 'custom:emailSubscribe',
  SmsSubscribe = 'custom:smsSubscribe',
  AdditionalSignUpTerms = 'custom:additionalSignUpTerms',
  AcceptanceAgreementInfo = 'custom:AcceptanceAgreementInfo',
  Nickname = 'nickname',
  Name = 'name',
  Locale = 'locale',
  PhoneNumber = 'phone_number',
  Birthdate = 'birthdate',
  Gender = 'gender',
  AgeFourteen = 'ageFourteen',
}

interface ISignUpTermAcceptance {
  name: string;
  accepted: boolean;
}

interface IRequiredAcceptanceAgreementInfoInput {
  id: string;
  updatedAt: string;
}

interface ISignUp {
  username: string;
  name: string;
  phoneNumber: string;
  wantsPromotionalEmails?: boolean;
  wantsPromotionalEmailOrSms?: boolean;
  country: string;
  dob?: string;
  invitationCode?: string;
  // Korea specific fields
  gender?: string;
  ageFourteen?: boolean;
  additionalSignUpTerms?: ISignUpTermAcceptance[];
  requiredAcceptanceAgreementInfo?: IRequiredAcceptanceAgreementInfoInput[];
}

interface IValidateLogin {
  username: string;
  code: string;
  sessionId: string;
}

////////////////////////////////////////////////////////////
// Sign In Moved to GQL queries
////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
// Sign Out
////////////////////////////////////////////////////////////

export const signOut = async (shouldSignOutGlobally: boolean = false): Promise<void> => {
  const cognitoUser = getCurrentCognitoUser();

  if (!cognitoUser) {
    // Should this reject instead? Signing out when no current user is currently a noop;
    // We could reject but it seems harmless to just do nothing.
    return Promise.resolve();
  }

  if (shouldSignOutGlobally) {
    return new Promise((resolve, reject) =>
      cognitoUser.globalSignOut({ onSuccess: () => resolve(), onFailure: reject })
    );
  }

  return new Promise(resolve => cognitoUser.signOut(() => resolve()));
};

////////////////////////////////////////////////////////////
// Sign Up
////////////////////////////////////////////////////////////

export const signUp = ({
  username,
  wantsPromotionalEmails,
  wantsPromotionalEmailOrSms,
  name,
  phoneNumber,
  country,
  dob,
  gender,
  ageFourteen,
  additionalSignUpTerms,
  requiredAcceptanceAgreementInfo,
  invitationCode,
}: ISignUp): Promise<ISignUpResult> => {
  // TODO: customAttributes must be predefined using nickname as a hack
  const normalizedUsername = normalizeString(username);
  const password = uuidv4();
  const emailConsent = wantsPromotionalEmails || wantsPromotionalEmailOrSms ? 'true' : 'false';

  const attributes: Array<CognitoUserAttribute> = [
    new CognitoUserAttribute({ Name: CustomCognitoAttribute.Nickname, Value: emailConsent }),
    new CognitoUserAttribute({ Name: CustomCognitoAttribute.Name, Value: name }),
    new CognitoUserAttribute({ Name: CustomCognitoAttribute.Locale, Value: country }),
  ];

  if (phoneNumber) {
    attributes.push(
      new CognitoUserAttribute({ Name: CustomCognitoAttribute.PhoneNumber, Value: phoneNumber })
    );
  }

  if (dob) {
    attributes.push(
      new CognitoUserAttribute({ Name: CustomCognitoAttribute.Birthdate, Value: dob })
    );
  }

  if (gender) {
    attributes.push(
      new CognitoUserAttribute({ Name: CustomCognitoAttribute.Gender, Value: gender })
    );
  }

  if (invitationCode) {
    attributes.push(
      new CognitoUserAttribute({ Name: CustomCognitoAttribute.ReferralCode, Value: invitationCode })
    );
  }

  if (wantsPromotionalEmails || wantsPromotionalEmailOrSms) {
    attributes.push(
      new CognitoUserAttribute({ Name: CustomCognitoAttribute.EmailSubscribe, Value: 'true' })
    );
  }

  if (wantsPromotionalEmailOrSms) {
    attributes.push(
      new CognitoUserAttribute({ Name: CustomCognitoAttribute.SmsSubscribe, Value: 'true' })
    );
  }

  if (requiredAcceptanceAgreementInfo) {
    const agreementInfoStr = JSON.stringify(requiredAcceptanceAgreementInfo);
    attributes.push(
      new CognitoUserAttribute({
        Name: CustomCognitoAttribute.AcceptanceAgreementInfo,
        Value: agreementInfoStr,
      })
    );
  }

  // Handling potentially complex custom attributes for a specific brand and country
  if (brand() === RBIBrand.PLK && getCountry()?.toUpperCase() === IsoCountryCode2.KR) {
    attributes.push(
      new CognitoUserAttribute({
        Name: CustomCognitoAttribute.AgeFourteen,
        Value: ageFourteen ? 'true' : 'false',
      })
    );

    if (additionalSignUpTerms && additionalSignUpTerms.length > 0) {
      const termsJson = JSON.stringify(
        additionalSignUpTerms.map(term => ({ name: term.name, accepted: term.accepted }))
      );
      attributes.push(
        new CognitoUserAttribute({
          Name: CustomCognitoAttribute.AdditionalSignUpTerms,
          Value: termsJson,
        })
      );
    }
  }

  const userPool = Cognito.userPool();

  return new Promise((resolve, reject) => {
    userPool.signUp(normalizedUsername, password, attributes, [], (err, result) => {
      if (err || !result) {
        return reject(err);
      }

      return resolve(result);
    });
  });
};

////////////////////////////////////////////////////////////
// Helper
////////////////////////////////////////////////////////////

const getCognitoUserForUsername = (username: string): CognitoUser => {
  const normalizedUsername = normalizeString(username);
  return new CognitoUser({
    Username: normalizedUsername,
    Pool: Cognito.userPool(),
    Storage: Cognito.storage,
  });
};

////////////////////////////////////////////////////////////
// Confirm Login
////////////////////////////////////////////////////////////

export const validateLogin = ({
  username,
  code,
  sessionId,
}: IValidateLogin): Promise<CognitoUserSession | null> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUserForUsername(username);
    // @ts-ignore
    cognitoUser.Session = sessionId;
    cognitoUser.sendCustomChallengeAnswer(code, { onSuccess: resolve, onFailure: reject });
  });
};
