import { useOfferCarouselSectionQuery } from 'generated/sanity-graphql';

export const useOfferCarouselSection = (id?: string) => {
  const offerCarouselId = id ?? '';

  const { data, loading } = useOfferCarouselSectionQuery({
    variables: {
      offerCarouselId,
    },
    skip: !offerCarouselId,
  });

  const offerCarousel = data?.OfferCarouselSection;

  return {
    offerCarousel,
    offerCarouselLoading: loading,
  };
};
