import { IFeatureLoyaltyUiQuery, useFeatureLoyaltyUiQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureLoyaltyUi {
  featureLoyaltyUiLoading: boolean;
  featureLoyaltyUi: IFeatureLoyaltyUiQuery['LoyaltyUI'];
}

export const useFeatureLoyaltyUi = (): IUseFeatureLoyaltyUi => {
  const { featureLoyaltyUIId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureLoyaltyUiQuery({
    variables: { featureLoyaltyUIId },
    skip: !featureLoyaltyUIId,
  });

  const featureLoyaltyUi = data?.LoyaltyUI ?? null;
  const featureLoyaltyUiLoading = featureIdsLoading || loading;

  return { featureLoyaltyUiLoading, featureLoyaltyUi };
};
