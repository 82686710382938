import { isNull } from 'lodash';

import { ICombo, IItem, IPicker, IPickerAspect, IPickerOption } from '@rbi-ctg/menu';

function findIdentifierForRemainingOptions(
  pickerAspect: IPickerAspect,
  selectedIdentifiers: string[],
  options: IPickerOption[]
): string | null {
  const defaultPickerAspectOption = pickerAspect.pickerAspectOptions.find(pickerAspectOption => {
    const { identifier } = pickerAspectOption;
    const identifiers = selectedIdentifiers.concat(identifier);
    return !!options.find(option =>
      option.pickerItemMappings.every(({ pickerAspectValueIdentifier }) =>
        identifiers.includes(pickerAspectValueIdentifier)
      )
    );
  });

  if (defaultPickerAspectOption) {
    return defaultPickerAspectOption.identifier;
  }

  return null;
}

/**
 * Given a Picker, returns the option from the
 * picker's options that corresponds to the default
 * picker aspects for the picker, or null
 * if the default cannot be determined or is not in
 * the picker's options array (possibly due to being unavailable)
 */
export function getDefaultPickerSelection(picker: IPicker): ICombo | IItem | null {
  if (!picker.options || !picker.pickerAspects) {
    return null;
  }

  const { options: pickerOptions, pickerAspects, pickerDefaults } = picker;

  /**
   * Map the existing pickerDefaults to the pickerAspect they correspond to
   * for faster lookup
   */
  const defaultMapping = (pickerDefaults || []).reduce(
    (acc, pickerDefault) => ({
      ...acc,
      [pickerDefault.pickerAspect._id]: pickerDefault.pickerAspectValueIdentifier,
    }),
    {}
  );

  const { options: defaultOptions } = pickerAspects.reduce<{
    options: IPickerOption[];
    selectedIdentifiers: string[];
  }>(
    ({ options, selectedIdentifiers }, pickerAspect) => {
      // if no default was specified, find the first identifier
      // that can still resolve to an option
      const defaultIdentifier =
        defaultMapping[pickerAspect._id] ||
        findIdentifierForRemainingOptions(pickerAspect, selectedIdentifiers, options);

      if (!isNull(defaultIdentifier)) {
        const identifiers = selectedIdentifiers.concat(defaultIdentifier);
        return {
          options: options.filter(option =>
            identifiers.every(
              identifier =>
                !!option.pickerItemMappings.find(
                  ({ pickerAspectValueIdentifier }) => pickerAspectValueIdentifier === identifier
                )
            )
          ),
          selectedIdentifiers: identifiers,
        };
      }

      // if a default could not be determined at all this is an illegal case;
      // no filtering can be performed
      return { options, selectedIdentifiers };
    },
    { options: pickerOptions, selectedIdentifiers: [] }
  );

  // if there are one or more options remaining, the first is considered
  // the default
  if (defaultOptions.length) {
    return defaultOptions[0].option as ICombo | IItem;
  }

  return null;
}
