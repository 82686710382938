import { useCallback } from 'react';

import { GroqArgs, groqQuery } from 'remote/api/groq';
import { useNetworkContext } from 'state/network';
import logger from 'utils/logger';

export const useGetOffersIncentivesIds = () => {
  const { connection, sanityEndpoints } = useNetworkContext();

  const fetchIncentiveIds = useCallback(async () => {
    try {
      const data = await getIncentiveIds(sanityEndpoints.groq, {
        connection,
      });
      return (data || []).flat();
    } catch (error) {
      logger.error(`Error fetching incentive ids with Groq query: ${error}`);
    }

    return null;
  }, [connection, sanityEndpoints.groq]);

  return { fetchIncentiveIds };
};

const offersQuery = `*[_type in ['systemwideOffer', 'configOffer'] && defined(incentives[0])
    && !(incentives[0]._type in ['offerDiscount', 'swapMapping'])]{
      "refs": incentives[0]{
        _type == 'picker' => @->{"_ref": options[].option._ref},
        (_type == 'combo' || _type == 'item') => {_ref},
        (_type != 'combo' && _type != 'picker') => @
      }
    }.refs._ref`;

type GetOffersIncentivesIds = GroqArgs<{}>;

export const getIncentiveIds = (endpoint: string, { connection }: GetOffersIncentivesIds) =>
  groqQuery<string[][], GetOffersIncentivesIds>(endpoint, offersQuery, {
    connection,
  });
