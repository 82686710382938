import { SecondaryIdentifierType } from 'generated/graphql-gateway';
import { RootState } from 'state/global-state';

// For more complex multiline selectors, export from this file
export const selectUser = ({ loyalty }: RootState) => loyalty.user.loyaltyUser;
export const selectLoyaltyUser = ({ loyalty }: RootState) => {
  return {
    loyaltyUser: loyalty.user.loyaltyUser || null,
    loading: loyalty.user.loyaltyLoading,
  };
};

export const selectLoyaltyLoading = ({ loyalty }: RootState) => loyalty.user.loyaltyLoading;

export const select12DigitSwipeCard = ({ loyalty }: RootState) =>
  loyalty.user.loyaltyUser?.secondaryIdentifiers?.find(
    identifier => identifier?.identifierType === SecondaryIdentifierType.LOYALTY12DIGITSWIPECARD
  );
