import { FeatureHomePage } from 'components/features/home-page/types';
import { useFeatureHomePage } from 'components/features/home-page/use-feature-home-page';
import { ITrendingFragment } from 'generated/sanity-graphql';

const getTrendingComponent = (featureHomePage: FeatureHomePage): ITrendingFragment | null => {
  if (featureHomePage && featureHomePage.components) {
    return featureHomePage.components.find(
      component => component && component.__typename === 'Trending'
    ) as ITrendingFragment;
  }
  return null;
};

export const useTrendingData = () => {
  const { featureHomePage, featureHomePageLoading } = useFeatureHomePage();
  return {
    data: getTrendingComponent(featureHomePage),
    loading: featureHomePageLoading,
  };
};
