import { useCallback, useRef } from 'react';

import { ICartEntry, ICombo, IItem, IPicker, ISection } from '@rbi-ctg/menu';
import { useMenuContext } from 'state/menu';
import { IState } from 'state/menu-options';
import { maybeMapCartEntryToMenuObjectIdentifier } from 'utils/menu';

export interface ICartEntriesMenuObjectEntry {
  data: IPicker | ICombo | IItem | ISection | null;
  entry: ICartEntry;
}

export const useCartEntriesMenuObjects = () => {
  const { asyncGetMenuObject } = useMenuContext();
  const getMenuObjectRef = useRef<(id: string) => Promise<IState>>();
  getMenuObjectRef.current = asyncGetMenuObject;

  const getCartEntriesMenuObjects = useCallback(async (cartEntries: ICartEntry[]) => {
    const itemsPromises = (cartEntries || []).reduce(
      (acc: Array<{ menuObject: Promise<IState>; entry: ICartEntry }>, entry: ICartEntry) => {
        // ensure CartEntryItem is valid before attempting to check
        if (!getMenuObjectRef.current) {
          return acc;
        }
        if (!entry._id || !entry.name) {
          return acc;
        }
        const menuIdentifier = maybeMapCartEntryToMenuObjectIdentifier(entry);
        // If a mapping cant be done, ignore and move on
        if (!menuIdentifier) {
          return acc;
        }

        acc.push({ menuObject: getMenuObjectRef.current(menuIdentifier), entry });
        return acc;
      },
      [] as Array<{ menuObject: Promise<IState>; entry: ICartEntry }>
    );
    const resolvedItemPromises: ICartEntriesMenuObjectEntry[] = await Promise.all(
      itemsPromises.map(async itemPromise => {
        const { data } = await itemPromise.menuObject;
        return { data, entry: itemPromise.entry };
      })
    );
    return resolvedItemPromises;
  }, []);

  return {
    getCartEntriesMenuObjects,
  };
};
