// This mParticleAdapter is used as the adapter layer to talk to the mParticle web SDK
// Usage of window.mParticle should be strongly discouraged outside of this file

import { isArray, isEqual, isPlainObject } from 'lodash';

import { EventTypes, SKIP_EVENTS } from '../constants';
import { ICdpAdapter } from '../types';

class BloomreachAdapterWeb implements ICdpAdapter {
  prevEventName: string;
  prevEventAttr: object | undefined;
  prevCustomFlags: object | undefined;
  prevEventTimeLimit: number;

  logEvent(name: string, eventTypes: EventTypes, attributes?: object, customFlags?: object) {
    name = this.standardizeEventName(name);
    attributes = this.standardizePropertiesName(attributes);
    customFlags = this.standardizePropertiesName(customFlags);
    if (this.skipEvent(name, attributes, customFlags)) {
      return;
    }
    this.updatePrevEvent(name, attributes, customFlags);
    let eventProperties = {
      type: eventTypes,
      ...attributes,
      ...customFlags,
    };
    try {
      window.exponea.track(name, eventProperties);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Bloomreach - track event not found', err);
    }
  }

  update(customerAttributes: any) {
    customerAttributes.customer_id = this.getCustomerId(customerAttributes);
    customerAttributes.email_id = this.getEmailId(customerAttributes);
    customerAttributes = this.standardizePropertiesName(customerAttributes);
    try {
      window.exponea.update(customerAttributes);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Bloomreach - update not found', err);
    }
  }

  identify(
    email: string,
    customerId: string,
    userAttributes: object,
    successCallback: Function,
    errorCallback?: Function
  ) {
    userAttributes = this.standardizePropertiesName(userAttributes);
    try {
      window.exponea.identify(
        { email_id: email, customer_id: customerId },
        { ...userAttributes, email },
        successCallback,
        errorCallback
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Bloomreach - identify not found', err);
    }
  }

  getCustomerId(props: any) {
    return props?.customerId || props?.customerid || '';
  }

  getEmailId(props: any) {
    return props?.email || '';
  }

  /**
   * Compares the current event with the previous one.
   * @param name
   * @param attributes
   * @param customFlags
   * @returns If it is the same event or not.
   */
  sameEvent(name: string, attributes?: object, customFlags?: object) {
    if (this.prevEventName !== name) {
      return false;
    } else if (
      this.sameObjects(attributes, this.prevEventAttr) &&
      this.sameObjects(customFlags, this.prevCustomFlags)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Compares the current object with the previous one. It skips the attribute validation for time related ones.
   * @param currObject
   * @param prevObject
   * @returns If it is the same object or not.
   */
  sameObjects(currObject?: object, prevObject?: object) {
    if (typeof prevObject !== typeof currObject) {
      return false;
    } else if (!prevObject || !currObject) {
      return true;
    }
    const keys: Array<string> = Object.keys(prevObject);
    for (const key of keys) {
      if (
        (typeof prevObject[key] === 'string' && /^\d{2}:\d{2}:\d{2}$/.test(prevObject[key])) ||
        isArray(prevObject[key]) ||
        isPlainObject(prevObject[key])
      ) {
        continue;
      } else if (!currObject.hasOwnProperty(key) || !isEqual(prevObject[key], currObject[key])) {
        return false;
      }
    }
    return true;
  }

  /**
   * Validates if the current event needs to be skipped.
   * @param name
   * @param attributes
   * @param customFlags
   * @returns If the event should be skipped or not.
   */
  skipEvent(name: string, attributes?: object, customFlags?: object) {
    if (SKIP_EVENTS.indexOf(name) === -1) {
      return false;
    }
    const isSameEvent: boolean = this.sameEvent(name, attributes, customFlags);
    if (isSameEvent) {
      if (new Date().getTime() > this.prevEventTimeLimit) {
        return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Updates the previous event data.
   * @param name
   * @param attributes
   */
  updatePrevEvent(name: string, attributes?: object, customFlags?: object) {
    this.prevEventName = name;
    this.prevEventAttr = attributes;
    this.prevCustomFlags = customFlags;
    this.prevEventTimeLimit = new Date().getTime() + 4000;
  }

  standardizeEventName(name: string) {
    return this.replaceNonAllowedCharacter(name);
  }

  standardizePropertiesName(properties?: { [key: string]: any }) {
    let newProperties: { [key: string]: any } = {};
    if (properties && Object.keys(properties).length > 0) {
      Object.keys(properties).map((key: string) => {
        let newKey = this.validateKeyValue(key);
        if (newKey) {
          newProperties[newKey] = properties[key];
        }
        return newKey;
      });
    }
    return newProperties;
  }

  validateKeyValue(keyProperty: string) {
    if (!keyProperty) {
      return false;
    } else {
      return this.replaceNonAllowedCharacter(keyProperty);
    }
  }

  replaceNonAllowedCharacter(value: string) {
    if (this.isCamelCase(value)) {
      value = value.replace(/([a-z])([A-Z])/g, '$1_$2');
    }

    if (value.search('(?=.*[$.])S')) {
      value = this.replaceCharacter(value, '$', '_');
      value = this.replaceCharacter(value, '.', '_');
      value = this.replaceCharacter(value, ' ', '_');
    }
    return value.toLocaleLowerCase();
  }

  isCamelCase(input: string): boolean {
    const camelCaseRegex = /^[a-z][a-zA-Z]*$/;
    return camelCaseRegex.test(input);
  }

  replaceCharacter(value: string, found: string, replace: string) {
    return value.split(found).join(replace);
  }

  Identity = BloomreachAdapterWeb;
}

const BloomreachAdapter = new BloomreachAdapterWeb();

export { BloomreachAdapter };
