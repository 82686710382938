import { ThreeDSData, ThreeDSDataPayment, ThreeDSType } from 'state/payment/hooks/types';

export const handleThreeDSPayment = (error: any): ThreeDSData | ThreeDSDataPayment | null => {
  if (!error?.graphQLErrors) {
    return null;
  }

  const FIRST_INDEX_ERROR = 0;
  const threeDS = error.graphQLErrors[FIRST_INDEX_ERROR]?.extensions ?? null;

  return threeDS;
};

export function getThreeDSError(error: unknown): ThreeDSData | ThreeDSDataPayment | null {
  const threeDSError = handleThreeDSPayment(error);
  if (threeDSError) {
    if (threeDSError.type === ThreeDSType.METHOD || threeDSError.type === ThreeDSType.CHALLENGE) {
      return threeDSError;
    }
  }
  return null;
}
