import React from 'react';

import { OnClick } from '@rbilabs/components-library/build/components/button';

import { CartPreviewToggleButton } from 'components/cart-preview';
import { TopServiceMode } from 'components/top-service-mode';
import { useAuthContext } from 'state/auth';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';

import { LoyaltyPointsButton } from '../loyalty-points-button';
import { SignupButton } from '../signup-button';
import { SecondaryActionContainer } from '../styled';

import { SIGN_UP_BUTTON_VARIANT } from './constants';
import { RecentOrdersLink } from './recent-orders-link';
import { RewardsLink } from './rewards-link';
import { UserAccountButton } from './user-account-button';

interface ISecondaryNav {
  onMyAccountClick: OnClick;
  enableTopServiceMode: boolean;
  enableCart: boolean;
  enableOrdering: boolean;
}

export const SecondaryNav: React.FC<ISecondaryNav> = ({
  onMyAccountClick,
  enableTopServiceMode,
  enableCart,
  enableOrdering,
}) => {
  const { isAuthenticated } = useAuthContext();
  const isLoyaltyEnabled = useIsLoyaltyEnabled();

  return (
    <SecondaryActionContainer>
      {enableTopServiceMode && <TopServiceMode />}
      {isLoyaltyEnabled && <RewardsLink />}
      {isAuthenticated() ? (
        <>
          {enableOrdering && <RecentOrdersLink />}
          <UserAccountButton onClick={onMyAccountClick} />
          <LoyaltyPointsButton />
        </>
      ) : (
        <SignupButton variant={SIGN_UP_BUTTON_VARIANT} data-testid="desktop-signup-link" />
      )}
      {enableCart && <CartPreviewToggleButton />}
    </SecondaryActionContainer>
  );
};
