import * as React from 'react';
import { FC } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ILocaleStringFragment } from 'generated/sanity-graphql';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { StorageKeys } from 'utils/local-storage';

import {
  AccordionTitleWrapper,
  Container,
  LegacyCodeContainer,
  ShortCodeContent,
  ShortCodeInstructions,
  ShortCodeSubtitle,
  ShortCodeTitle,
  StyledExpandableContent,
} from './loyalty-legacy-short-code-widget.styled';

export interface ILoyaltyLegacyShortCodeWidgetProps extends IBaseProps {
  title: ILocaleStringFragment;
  subtitle: ILocaleStringFragment;
  instructions: ILocaleStringFragment;
}

const LoyaltyLegacyShortCodeWidget: FC<ILoyaltyLegacyShortCodeWidgetProps> = ({
  title,
  subtitle,
  instructions,
}) => {
  const [offerDetails] = useLocalStorageState<any>({
    key: StorageKeys.IN_RESTAURANT_REDEMPTION,
    defaultReturnValue: {},
  });

  const latestOfferAdded = offerDetails?.[offerDetails.length - 1];

  // Show legacy short code only for offers that have a short code
  if (!latestOfferAdded?.details?.offer?.shortCode) {
    return null;
  }

  return (
    <Container>
      <StyledExpandableContent
        title={
          <AccordionTitleWrapper>
            <ShortCodeTitle>{title?.locale}</ShortCodeTitle>
            <ShortCodeSubtitle>{subtitle?.locale}</ShortCodeSubtitle>
          </AccordionTitleWrapper>
        }
        content={
          <LegacyCodeContainer>
            <ShortCodeInstructions>{instructions?.locale}</ShortCodeInstructions>
            <ShortCodeContent data-testid="legacy-short-code">
              {latestOfferAdded?.details?.offer?.shortCode}
            </ShortCodeContent>
          </LegacyCodeContainer>
        }
      />
    </Container>
  );
};

export default LoyaltyLegacyShortCodeWidget;
