import React, { useCallback } from 'react';

import { useIntl } from 'react-intl';

import { ArrowLink } from 'components/arrow-link';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames } from 'state/cdp/constants';
import { routes } from 'utils/routing';

const CommPreference = () => {
  const cdp = useCdpContext();
  const { formatMessage } = useIntl();

  const onCommPreferenceClick = useCallback(() => {
    cdp.logNavigationClick(CustomEventNames.BUTTON_CLICK_COMMUNICATION_PREFERENCES);
  }, [cdp]);

  return (
    <ArrowLink
      data-testid="communication-preferences"
      to={routes.communication}
      onClick={onCommPreferenceClick}
      prefixIcon="communicationPref"
    >
      {formatMessage({ id: 'communicationPreferences' })}
    </ArrowLink>
  );
};

export default CommPreference;
