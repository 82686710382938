import { appVersionCode, platform } from 'utils/environment';

import { withHeaders } from './with-headers';

const VERSION = (appVersionCode() ?? '').substring(0, 7);
const NAME = `wl-${platform()}`; // TODO: frontend forks should replace "wl" with their own name.

export const withClientInfo = withHeaders(() => {
  return {
    'apollographql-client-name': NAME,
    'apollographql-client-version': VERSION,
  };
});
