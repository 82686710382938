import { IMainMenuViewOption, MenuObject } from '@rbi-ctg/menu';

import { isSection } from './is-menu-type';
import { getMenuOptionId } from './menu-option';

export const handleMenuOptionPrefetch =
  (prefetchFn: (optionId: string) => void, menuOption: MenuObject | IMainMenuViewOption) => () => {
    if (isSection(menuOption)) {
      return;
    }

    prefetchFn(getMenuOptionId(menuOption));
  };
