import { noop } from 'lodash';

import { OnPrepareNowButtonClick, UsePrepareNow } from './types';

export const usePrepareNow: UsePrepareNow = () => {
  return {
    showPrepareNowButton: false,
    hasPrepareNowBeenPressed: false,
    showErrorMessage: false,
    loading: false,
    onClick: noop as OnPrepareNowButtonClick,
  };
};
