import { normalizedTranslate } from '@rbilabs/components-library';
import styled from 'styled-components';

export const ReactToastifyContainer = styled.div`
  .Toastify__toast-container {
    z-index: ${Styles.zIndex.max};
    -webkit-transform: ${normalizedTranslate(0, 0, '9999px')};
    box-sizing: border-box;
    color: #fff;
    animation-duration: 10s;
    inset-block-end: env(safe-area-inset-bottom);
    width: 100vw;
    padding: 0;
    margin: 0;
    transition: bottom 250ms;
    transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
    position: absolute;
  }

  .Toastify__toast {
    position: relative;
    box-sizing: border-box;
    margin-block-end: 0;
    border-radius: 1px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    direction: ltr;
  }

  .Toastify__toast-body {
    margin: auto 0;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-block-end: 0;
    }
  }

  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .Toastify__progress-bar {
    position: absolute;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 3px;
    z-index: ${Styles.zIndex.max};
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.5);
    transform-origin: left;
  }

  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }

  .Toastify__progress-bar--controlled {
    transition: transform 0.2s;
  }

  .Toastify__progress-bar--rtl {
    inset-inline-end: 0;
    inset-inline-start: initial;
    transform-origin: right;
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.8, 0.8, 0);
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes zoomOut {
    from {
      opacity: 1;
    }
    to {
      transform: scale3d(0.8, 0.8, 0);
      /* transform: scale3d(0.99, 0.8, 1); */
      opacity: 0;
    }
  }

  .zoomOut {
    animation-name: zoomOut;
    animation-duration: 0.25s;
  }

  .zoomIn {
    animation-name: zoomIn;
    animation-duration: 0.25s;
  }

  .zoom-enter {
    animation-name: zoomIn;
    animation-duration: 0.25s;
  }

  .zoom-exit {
    animation-name: zoomOut;
    animation-duration: 0.25s;
  }
`;
