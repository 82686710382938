import { useCallback, useEffect, useMemo, useState } from 'react';

import { add, addSeconds } from 'date-fns';

import { Nullable } from '@rbi-ctg/frontend';
import { ICartEntry } from '@rbi-ctg/menu';
import { RbiOrderStatus } from 'generated/rbi-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import { useFeatureQRCodeRefillDrinks } from 'hooks/use-feature-qrcode-refill-drinks';
import { useRefillDrinkQRCode } from 'hooks/use-refill-drink-qrcode';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { isNative } from 'utils/environment';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';

const INTERVAL_MILLISECONDS = 5000; // five seconds
const INTERVAL_SCHEDULE_SHOW_QRCODE = 15000; // fifteen seconds

type FreeRefillOrderInfosProps = {
  cartEntries: ICartEntry[];
  orderId: string;
  storeNumber: string;
  hashQrCodeValue?: string | undefined;
  fireOrderIn?: number | null;
  updatedAt: string;
  status?: RbiOrderStatus;
  posOrderId: string;
  serviceMode: string;
};

const freeRefillOrderInfosEmpty: FreeRefillOrderInfosProps = {
  cartEntries: [],
  orderId: '',
  storeNumber: '',
  hashQrCodeValue: '',
  updatedAt: '',
  posOrderId: '',
  serviceMode: '',
};

export const useFreeRefillDrinks = () => {
  const { store } = useStoreContext();
  const { featureQrCodeRefillDrinks } = useFeatureQRCodeRefillDrinks();
  const { getRefillDrinkQRCodeInfo, qrCodeInfo } = useRefillDrinkQRCode();

  const [isBottomSheetTimerValid, setIsBottomSheetTimerValid] = useState(false);
  const [isFreeRefillRecentOrdersValid, setIsFreeRefillRecentOrdersValid] = useState(false);
  const [qrCodeInfoValue, setQrCodeInfoValue] = useState('');

  const enableQRCodeRefill = useFlag(LaunchDarklyFlag.ENABLE_QRCODE_REFILL);
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);

  const {
    refillDrinksServiceMode,
    refillDrinksTimers,
    restaurants: refillDrinksRestaurants,
    items,
  } = featureQrCodeRefillDrinks || {};

  const refillDrinksItems = items?.map(item => item?._id);

  const isFreeRefillEnabled = useMemo(
    () => !!enableQRCodeRefill && !!qrCodeInfoValue && isNative,
    [enableQRCodeRefill, qrCodeInfoValue]
  );

  const getLocalStorageOrderInfos = useCallback((): FreeRefillOrderInfosProps => {
    const orderInfos = LocalStorage.getItem(
      Keys.FREE_REFILL_ORDER_INFOS
    ) as FreeRefillOrderInfosProps;
    return orderInfos ?? freeRefillOrderInfosEmpty;
  }, []);

  const setLocalStorageOrderInfos = useCallback(
    (data: FreeRefillOrderInfosProps) => LocalStorage.setItem(Keys.FREE_REFILL_ORDER_INFOS, data),
    []
  );

  const isServiceValid = useCallback((): boolean => {
    const { serviceMode } = getLocalStorageOrderInfos();

    if (!serviceMode) {
      return false;
    }

    const serviceModeMap = {
      [ServiceMode.TAKEOUT]: 'pickUpServiceMode',
      [ServiceMode.EAT_IN]: 'dineInServiceMode',
      [ServiceMode.CURBSIDE]: 'curbsideServiceMode',
      [ServiceMode.DRIVE_THRU]: 'driveThruServiceMode',
      [ServiceMode.TABLE_SERVICE]: 'tableServiceMode',
      [ServiceMode.DELIVERY]: 'deliveryServiceMode',
      [ServiceMode.CATERING_DELIVERY]: 'cateringDeliveryServiceMode',
      [ServiceMode.CATERING_PICKUP]: 'cateringPickUpServiceMode',
    };

    const correspondingProperty = serviceModeMap[serviceMode];
    const isValid = !!(refillDrinksServiceMode && refillDrinksServiceMode[correspondingProperty]);

    return isValid;
  }, [getLocalStorageOrderInfos, refillDrinksServiceMode]);

  const isStoreValid = useCallback(
    (): boolean =>
      refillDrinksRestaurants
        ? refillDrinksRestaurants?.filter(restaurant => restaurant?._id === store?._id).length > 0
        : false,
    [refillDrinksRestaurants, store]
  );

  const isItemsValid = useCallback(
    (cartEntry: ICartEntry[]): boolean => {
      const findItems = cartEntry.some(item => {
        if (item?.children) {
          const find = isItemsValid(item?.children);

          if (find) {
            return find;
          }
        }

        return refillDrinksItems?.includes(item?.sanityId);
      });

      return findItems;
    },
    [refillDrinksItems]
  );

  const isEligibleForQRCode = useCallback(
    (entries: ICartEntry[]) => isItemsValid(entries) && isStoreValid(),
    [isItemsValid, isStoreValid]
  );

  const checkFreeRefillValidate = useCallback(
    (timer: Nullable<number> | undefined, updateAt: string): boolean => {
      if (!timer) {
        return false;
      }

      const orderDate = new Date(updateAt).getTime();

      const orderDatePlusTimer = add(orderDate, { minutes: timer }).getTime();
      const dateNow = Date.now();

      const isFreeRefillAvailable = dateNow < orderDatePlusTimer;

      return isFreeRefillAvailable && isServiceValid();
    },
    [isServiceValid]
  );

  const hasFreeRefillOrder = useCallback(
    (order: FreeRefillOrderInfosProps): boolean => {
      const isValid =
        isNative &&
        (order.status === RbiOrderStatus.INSERT_SUCCESSFUL ||
          order.status === RbiOrderStatus.UPDATE_SUCCESSFUL);

      if (!isValid || !isEligibleForQRCode(order?.cartEntries)) {
        return false;
      }

      const { hashQrCodeValue, posOrderId } = getLocalStorageOrderInfos();
      const qrCodeValue = posOrderId === order?.posOrderId ? hashQrCodeValue : '';

      setLocalStorageOrderInfos({ ...order, hashQrCodeValue: qrCodeValue });

      if (!order?.fireOrderIn && !qrCodeValue) {
        getRefillDrinkQRCodeInfo({
          orderUpdateAt: order?.updatedAt,
          posOrderId: order?.posOrderId,
          storeNumber: order?.storeNumber,
        });
      }
      return true;
    },
    [
      isEligibleForQRCode,
      getLocalStorageOrderInfos,
      setLocalStorageOrderInfos,
      getRefillDrinkQRCodeInfo,
    ]
  );

  const checkScheduleShowQrCode = useCallback((): void => {
    const { cartEntries, updatedAt, fireOrderIn, posOrderId, storeNumber } =
      getLocalStorageOrderInfos();
    const isValid = !!fireOrderIn && isEligibleForQRCode(cartEntries);

    if (!isValid) {
      return;
    }

    const updateAtOrder = new Date(updatedAt).getTime();
    const fireOrderShowQrCode = addSeconds(
      updateAtOrder,
      fireOrderIn + (fireOrderAhead ?? 0)
    ).getTime();

    const dateNow = Date.now();

    const availableSchedule = dateNow >= fireOrderShowQrCode;

    if (availableSchedule) {
      getRefillDrinkQRCodeInfo({
        orderUpdateAt: updatedAt,
        posOrderId,
        storeNumber,
      });
    }
  }, [fireOrderAhead, getLocalStorageOrderInfos, getRefillDrinkQRCodeInfo, isEligibleForQRCode]);

  const checkQrCodeRefillDrinksTimers = useCallback((): void => {
    const { updatedAt, cartEntries, fireOrderIn, hashQrCodeValue } = getLocalStorageOrderInfos();

    const isValid = !!updatedAt && isEligibleForQRCode(cartEntries);

    if (!isValid) {
      setIsBottomSheetTimerValid(false);
      setIsFreeRefillRecentOrdersValid(false);
      setQrCodeInfoValue('');
      return;
    }

    const updatedAtWithFireOrder = fireOrderIn
      ? addSeconds(new Date(updatedAt).getTime(), fireOrderIn + (fireOrderAhead ?? 0)).toISOString()
      : updatedAt;

    setQrCodeInfoValue(hashQrCodeValue ?? '');

    setIsBottomSheetTimerValid(
      checkFreeRefillValidate(refillDrinksTimers?.timeBottomSheet, updatedAtWithFireOrder)
    );

    setIsFreeRefillRecentOrdersValid(
      checkFreeRefillValidate(refillDrinksTimers?.timeRecentOrder, updatedAtWithFireOrder)
    );
  }, [
    getLocalStorageOrderInfos,
    isEligibleForQRCode,
    fireOrderAhead,
    checkFreeRefillValidate,
    refillDrinksTimers?.timeBottomSheet,
    refillDrinksTimers?.timeRecentOrder,
  ]);

  useEffectOnce(() => {
    if (!isNative && !enableQRCodeRefill) {
      return;
    }

    checkQrCodeRefillDrinksTimers();
  });

  useEffect(() => {
    if (!isNative) {
      return;
    }

    const intervalCheckQrCodeTimers = setInterval(
      checkQrCodeRefillDrinksTimers,
      INTERVAL_MILLISECONDS
    );

    const intervalCheckSchedule = setInterval(
      checkScheduleShowQrCode,
      INTERVAL_SCHEDULE_SHOW_QRCODE
    );

    if (qrCodeInfo) {
      clearInterval(intervalCheckQrCodeTimers);
    }

    return () => {
      clearInterval(intervalCheckQrCodeTimers);
      clearInterval(intervalCheckSchedule);
    };
  }, [checkQrCodeRefillDrinksTimers, checkScheduleShowQrCode, qrCodeInfo]);

  useEffect(() => {
    if (!qrCodeInfo) {
      return;
    }

    const qrCode = { ...getLocalStorageOrderInfos(), hashQrCodeValue: qrCodeInfo };
    setLocalStorageOrderInfos(qrCode);
    setQrCodeInfoValue(qrCode.hashQrCodeValue);
  }, [getLocalStorageOrderInfos, qrCodeInfo, setLocalStorageOrderInfos]);

  return {
    isFreeRefillEnabled,
    isBottomSheetTimerValid,
    isFreeRefillRecentOrdersValid,
    qrCodeInfoValue,
    refillDrinksTimers,
    hasFreeRefillOrder,
  };
};
