import styled from 'styled-components';

import Picture from 'components/picture';

export const StyledTileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5rem;
  padding: 1rem;
  border-radius: 0.563rem;
  background-color: rgb(255, 255, 255, 0.5);
  margin-block-end: 1rem;
  ${Styles.desktop} {
    height: 7rem;
  }
`;

export const TileImage = styled(Picture)`
  max-height: 2.5em;
  max-width: 2.5rem;
  margin-block-start: 0;
  margin-block-end: 0.31rem;
  margin-inline: 0;
  ${Styles.desktop} {
    margin-block: 0;
    margin-inline-start: 0;
    margin-inline-end: 1.5rem;
    max-height: 5em;
    max-width: 5rem;
  }
`;
export const TextContainer = styled.div`
  margin-inline-start: 1rem;
  text-align: start;
  p {
    margin: 0;
    font-size: 0.75rem;
    font-family: ${Styles.fontFamily.body};
  }
  ${Styles.desktop} {
    p {
      font-size: 1.25rem;
    }
  }
`;

export const TileTitle = styled.h3`
  color: ${Styles.color.black};
  font-family: ${Styles.fontFamily.base};
  line-height: 20px;
  font-size: 1rem;
  margin: 0;
  ${Styles.desktop} {
    font-size: 1.5rem;
  }
`;
