import { IRewardPrice } from 'generated/sanity-graphql';
import { IAppliedRewards, IParseAppliedRewards } from 'state/loyalty/hooks/types';
import { LoyaltyReward, RuleType } from 'state/loyalty/types';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { parseStringifiedJSON } from 'utils/parse-string';
import { PosVendors, getVendorConfig } from 'utils/vendor-config';

import { IRewardsState } from './rewards.types';

export const getAppliedRewardsFromStorage = () => {
  const storedAppliedRewards = LocalStorage.getItem(StorageKeys.APPLIED_LOYALTY_REWARDS);
  return parseStringifiedJSON({ value: storedAppliedRewards, defaultValue: {} }) || {};
};

export const updateAppliedRewardsInStorage = (appliedReward: IAppliedRewards) => {
  LocalStorage.setItem(StorageKeys.APPLIED_LOYALTY_REWARDS, JSON.stringify(appliedReward));
};

export const removeAppliedRewardsInStorage = () => {
  LocalStorage.removeItem(StorageKeys.APPLIED_LOYALTY_REWARDS);
};

export const getAppliedRewardsArrayAndTimesApplied = (appliedRewards: IAppliedRewards | {}) => {
  const { appliedRewardsArray, timesRewardApplied } = Object.entries(appliedRewards).reduce(
    (acc: IParseAppliedRewards, [cartId, { timesApplied, rewardId, sanityId, rewardPrice }]) => {
      if (!timesApplied) {
        return acc;
      }
      // create array to send to BE for cart pricing
      acc.appliedRewardsArray.push({
        rewardId,
        timesApplied,
        cartId,
        sanityId,
        price: rewardPrice,
      });
      // create map of total number of times a reward is applied within the cart
      acc.timesRewardApplied[rewardId] = ++acc.timesRewardApplied[rewardId] || timesApplied || 1;
      return acc;
    },
    { appliedRewardsArray: [], timesRewardApplied: {} }
  );
  const rewardsArray = appliedRewardsArray.length ? appliedRewardsArray : null;
  return { appliedRewardsArray: rewardsArray, timesRewardApplied };
};

export const setStateFromAppliedRewards = (
  state: IRewardsState,
  appliedRewards: IAppliedRewards
) => {
  updateAppliedRewardsInStorage(appliedRewards);
  const { appliedRewardsArray, timesRewardApplied } =
    getAppliedRewardsArrayAndTimesApplied(appliedRewards);
  state.appliedLoyaltyRewardsArray = appliedRewardsArray;
  state.totalTimesRewardApplied = timesRewardApplied;
  state.appliedLoyaltyRewards = appliedRewards;
};

export const getAppliedPointsInCart = (state: IRewardsState) => {
  const { appliedLoyaltyRewards = [], availableLoyaltyRewardsMap = {} } = state;
  return Object.values(appliedLoyaltyRewards).reduce(
    (acc, { pointCost, rewardBenefitId, timesApplied }) => {
      const { pointCost: MappedPointCost } = availableLoyaltyRewardsMap[rewardBenefitId] || {};
      return acc + (pointCost ?? MappedPointCost ?? 0) * timesApplied;
    },
    0
  );
};

export const getStagedCartPoints = (state: IRewardsState, basePoints: number) => {
  const appliedPointsInCart = getAppliedPointsInCart(state);
  const availablePointsToUse = basePoints - appliedPointsInCart;
  const isValid = 0 <= availablePointsToUse && availablePointsToUse <= basePoints;
  if (isValid) {
    return availablePointsToUse;
  } else {
    // TODO: on the else case, analyze if we need to reset some props if we find a discrepancy
    return null;
  }
};

/*
  Note: A reward is a cart discount when the incentive is of type Offer Discount and no PLUs are configured
*/
export const rewardIsCartDiscount = (item: any, vendor: PosVendors | null) => {
  if (!item || !item.vendorConfigs) {
    return false;
  }

  const vendorConfig = getVendorConfig(item, vendor);

  if (!vendorConfig) {
    return false;
  }

  // Check if the reward has a discount with a preselected product
  if (item.incentives?.[0]?.discountProduct) {
    return false;
  }

  // If the PLU is present it means the discount is only for that specific item
  if (vendorConfig[vendorConfig.pluType]) {
    return false;
  }

  // If no vendor config is found it's a global cart discount
  return true;
};

/**
 *
 * Given a reward, will return the Reward Price or false if the rule is not set in Sanity
 *
 * @param reward LoyaltyReward
 * @returns number or false
 */
export const getRewardPrice = (reward: LoyaltyReward): number | false => {
  const rewardPriceRule = reward?.ruleSet?.findIndex(
    rule => rule?.__typename === RuleType.RewardPrice
  );

  if (!rewardPriceRule) {
    return false;
  }

  if (reward.ruleSet && reward.ruleSet[rewardPriceRule]) {
    const rule = reward.ruleSet[rewardPriceRule] as IRewardPrice;

    return rule.price ?? 0;
  }

  return false;
};
