import React, { FC, ReactNode, useRef } from 'react';

export interface ICustomScrollElementProps {
  children: NonNullable<ReactNode>;
}

let isTouching = false;
let startY = 0;
let startScrollTop = 0;

let lastY = 0;
let lastTime = 0;
let velocityY = 0;

/* 
    This component emulates the scrolling of a container for touch devices
    It is useful for places where scrolling event can't be captured correctly (or at all) e.g: iOS Safari
    https://medium.com/@nathantodd_/solution-scroll-trapped-issue-on-ios-sticky-scroll-overflow-css-fix-9d3800722feb
*/
const CustomScrollElement: FC<ICustomScrollElementProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const applyMomentumScroll = () => {
    const momentumInterval = 20;
    const friction = 0.95; // Reduce velocity over time for a smooth stop

    function step() {
      if (containerRef.current && Math.abs(velocityY) > 0.1) {
        // Continue scrolling while velocity is significant
        containerRef.current.scrollTop += velocityY * momentumInterval;
        velocityY *= friction; // Apply friction to reduce velocity

        requestAnimationFrame(step);
      }
    }

    step();
  };

  return (
    <div
      ref={containerRef}
      onTouchStart={event => {
        isTouching = true;
        startY = event.touches[0].clientY;
        startScrollTop = containerRef.current?.scrollTop ?? 0;

        lastY = startY;
        lastTime = Date.now();
        velocityY = 0;
      }}
      onTouchMove={event => {
        if (!isTouching) {
          return;
        }
        const currentY = event.touches[0].clientY;
        const deltaY = startY - currentY;

        if (containerRef.current) {
          containerRef.current.scrollTop = startScrollTop + deltaY;
        }

        const currentTime = Date.now();
        const timeDelta = currentTime - lastTime;
        if (timeDelta > 0) {
          velocityY = (lastY - currentY) / timeDelta;
        }

        lastY = currentY;
        lastTime = currentTime;
      }}
      onTouchEnd={() => {
        // Simulate native free scrolling
        applyMomentumScroll();
        isTouching = false;
      }}
      onTouchCancel={() => {
        isTouching = false;
      }}
    >
      {children}
    </div>
  );
};

export default CustomScrollElement;
