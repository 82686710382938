import React from 'react';

import { Column } from './column';
import { RowContainer } from './styled';
import { IRow as IRowProps } from './types';

export const Row: React.FC<IRowProps> = props => {
  return (
    <RowContainer>
      {(props.columns ?? []).map(
        (column, index) =>
          // columns don't change position between renders, so `index` as key here is fine
          column && <Column key={column._key || index} {...column} />
      )}
    </RowContainer>
  );
};
