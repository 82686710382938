import React from 'react';

import Modal from 'components/modal';

import DiagnosticModalContents from './diagnostic-modal-contents';

export interface IStoreDiagnosticData {
  driveThruOpen: boolean;
  diningRoomOpen: boolean;
  isRestaurantOpen: boolean;
  isMobileOrderingAvailable: boolean;
  isRestaurantPosOnline: boolean;
  restaurantName: string;
  restaurantHasMobileOrdering: boolean;
  restaurantCountry: string;
  restaurantStreetAddress: string;
}

export interface IInternalStoreDiagnosticsModalProps {
  onModalDismiss: VoidFunction;
  storeDiagnosticData: IStoreDiagnosticData | undefined;
}

export const InternalStoreDiagnosticModal: React.FC<IInternalStoreDiagnosticsModalProps> = ({
  onModalDismiss,
  storeDiagnosticData,
}) => (
  <Modal
    onDismiss={onModalDismiss}
    eventData={{
      modalAppearanceEventMessage: 'Store Diagnostics',
    }}
  >
    <DiagnosticModalContents storeDiagnosticData={storeDiagnosticData} />
  </Modal>
);
