import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import { Dropdown } from 'components/dropdown';
import { ListOfLinks, ListOfLinksProps } from 'components/list-of-links';

import { NavLink } from '../nav-link';

interface NavMenuProps extends Pick<ListOfLinksProps, 'entries'> {
  title: string;
}

export const NavMenu: React.FC<NavMenuProps> = ({ title, entries }) => {
  const { formatMessage } = useIntl();

  return (
    <Dropdown
      id="about-menu-entries"
      content={({ dismiss }) => <ListOfLinks entries={entries} onDismiss={dismiss} />}
    >
      <NavLink to="#" data-testid="about-menu-entries" aria-controls="aboutMenuEntries">
        {title}
        <VisuallyHidden>
          {formatMessage({
            id: 'toggleMenu',
          })}
        </VisuallyHidden>
      </NavLink>
    </Dropdown>
  );
};
