import React, { useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import useEffectOnce from 'hooks/use-effect-once';
import { RBIBrand, brand } from 'utils/environment';

import {
  BottomDrawer,
  BottomDrawerContent,
  BottomDrawerHandler,
  BottomDrawerPreview,
  BottomDrawerPreviewInfo,
  Container,
  Description,
  Overlay,
  Title,
} from './styles';
import { IBottomSheet, RefElement } from './types';

type IBottomSheetPortal = {
  bottomSheetContent: React.ReactNode;
  drawerPositionOffset: number;
  onCalculatePreviewHeight: (height: number) => void;
} & Partial<IBottomSheet>;

export const BottomSheetPortal = ({
  bottomSheetContent,
  previewIconPrefix,
  previewIconSufix,
  title,
  titleExpanded,
  description,
  descriptionExpanded,
  drawerPositionOffset,
  onCalculatePreviewHeight,
}: IBottomSheetPortal) => {
  const bottomDrawerElement = useRef<RefElement>(null);
  const bottomDrawerHandlerElement = useRef<RefElement>(null);
  const bottomDrawerPreviewElement = useRef<RefElement>(null);
  const [isExpanded, setExpand] = useState<undefined | boolean>(undefined);
  const [isPortalMounted, setIsPortalMounted] = useState(false);
  const isFHSBrand = brand() === RBIBrand.FHS;

  const previewHeight = isPortalMounted
    ? bottomDrawerHandlerElement.current!.offsetHeight +
      bottomDrawerPreviewElement.current!.offsetHeight
    : 0;

  const drawerInitialPosition =
    isPortalMounted && bottomDrawerElement.current!.offsetHeight - previewHeight;

  const handleExpand = () => setExpand(prevState => !prevState);

  const renderBottomDrawer = () => (
    <Container
      drawerOffset={drawerPositionOffset}
      isExpanded={isExpanded}
      hasAllDrawerElementsRendered={isPortalMounted}
    >
      {isExpanded && <Overlay onClick={handleExpand} />}
      <BottomDrawer
        isExpanded={isExpanded}
        drawerContentHeight={drawerInitialPosition}
        ref={bottomDrawerElement}
      >
        <BottomDrawerHandler ref={bottomDrawerHandlerElement} onClick={handleExpand} />
        <BottomDrawerPreview
          ref={bottomDrawerPreviewElement}
          isExpanded={isExpanded}
          onClick={handleExpand}
        >
          {previewIconPrefix}

          <BottomDrawerPreviewInfo>
            <Title isFHSBrand={isFHSBrand}>
              {isExpanded && titleExpanded ? titleExpanded : title}
            </Title>
            <Description isFHSBrand={isFHSBrand}>
              {isExpanded && descriptionExpanded ? descriptionExpanded : description}
            </Description>
          </BottomDrawerPreviewInfo>

          {previewIconSufix}
        </BottomDrawerPreview>
        <BottomDrawerContent>{bottomSheetContent}</BottomDrawerContent>
      </BottomDrawer>
    </Container>
  );

  useEffectOnce(() => {
    setIsPortalMounted(true);
  });

  useEffect(() => {
    if (previewHeight) {
      onCalculatePreviewHeight(previewHeight);
    }
  }, [previewHeight, onCalculatePreviewHeight]);

  return createPortal(renderBottomDrawer(), document.body);
};
