import styled from 'styled-components';

const Counter = styled.div`
  width: 2rem;
  text-align: center;
  line-height: 2.5rem;
  font-size: 0.9375rem;
`;

export default Counter;
