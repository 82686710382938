import styled from 'styled-components';

export const VerticalLinksContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;

  ${Styles.desktop} {
    display: flex;
  }
`;
