import cssConstants from './constants';
import mediaQueries from './constants/media-queries';

const Styles = {
  ...cssConstants,
  ...mediaQueries,
};

if (process.env.NODE_ENV === 'development') {
  const _global = typeof window === 'undefined' ? global : window;
  _global.Styles = Styles;

  // Make window.css available in storybook
  if (_global.parent) {
    _global.parent.Styles = cssConstants;
  }
}

export default Styles;
