import { TRouterRobotsProps } from 'types/react-router';
import { or } from 'utils/index';
import { hasTruthyBooleanProperties, hasTruthyBooleanProperty } from 'utils/object';

type TMetaRobotsValues = 'none' | 'nofollow' | 'noindex';

export const getMetaRobotsContent = (args: Record<string, unknown>) => {
  const conditions: [TMetaRobotsValues, (args: TRouterRobotsProps) => Boolean][] = [
    [
      'none',
      or(hasTruthyBooleanProperty('noRobots'), hasTruthyBooleanProperties(['noIndex', 'noFollow'])),
    ],
    ['nofollow', hasTruthyBooleanProperty('noFollow')],
    ['noindex', hasTruthyBooleanProperty('noIndex')],
  ];

  return conditions.find(([, f]) => f(args))?.[0] || null;
};
