import React, { useCallback } from 'react';

import { ActionButtonSizes } from 'components/action-button';
import { LoyaltyCartDrawer } from 'pages/loyalty/loyalty-cart-drawer';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { routes } from 'utils/routing';

import { StyledButton, StyledMyCodeIcon } from './styled';

export const MyCodeButton = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useInRestaurantRedemptionContext();
  const { navigate, location } = useLocationContext();
  const cdp = useCdpContext();

  const enableRedesignLoyaltyQRCodePage = useFlag(
    LaunchDarklyFlag.ENABLE_REDESIGN_LOYALTY_QR_CODE_PAGE
  );

  const handleClick = useCallback(() => {
    cdp.trackEvent({
      name: CustomEventNames.BUTTON_CLICK,
      type: EventTypes.Navigation,
      attributes: {
        Name: 'My Code Header',
      },
    });

    if (enableRedesignLoyaltyQRCodePage) {
      setIsDrawerOpen(true);
      return;
    }

    navigate(routes.redeem, {
      state: {
        backRoute: location.pathname,
      },
    });
  }, [enableRedesignLoyaltyQRCodePage, location.pathname, cdp, navigate, setIsDrawerOpen]);

  return (
    <>
      <StyledButton
        data-testid="my-code-header-button"
        onClick={handleClick}
        size={ActionButtonSizes.SMALL}
      >
        <StyledMyCodeIcon />
      </StyledButton>
      {isDrawerOpen ? (
        <LoyaltyCartDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
      ) : null}
    </>
  );
};
