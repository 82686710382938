import { ILocation } from '@rbi-ctg/frontend';

type NativeLocation = null | false | ILocation;

function isIOSWeb(navigatorPlatform: typeof navigator.platform): boolean {
  return (
    navigatorPlatform.indexOf('iPhone') !== -1 ||
    navigatorPlatform.indexOf('iPad') !== -1 ||
    navigatorPlatform.indexOf('iPod') !== -1
  );
}

function joinCoordinates(location: NativeLocation): string {
  return location && location.lat ? `${location.lat},${location.lng}` : '';
}

export function nativeMapsHref(origin: NativeLocation, destination: false | ILocation = false) {
  const scheme = isIOSWeb(navigator.platform) ? 'maps' : 'https';

  if (!origin) {
    return `${scheme}://www.google.com/maps/search/?api=1&query=${joinCoordinates(destination)}`;
  }

  return `${scheme}://www.google.com/maps/dir/?api=1&origin=${joinCoordinates(
    origin
  )}&destination=${joinCoordinates(destination)}`;
}

export function openNativeMaps(origin: NativeLocation, destination: false | ILocation = false) {
  window.open(nativeMapsHref(origin, destination));
}

declare interface IBillingAddress {
  city: string;
  street: string;
  country: string;
  postalCode: string;
  state: string;
}
export const buildFormattedBillingAddress = ({
  city,
  street,
  country,
  postalCode,
  state,
}: IBillingAddress) => {
  return `${street}, ${city}, ${state}, ${postalCode}, ${country}`;
};
