import { IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { getOrderNumber } from 'utils/order';

export default function useOrderNumber(
  serverOrder: IServerOrder | IHistoricalOrder
): string | null {
  const enableOrderNumberGeneration = useFlag(LaunchDarklyFlag.ENABLE_ORDER_NUMBER_GENERATION);
  return getOrderNumber(serverOrder, enableOrderNumberGeneration);
}
