export enum HorizontalPosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum TileSize {
  HALF = 'half',
  FULL = 'full',
}

export enum Direction {
  DEFAULT = 'default',
  REVERSE = 'reverse',
}
