import { normalizedTranslate, toPx } from '@rbilabs/components-library';
import styled, { keyframes } from 'styled-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import IconCirclePlus from 'components/icons/circle-plus';
import IconCircleTimes from 'components/icons/circle-times';
import LockedIcon from 'components/icons/locked';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { RewardLock } from 'pages/loyalty/reward-lock';

import theme from '../theme';

import { FloatDirection } from './types';

const StyledPointsIcon = styled(LoyaltyPointsIcon)`
  width: 1.125rem;

  path {
    fill: ${Styles.color.success};
  }
`;

const FLOAT_POSITION = 15;

const floatIcon = (floatDirection: FloatDirection) => keyframes`
  0% {
    opacity: 1;
  }
  100% {
    transform: ${normalizedTranslate(
      0,
      `calc(${floatDirection === FloatDirection.UP ? -1 : 1} * ${toPx(FLOAT_POSITION)})`
    )};
    opacity: 0
  }
`;

export const FloatingPointsIcon = styled(StyledPointsIcon)<{ $floatDirection: FloatDirection }>`
  position: absolute;
  opacity: 0;
  inset-inline-start: 0;
  inset-block-start: ${props =>
    props.$floatDirection === FloatDirection.DOWN ? -FLOAT_POSITION : 0}px;
  z-index: 1;
  animation: ${props => floatIcon(props.$floatDirection)} 0.5s;
`;

export const Wrapper = styled.div`
  display: flex;
  margin: 1.25rem 0.5rem;
`;

export const Header = styled.p`
  font-weight: ${Styles.fontWeight.heavy};

  span {
    color: ${theme.headerColor};
  }
`;

export const HeaderContainer = styled.div`
  margin-block-start: 0.5rem 0 0;
  display: flex;
  align-items: center;
`;

const BUTTON_CONTAINER_BREAKPOINT = 480;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${BUTTON_CONTAINER_BREAKPOINT}px) {
    flex-direction: column;
  }

  button {
    padding: 4px 8px;

    &:not(:first-child) {
      @media screen and (max-width: ${BUTTON_CONTAINER_BREAKPOINT}px) {
        margin-block-start: 0.5rem;
      }
      @media screen and (min-width: ${BUTTON_CONTAINER_BREAKPOINT + 1}px) {
        margin-inline-start: 0.5rem;
      }
    }
  }
`;

export const StyledIconCirclePlus = styled(IconCirclePlus)`
  path:first-of-type {
    fill: ${Styles.color.background};
  }

  path:last-of-type {
    fill: ${Styles.color.primary};
  }
`;

export const StyledIconCircleTimes = styled(IconCircleTimes)`
  path:first-of-type {
    fill: ${Styles.color.primary};
  }
`;

export const StyledActionButton = styled(ActionButton)`
  color: ${Styles.color.white};
  display: flex;

  ${StyledPointsIcon} {
    path {
      fill: ${props =>
        props.variant === ActionButtonVariants.PRIMARY ? Styles.color.white : Styles.color.primary};
    }
  }

  &:active:not(:disabled) {
    ${StyledPointsIcon} {
      path {
        fill: ${props =>
          props.variant === ActionButtonVariants.OUTLINE ? Styles.color.white : ''};
      }
    }
  }

  ${StyledIconCirclePlus}, ${StyledIconCircleTimes} {
    opacity: ${props => (props.disabled ? 0.5 : 1)};
  }
`;

export const ButtonContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline-start: 0.4rem;
`;

export const InfoContainer = styled.div`
  width: 100%;
`;

export const PointsApplied = styled.div<{ arePointsApplied: boolean }>`
  position: relative;
  font-weight: ${Styles.fontWeight.heavy};
  font-size: 0.875rem;
  margin-block: 0;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0;
  opacity: ${p => ~~p.arePointsApplied};
  transition: 0.26s;
  display: flex;
  align-items: center;
  gap: 0.2rem;
`;

export const CrownContainer = styled.div`
  position: relative;
  margin-block-start: 0.45rem;
  margin-block-end: 0.1rem;
  margin-inline-start: 0;
  margin-inline-end: 0.5rem;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const StyledRewardLock = styled(RewardLock)`
  position: relative;
  transform: scale(0.45);
  width: auto;
  height: auto;
`;

export const Lock = styled(LockedIcon)`
  transform: scale(0.85);
  margin-block-end: 0;
`;

export { StyledPointsIcon as PointsIcon };
