/* eslint-disable no-console */
import { noop } from 'lodash';

interface IAttTrackingOptions {
  requestPermission: boolean;
}

export interface IAttTrackingStatusResponse {
  value: string;
  status: 'authorized' | 'denied' | 'unrequested' | 'restricted';
}

export const enablePush = () => {
  console.debug(`Permissions - enablePush triggered - but only works for natives`);
};

export const openPushSetting = noop;

export const isNotificationsPermissionGranted = async () => noop;

export const isNotificationsPermissionDenied = async () => {
  console.debug(
    `Permissions isNotificationsPermissionDenied triggered - but only works for natives`
  );
  return false;
};

export const selectPermission = async () => {
  console.debug(`Permissions - selectPermission triggered - but only works for natives`);
};

export const enableLocation = (timeout: number): Promise<any | void> => {
  console.debug(`Permissions ${timeout} enableLocation triggered - but only works for natives`);
  return Promise.reject(new Error('Permissions dont work for web'));
};

export const appTrackingTransparency = async ({
  requestPermission,
}: IAttTrackingOptions): Promise<IAttTrackingStatusResponse | undefined> => {
  console.debug(
    `Permissions ${requestPermission} appTrackingTransparency triggered - but only works for natives`
  );
  return undefined;
};
