import { getGoogleApiSrcPath } from './utils';

let scriptLoadingListeners: Array<(value?: unknown) => void> = [];
let scriptLoadingListenersReject: Array<(value?: unknown) => void> = [];

export const geolocationLibraryIsLoaded = () => {
  const loadedLibraryScriptElement = document.querySelector('script#google.loaded');
  return !!loadedLibraryScriptElement;
};

const clearListenerArrays = () => {
  scriptLoadingListeners = [];
  scriptLoadingListenersReject = [];
};

export const loadGeolocationLibrary = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    // only load the library once
    // if it is already loaded, we can resolve immediately
    if (geolocationLibraryIsLoaded()) {
      resolve();
    }

    // if library is not yet loaded, push promise
    //  resolve / reject fns to arrays so we can
    //  notifiy caller when it is ready
    scriptLoadingListeners.push(resolve);
    scriptLoadingListenersReject.push(reject);

    // if this is the first request for the library
    // we won't have a script for it in the head yet
    if (!document.querySelector('script#google')) {
      const googleApiSrcPath = getGoogleApiSrcPath();
      // kick off async loading of library
      const script = document.createElement('script');
      script.src = googleApiSrcPath;
      script.id = 'google';
      script.async = true;
      // add script tag to document head
      document.head.appendChild(script);
      // create listeners to notify callers when
      //  it is ready (or if it failed to load)
      script.addEventListener('load', () => {
        // once loaded, add 'loaded' class to script element
        // to enable geolocationLibraryIsLoaded fn to be true
        const scriptElement = document.querySelector('script#google');
        if (scriptElement) {
          scriptElement.setAttribute('class', 'loaded');
        }
        scriptLoadingListeners.forEach(resolver => resolver());
        // once we've invoked all the resolvers clear out the listener arrays
        clearListenerArrays();
      });
      script.addEventListener('error', () => {
        // remove library script element from head
        // something failed so let's enable retry
        const scriptElement = document.querySelector('script#google');
        if (scriptElement?.parentElement) {
          scriptElement.parentElement.removeChild(scriptElement);
        }
        scriptLoadingListenersReject.forEach(rejecter => rejecter());
        // once we've invoked all the rejecters clear out the listener arrays
        clearListenerArrays();
      });
    }
  });
};
