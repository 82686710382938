import { format, isBefore, subMinutes } from 'date-fns';

import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

interface IUseOrderEdit {
  pickupDate: Date;
}

export const useOrderEdit = ({ pickupDate }: IUseOrderEdit) => {
  const fireScheduledTimeModificationAhead = useFlag(
    LaunchDarklyFlag.FIRE_SCHEDULED_TIME_MODIFICATION_AHEAD
  );
  const lastCallToEditOrder = subMinutes(pickupDate, fireScheduledTimeModificationAhead);
  const lastCallToEditOrderTime = format(lastCallToEditOrder, 'h:mm aaaa').replace(
    ' ',
    String.fromCharCode(160)
  );

  const canOrderBeEdited = () => {
    return isBefore(new Date(), lastCallToEditOrder);
  };

  return {
    canOrderBeEdited,
    lastCallToEditOrder,
    lastCallToEditOrderTime,
  };
};
