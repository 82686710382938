import { useMarketingTileCarouselQuery } from 'generated/sanity-graphql';

export const useMarketingTileCarousel = (id?: string) => {
  const marketingTileCarouselId = id ?? '';
  const { data, loading } = useMarketingTileCarouselQuery({
    variables: {
      marketingTileCarouselId,
    },
    skip: !marketingTileCarouselId,
  });

  const marketingTileCarousel = data?.MarketingTileCarousel;

  return {
    marketingTileCarousel,
    marketingTileCarouselLoading: loading,
  };
};
