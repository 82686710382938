import React from 'react';

import styled from 'styled-components';

import BrandIcon from 'components/brand-icon';

const LoadingContainer = styled.div`
  display: flex;
  flex: 2;
  background-color: ${Styles.color.grey.four};
  justify-content: center;
  align-items: center;
`;

const BrandIconContainer = styled.div`
  fill: ${Styles.color.grey.three};
  display: flex;
  justify-content: center;
  svg {
    width: 10rem;
  }
`;

const Fallback = () => {
  return (
    <LoadingContainer data-testid="order-confirmation-fallback">
      <BrandIconContainer>
        <BrandIcon />
      </BrandIconContainer>
    </LoadingContainer>
  );
};

export default Fallback;
