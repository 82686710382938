import { ChangeEvent } from 'react';

import { FormikErrors } from 'formik';

import { IFloatingLabelInputProps } from 'components/floating-label-fields';
import { FormValidationState } from 'utils/form';

export interface IPhoneNumberField {
  name: string;
  onChange(fieldName: string, value: string): void;
  disclaimer: string;
}

export interface ILegacyBirthdayField {
  value: string | undefined;
  label: string;
  error?: FormikErrors<any> | FormikErrors<any>[] | string | string[];
  onChange: (e: ChangeEvent) => void;
  birthdayLegalText?: string;
}

export interface IZipCode extends Omit<IFloatingLabelInputProps, 'label'> {
  validation: FormValidationState | undefined;
  country: string;
  disclaimer: string;
}

export enum OverridableFields {
  PHONE = 'phoneNumber',
}

export enum SignInMethods {
  OTP = 'OTP',
  SMS_OTP = 'SMS OTP',
  SOCIAL = 'Social',
  BIOMETRICS = 'Biometrics',
  AUTO_SIGN_UP = 'Auto Sign Up',
}

export enum SignUpBiometricType {
  FACE_ID = 'Face ID',
  TOUCH_ID = 'Touch ID',
  FACE = 'Face',
  FINGERPRINT = 'Fingerprint',
}

export type IOverrides = {
  [key in OverridableFields]: {
    required: boolean;
  };
};

export interface IValuesValidationOverrides {
  minimumSignUpAge: number | null | undefined;
}

export type DialogUserExistType = 'email' | 'phone' | null;

export type UserNotFoundContinueToSignUp = {
  shouldContinueToSignUp: boolean;
};

export type UserBlocked = {
  isBlocked: boolean;
};
