import { primitive } from 'styles/constants/primitives';

export const barcodeOptions = {
  margin: 1,
  scale: 5,
  color: {
    dark: primitive.$black,
    light: primitive.$white,
  },
};

export const QR_CODE_TEST_ID = 'loyalty-qr-code';
export const QR_CODE_SELECTOR = `[data-testid="${QR_CODE_TEST_ID}"]`;
