export interface IBluecodeContext {
  isBluecodeEnabled: boolean;
  isBluecodeInitialized: boolean;
  isBluecodeViewOpen: boolean;
  openBluecodeView: (deepLinkUrl?: string) => Promise<void>;
  requestResetBluecode: () => Promise<void>;
  userHasBluecodeAccount: boolean;
}

export const providerExceptionMessage =
  'useBluecodeContext must be used within an BluecodeProvider';

export const defaultBluecode: IBluecodeContext = {
  isBluecodeEnabled: false,
  isBluecodeInitialized: false,
  isBluecodeViewOpen: false,
  userHasBluecodeAccount: false,
  openBluecodeView: () => {
    throw new Error(providerExceptionMessage);
  },
  requestResetBluecode: () => {
    throw new Error(providerExceptionMessage);
  },
};
