import React, { forwardRef } from 'react';

import { Icon } from '@rbilabs/components-library';
import { ThemeColorArg } from '@rbilabs/components-library/build/utils';
import { useIntl } from 'react-intl';

import { IconContainer } from './styled';

export interface ToggleButtonProps {
  isOpen: boolean;
  iconColor: ThemeColorArg;
  onClick: VoidFunction;
}

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  ({ isOpen, iconColor, onClick }, ref) => {
    const { formatMessage } = useIntl();

    return (
      <IconContainer
        aria-label={formatMessage({ id: 'toggleMenu' })}
        data-testid="side-nav-action-btn"
        onClick={onClick}
        ref={ref}
      >
        <Icon icon={isOpen ? 'close' : 'menu'} color={iconColor} width="1.5rem" aria-hidden />
      </IconContainer>
    );
  }
);
