import { useFeatureLoyaltyInRestaurantRedemptionQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

/* TODO: Remove this hook and its usage after we migrate completely to the new location for Redeem In Restaurant Config */
export const useFeaturesInRestaurantRedemption = ({ skip = false }: { skip: boolean }) => {
  const { featureLoyaltyUIId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyInRestaurantRedemptionQuery({
    variables: { featureLoyaltyUIId },
    skip: !featureLoyaltyUIId || skip,
  });
  return {
    data: data?.LoyaltyUI?.loyaltyInRestaurantRedemption,
    loading: featureIdsLoading || loading,
  };
};
