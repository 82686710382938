import { LoyaltyTierKey } from 'generated/graphql-gateway';
import { useFeatureLoyaltyTiersContentQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

export const useFeaturesLoyaltyTiersContent = () => {
  const { featureLoyaltyUIId = '', featureIdsLoading } = useFeaturesContext();
  const { data, loading } = useFeatureLoyaltyTiersContentQuery({
    variables: { featureLoyaltyUIId },
    skip: !featureLoyaltyUIId,
  });
  return {
    data: data?.LoyaltyUI?.loyaltyTiersContent,
    loading: featureIdsLoading || loading,
    getContentForTier: (loyaltyTierKey: LoyaltyTierKey) =>
      data?.LoyaltyUI?.loyaltyTiersContent?.loyaltyTiersUIConfig?.find(
        config => config?.loyaltyTiersUIConfigID === loyaltyTierKey
      ),
  };
};
