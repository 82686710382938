import React from 'react';

import useMediaQuery, { MediaQuery } from 'hooks/use-media-query';
import { OrderId, OrderIssues } from 'pages/order-confirmation/common';
import Map from 'pages/order-confirmation/map';
import { IConfirmationUIProps } from 'pages/order-confirmation/order-confirmation';
import { useDeliveryConfirmationContext } from 'state/delivery-confirmation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import DeliveryDetails from './delivery-details';
import DeliveryDisclaimer from './delivery-disclaimer';
import {
  DeliveryConfirmationRoot,
  FixedMapWindow,
  ScrollContainer,
  StyledOrderIssues,
} from './styled';

const DeliveryConfirmation: React.FC<IConfirmationUIProps> = ({ serverOrder, isInsideModal }) => {
  const isShowingOrderingIssue = useFlag(LaunchDarklyFlag.SHOW_ORDERING_ISSUE);

  const { bringgUuids } = useDeliveryConfirmationContext();
  const isMobile = useMediaQuery(MediaQuery.Mobile);
  const isDeliveryDesktop = !(isInsideModal || isMobile);

  return (
    <DeliveryConfirmationRoot>
      <FixedMapWindow isDeliveryDesktop={isDeliveryDesktop}>
        <Map bringgUuids={bringgUuids} serverOrder={serverOrder} />
      </FixedMapWindow>

      {!isDeliveryDesktop && (
        <ScrollContainer>
          {serverOrder.delivery && <DeliveryDetails serverOrder={serverOrder} />}
          {isShowingOrderingIssue && (
            <StyledOrderIssues>
              <OrderIssues>
                <DeliveryDisclaimer />
              </OrderIssues>
            </StyledOrderIssues>
          )}
          <OrderId orderId={serverOrder.rbiOrderId} />
        </ScrollContainer>
      )}
    </DeliveryConfirmationRoot>
  );
};

export default DeliveryConfirmation;
