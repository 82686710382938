import { ICartEntry } from '@rbi-ctg/menu';
import { IRewardFragment } from 'generated/graphql-gateway';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { selectors, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';

const DEFAULT_STATE = {
  rewardRedeemable: false,
  incentiveNotInMenu: false,
  redeemRewardLoading: false,
  hasSufficientBalance: false,
  limitPerOrderMet: false,
  rewardPointCost: 0,
};

export const useRedeemRewardState = ({
  cartId,
  quantity,
  reward,
}: {
  cartId?: string;
  quantity: number;
  reward?: IRewardFragment;
}) => {
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { sanityRewardsMap, loading } = useLoyaltyRewardsList();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const stagedCartPoints = useAppSelector(selectors.loyalty.selectStagedCartPoints);
  const totalTimesRewardApplied = useAppSelector(selectors.loyalty.selectTotalTimesRewardApplied);

  if (!reward || !loyaltyEnabled) {
    return DEFAULT_STATE;
  }

  const { pointCost = 0, id, limitPerOrder } = reward;
  const limitPerOrderMet =
    !!limitPerOrder &&
    (limitPerOrder === totalTimesRewardApplied[id] || limitPerOrder === quantity);

  const hasSufficientBalance = stagedCartPoints >= pointCost;

  const rewardId = reward.sanityId || '';

  const { incentiveNotInMenu } = sanityRewardsMap?.[rewardId] || {};

  if (!cartId) {
    return {
      ...DEFAULT_STATE,
      limitPerOrderMet,
      rewardPointCost: pointCost,
      hasSufficientBalance,
      incentiveNotInMenu: Boolean(incentiveNotInMenu),
    };
  }

  const numberOfTimesRewardApplied = appliedLoyaltyRewards[cartId]?.timesApplied || 0;
  const maxRedemptionsMet =
    numberOfTimesRewardApplied >= quantity ||
    (!!limitPerOrder && numberOfTimesRewardApplied >= limitPerOrder);

  const rewardRedeemable = hasSufficientBalance && !maxRedemptionsMet;

  return {
    rewardRedeemable,
    incentiveNotInMenu: Boolean(incentiveNotInMenu),
    redeemRewardLoading: loading,
    hasSufficientBalance,
    limitPerOrderMet,
    rewardPointCost: pointCost,
  };
};

export const useRedeemReward = (item?: ICartEntry) => {
  const { getAvailableRewardFromCartEntry } = useLoyaltyContext();

  const reward = item && getAvailableRewardFromCartEntry(item);

  return useRedeemRewardState({ cartId: item?.cartId, quantity: item?.quantity || 0, reward });
};
