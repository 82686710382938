import { loadGeolocationLibrary } from './init';

type LatLng = google.maps.LatLngLiteral;
type GeocoderResult = google.maps.GeocoderResult;
type GeocoderStatus = google.maps.GeocoderStatus;

type ParseGeocoderResultsFunction = (
  results: GeocoderResult[],
  status: GeocoderStatus
) => GeocoderResult | null | undefined;

export const getStreetAddressFromResults: ParseGeocoderResultsFunction = (results, status) => {
  if (status !== window.google.maps.GeocoderStatus.OK) {
    return null;
  }

  // Grab the address component that includes the street address
  const streetAddress = results.find(result => result.types.includes('street_address'));
  // otherwise, Route address will be used as backup if a street address is not found
  const routeAddress = results.find(result => result.types.includes('route'));

  return streetAddress ?? routeAddress;
};

export const getCoordinateDetails = async ({
  lat,
  lng,
}: LatLng): Promise<google.maps.GeocoderResult | null | undefined> => {
  if (!lat || !lng) {
    return null;
  }

  try {
    await loadGeolocationLibrary();
  } catch {
    return null;
  }

  const geocoder = new window.google.maps.Geocoder();

  return await new Promise(resolve => {
    geocoder.geocode(
      {
        location: new window.google.maps.LatLng(lat, lng),
      },
      (results, status) => {
        const parsedResult = getStreetAddressFromResults(results, status);

        return resolve(parsedResult);
      }
    );
  });
};
