import { IsoCountryCode2 } from '@rbilabs/intl';

import { IBillingAddressInput } from 'generated/rbi-graphql';
import {
  IAdyenPaymentState,
  IFraudPreventionValues,
  IPaymentState,
  IVrPaymentState,
} from 'utils/payment';

// @TODO This will be generated from Sanity
export type CardType = 'AMEX' | 'DINERS_CLUB' | 'DISCOVER' | 'JCB' | 'MASTERCARD' | 'VISA';

/**
 * @TODO This can be removed in favor of the generated `CartPaymentCardType` enum
 */
export enum CardTypes {
  AMEX = 'AMEX',
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  DINERS_CLUB = 'DINERS_CLUB',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  PAYPAL = 'PAYPAL',
  VISA = 'VISA',
  MADA = 'MADA',
}

/**
 * These card types are the ones supported by our Apple Pay plugin (frontend/ios/App/App/ApplePayPlugin.swift)
 */
export enum ApplePayCardTypes {
  AMEX = 'AmEx',
  DISCOVER = 'Discover',
  MASTERCARD = 'MasterCard',
  VISA = 'Visa',
}

export enum PaymentType {
  CASH = 'CASH',
  DIGITAL = 'DIGITAL',
  GIFT = 'GIFT',
}
export enum PaymentMethodBrand {
  SODEXO = 'SODEXO',
  SODEXO_VOUCHER = 'SODEXO_VOUCHER',
  CHEQUE_GOURMET = 'CHEQUE_GOURMET',
  CHEQUE_GOURMET_VOUCHER = 'CHEQUE_GOURMET_VOUCHER',
  TICKET_RESTAURANT_CARD = 'TICKET_RESTAURANT_CARD',
  TICKET_RESTAURANT_VOUCHER = 'TICKET_RESTAURANT_VOUCHER',
  PAYMENT_ON_DELIVERY_CARD = 'PAYMENT_ON_DELIVERY_CARD',
  BIZUM = 'BIZUM',
  WAYLET = 'WAYLET',
  APPLEPAYLINK = 'APPLEPAYLINK',
}

export interface ICredit {
  alias: string;
  /**
   * The BE does not currently standardize card type, for example upper case vs lower case.
   * Eventually we want to align the FE and BE.
   *
   * TODO: Use type from `IUserAccountsQuery` and not force our own.
   */
  cardType: CardTypes | string;
  expiryMonth: string;
  expiryYear: string;
  panToken: string;
}

export interface IPrepaid {
  alias: string;
  cardNumber: string;
  currentBalance: number;
  expiryMonth: string;
  expiryYear: string;
  feFormattedCurrentBalance: number | null;
  shuffledCardNumber: string;
}

export interface IPaymentMethod {
  accountIdentifier: string | null;
  fdAccountId: string | null;
  paymentMethodBrand?: string | null;
  chaseProfileId: string | null;
  paypalIdentifier: string | null;
  credit: ICredit | null;
  selected?: boolean;
  prepaid: IPrepaid | null;
  // make cash required eventually
  cash?: boolean | null;
  twint?: boolean | null;
  paypal: boolean | null;
  ideal?: boolean | null;
  blik?: boolean | null;
  sodexo?: boolean | null;
  chequeGourmet?: boolean | null;
  ticketRestaurant?: boolean | null;
  paymentOnDeliveryCard?: boolean | null;
  transient?: boolean;
  bizum?: boolean | null;
  waylet?: boolean | null;
  applePayLink?: boolean | null;
  mbway?: boolean | null;
  onlinePayment: boolean;
}

export interface IAddAccount {
  addAccount: IPaymentMethod;
}

export interface IAddress {
  locality: string;
  postalCode: string;
  region: string;
  streetAddress: string;
  unitNumber?: string;
}

export interface IAddPaymentMethodOptions {
  skipErrorDialogOnError?: boolean;
}

export interface IDeleteAccount {
  deleteAccount: boolean;
}

export interface IGetAccounts {
  userAccounts?: {
    count?: number;
    accounts?: IPaymentMethod[];
  };
}

// @todo validate type
export interface IGetNonce {
  token: {
    tokenId: string;
  };
}

export interface IApplePayDetails {
  signature: string;
  applicationData: string;
  country: IsoCountryCode2;
  data: string;
  decryptAlias: string;
  ephemeralPublicKey: string;
  formatted: string;
  paymentData: string;
  primary: boolean;
  publicKeyHash: string;
  transactionId: string;
  type: string;
  version: string;
  billingAddress: IBillingAddressInput;
  PaymentMethodData?: IPaymentMethodData;
}

export interface IGooglePayDetails {
  signature: string;
  country: IsoCountryCode2;
  data: string;
  formatted: string;
  primary: boolean;
  type: string;
  version: string;
  billingAddress: IBillingAddressInput;
  PaymentMethodData?: IPaymentMethodData;
  paymentData: string;
}

interface IPaymentMethodData {
  /* Visa, Mastercard ... */
  paymentType: string;
  displayName: string;
  /* debit, credit, prepaid, store */
  paymentMethodType?: string;
}

export interface IReloadPrepaidCard {
  countryCode: string;
  fundValue: number;
  prepaidBalance: number;
  fdAccountId?: string | null;
  prepaidFdAccountId?: string | null;
  currencyCode?: string;
  cardType?: string | null;
  applePayDetails?: IApplePayDetails;
  googlePayDetails?: IGooglePayDetails;
}

export interface IReloadPrepaidCardConfig {
  swallowError?: boolean;
}

export interface IReloadDetails {
  prepaidsReload: {
    currentBalance: number;
    fdAccountId: string;
    fdCorrelationId: string;
    transactionId: string;
  };
}

/**
 * Vendor specific payment state
 */
export type PaymentState = IVrPaymentState | IAdyenPaymentState | IPaymentState;

export interface IPaymentDetails {
  accountIdentifier?: string;
  encryptedCN?: string;
  encryptedEM?: string;
  encryptedEY?: string;
  encryptedSC?: string;
  fdAccessToken?: string;
  fdNonce?: string;
  creditType?: string;
  fraudPreventionValues?: IFraudPreventionValues;
  applePayDetails?: IApplePayDetails;
  googlePayDetails?: IGooglePayDetails;
  saveCard?: boolean;
  /**
   * Vendor specific payment state
   */
  state?: PaymentState;
}
