import { DiscountTypes } from 'enums/menu';

import { IGetRewardsDiscountsProps } from './types';

const getRewardsDiscount = ({ discounts }: IGetRewardsDiscountsProps) =>
  (discounts || []).reduce(
    (acc, { name, value }) => (name === DiscountTypes.REWARDS_DISCOUNTS ? acc + value : acc),
    0
  );

export default getRewardsDiscount;
