import {
  IItem,
  IItemOption,
  IItemOptionModifier,
  IItemOptionModifierWithQuantity,
} from '@rbi-ctg/menu';

export interface IModifierSelections {
  [modifierOptionKey: string]: IItemOptionModifierWithQuantity;
}

export enum ModifierTypes {
  NON_NUMERIC = 'NON_NUMERIC',
  NUMERIC = 'NUMERIC',
  CHECKBOX = 'CHECKBOX',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export interface IModifierListItemProps {
  content: IItemOption | IItemOptionModifier;
  selectedOption?: IItemOptionModifier;
  onListItemClick?: VoidFunction;
  isListItemClickable?: boolean;
  controls: React.ReactNode;
}

export interface IModifierTypeUIProps {
  modifier: IItemOption;
  selectedItem: IItem;
  selections: IModifierSelections;
  onSelectionsChange: (option: IModifierSelections) => void;
}

export interface IModifierData {
  modifier: IItemOption;
  selections: IModifierSelections;
}

export interface IModifierProps extends IModifierData {
  onSelectionsChange: (selections: IModifierSelections) => void;
  selectedItem: IItem;
}
