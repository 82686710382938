import styled from 'styled-components';

import { ExpandableContent } from 'components/expandable-content';
import { primitive } from 'styles/constants/primitives';

export const AccordionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${Styles.fontWeight.normal};
  font-size: 0.8125rem;
  font-family: ${Styles.fontFamily.base};
  flex-wrap: wrap;
`;

export const StyledExpandableContent = styled(ExpandableContent)`
  margin-block-start: 0;
  margin-block-end: 1rem;
  margin-inline: 1rem;
  border: none;

  ul > li {
    border-block-end: none;
  }

  ul > li > div {
    padding-block-start: 0;
    padding-block-end: 0.5rem;
    padding-inline: 0;
  }

  span {
    color: ${Styles.color.black};
    font-size: 1rem;
    text-align: start;
  }

  [data-testid='accordion-item-content'] > div {
    border-block-start: 1px solid ${primitive.$blackOpacity10};
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const ShortCodeTitle = styled.span`
  margin: 0;
  font-family: ${Styles.fontFamily.brand};
  font-size: 1.1rem;
  font-weight: 300;
  color: ${Styles.color.primary};
  text-align: center;
  width: 100%;
`;

export const LegacyCodeContainer = styled.div`
  margin: 1rem;
`;

export const ShortCodeSubtitle = styled.div`
  margin-block-start: 0.3rem;
  margin-block-end: 0;
  margin-inline: 0;
  font-size: 0.85rem;
  font-family: ${Styles.fontFamily.body};
  font-weight: 500;
  text-align: start;
`;

export const ShortCodeInstructions = styled.div`
  margin: 0;
  font-size: 0.85rem;
  text-align: center;
`;

export const ShortCodeContent = styled.div`
  margin-block-start: 1rem;
  margin-block-end: 0;
  margin-inline: auto;
  width: fit-content;
  font-family: ${Styles.fontFamily.brand};
  border-radius: ${Styles.borderRadius};
  background-color: ${Styles.color.black};
  color: ${Styles.color.white};
  font-size: 2rem;
  text-align: center;
  line-height: 3.125rem;
  padding: 1rem 1.5rem;

  ${Styles.desktop} {
    font-size: 3.5rem;
    line-height: 4.125rem;
  }
`;
