import { useCallback } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { useBrazeContext } from 'state/braze';
import { useCdpContext } from 'state/cdp';
import { useLDContext } from 'state/launchdarkly';
import { getCustomerIdForCRMStack } from 'utils/environment';

import { UserDetails } from './types';

export const useThirdPartyAuthentication = () => {
  const { setUserId: brazeSetUserId } = useBrazeContext();
  const { login: launchDarklyLogin, logout: launchDarklyLogout } = useLDContext();
  const { login, logout } = useCdpContext();
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();

  const logUserInToThirdPartyServices = useCallback(
    (currentUser: UserDetails) => {
      launchDarklyLogin(currentUser);

      const sanitizedDetails = Object.keys(currentUser.details).reduce(
        (acc: { [key: string]: string | null }, key: string) => {
          return { ...acc, [key]: currentUser.details[key]?.toString() || null };
        },
        {}
      );

      const customerid = getCustomerIdForCRMStack(
        currentUser.cognitoId,
        currentUser.thLegacyCognitoId
      );

      login(
        {
          customerid,
          rbiCognitoId: currentUser.cognitoId,
          email_subscribe: currentUser.details.promotionalEmails ? 'opted_in' : 'unsubscribed',
          ...sanitizedDetails,
        },
        {},
        sendUpdateUserAttributesEvent
      );

      if (customerid) {
        brazeSetUserId(customerid);
      }
    },
    [brazeSetUserId, launchDarklyLogin, login, sendUpdateUserAttributesEvent]
  );

  const logUserOutOfThirdPartyServices = useCallback(() => {
    launchDarklyLogout();
    logout();
    brazeSetUserId(null);
    datadogRum.clearUser();
  }, [brazeSetUserId, launchDarklyLogout, logout]);

  return {
    logUserInToThirdPartyServices,
    logUserOutOfThirdPartyServices,
  };
};
