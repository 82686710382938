import { ISanityBlockContent, ISanityLocaleBlockContent } from '@rbi-ctg/menu';

export enum PlaceholderFormatter {
  'CURRENCY' = 'currency',
  'DATE' = 'date',
  'TO_STRING' = 'toString',
  'LOCALIZED' = 'localized',
  'TIME' = 'time',
  'SANITY_BLOCK_CONTENT' = 'sanityBlockContent',
}

export type PlaceholderField = {
  _id?: string;
  fieldId: string;
  formatter: PlaceholderFormatter;
  path: string;
};

export type PlaceholderFieldsMap = Record<string, PlaceholderField>;

export interface BlockContentText extends ISanityBlockContent {}

export interface BlockContentReference {
  _key: string;
  _type: 'reference';
  _ref: string;
}

export type BlockContentItem = BlockContentText | BlockContentReference;

export const isSanityLocaleBlockContent = (field: any): field is ISanityLocaleBlockContent =>
  field?.__typename === 'LocaleBlockContent' && Array.isArray(field?.localeRaw);

export const isBlockContentReference = (block: BlockContentItem): block is BlockContentReference =>
  block._type === 'reference';

export const isBlockContentText = (block: BlockContentItem): block is BlockContentText =>
  block._type === 'block';

export interface ILocaleSmartBlockContent {
  readonly __typename?: 'LocaleSmartBlockContent' | undefined;
  readonly localeRaw?: BlockContentItem[];
}

export interface TransformSmartBlockContentFnParams {
  blockContent: ILocaleSmartBlockContent;
  interpolationSourceObject: Object;
  placeholderFieldsMap: PlaceholderFieldsMap;
}
export type TransformSmartBlockContentFn = (
  params: TransformSmartBlockContentFnParams
) => ISanityLocaleBlockContent | null;

export type InterpolationValue = string | ISanityLocaleBlockContent;

export type PlaceholderFormatterMethods = {
  isValidType: (value: any) => boolean;
  formatMethod: (value: any) => InterpolationValue;
};
export type FormattersMap = Record<PlaceholderFormatter, PlaceholderFormatterMethods>;

export type InterpolatePlaceholdersArgs = {
  blocks: BlockContentItem[];
  interpolationSourceObject: Object;
  placeholderFieldsMap: PlaceholderFieldsMap;
  formattersMap: FormattersMap;
};

export type InterpolationsMap = Record<string, InterpolationValue>;
