import { useCallback } from 'react';

import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { StorageKeys } from 'utils/local-storage';

import { addOffer, addReward } from './add-in-restaurant-cart-entry';
import { MIN_IN_RESTAURANT_CART_QUANTITY } from './constants';
import {
  ICart,
  ICartEntry,
  ICartEntryType,
  createCartEntry,
  existCartEntry,
  filterCartEntries,
  removeCartEntry,
  updateCartEntry,
} from './incentive-cart';
import { IUseInRestaurantCart } from './types';
import { isCartEntryQuantityValid } from './utils';

/**
 * useInRestaurantCart holds the cart state and expose an API to interact with.
 */
export const useInRestaurantCart = (): IUseInRestaurantCart => {
  const [cart, updateCart, resetCart] = useLocalStorageState<ICart>({
    key: StorageKeys.IN_RESTAURANT_REDEMPTION,
    defaultReturnValue: [],
  });
  const [lastModificationDate, updateLastModificationDate, clearLastModificationDate] =
    useLocalStorageState<Date | undefined>({
      key: StorageKeys.IN_RESTAURANT_REDEMPTION_LAST_MOD,
      defaultReturnValue: undefined,
    });

  const existEntryTypeInRestaurantCart: IUseInRestaurantCart['existEntryTypeInRestaurantCart'] =
    useCallback(type => existCartEntry(cart, (ce: ICartEntry) => ce.type === type), [cart]);

  // Cart Entry CRUD
  const addInRestaurantCartEntry: IUseInRestaurantCart['addInRestaurantCartEntry'] = item => {
    const newCartEntry = createCartEntry(item, {
      initialQuantity: MIN_IN_RESTAURANT_CART_QUANTITY,
    });

    let isItemAdded = true;
    updateCart(previousCart => {
      let cartResult = previousCart;
      switch (newCartEntry?.type) {
        case ICartEntryType.REWARD: {
          cartResult = addReward(previousCart, newCartEntry);
          break;
        }
        case ICartEntryType.OFFER: {
          cartResult = addOffer(previousCart, newCartEntry);
          break;
        }
        default:
      }
      // the cart is updated if the item was added correctly
      isItemAdded = cartResult !== previousCart;

      return isItemAdded ? cartResult : previousCart;
    });
    updateLastModificationDate(new Date());
    return isItemAdded ? newCartEntry : null;
  };

  const removeInRestaurantCartEntry: IUseInRestaurantCart['removeInRestaurantCartEntry'] =
    useCallback(
      cartEntry => {
        updateCart(prevCart => removeCartEntry(prevCart, cartEntry));
        clearLastModificationDate();
      },
      [clearLastModificationDate, updateCart]
    );

  const removeTypeFromCart = useCallback(
    (typeToRemove: ICartEntryType) => {
      updateCart(prevCart => filterCartEntries(prevCart, ce => ce.type !== typeToRemove));
    },
    [updateCart]
  );

  const clearInRestaurantCartAllRewards: IUseInRestaurantCart['clearInRestaurantCartAllRewards'] =
    useCallback(() => {
      removeTypeFromCart(ICartEntryType.REWARD);
    }, [removeTypeFromCart]);

  const updateInRestaurantCartEntryQuantity: IUseInRestaurantCart['updateInRestaurantCartEntryQuantity'] =
    useCallback(
      (cartEntry, quantity) => {
        if (
          [ICartEntryType.OFFER, ICartEntryType.REWARD].includes(cartEntry.type) &&
          isCartEntryQuantityValid(quantity)
        ) {
          updateCart(prevCart => updateCartEntry(prevCart, cartEntry, { quantity }));
        }
      },
      [updateCart]
    );

  return {
    inRestaurantCart: cart,
    addInRestaurantCartEntry,
    removeInRestaurantCartEntry,
    updateInRestaurantCartEntryQuantity,
    resetInRestaurantCart: resetCart,
    existEntryTypeInRestaurantCart,
    clearInRestaurantCartAllRewards,
    isInRestaurantCartEmpty: !cart?.length,
    lastModificationDate,
    updateLastModificationDate,
    removeTypeFromCart,
  };
};
