import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { merge } from 'lodash';

const SESSION_ID_HEADER = 'X-Session-Id';

export class SessionIDLink extends ApolloLink {
  public sessionId?: string;

  public request(operation: Operation, forward: NextLink) {
    const context = operation.getContext();

    if (this.sessionId) {
      operation.setContext(
        merge(
          {
            headers: {
              [SESSION_ID_HEADER]: this.sessionId,
            },
          },
          context
        )
      );
    }

    return forward(operation);
  }

  public setSessionId(sessionId: string): string {
    this.sessionId = sessionId;
    return sessionId;
  }
}

/**
 * use a singleton instance to share session ID state
 */
export const withSessionId = new SessionIDLink();
