import { ExcludeNil } from '@rbi-ctg/frontend';
import {
  INavigationButtonFragment,
  INavigationLinksFragment,
  INavigationSectionFragment,
  INavigationTabsFragment,
  useFeatureNavigationQuery,
} from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

interface IUseFeatureNavigationValue {
  featureNavigationLoading: boolean;
  featureNavigationDesktop: ExcludeNil<INavigationLinksFragment['navigationLinks']>;
  featureNavigationDesktopWithStore: ExcludeNil<
    INavigationLinksFragment['navigationLinksWithServiceModeOrStoreSelected']
  >;
  featureNavigationMobile: ExcludeNil<INavigationTabsFragment['navigationTabs']>;
  featureNavigatonMobileWithStore: ExcludeNil<
    INavigationTabsFragment['navigationTabsWithServiceModeSelected']
  >;
  featureAboutMenuEntriesDesktop: INavigationSectionFragment | null;
  featureAboutMenuEntriesMobile: INavigationSectionFragment | null;
  featureNavigationMobileHeaderButton: ExcludeNil<
    INavigationButtonFragment['navigationMobileHeaderButton']
  > | null;
}

export const useFeatureNavigation = (): IUseFeatureNavigationValue => {
  const featureNavigationId = useFlag(LaunchDarklyFlag.AB_TESTING_FEATURE_NAVIGATION);

  const { data, loading } = useFeatureNavigationQuery({
    variables: { featureNavigationId },
    skip: !featureNavigationId,
  });

  const { navigationDesktop, navigationMobile, navigationMobileHeaderButton } =
    data?.FeatureNavigation ?? {};
  const featureNavigationLoading = loading;

  return {
    featureNavigationDesktop: navigationDesktop?.navigationLinks ?? [],
    featureNavigationMobile: navigationMobile?.navigationTabs ?? [],
    featureNavigationDesktopWithStore:
      navigationDesktop?.navigationLinksWithServiceModeOrStoreSelected ?? [],
    featureNavigatonMobileWithStore: navigationMobile?.navigationTabsWithServiceModeSelected ?? [],
    featureAboutMenuEntriesDesktop: navigationDesktop?.aboutMenuEntries ?? null,
    featureAboutMenuEntriesMobile: navigationMobile?.aboutMenuEntries ?? null,
    featureNavigationMobileHeaderButton: navigationMobileHeaderButton ?? null,
    featureNavigationLoading,
  };
};
