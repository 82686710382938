import { configureStore } from '@reduxjs/toolkit';

import { loyaltyReducer, orderingReducer } from './models';

export const store = configureStore({
  reducer: {
    loyalty: loyaltyReducer,
    ordering: orderingReducer,
  },
});

export type ConfiguredStore = typeof store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
