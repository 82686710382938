import { ApolloError } from '@apollo/client';
import { IntlShape } from 'react-intl';

import { TLocalizationKey } from 'types/i18n';
import {
  IErrorDialog,
  getAppleOrGooglePayError,
  getErrorDialogForGraphQLCode,
  parseGraphQLErrorCodes,
  paymentErrorModalMaps,
} from 'utils/errors';
import { GraphQLError } from 'utils/network';

interface IGetErrorDialogOptions {
  error: ApolloError | GraphQLError;
  formatMessage: IntlShape['formatMessage'];
  body: React.ReactNode;
  errorMsgId: TLocalizationKey;
}

export const ORDER_FAILURE_MESSAGE_KEY: TLocalizationKey = 'orderFailureMessage';
export const CONTACT_US_ROUTE_KEY = 'contact-us';

interface IErrorDialogOptions {
  body: React.ReactNode;
  error: ApolloError | GraphQLError;
  title: string;
  message: string;
}

/**
 * If you provide a body it will be used, if not it will default to the body of the errorDialogOptions
 *
 * @param body
 * @param error
 * @param formatMessage
 * @param errorMsgId
 */
export const getErrorDialogOptions = ({
  body,
  error,
  formatMessage,
  errorMsgId,
}: IGetErrorDialogOptions): IErrorDialog | IErrorDialogOptions => {
  const [errorCode] = parseGraphQLErrorCodes(error);
  const errorDialog = errorCode && getErrorDialogForGraphQLCode(errorCode, formatMessage);

  return {
    body: body ?? errorDialog?.body,
    error,
    message: errorDialog?.message ?? formatMessage({ id: errorMsgId }),
    title: errorDialog?.title ?? formatMessage({ id: 'uhoh' }),
  };
};

interface IDialogMessageIds {
  body: TLocalizationKey;
  message: TLocalizationKey;
  title: TLocalizationKey;
}

export const messageIdMappingForDialogs: Record<string, IDialogMessageIds> = {
  paymentNotSupported: {
    title: 'paymentNotSupportedHeading',
    body: 'paymentNotSupported',
    message: 'paymentNotSupported',
  },
  paymentDeclined: {
    title: 'paymentDeclinedHeading',
    body: 'paymentDeclined',
    message: 'paymentDeclined',
  },
  paymentDetailsError: {
    title: 'paymentDetailsErrorHeading',
    body: 'paymentDetailsError',
    message: 'paymentDetailsError',
  },
  validationError: {
    title: 'validationErrorHeading',
    body: 'validationError',
    message: 'validationError',
  },
  duplicateSupportFormSubmission: {
    title: 'duplicateSupportFormSubmission',
    body: 'duplicateSupportFormSubmission',
    message: 'duplicateSupportFormSubmission',
  },
  coolingPeriodError: {
    title: 'errorDialogCooldownTitle',
    body: 'errorDialogCooldownMessage',
    message: 'errorDialogCooldownMessage',
  },
  giftCardUnavailableAtLocation: {
    title: 'giftCardUnavailableAtLocationErrorHeading',
    body: 'giftCardUnavailableAtLocationError',
    message: 'giftCardUnavailableAtLocationError',
  },
};

/**
 * Payment error modal key
 * Returns the key for the payment error modal
 * @param fdAccountId
 * @param defaultMessage
 * @returns
 */
export const getOrderFailureMessageKey = (
  fdAccountId: string,
  error: ApolloError | GraphQLError | undefined
): TLocalizationKey => {
  const appleOrGooglePayError = getAppleOrGooglePayError(fdAccountId);

  if (appleOrGooglePayError) {
    return appleOrGooglePayError;
  }

  if (!error) {
    return ORDER_FAILURE_MESSAGE_KEY;
  }
  const errorCode = parseGraphQLErrorCodes(error)[0]?.errorCode;

  const paymentModalKey = paymentErrorModalMaps[errorCode];
  const messageIdMappingForDialog = messageIdMappingForDialogs[paymentModalKey];
  return messageIdMappingForDialog?.body ?? ORDER_FAILURE_MESSAGE_KEY;
};
