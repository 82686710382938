import React from 'react';

import SkipToContent from 'components/skip-content';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useLDContext } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';

import { HamburgerButton } from '../hamburger-button';
import { NavLink } from '../nav-link';
import { SideNav } from '../side-nav';
import { PrimaryActionContainer } from '../styled';

import { NavMenu } from './nav-menu';
import { getNavLinks } from './utils';

interface PrimaryNavProps {
  enableDesktopSideNavigation: boolean;
}

export const PrimaryNav: React.FC<PrimaryNavProps> = ({ enableDesktopSideNavigation }) => {
  const {
    featureNavigationDesktop,
    featureNavigationDesktopWithStore,
    featureAboutMenuEntriesDesktop: aboutMenuEntries,
    featureNavigationLoading,
  } = useFeatureNavigation();
  const { store } = useStoreContext();
  const { flags } = useLDContext();

  const navigation =
    store._id && featureNavigationDesktopWithStore?.length
      ? featureNavigationDesktopWithStore
      : featureNavigationDesktop;
  const navLinks = getNavLinks(navigation, flags);

  if (featureNavigationLoading) {
    return null;
  }

  return (
    <PrimaryActionContainer>
      {enableDesktopSideNavigation ? (
        <SideNav entries={aboutMenuEntries?.pages ?? []} />
      ) : (
        <HamburgerButton />
      )}
      <SkipToContent />
      {navLinks.map(navLink => (
        <NavLink to={navLink.link} key={navLink.key} data-testid={navLink.text}>
          {navLink.text}
        </NavLink>
      ))}
      {aboutMenuEntries && !enableDesktopSideNavigation && (
        <NavMenu title={aboutMenuEntries?.name?.locale ?? ''} entries={aboutMenuEntries?.pages} />
      )}
    </PrimaryActionContainer>
  );
};
