import { Geography } from 'utils/geography';

import { Coordinates } from '../geolocation/types';

export const isLocationFarFromThreshold = (
  userCoordinates: Coordinates,
  targetCoordinates: Coordinates,
  threshold: number
) => {
  const distance = Geography.coordinateDistance(
    { latitude: userCoordinates.lat, longitude: userCoordinates.lng },
    { latitude: targetCoordinates.lat, longitude: targetCoordinates.lng },
    false
  );

  return distance >= threshold;
};

export const isLocationTheSame = (
  userCoordinates: Coordinates,
  targetCoordinates: Coordinates
): boolean =>
  userCoordinates.lat === targetCoordinates.lat && userCoordinates.lng === targetCoordinates.lng;
