import { DefaultTheme } from 'styled-components';

import { theme as brandTheme } from 'components/layout/brand-theme';
import { pxToRem } from 'utils/css';

import { DesignTokens, designTokens } from './constants/design-tokens';
import { IFontConfig } from './constants/fonts';

const BASE_FONT_SIZE = 16;

export const theme: DefaultTheme = {
  ...brandTheme,
  pxToRem: pxToRem(BASE_FONT_SIZE),
  token(tokenName: keyof DesignTokens) {
    return designTokens[tokenName];
  },
};

export const createTheme = (fontConfig: IFontConfig): DefaultTheme => ({
  ...theme,
  pxToRem: pxToRem(fontConfig.baseFontSize),
});
