import { RedirectData } from 'state/payment/hooks/types';

export const handleRedirectPayment = (error: any): RedirectData | null => {
  if (!error?.graphQLErrors) {
    return null;
  }

  const FIRST_INDEX_ERROR = 0;
  const redirectData = error.graphQLErrors[FIRST_INDEX_ERROR]?.extensions ?? null;

  return redirectData;
};

export function getRedirectError(error: unknown): RedirectData | null {
  const redirectError = handleRedirectPayment(error);
  if (redirectError) {
    if (redirectError.action.type === 'redirect') {
      return redirectError;
    }
  }
  return null;
}
