import { IFeatureBeeperQuery, useFeatureBeeperQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureBeeper {
  featureBeeperLoading: boolean;
  featureBeeper: IFeatureBeeperQuery['FeatureBeeperModal'];
}

export const useFeatureBeeper = (): IUseFeatureBeeper => {
  const { featureBeeperId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureBeeperQuery({
    variables: { featureBeeperId },
    skip: !featureBeeperId,
  });

  const featureBeeper = data?.FeatureBeeperModal ?? null;
  const featureBeeperLoading = featureIdsLoading || loading;

  return { featureBeeperLoading, featureBeeper };
};
