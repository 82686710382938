import * as React from 'react';
import { FC } from 'react';

import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';

import { usePrevious } from 'hooks/use-previous';
import { delayUnmountHOC } from 'utils/delay-unmount-hoc';

import { IPrice } from '../types';

import {
  CartItemPrice,
  CurrencyContainer,
  StrikeThrough,
  StyledCurrency,
  StyledDiscountPrice,
  StyledPointsDisplay,
} from './styled';
import { useCartItemPrice } from './use-cart-item-price';

const DelayedUnmountPrice = delayUnmountHOC(StyledDiscountPrice);

const RewardDiscountPrice = ({
  rewardApplied,
  showPriceInsteadFreeInCart,
  discountPrice,
}: {
  rewardApplied: boolean;
  showPriceInsteadFreeInCart: boolean;
  discountPrice: number;
}) => {
  const { formatMessage } = useIntl();
  const prevDiscountPrice = usePrevious(discountPrice);
  const discountDisplay = rewardApplied ? discountPrice : prevDiscountPrice;

  if (showPriceInsteadFreeInCart) {
    return null;
  }

  if (rewardApplied && discountPrice === 0) {
    return <StyledPointsDisplay value={capitalize(formatMessage({ id: 'free' }))} />;
  }

  return (
    <div>
      <DelayedUnmountPrice delayTime={300} isVisible={rewardApplied} amount={discountDisplay} />
    </div>
  );
};

const Price: FC<IPrice> = ({ item, isOffer, offerId, className, rewardApplied }) => {
  const {
    price,
    showStrike,
    discountPrice,
    loyaltyEnabled,
    isRewardApplied,
    showPriceInsteadFreeInCart,
  } = useCartItemPrice({ item, isOffer, offerId, rewardApplied });

  return (
    <CartItemPrice className={className} isOffer={isOffer}>
      <CurrencyContainer isRewardApplied={isRewardApplied}>
        {showStrike && <StrikeThrough />}
        <StyledCurrency amount={showPriceInsteadFreeInCart ? discountPrice : price} />
      </CurrencyContainer>
      {loyaltyEnabled && (
        <RewardDiscountPrice
          discountPrice={discountPrice}
          rewardApplied={isRewardApplied}
          showPriceInsteadFreeInCart={showPriceInsteadFreeInCart}
        />
      )}
    </CartItemPrice>
  );
};

export default Price;
