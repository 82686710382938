import { TLocalizationKey } from 'types/i18n';

export enum DistanceWarningType {
  DELIVERY_GEOLOCATION_ENABLED = 'DELIVERY_GEOLOCATION_ENABLED',
  DELIVERY_GEOLOCATION_DISABLED = 'DELIVERY_GEOLOCATION_DISABLED',
  PICKUP_GEOLOCATION_ENABLED = 'PICKUP_GEOLOCATION_ENABLED',
  PICKUP_GEOLOCATION_DISABLED = 'PICKUP_GEOLOCATION_DISABLED',
}

export interface ButtonLabels {
  onConfirmLabel: string;
  onCancelLabel: string;
}

type CommomLabels = {
  delivery: {
    onConfirmLabel: TLocalizationKey;
    onCancelLabel: TLocalizationKey;
  };
  pickup: {
    onConfirmLabel: TLocalizationKey;
    onCancelLabel: TLocalizationKey;
  };
};

const commonLabels: CommomLabels = {
  delivery: {
    onConfirmLabel: 'yesDeliverHere',
    onCancelLabel: 'noChangeAddress',
  },
  pickup: {
    onConfirmLabel: 'yesOrderHere',
    onCancelLabel: 'noChangeRestaurant',
  },
};

export const warningConfig = {
  permissionGranted: {
    delivery: {
      type: DistanceWarningType.DELIVERY_GEOLOCATION_ENABLED,
      analytics: {
        name: 'Modal - Far away. Home Delivery',
        onCancelName: 'No, change address',
      },
    },
    pickup: {
      type: DistanceWarningType.PICKUP_GEOLOCATION_ENABLED,
      analytics: {
        name: 'Modal - Far away. Mobile Ordering',
        onCancelName: 'No, change restaurant',
      },
    },
  },
  permissionDenied: {
    delivery: {
      type: DistanceWarningType.DELIVERY_GEOLOCATION_DISABLED,
      analytics: {
        name: 'Modal - New address. Home Delivery',
        onCancelName: 'No, change address',
      },
    },
    pickup: {
      type: DistanceWarningType.PICKUP_GEOLOCATION_DISABLED,
      analytics: {
        name: 'Modal - New restaurant. Mobile Ordering',
        onCancelName: 'No, change restaurant',
      },
    },
  },
};

export const titleMessages: Record<DistanceWarningType, TLocalizationKey> = {
  [DistanceWarningType.DELIVERY_GEOLOCATION_ENABLED]: 'youAreFarFromThisAddress',
  [DistanceWarningType.DELIVERY_GEOLOCATION_DISABLED]: 'youAreSelectingNewAddress',
  [DistanceWarningType.PICKUP_GEOLOCATION_ENABLED]: 'youAreFarFromThisRestaurant',
  [DistanceWarningType.PICKUP_GEOLOCATION_DISABLED]: 'youAreSelectingNewRestaurant',
};

export const buttonLabels = {
  [DistanceWarningType.DELIVERY_GEOLOCATION_ENABLED]: commonLabels.delivery,
  [DistanceWarningType.DELIVERY_GEOLOCATION_DISABLED]: commonLabels.delivery,
  [DistanceWarningType.PICKUP_GEOLOCATION_ENABLED]: commonLabels.pickup,
  [DistanceWarningType.PICKUP_GEOLOCATION_DISABLED]: commonLabels.pickup,
};
