import { IUserAccountsQuery } from 'generated/rbi-graphql';

import { IPaymentMethod } from '../types';

type UserAccount = Exclude<Exclude<IUserAccountsQuery['userAccounts'], null>['accounts'], null>[0];

export const userAccountsToPaymentMethods = (userAccount: UserAccount): IPaymentMethod => {
  // Maps all the undefined in UserAccount to nulls.
  const cleanedUserAccounts = {
    ...userAccount,
    accountIdentifier: userAccount?.accountIdentifier ?? null,
    fdAccountId: userAccount?.fdAccountId ?? null,
    chaseProfileId: userAccount?.chaseProfileId ?? null,
    paypalIdentifier: userAccount?.paypalIdentifier ?? null,
    credit: userAccount?.credit
      ? { ...userAccount?.credit, panToken: userAccount?.credit.panToken ?? '' }
      : null,
    prepaid: userAccount?.prepaid
      ? { ...userAccount?.prepaid, currentBalance: userAccount?.prepaid.currentBalance ?? 0 }
      : null,
    paypal: userAccount?.paypal ?? null,
  };

  return {
    ...cleanedUserAccounts,
    onlinePayment: true,
  };
};
