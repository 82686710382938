import { useEffect } from 'react';

import { IRestaurantNode } from 'generated/rbi-graphql';
import useGoogleGeolocationLibrary from 'hooks/geolocation/use-google-geolocation-library';
import { MarkerTypes } from 'hooks/use-map';
import { useGeolocation } from 'state/geolocation';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import {
  isMobileOrderingAvailable,
  isRestaurantOpen,
  useGetRestaurantAvailabilityFn,
} from 'utils/restaurant';
import { isCatering } from 'utils/service-mode';

// TODO: properly type some of thess
export interface IUseMarkersProps {
  createMarker: (data: any) => any;
  markers: any;
  map: unknown;
  onClickMarker: (store: IRestaurantNode) => any;
  panTo: (newPosition?: any) => void;
  storesNearby?: IRestaurantNode[];
  storesFavs?: IRestaurantNode[];
  activeStoreId?: string | null;
}

export default function useMarkers({
  createMarker,
  markers,
  map,
  onClickMarker,
  panTo,
  storesNearby,
  storesFavs,
  activeStoreId,
}: IUseMarkersProps) {
  const { libraryLoaded: libLoaded } = useGoogleGeolocationLibrary();
  const { userCoordinates } = useGeolocation();
  const { serviceMode } = useServiceModeContext();
  const hasAvailableProperty = useGetRestaurantAvailabilityFn();
  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );

  useEffect(() => {
    if (!activeStoreId) {
      return;
    }

    // inactivate all current markers before we activate the current marker
    if (markers.current.active) {
      markers.current.active.inactive();
    }
    const activeMarker = markers.current[activeStoreId];

    if (activeMarker) {
      markers.current.active = activeMarker;
      activeMarker.active();
      panTo({ lat: activeMarker.lat, lng: activeMarker.lng });
    }
  }, [activeStoreId, markers, panTo]);

  useEffect(() => {
    if (!libLoaded) {
      return;
    }

    (storesNearby ?? []).forEach(store => {
      const id = store._id;

      if (!id) {
        return;
      }

      const location = { lat: store.latitude, lng: store.longitude };

      const diningRoomOpen = isRestaurantOpen(store.diningRoomHours);
      const driveThruOpen = isRestaurantOpen(store.driveThruHours);
      const open = driveThruOpen || diningRoomOpen;

      const marker = createMarker({
        type: MarkerTypes.Store,
        location,
        onClick: () => {
          onClickMarker(store);
        },
        introAnim: true,
      });

      if (marker) {
        markers.current[id] = {
          active: marker.active,
          inactive: marker.inactive,
          disabled: marker.disabled,
          favOpen: marker.favOpen,
          favClosed: marker.favClosed,
          ...location,
        };

        if (id === activeStoreId) {
          markers.current.active = marker;
        }
        const isStoreFavorite = storesFavs?.some(favStore => favStore._id === id);
        if (isStoreFavorite && id !== activeStoreId) {
          marker.favOpen();
        }

        const cateringServiceMode = isCatering(serviceMode);
        const restaurantAvailable = hasAvailableProperty(store);
        const mobileOrderingAvailable = isMobileOrderingAvailable(
          store,
          isAlphaBetaStoreOrderingEnabled
        );
        const isStoreAvailable = mobileOrderingAvailable && open && restaurantAvailable;

        if (cateringServiceMode || isStoreAvailable) {
          return;
        }
        if (store._id === activeStoreId) {
          marker.active();
        } else if (isStoreFavorite) {
          marker.favClosed();
        } else {
          marker.disabled();
        }
      }
    });
  }, [
    storesNearby,
    libLoaded,
    createMarker,
    activeStoreId,
    markers,
    onClickMarker,
    storesFavs,
    serviceMode,
    isAlphaBetaStoreOrderingEnabled,
    hasAvailableProperty,
  ]);
  // Create a marker for the users coordinates
  useEffect(() => {
    if (libLoaded && map) {
      createMarker({ type: MarkerTypes.User, location: userCoordinates });
    }
  }, [userCoordinates, libLoaded, map, createMarker]);
}
