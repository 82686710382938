import React, { useEffect, useState } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { deliveryCompleteTimeout, postOrderTimeout } from 'components/bottom-service-mode/utils';
import LoyaltyPointsIcon from 'components/icons/loyalty-points-icon';
import { DeliveryStatus, IUserOrderFragment, RbiOrderStatus } from 'generated/rbi-graphql';
import { useUserOrders } from 'hooks/use-user-orders';
import { STATUS_MESSAGES } from 'pages/order-confirmation/delivery/delivery-progress';
import { getCustomerFacingDeliveryStatuses } from 'pages/order-confirmation/delivery/get-customer-facing-delivery-statuses';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { ServiceMode, useOrderContext } from 'state/order';
import { useServiceModeContext } from 'state/service-mode';
import { routes } from 'utils/routing';

import { Close } from '../top-service-mode-notification.styled';
import { IOrderInProgress, IOrderInProgressResult, IServiceNotification } from '../types';

const DELIVERY_COMPLETED = [
  DeliveryStatus.ORDER_DROPPED_OFF,
  DeliveryStatus.ORDER_ABANDONED,
  DeliveryStatus.ORDER_CANCELLED,
];

const ORDER_PROGRESS_POLLING_DURATION_IN_MS = 10000;
const DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES = 10;
export const PICKUP_UPDATED_TIMEOUT_IN_MINUTES = 10;
export const PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES = 60;

const getDeliveryHeading = ({
  deliveryStatus,
  rbiOrderStatus,
}: {
  deliveryStatus: DeliveryStatus;
  rbiOrderStatus: RbiOrderStatus;
}) => {
  const { isBeingPrepared, isDriverEnroute, isDelivered } = getCustomerFacingDeliveryStatuses(
    deliveryStatus,
    rbiOrderStatus
  );
  if (isDelivered) {
    return 'deliveryDelivered';
  }
  if (isDriverEnroute) {
    return 'deliveryDriverEnroute';
  }

  if (isBeingPrepared) {
    return 'deliveryOrderBeingPrepared';
  }

  return 'orderPlaced';
};

const isPollingForDelivery = (
  recentOrder: IUserOrderFragment | null | undefined,
  isDeliveryOrderCompleted: boolean
) => {
  if (
    recentOrder?.delivery &&
    !isDeliveryOrderCompleted &&
    !DELIVERY_COMPLETED.includes(recentOrder.delivery.status)
  ) {
    return true;
  }
  return false;
};

const orderInProgress = ({
  recentOrder,
  fireOrderAhead,
  curbsidePickupOrderId,
  curbsidePickupOrderTimePlaced,
  isDeliveryOrderCompleted,
  isCurbside,
}: IOrderInProgress): IOrderInProgressResult | null => {
  if (
    recentOrder &&
    [ServiceMode.CATERING_PICKUP, ServiceMode.CATERING_DELIVERY].includes(
      recentOrder?.cart.serviceMode
    )
  ) {
    return null;
  }

  const isPickupTimedOut = () => {
    // Add the fire order delay to the total timeout
    const fireOrderInSeconds = recentOrder?.fireOrderIn
      ? recentOrder?.fireOrderIn + fireOrderAhead
      : 0;
    const fireOrderInMinutes = fireOrderInSeconds / 60;
    const timeOutWithDelay = PICKUP_UPDATED_TIMEOUT_IN_MINUTES + fireOrderInMinutes;

    return (
      recentOrder &&
      !recentOrder?.delivery &&
      postOrderTimeout(recentOrder?.updatedAt, timeOutWithDelay)
    );
  };

  const isPreConfirmCurbsideTimedOut = () =>
    curbsidePickupOrderId &&
    postOrderTimeout(
      curbsidePickupOrderTimePlaced,
      PRE_CURBSIDE_CONFIRM_ARRIVAL_TIMEOUT_IN_MINUTES
    );

  // ServiceMode.DELIVERY - ordered
  if (recentOrder?.delivery && !isDeliveryOrderCompleted) {
    const {
      status: rbiOrderStatus,
      delivery: { status: deliveryStatus },
    } = recentOrder;
    const headingId = getDeliveryHeading({ deliveryStatus, rbiOrderStatus });
    const detailsId = STATUS_MESSAGES[deliveryStatus];

    return {
      headingId,
      detailsId,
      icon: <Icon icon="location" color="white" width="24px" height="24px" aria-hidden />,
    };
  }
  // ServiceMode.CURBSIDE - Before order is commited on confirming arrival , selecting "I'm here!"
  if (isCurbside && isPreConfirmCurbsideTimedOut()) {
    return {
      headingId: 'serviceModeDetailsCurbsideHeader',
      detailsId: 'headToRestaurant',
      icon: <Icon icon="restaurant" color="white" width="24px" height="24px" aria-hidden />,
    };
  }
  // PICKUP - ordered
  if (recentOrder && isPickupTimedOut()) {
    return {
      headingId: 'orderPlaced',
      detailsId: 'yourOrderIsBeingPrepared',
      icon: <Icon icon="restaurant" color="white" width="24px" height="24px" aria-hidden />,
    };
  }

  return null;
};

export const LOYALTY_REDEEM_TEXT_KEY = 'perksReadyToUse';

const StyledLoyaltyPointsIcon = styled(LoyaltyPointsIcon)`
  margin-block-start: 0.5rem;
`;

export const useServiceModeNotification = (): IServiceNotification | null => {
  const { formatMessage } = useIntl();
  const fireOrderAhead = useFlag(LaunchDarklyFlag.FIRE_ORDER_AHEAD);
  const { isAuthenticated } = useAuthContext();
  const isAuth = isAuthenticated();
  const { serviceMode, curbsidePickupOrderId, curbsidePickupOrderTimePlaced, serverOrder } =
    useOrderContext();
  const { isCurbside } = useServiceModeContext();

  const { inRestaurantRedemptionEnabled, inRestaurantRedemptionCart } =
    useInRestaurantRedemptionContext();

  const [hasSeenCancelModal, setHasSeenCancelModal] = useState(false);
  const [shouldDisplayNotification, setShouldDisplayNotification] = useState<boolean>(true);
  const [shouldDisplayLoyaltyNotification, setShouldDisplayLoyaltyNotification] =
    useState<boolean>(true);
  const [currentOrderId, setCurrentOrderId] = useState<string>('');
  const [currentDeliveryStatus, setCurrentDeliveryStatus] = useState<DeliveryStatus>();

  const isOnMenuPage = useMatch({
    path: routes.menu,
    end: false,
  });

  // TODO: reroute
  // start polling for recent user orders
  const { data, called, startPolling, stopPolling, refetch } = useUserOrders({
    variables: {
      limit: 1,
      orderStatuses: [
        RbiOrderStatus.INSERT_SUCCESSFUL,
        RbiOrderStatus.UPDATE_SUCCESSFUL,
        RbiOrderStatus.UPDATE_ERROR, // not a terminal status
        RbiOrderStatus.REFUND_SUCCESSFUL,
      ],
    },
  });

  // Upon order confirmation, data from `useGetUseOrdersQuery` is not updated with newly placed order
  // Force a refetch to update query with new confirm order instead of previous order
  useEffect(() => {
    if (isCurbside && serverOrder?.status === RbiOrderStatus.INSERT_SUCCESSFUL) {
      refetch();
    }
  }, [isCurbside, refetch, serverOrder]);

  useEffect(() => {
    // Update notification for new orders being placed
    const order = data?.userOrders?.orders?.[0];
    if (order && !isCurbside && order.rbiOrderId !== currentOrderId) {
      setShouldDisplayNotification(true);
      setCurrentOrderId(order.rbiOrderId);
    }
  }, [currentOrderId, data, isCurbside]);

  useEffect(() => {
    // Update notification for delivery status changes
    const order = data?.userOrders?.orders?.[0];
    if (order?.delivery?.status && order?.delivery.status !== currentDeliveryStatus) {
      setShouldDisplayNotification(true);
      setCurrentDeliveryStatus(order?.delivery?.status);
    }
  }, [currentDeliveryStatus, data]);

  useEffect(() => {
    // reactivate shouldDisplayNotification after closing first Curbside notification
    if (serverOrder && serverOrder.status === RbiOrderStatus.INSERT_SUCCESSFUL) {
      setShouldDisplayNotification(true);
    }
  }, [serverOrder]);

  useEffect(() => {
    // reset shouldDisplayLoyaltyNotification when the loyalty redemption cart is updated.
    if (inRestaurantRedemptionCart?.length > 0) {
      setShouldDisplayLoyaltyNotification(true);
    }
  }, [inRestaurantRedemptionCart]);

  const isCurbsideOrderCompleted =
    isCurbside && serverOrder && serverOrder.status === RbiOrderStatus.INSERT_SUCCESSFUL;

  const recentOrder = isCurbsideOrderCompleted
    ? { ...serverOrder, updatedAt: serverOrder.createdAt }
    : data?.userOrders?.orders?.[0];

  if (!isOnMenuPage && isAuth && recentOrder?.status !== RbiOrderStatus.REFUND_SUCCESSFUL) {
    const isDeliveryOrderCompleted =
      !!recentOrder?.delivery &&
      DELIVERY_COMPLETED.includes(recentOrder.delivery.status) &&
      (hasSeenCancelModal ||
        deliveryCompleteTimeout(recentOrder?.updatedAt, DELIVERY_COMPLETED_TIMEOUT_IN_MINUTES));

    if (isPollingForDelivery(recentOrder, isDeliveryOrderCompleted)) {
      if (hasSeenCancelModal) {
        setHasSeenCancelModal(false);
      }
      startPolling(ORDER_PROGRESS_POLLING_DURATION_IN_MS);
    }

    const inRestaurantRedemptionServiceModes = [
      serviceMode === ServiceMode.TAKEOUT,
      serviceMode === ServiceMode.EAT_IN,
      serviceMode === ServiceMode.DRIVE_THRU,
    ];

    if (
      inRestaurantRedemptionServiceModes.includes(true) &&
      inRestaurantRedemptionEnabled &&
      inRestaurantRedemptionCart?.length &&
      shouldDisplayLoyaltyNotification
    ) {
      return {
        heading: formatMessage({ id: LOYALTY_REDEEM_TEXT_KEY }),
        details: '',
        icon: <StyledLoyaltyPointsIcon />,
        cta: (
          <Close onClick={() => setShouldDisplayLoyaltyNotification(false)}>
            <Icon color="white" icon="close" height="2rem" width="2rem" aria-hidden />
          </Close>
        ),
      };
    }

    const inProgress = orderInProgress({
      recentOrder,
      fireOrderAhead,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
      isDeliveryOrderCompleted,
      isCurbside,
    });
    if (recentOrder && inProgress && shouldDisplayNotification) {
      const { headingId, detailsId, icon } = inProgress;

      return {
        heading: formatMessage({ id: headingId }),
        details: formatMessage({ id: detailsId }),
        icon,
        cta: (
          <Close onClick={() => setShouldDisplayNotification(false)}>
            <Icon color="white" icon="close" height="2rem" width="2rem" aria-hidden />
          </Close>
        ),
        orderId: currentOrderId,
      };
    }
  }

  // Stop polling for recent user orders
  if (called) {
    stopPolling();
  }

  return null;
};
