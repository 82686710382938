export class JwtValidationError extends Error {
  constructor() {
    super('JWT validation unsuccessful');
  }
}

export class UserNotFoundError extends Error {
  constructor() {
    super('user not found');
  }
}

export class OtpValidationError extends Error {
  constructor(message: string) {
    super(`OTP validation unsuccessful: ${message}`);
  }
}
