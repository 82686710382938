import styled from 'styled-components';

import { LoyaltyTextWidget } from '.';

export const StyledLoyaltyTextWidget = styled(LoyaltyTextWidget)`
  opacity: 0.5;
  max-width: initial;
  text-align: initial;
  margin: 0 16px;
  font-size: 0.6875rem;
`;

export const LoyaltyTextWidgetV3 = styled(LoyaltyTextWidget)`
  max-width: initial;
  text-align: initial;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
  margin-inline: 1rem;
  font-size: 0.75rem;
`;
