import { IUserOrderFragment, RbiOrderStatus } from 'generated/rbi-graphql';
import { TLocalizationKey } from 'types/i18n';

interface IGetOrderTrackingStatusesOptions {
  flags?: Record<string, any>;
}

export const getOrderTrackingStatuses = ({
  flags,
}: IGetOrderTrackingStatusesOptions): RbiOrderStatus[] => {
  const statuses = [];

  if (flags?.enableWaitTimeManagement) {
    statuses.push(RbiOrderStatus.QUEUED);
  }

  if (flags?.enableAsyncPayment) {
    statuses.push(RbiOrderStatus.PAYMENT_SUCCESSFUL);
  }

  return statuses;
};

interface IGetOrderTrackingMessageOptions {
  order?: IUserOrderFragment | null;
}

export const getOrderTrackingMessage = ({
  order,
}: IGetOrderTrackingMessageOptions): TLocalizationKey => {
  if (order?.status === RbiOrderStatus.QUEUED) {
    return 'orderIsInQueue';
  }

  if (order?.status === RbiOrderStatus.PAYMENT_SUCCESSFUL) {
    return 'orderIsPaid';
  }

  return 'orderIsInProgress';
};
