import { CardTypes } from 'state/payment/types';

import { IGetCheckoutPaymentMethodId } from '../types';

import { getValidPaymentMethodId } from './getValidPaymentMethodId';

/**
 * Checkout payment method can be a CC, prepaid card, Apple or Google Pay
 */
export const getCheckoutPaymentMethodId = ({
  paymentMethodHasBeenInit,
  accounts,
  usersPaymentMethodId,
  canUseApplePay,
  canUseGooglePay,
  applePayCardDetails,
  googlePayCardDetails,
}: IGetCheckoutPaymentMethodId) => {
  const checkoutAccounts = accounts;
  let userCheckoutPaymentMethodId = usersPaymentMethodId;
  // On initialization, set the checkout payment method to apple/google pay if available
  // Check if the user can use apple pay at checkout
  if (canUseApplePay) {
    checkoutAccounts.unshift(applePayCardDetails);
    if (!paymentMethodHasBeenInit) {
      // only override if it's the first time running
      userCheckoutPaymentMethodId = CardTypes.APPLE_PAY;
    }
  }
  // Check if the user can use google pay at checkout
  if (canUseGooglePay) {
    checkoutAccounts.unshift(googlePayCardDetails);
    if (!paymentMethodHasBeenInit) {
      // only override if it's the first time running
      userCheckoutPaymentMethodId = CardTypes.GOOGLE_PAY;
    }
  }
  // Return a valid checkout payment method
  return getValidPaymentMethodId({
    canUseApplePay,
    canUseGooglePay,
    paymentMethodId: userCheckoutPaymentMethodId,
    paymentMethods: checkoutAccounts,
  });
};
