import { differenceInMilliseconds, isAfter, parseISO } from 'date-fns';

export const differenceMsFromNow = (date: string) => {
  const parsedExpiry = parseISO(date);
  const now = new Date();

  return differenceInMilliseconds(parsedExpiry, now);
};

export const isExpired = (date: string) => {
  const parsedDate = parseISO(date);
  const now = new Date();

  return isAfter(now, parsedDate);
};
