import React from 'react';

import { noop } from 'lodash';

import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import { useCdpContext } from 'state/cdp';
import { ClickEventComponentNames, CustomEventNames, EventTypes } from 'state/cdp/constants';

import buttonColor from './button-color';
import { NextIcon } from './icons';
import {
  AddressStyle,
  BottomServiceWrapper,
  ButtonContainer,
  Container,
  Details,
  Heading,
  IconContainer,
  LocationOverlayButton,
  LocationStyle,
  Underline,
} from './styled';
import theme from './theme';
import { BottomServiceVariants, IBottomServiceModeViewProps } from './types';

export const BottomServiceModeView = React.forwardRef<HTMLDivElement, IBottomServiceModeViewProps>(
  (
    {
      isLoading = false,
      variant = BottomServiceVariants.DEFAULT,
      heading,
      details,
      icon,
      buttonBadgeContentText,
      buttonContentText,
      buttonContentTextLabel,
      buttonContentIcon,
      onBottomServiceClick = noop,
      onButtonClick = noop,
      buttonDisabled = false,
      className = '',
    },
    ref
  ) => {
    const cdp = useCdpContext();

    if (isLoading) {
      const isInProgress = variant === BottomServiceVariants.IN_PROGRESS;
      return (
        <Container variant={variant} className={className} ref={ref}>
          <LoadingAnimation fillColor={Styles.color[isInProgress ? 'white' : 'black']} />
        </Container>
      );
    }

    const handleCartClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      cdp.trackEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Navigation,
        attributes: {
          component: ClickEventComponentNames.BUTTON,
          text: 'View Cart',
        },
      });
      onButtonClick(e);
    };

    let buttonVariant: ActionButtonVariants;
    switch (variant) {
      case BottomServiceVariants.IN_PROGRESS:
      case BottomServiceVariants.IN_RESTAURANT_LOYALTY:
        buttonVariant = theme.inProgressVariant;
        break;
      case BottomServiceVariants.NO_STORE:
      case BottomServiceVariants.DEFAULT:
      default:
        buttonVariant = theme.defaultButtonVariant;
    }
    const isStatusUpdated = variant !== BottomServiceVariants.IN_PROGRESS;

    const color = buttonColor(variant, true);
    const bottomServiceButtonId = 'bottom-service-button';
    const bottomServiceHeadingId = 'bottom-service-heading';
    const bottomServiceDetailsId = 'bottom-service-details';

    return (
      <Container variant={variant} className={className} ref={ref} data-testid="bottom-service">
        <BottomServiceWrapper>
          <LocationOverlayButton
            onClick={onBottomServiceClick}
            data-testid="bottom-service-location"
            aria-labelledby={`${bottomServiceHeadingId} ${bottomServiceDetailsId}`}
          ></LocationOverlayButton>
          <IconContainer>{icon}</IconContainer>
          <Heading id={bottomServiceHeadingId} variant={variant}>
            {heading}
          </Heading>
          <Details id={bottomServiceDetailsId} variant={variant}>
            <LocationStyle>
              <AddressStyle
                data-testid="bottom-service-address"
                data-private
                data-dd-privacy="mask"
              >
                {details}
              </AddressStyle>
              {isStatusUpdated && <NextIcon />}
            </LocationStyle>
            {isStatusUpdated && <Underline />}
          </Details>
          {buttonContentText && (
            <ButtonContainer $badgeContent={buttonBadgeContentText}>
              <ActionButton
                onClick={handleCartClick}
                id={bottomServiceButtonId}
                variant={buttonVariant}
                color={color}
                size={ActionButtonSizes.SMALL}
                data-testid={bottomServiceButtonId}
                disabled={buttonDisabled}
                startIcon={buttonContentIcon}
                aria-label={buttonContentTextLabel || buttonContentText}
              >
                {buttonContentText}
              </ActionButton>
            </ButtonContainer>
          )}
        </BottomServiceWrapper>
      </Container>
    );
  }
);
