import { Dispatch, Reducer } from 'react';

import { Action } from '@rbi-ctg/frontend';
import { IRestaurantNode } from 'generated/rbi-graphql';

export const initialState: IState = {
  errorNearby: null,
  errorFavs: null,
  errorRecent: null,
  searchingNearby: false,
  searchingRecent: false,
  searchingFavs: false,
  storesNearby: undefined,
  storesFavs: undefined,
  storesRecent: undefined,
  activeStoreId: '',
};

export enum ActionTypes {
  SEARCH_NEARBY_REQUESTED = 'SEARCH_NEARBY_REQUESTED',
  SEARCH_NEARBY_SUCCESSFUL = 'SEARCH_NEARBY_SUCCESSFUL',
  SEARCH_FAVS_SUCCESSFUL = 'SEARCH_FAVS_SUCCESSFUL',
  SEARCH_RECENT_SUCCESSFUL = 'SEARCH_RECENT_SUCCESSFUL',
  SEARCH_NEARBY_ERROR = 'SEARCH_NEARBY_ERROR',
  SEARCH_FAVS_ERROR = 'SEARCH_FAVS_ERROR',
  SEARCH_RECENT_ERROR = 'SEARCH_RECENT_ERROR',
  SET_ACTIVE_STORE_ID = 'SET_ACTIVE_STORE_ID',
  SEARCH_RECENT_REQUESTED = 'SEARCH_RECENT_REQUESTED',
  SEARCH_FAV_REQUESTED = 'SEARCH_FAV_REQUESTED',
}

export const searchNearbyRequested = (): Action<ActionTypes.SEARCH_NEARBY_REQUESTED> => ({
  type: ActionTypes.SEARCH_NEARBY_REQUESTED,
});

export const searchRecentRequested = (): Action<ActionTypes.SEARCH_RECENT_REQUESTED> => ({
  type: ActionTypes.SEARCH_RECENT_REQUESTED,
});

export const searchFavRequested = (): Action<ActionTypes.SEARCH_FAV_REQUESTED> => ({
  type: ActionTypes.SEARCH_FAV_REQUESTED,
});

export const searchNearbySuccess = (
  result: readonly IRestaurantNode[]
): Action<ActionTypes.SEARCH_NEARBY_SUCCESSFUL, readonly IRestaurantNode[]> => ({
  payload: result,
  type: ActionTypes.SEARCH_NEARBY_SUCCESSFUL,
});

export const searchNearbyNotGranted = (): Action<
  ActionTypes.SEARCH_NEARBY_SUCCESSFUL,
  IRestaurantNode[]
> => ({
  payload: [],
  type: ActionTypes.SEARCH_NEARBY_SUCCESSFUL,
});

export const searchFavsSuccess = (
  result: readonly IRestaurantNode[]
): Action<ActionTypes.SEARCH_FAVS_SUCCESSFUL, readonly IRestaurantNode[]> => ({
  payload: result,
  type: ActionTypes.SEARCH_FAVS_SUCCESSFUL,
});

export const searchRecentSuccess = (
  result: readonly IRestaurantNode[]
): Action<ActionTypes.SEARCH_RECENT_SUCCESSFUL, readonly IRestaurantNode[]> => ({
  payload: result,
  type: ActionTypes.SEARCH_RECENT_SUCCESSFUL,
});

export const searchNearbyError = (
  error: Error
): Action<ActionTypes.SEARCH_NEARBY_ERROR, Error> => ({
  error: true,
  payload: error,
  type: ActionTypes.SEARCH_NEARBY_ERROR,
});

export const searchFavsError = (error: Error): Action<ActionTypes.SEARCH_FAVS_ERROR, Error> => ({
  error: true,
  payload: error,
  type: ActionTypes.SEARCH_FAVS_ERROR,
});

export const searchRecentError = (
  error: Error
): Action<ActionTypes.SEARCH_RECENT_ERROR, Error> => ({
  error: true,
  payload: error,
  type: ActionTypes.SEARCH_RECENT_ERROR,
});

export const setActiveStoreId = (
  storeId: string
): Action<ActionTypes.SET_ACTIVE_STORE_ID, string> => ({
  payload: storeId,
  type: ActionTypes.SET_ACTIVE_STORE_ID,
});

export interface IState {
  errorNearby: null | Error;
  errorFavs: null | Error;
  errorRecent: null | Error;
  searchingNearby: boolean;
  searchingRecent: boolean;
  searchingFavs: boolean;
  storesNearby?: IRestaurantNode[];
  storesFavs?: IRestaurantNode[];
  storesRecent?: IRestaurantNode[];
  activeStoreId: string;
}

type Actions = ReturnType<
  | typeof searchNearbyRequested
  | typeof searchNearbySuccess
  | typeof searchNearbyError
  | typeof searchFavsError
  | typeof searchRecentError
  | typeof searchFavsSuccess
  | typeof searchRecentSuccess
  | typeof setActiveStoreId
  | typeof searchRecentRequested
  | typeof searchFavRequested
>;

export type StoreAction = Dispatch<Actions>;

const reducer: Reducer<IState, Actions> = (state = initialState, action: Actions) => {
  switch (action.type) {
    case ActionTypes.SEARCH_NEARBY_ERROR:
      return {
        ...state,
        errorNearby: action.payload,
        storesNearby: [],
        searchingNearby: false,
      } as IState;
    case ActionTypes.SEARCH_FAVS_ERROR:
      return {
        ...state,
        errorFavs: action.payload,
        storesFavs: [],
        searchingFavs: false,
      };
    case ActionTypes.SEARCH_RECENT_ERROR:
      return {
        ...state,
        errorRecent: action.payload,
        storesRecent: [],
        searchingRecent: false,
      };
    case ActionTypes.SEARCH_NEARBY_REQUESTED:
      return {
        ...state,
        searchingNearby: true,
      };
    case ActionTypes.SEARCH_RECENT_REQUESTED:
      return { ...state, searchingRecent: true };
    case ActionTypes.SEARCH_FAV_REQUESTED:
      return { ...state, searchingFavs: true };
    case ActionTypes.SEARCH_NEARBY_SUCCESSFUL: {
      const storesNearby = [...action.payload];
      const { activeStoreId } = state;
      const isActiveStoreInResult = storesNearby.some(store => store._id === activeStoreId);
      const activeStore = state.storesNearby?.find(store => store._id === activeStoreId);
      // Keep currently active store in the list
      if (activeStore && !isActiveStoreInResult) {
        storesNearby.unshift(activeStore);
      }
      return {
        ...state,
        storesNearby,
        searchingNearby: false,
      };
    }
    case ActionTypes.SEARCH_FAVS_SUCCESSFUL:
      return {
        ...state,
        storesFavs: [...action.payload],
        searchingFavs: false,
      };
    case ActionTypes.SEARCH_RECENT_SUCCESSFUL:
      return {
        ...state,
        storesRecent: [...action.payload],
        searchingRecent: false,
      };
    case ActionTypes.SET_ACTIVE_STORE_ID:
      return {
        ...state,
        activeStoreId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
