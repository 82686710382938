import React from 'react';

import styled from 'styled-components';

import { ICopyrightAndLanguageSelectorFragment } from 'generated/sanity-graphql';

import { FooterLanguageSelector } from './footer-language-selector';
import { P } from './styled';

type ICopyrightAndLanguageSelectorProps = ICopyrightAndLanguageSelectorFragment;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 2rem;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  padding-inline: 0;

  > * {
    flex-basis: min(${props => 100 / React.Children.count(props.children)}%, 33.33%);
  }
`;

export const CopyrightAndLanguageSelector: React.FC<ICopyrightAndLanguageSelectorProps> = props => {
  return (
    <Container>
      <P>{props.copyrightText?.locale}</P>
      <FooterLanguageSelector />
    </Container>
  );
};
