import { useAlertQuery } from 'generated/sanity-graphql';

export const useAlert = (id?: string) => {
  const alertId = id ?? '';
  const { data, loading } = useAlertQuery({
    variables: {
      alertId,
    },
    skip: !alertId,
  });

  const alert = data?.Alert;

  return {
    alert,
    alertLoading: loading,
  };
};
