import * as React from 'react';
import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { ITermsProps } from './types';

const useTerms = ({
  onShowTermsModal,
  termsButtonText,
  termsButtonUrl,
  termsText,
}: ITermsProps) => {
  const navigate = useNavigate();

  const onClickTermsLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      // If there is termsText then this is a modal
      if (termsText) {
        onShowTermsModal({
          heading: termsButtonText,
          content: termsText,
        });
      }
      // Otherwise navigate to the url
      if (termsButtonUrl) {
        navigate(termsButtonUrl);
        e.preventDefault();
      }
    },
    [termsText, navigate, onShowTermsModal, termsButtonText, termsButtonUrl]
  );
  return onClickTermsLink;
};

export default useTerms;
