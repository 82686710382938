/* eslint-disable no-restricted-properties -- Rule is disabled to let us access window.location properties  */
type LocationReadableProperties = Exclude<
  keyof Location,
  'ancestorOrigins' | 'assign' | 'replace' | 'reload' | 'toString'
>;

export const get = (property: LocationReadableProperties) => window.location[property];

export const getSearchParams = () => new URLSearchParams(window.location.search);

export const reload = () => window.location.reload();

export const assign = (url: string | URL) => window.location.assign(url);
