import { PayloadAction } from '@reduxjs/toolkit';

import { createAppSlice } from 'state/global-state/utils';
import { LoyaltyUser } from 'state/loyalty/hooks/types';

import { IUserState } from './user.types';

export const initialState: IUserState = {
  loyaltyUser: null,
  loyaltyLoading: false,
};

export const userSlice = createAppSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<LoyaltyUser | null>) => {
      state.loyaltyUser = payload;
    },
    setLoyaltyLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyLoading = payload;
    },
  },
});
