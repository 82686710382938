import React, { FC } from 'react';

import { ListItemButton, ListItemContent } from './product-list-item.styled';
import { IProductListItemProps } from './types';

const Container: FC<Partial<IProductListItemProps>> = ({
  tabIndex,
  children,
  isClickable,
  onProductClick,
}) => {
  if (isClickable) {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onProductClick?.();
      }
    };

    return (
      <ListItemButton
        tabIndex={tabIndex}
        data-testid="product-list-item-button"
        onClick={onProductClick}
        onKeyDown={event => handleKeyDown(event)}
      >
        {children}
      </ListItemButton>
    );
  }
  return <ListItemContent data-testid="product-list-item-content">{children}</ListItemContent>;
};

export default Container;
