import React, { FC, useCallback, useMemo } from 'react';

import { NumberInput } from '@rbilabs/components-library/build/components/number-input';

import { useCdpContext } from 'state/cdp';

import { ModifierListItem } from './modifier-list-item';
import { IModifierTypeUIProps } from './types';
import {
  getModifierOptionMultiplier,
  getSingleSelectedOption,
  logProductModifierCustomization,
  transformSelectedOptionToSelections,
} from './utils';

export const ModifierNumeric: FC<IModifierTypeUIProps> = ({
  modifier,
  selections,
  onSelectionsChange,
}) => {
  const selectedOption = useMemo(() => getSingleSelectedOption(selections), [selections]);
  const selectedOptionIndex = useMemo(
    () => modifier.options.findIndex(opt => opt._key === selectedOption._key),
    [modifier.options, selectedOption._key]
  );
  const cdp = useCdpContext();
  const handleNumberInputChange = useCallback(
    (newOptionsIndex: number) => {
      const newSelectedOption = modifier.options[newOptionsIndex];
      onSelectionsChange(transformSelectedOptionToSelections(newSelectedOption));
      logProductModifierCustomization(
        newSelectedOption.name?.locale ?? '',
        modifier.name.locale,
        newSelectedOption._key,
        cdp
      );
    },
    [cdp, modifier.name.locale, modifier.options, onSelectionsChange]
  );
  const decrementAriaLabel =
    selectedOptionIndex === 0
      ? ''
      : `Select ${getModifierOptionMultiplier(modifier.options[selectedOptionIndex - 1])}`;
  const incrementAriaLabel =
    selectedOptionIndex === modifier.options.length - 1
      ? ''
      : `Select ${getModifierOptionMultiplier(modifier.options[selectedOptionIndex + 1])}`;
  const numberInputDisplayedValue = getModifierOptionMultiplier(
    modifier.options[selectedOptionIndex]
  );

  return (
    <ModifierListItem
      content={modifier}
      selectedOption={selectedOption}
      controls={
        <NumberInput
          data-testid="modifier-number-input"
          min={0}
          max={modifier.options.length - 1}
          hideNumberInput
          value={selectedOptionIndex}
          onChange={handleNumberInputChange}
          displayedValue={numberInputDisplayedValue}
          aria-label="Select modifier option"
          decrement-aria-label={decrementAriaLabel}
          increment-aria-label={incrementAriaLabel}
        />
      }
    />
  );
};
