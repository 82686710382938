/**
 * This file utilizes and enhances @sanity/block-content-to-react so that we can
 * more easily customize how the sanity block renderer maps its data structure to
 * react components.
 */
import React, { useMemo } from 'react';

import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';

import { IBaseProps } from '@rbi-ctg/frontend';
import { ISanityBlockContent, ISanityTypographyBlock } from '@rbi-ctg/menu';
import Picture from 'components/picture';

import { formatSanityLink } from './helpers';
import {
  ComponentOverride,
  IBlockContentOverrides,
  ISerializers,
  ITypographyBlockOverrides,
} from './types';

interface IDefaultBlockRenderer extends IBaseProps {
  node: {
    style?: string;
  };
}

interface IDefaultImageRenderer extends IBaseProps {
  node: {
    asset: {
      _ref: string;
    };
    caption?: string;
  };
}

interface IBlockRenderer extends IDefaultBlockRenderer {
  blockContentOverrides?: IBlockContentOverrides | ITypographyBlockOverrides;
}

// This code is based off of https://github.com/sanity-io/block-content-to-react#customizing-the-default-serializer-for-block-type
const BlockRenderer: React.FC<IBlockRenderer> = props => {
  const { node, children, blockContentOverrides = {} } = props;
  const { style = 'normal' } = node;

  // Match style to the block child tag override if it exists
  const MatchingTagOverride: ComponentOverride | undefined = blockContentOverrides[style];

  if (MatchingTagOverride) {
    return <MatchingTagOverride>{children}</MatchingTagOverride>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const StyledBlockContent = styled(BlockContent)`
  & > p {
    margin: ${({ withMargins }) => (withMargins ? undefined : 0)};
  }
`;

interface ISanityBlockRenderer extends IBaseProps {
  content?: ISanityBlockContent[] | ISanityTypographyBlock[] | null;
  serializers?: ISerializers;
  blockContentOverrides?: IBlockContentOverrides | ITypographyBlockOverrides;
  withMargins?: boolean;
}

// See https://github.com/sanity-io/block-content-to-react
// for more options
const createDefaultSerializers = (
  tagOverrides: IBlockContentOverrides | ITypographyBlockOverrides
): ISerializers => ({
  marks: {
    small: ({ children }: IBaseProps) => <small>{children}</small>,
    link: formatSanityLink,
  },
  types: {
    block: (props: IDefaultBlockRenderer) => (
      <BlockRenderer {...props} blockContentOverrides={tagOverrides} />
    ),
    blockContentImage: (props: IDefaultImageRenderer) => (
      <Picture
        image={{
          asset: {
            metadata: null,
            _id: props.node.asset._ref,
            __typename: 'SanityImageAsset',
          },
          hotspot: null,
          crop: null,
        }}
        alt={props.node.caption || ''}
      />
    ),
  },
});

const SanityBlockRenderer: React.FC<ISanityBlockRenderer> = ({
  className,
  content,
  serializers = {},
  blockContentOverrides = {},
  withMargins,
}) => {
  const allSerializers = useMemo(() => {
    const defaultSerializers = createDefaultSerializers(blockContentOverrides);
    return { ...defaultSerializers, ...serializers };
  }, [blockContentOverrides, serializers]);

  if (!content) {
    return null;
  }

  return (
    <StyledBlockContent
      withMargins={withMargins}
      className={className}
      blocks={content}
      serializers={allSerializers}
      renderContainerOnSingleChild
    />
  );
};

export default SanityBlockRenderer;
