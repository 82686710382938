import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { MParticleAdapter } from 'state/cdp/mParticle/mparticle-adapter';
import { CdpProviderTypes, ItrackEvent } from 'state/cdp/types';
import * as Braze from 'utils/braze';

import {
  BloomreachConsentAction,
  ConsentGroupCategory,
  ConsentGroups,
  OneTrustGlobalConsentState,
} from './types';

const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365;

export const ConsentGroupCategoryMap: Record<keyof ConsentGroups, ConsentGroupCategory> = {
  C0001: 'strictly_necessary',
  C0002: 'performance',
  C0003: 'functional',
  C0004: 'targeting',
};

export const getAllServicesInitialized = (
  cdpProvider: CdpProviderTypes,
  branchValidation: boolean
) => {
  return cdpProvider === CdpProviderTypes.MParticle
    ? !!window.mParticle && branchValidation && !!Braze.getSdk()
    : !!window.exponea;
};

export const getValidUntilMapping = (group: keyof ConsentGroups, timestamp: number) => {
  return group === 'C0002' ? 'unlimited' : timestamp + ONE_YEAR_IN_SECONDS;
};

export const handleBloomreachConsent = (
  otConsentGroups: ConsentGroups,
  trackEvent: ItrackEvent
) => {
  if (Object.keys(otConsentGroups).length) {
    const timestamp = Math.floor(new Date().getTime() / 1000);
    Object.keys(otConsentGroups).forEach((group: keyof ConsentGroups) => {
      trackEvent({
        name: CustomEventNames.CONSENT,
        type: EventTypes.Other,
        attributes: {
          category: ConsentGroupCategoryMap[group],
          action:
            otConsentGroups[group] === 1
              ? BloomreachConsentAction.ACCEPT
              : BloomreachConsentAction.REJECT,
          timestamp,
          ...(otConsentGroups[group] === 1
            ? { valid_until: getValidUntilMapping(group, timestamp) }
            : {}),
        },
      });
    });
  }
};

export const handleOTMparticleConsent = (
  cdpConsentState: number,
  brazeConsentState: number,
  branchConsentState: number,
  globalConsentState: OneTrustGlobalConsentState,
  branchUnavailable: boolean,
  isStrictlyNecessary: boolean
) => {
  const isOptOutState = globalConsentState === OneTrustGlobalConsentState.OPT_OUT;
  if ((cdpConsentState === 0 && !isStrictlyNecessary) || isOptOutState) {
    MParticleAdapter.setOptOut(true);
  }
  if (brazeConsentState === 0 || isOptOutState) {
    Braze.disableSDK();
  }
  if ((branchConsentState === 0 || isOptOutState) && !branchUnavailable) {
    window.branch.disableTracking(true);
  }
  if (cdpConsentState === 1) {
    if (!isStrictlyNecessary) {
      MParticleAdapter.setOptOut(false);
    }
  }
  // This is necessary because the native solution will disable and remove
  // Braze configurations from the browser.
  if (brazeConsentState === 1) {
    Braze.enableSDK();
    // Braze initialize should be called after enableSDK as mentioned in braze
    // documentation: https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/disabling_tracking
    // "Note that the Braze SDK does not need to be initialized to call disableSDK(), allowing you to disable tracking for fully anonymous users.
    // Conversely,enableSDK() does not initialize the Braze SDK so you must also call initialize() afterward to enable tracking."
    Braze.initialize();
  }
  if (branchConsentState === 1 && !branchUnavailable) {
    window.branch.disableTracking(false);
  }
};
