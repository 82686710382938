import React from 'react';

interface StoreCardCaptionProps {
  prefix?: string;
  suffix: string | JSX.Element;
}

export const StoreCardCaption: React.FC<StoreCardCaptionProps> = ({ prefix, suffix }) => {
  return (
    <>
      {prefix ?? null}
      {prefix && suffix ? <span role="separator"> &bull; </span> : null}
      {suffix}
    </>
  );
};
