import styled from 'styled-components';

import ActionButton from 'components/action-button';
import { brandFont } from 'components/layout/brand-font';
import { primitive } from 'styles/constants/primitives';

// Content
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: ${Styles.borderRadius};
  margin: ${primitive.$spacing4};
`;

export const Content = styled.div`
  position: relative;
  padding-block-start: 1rem;
  padding-block-end: 1.5rem;
  padding-inline: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${primitive.$white};
  gap: 1.5rem;
`;

export const QrCodeHeader = styled.div`
  width: 55%;
  min-width: 180px;
`;

export const IconContainer = styled.div`
  position: absolute;
  inset-block-start: ${primitive.$spacing4};
  inset-inline-end: ${primitive.$spacing4};
`;

export const Title = styled.p`
  text-align: center;
  font: ${brandFont.headerTwo};
  margin: 0;
`;

export const Footer = styled.div`
  background: ${primitive.$whiteOpacity30};
  padding: ${primitive.$spacing3};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ShortCodeText = styled.p`
  margin: 0;
`;

export const Code = styled.p`
  font: ${brandFont.hero};
  font-size: 1.75rem;
  margin: 0;
  margin-block-start: ${primitive.$spacing3};
  direction: ltr;
`;

// Resfresh Code
export const RefreshCodeButton = styled(ActionButton)`
  background: ${primitive.$white};
  margin: ${primitive.$spacing6};
  font-size: 1rem;
  height: 42px;
`;

// Resfresh Code
export const TryAgainCodeButton = styled(ActionButton)`
  background: ${primitive.$white};
  font-size: 1rem;
  height: 42px;
`;

// Skeleton
export const SkeletonContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;

export const SkeletonBox = styled.div`
  text-align: center;
`;

export const GenerateButton = styled(ActionButton)`
  flex: 1;
  margin-block-start: 1rem;
  font-size: 0.95rem;
  padding: 0.75rem 1rem;
`;
