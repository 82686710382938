import { IFeatureMenuQuery, useFeatureMenuQuery } from 'generated/sanity-graphql';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import { isCatering } from 'utils/service-mode';

interface IUseFeatureMenuValue {
  menuId: string;
  featureMenu: IFeatureMenuQuery['FeatureMenu'];
  featureMenuLoading: boolean;
}

export const useFeatureMenu = (): IUseFeatureMenuValue => {
  const featureMenuId = useFlag(LaunchDarklyFlag.AB_TESTING_MENU_PAGE);
  const { serviceMode } = useServiceModeContext();

  const { data, loading } = useFeatureMenuQuery({
    variables: { featureMenuId },
    skip: !featureMenuId,
  });

  const featureMenu = data?.FeatureMenu ?? null;
  const { cateringMenu, defaultMenu } = featureMenu || {};
  const featureMenuLoading = loading;

  const menuId: string = (isCatering(serviceMode) ? cateringMenu : defaultMenu)?._id || '';

  return {
    menuId,
    featureMenu,
    featureMenuLoading,
  };
};
