import { useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';

import {
  RefundIneligibilityReason,
  useCheckRefundEligibilityQuery,
} from '../../generated/rbi-graphql';

import {
  IUseCheckOrderEligibilityResponse,
  IUseRefundEligibilityInput,
  IUseRefundEligibilityResponse,
} from './types';

/*
 * Determines whether or not a user and the order permit performing
 * a customer initiated refund.
 *
 * Includes details about the refund eligibility along with
 * reason text for messaging.
 */
export const useRefundEligibility = ({
  orderId,
}: IUseRefundEligibilityInput): IUseRefundEligibilityResponse => {
  const { ineligibilityReason, isOrderEligibleForRefund, isDeterminingRefundEligibility } =
    useCheckOrderEligibilityQuery(orderId);

  const cdp = useCdpContext();

  const refundIneligibilityReason = useRefundIneligibilityReason(
    isOrderEligibleForRefund,
    ineligibilityReason
  );

  const isDeliveryActive = ineligibilityReason === RefundIneligibilityReason.ORDER_BEING_DELIVERED;

  const result: IUseRefundEligibilityResponse = useMemo(
    () => ({
      isOrderEligibleForRefund,
      isDeterminingRefundEligibility,
      refundIneligibilityReason,
      isDeliveryActive,
    }),
    [
      isOrderEligibleForRefund,
      isDeterminingRefundEligibility,
      refundIneligibilityReason,
      isDeliveryActive,
    ]
  );

  useEffect(() => {
    if (!isDeterminingRefundEligibility) {
      cdp.trackEvent({
        name: CustomEventNames.REFUND_ELIGIBILITY_CHECK,
        type: EventTypes.Other,
        attributes: {
          message: `Refund eligibility check results`,
          orderId,
          ineligibilityReason,
          ...result,
        },
      });
    }
  }, [ineligibilityReason, isDeterminingRefundEligibility, cdp, orderId, result]);

  return result;
};

const useCheckOrderEligibilityQuery = (
  orderId?: string | null
): IUseCheckOrderEligibilityResponse => {
  const { data, loading } = useCheckRefundEligibilityQuery({
    variables: { rbiOrderId: orderId ?? '' },
    skip: !orderId,
  });

  const ineligibilityReason = data?.checkRefundEligibility?.ineligibilityReason;
  const isOrderEligibleForRefund = data?.checkRefundEligibility?.eligible || false;

  return {
    ineligibilityReason,
    isOrderEligibleForRefund,
    isDeterminingRefundEligibility: loading,
  };
};

// Messaging to be displayed on the support form if the user is not allowed to obtain a refund.
const useRefundIneligibilityReason = (
  isOrderEligibleForRefund: boolean,
  ineligibilityReason?: RefundIneligibilityReason | null
): string | false => {
  const { formatMessage } = useIntl();

  switch (ineligibilityReason) {
    case RefundIneligibilityReason.ALREADY_REFUNDED:
      return formatMessage({ id: 'supportFormOrderAlreadyRefunded' });
    case RefundIneligibilityReason.ORDER_BEING_DELIVERED:
      return formatMessage({ id: 'supportFormDeliveryActive' });
    default:
      if (!isOrderEligibleForRefund) {
        return formatMessage({ id: 'supportFormRefundGenericError' });
      }
      return false;
  }
};
