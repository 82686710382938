import delv from 'dlv';

import { getName } from 'utils/attributes';

import { UpdatedUserAttributes } from '../types';
import { flattenCommunicationPreferences, getPushOptions, sanitizeValues } from '../utils';

export const bloomreachAttributes = (
  updatedAttributes: UpdatedUserAttributes = {},
  enablePushNotificiationsOnSignUp?: boolean
) => {
  const flattenedCommunicationPreferences = flattenCommunicationPreferences(
    updatedAttributes.communicationPreferences
  );
  const promotionalEmails = String(updatedAttributes.promotionalEmails);

  const pushOptions = getPushOptions(
    enablePushNotificiationsOnSignUp,
    updatedAttributes,
    promotionalEmails
  );
  delete updatedAttributes.communicationPreferences;
  const sanitizedValues = sanitizeValues({
    ...getName(updatedAttributes, { first_name: '', last_name: '' }),
    ...updatedAttributes,
    email_subscribe:
      delv(updatedAttributes, 'promotionalEmails', promotionalEmails).toLowerCase() === 'true'
        ? 'opted_in'
        : 'unsubscribed',
    ...pushOptions,
    communicationPreferences: flattenedCommunicationPreferences,
  });

  return {
    ...sanitizedValues,
  };
};
