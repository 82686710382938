import React from 'react';

import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMobileHeaderComponent } from 'state/mobile-header-nav';

import { AppHeaderContainer } from '../app-header-container';
import { Logo } from '../logo';

import { PrimaryAction } from './primary-action';
import { SecondaryAction } from './secondary-action';

const StandardMobileHeader: React.FC = () => {
  const isHamburgerMenuEnabled = useFlag(LaunchDarklyFlag.ENABLE_HAMBURGER_MENU_ON_MOBILE);

  return (
    <AppHeaderContainer data-mediaquery="headerMobile" data-testid="mobile-header">
      <PrimaryAction isHamburgerMenuEnabled={isHamburgerMenuEnabled} />
      <Logo />
      <SecondaryAction isHamburgerMenuEnabled={isHamburgerMenuEnabled} />
    </AppHeaderContainer>
  );
};

export const MobileHeader: React.FC = () => {
  const HeaderComponent = useMobileHeaderComponent();

  return HeaderComponent ? <HeaderComponent /> : <StandardMobileHeader />;
};
