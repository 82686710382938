import styled from 'styled-components';

import theme from './theme';

/**
 * A wrapper for `ListItem` components.
 *
 * A list is built from its building blocks. See Storybook for example.
 */
export const List = styled.ul`
  margin-block-start: 0;
  margin-block-end: 1rem;
  margin-inline: 0;
  padding-inline-start: 0;
  list-style: none;
`;

/**
 * Displays a styled list item with borders and padding.
 */
export const ListItem = styled.li<{ activeHighlight?: boolean }>`
  border-block-end: 1px solid ${Styles.color.grey.four};
  ${p => p.activeHighlight && `background-color: ${Styles.color.background}`};
  &:hover {
    background-color: ${Styles.color.background};
  }
`;

/**
 * Content spacing for a List item.
 *
 * This component exists if the entire contend needs to
 * be replaced for customization.
 */
export const ListItemContent = styled.div`
  padding: 1rem 0;
`;

/**
 * Styled title for ListItem content
 */
export const ListItemTitle = styled.span<{ activeHighlight?: boolean }>`
  width: calc(100% - 24px);
  display: block;
  font-family: ${Styles.fontFamily.base};
  font-weight: ${Styles.fontWeight.heavy};
  font-size: 1rem;
  color: ${theme.listItemTitleColor};
  ${p => p.activeHighlight && `color: ${Styles.color.primary}`};
`;

/**
 * Styled subtext for a ListItem's content
 */
export const ListItemSubtext = styled.span`
  display: block;
  font-size: 0.87rem;
  color: ${theme.listItemSubtextColor};
`;
