export type ImpactArgs = {
  style?: HapticsImpactStyle;
};

export type NotifyArgs = {
  type: HapticsNotificationType;
};

export type VibrateArgs = {
  duration: number;
};

export enum HapticsImpactStyle {
  Heavy = 'HEAVY',
  Medium = 'MEDIUM',
  Light = 'LIGHT',
}

export enum HapticsNotificationType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
