import { ReactNode } from 'react';

import { ServiceMode } from 'generated/graphql-gateway';

import { IWidgetElement } from '../loyalty-widget-components';

export enum LoyaltyInRestaurantRedemptionWidgets {
  MarketingTiles = 'marketingTileGroupWidget',
  ShortCodeWidget = 'shortCodeWidget',
  LegacyCodeWidget = 'legacyCodeWidget',
  Text = 'textWidget',
  QrCodeWidget = 'qrCodeWidget',
  QrAndShortCode = 'qrAndShortCodeWidget',
}

export interface LoyaltyInRestaurantRedemptionViewProps {
  widgets: Array<IWidgetElement>;
}

export interface IRefreshCodeProps {
  onRefreshClick: VoidFunction;
  isCodeLoading: boolean;
  children?: ReactNode;
}

export interface LoyaltyInRestaurantRedemptionDrawerProps {
  widgets: {
    topWidgets: Array<IWidgetElement>;
    bottomWidgets: Array<IWidgetElement>;
  };
}

export type IsServiceModeValid = (params: { serviceMode: ServiceMode | null }) => boolean;
