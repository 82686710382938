import { ApolloLink } from '@apollo/client';
import {
  getMainDefinition,
  hasDirectives,
  removeDirectivesFromDocument,
} from '@apollo/client/utilities';
import { DocumentNode, StringValueNode } from 'graphql';

import LaunchDarklyHelper from 'utils/launchdarkly';

const GATEWAY_DIRECTIVE = 'gateway';

export const stripGatewayDirective = new ApolloLink((operation, forward) => {
  if (!hasDirectives([GATEWAY_DIRECTIVE], operation.query)) {
    return forward(operation);
  }

  // Remove @gateway directive
  let strippedQuery = removeDirectivesFromDocument([{ name: GATEWAY_DIRECTIVE }], operation.query);
  if (strippedQuery) {
    operation.query = strippedQuery;
  }

  return forward(operation);
});

export const isGatewayRequest = (query: DocumentNode) => {
  const definition = getMainDefinition(query);
  const foundDirective = definition.directives?.find(
    directive => directive.name.value === GATEWAY_DIRECTIVE
  );

  // If no @gateway then false
  if (!foundDirective) {
    return false;
  }

  // Identify if directive looks like this: @gateway(flag: "enable-some-operation")
  const flagArg = foundDirective.arguments?.find(n => n.name.value === 'flag');
  if (!flagArg) {
    // no param found, so this always goes to Gateway
    return true;
  }

  const client = LaunchDarklyHelper.getInstance();
  if (!client?.launchDarkly) {
    return false;
  }
  const ldFlags = client.launchDarkly.allFlags();

  // Check if operation is enabled based on LD flag
  const flagName = (flagArg?.value as StringValueNode).value;
  return ldFlags[flagName] === true;
};
