import styled from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import { primitive } from 'styles/constants/primitives';

export const RadioCheckListContainer = styled.div`
  ul {
    padding-inline-start: ${primitive.$spacing4};
    li:not(:last-child) {
      border-block-end: 1px solid ${p => p.theme.token('border-color-default')};
    }
  }
`;

export const RadioCheckListItem = styled.button`
  background-color: transparent;
  border: none;
  align-items: center;
  display: flex;
  font: ${brandFont.copyOne};
  justify-content: space-between;
  padding-block: ${primitive.$spacing4};
  padding-inline-start: ${primitive.$spacing4};
  padding-inline-end: ${primitive.$spacing6};
  width: 100%;
  cursor: pointer;
`;
