import { routes } from 'utils/routing';

interface IMenuOptionDescriptor {
  _type: string;
  _id: string;
}

export const getMenuOptionId = (descriptor: IMenuOptionDescriptor) =>
  `${descriptor._type}-${descriptor._id}`;

export const getMenuOptionUrl = (descriptor: IMenuOptionDescriptor) =>
  `${routes.menu}/${getMenuOptionId(descriptor)}`;
