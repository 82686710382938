import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { BottomSheetPortal } from './bottom-sheet-portal';
import { AnchorElement } from './styles';
import { IBottomSheet, RefElement } from './types';

// This is important to guarantee that the bottom sheet will not have any space where is appended to
const SAFETY_PIXEL = 2;

export const BottomSheet = ({
  children,
  previewIconPrefix,
  previewIconSufix,
  title,
  titleExpanded,
  description,
  descriptionExpanded,
  showBottomSheet,
}: PropsWithChildren<IBottomSheet>) => {
  const [anchorHeight, setAnchorHeight] = useState(0);
  const anchorElement = useRef<RefElement>(null);
  const anchorElementRec = anchorElement.current?.getBoundingClientRect();
  const anchorElementPosition = anchorElementRec?.top;
  const isPortalRendered = !!anchorElementPosition;
  const totalWindowHeight = window.innerHeight;

  const drawerPositionOffset = anchorElementPosition
    ? totalWindowHeight - anchorElementPosition - SAFETY_PIXEL
    : 0;

  const handleCalculatedPreviewHeight = useCallback((previewHeight: number) => {
    const fixedMargin = 8;
    setAnchorHeight(previewHeight + fixedMargin);
  }, []);

  return (
    <>
      <AnchorElement
        ref={anchorElement}
        previewMargin={anchorHeight}
        showBottomSheet={showBottomSheet}
      />
      {isPortalRendered && showBottomSheet && (
        <BottomSheetPortal
          bottomSheetContent={children}
          previewIconPrefix={previewIconPrefix}
          previewIconSufix={previewIconSufix}
          title={title}
          titleExpanded={titleExpanded}
          description={description}
          descriptionExpanded={descriptionExpanded}
          drawerPositionOffset={drawerPositionOffset}
          onCalculatePreviewHeight={handleCalculatedPreviewHeight}
        />
      )}
    </>
  );
};
