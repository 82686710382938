import { ApolloError } from '@apollo/client';

import { ICartEntry } from '@rbi-ctg/menu';
import {
  BenefitType,
  IBenefit,
  IBenefitSwap,
  ILoyaltyUserQuery,
  IQuest,
  IRewardFragment,
  IRuleConditionEvaluation,
  LoyaltyTierKey,
  OfferRedemptionType,
  PaymentMethod,
  useLoyaltyUserOffersQuery,
  useLoyaltyUserQuery,
} from 'generated/graphql-gateway';
import {
  ILoyaltyOfferConfigsQuery,
  INotificationDetailsFragment,
  IRewardOrSystemwideOfferOrSystemwidePromotion,
  useFeatureSortedLoyaltyOffersQuery,
} from 'generated/sanity-graphql';
import { IUnlockedIncentiveModalStatus } from 'state/loyalty/hooks/use-loyalty-quests';

import { IEngineRewardsMap, LoyaltyAppliedOffer } from '../types';

export type LoyaltyUser = ILoyaltyUserQuery['loyaltyUser'];

export type AvailableRewards = {
  [id: string]: IRewardFragment;
};

export interface IUseLoyalty {
  loyaltyEnabled: boolean;
  loyaltyLoading: boolean;
  loyaltyUser: LoyaltyUser | null;
  refetchLoyaltyUser: ReturnType<typeof useLoyaltyUserQuery>['refetch'];
  claimPointsEnabled: boolean;
}

export type UseLoyaltyRewards = (loyaltyUser: LoyaltyUser | null) => IUseLoyaltyRewards;

export interface IAppliedReward {
  timesApplied: number;
  rewardId: string;
  minimumSpend?: number | null;
  minimumTotalSpend?: number | null;
  pointCost: number;
  rewardBenefitId: string;
  sanityId?: string;
  rewardPrice?: number;
}

export interface IAppliedRewards {
  [cartId: string]: IAppliedReward;
}
export interface IParsedLoyaltyReward {
  cartId: string;
  rewardId: string;
  timesApplied: number;
  sanityId?: string;
  price?: number;
}

export interface IParseAppliedRewards {
  appliedRewardsArray: IParsedLoyaltyReward[];
  timesRewardApplied: { [rewardId: string]: number };
}
export interface IUseLoyaltyOffers {
  queryLoyaltyUserStandardOffers: (options: IQueryLoyaltyUserOffersOptions) => void;
  queryLoyaltyUserSurpriseOffers: (options: IQueryLoyaltyUserOffersOptions) => void;
  loyaltySurpriseOffersEnabled: boolean;
  queryLoyaltyOffers: (options: IQueryLoyaltyOffersOptions) => void;
  evaluateLoyaltyUserIncentives: (
    loyaltyId: string,
    appliedLoyaltyOffers: LoyaltyAppliedOffer[],
    cartEntries?: ICartEntry[],
    appliedRewards?: IAppliedRewards,
    subtotalAmount?: number,
    totalAmount?: number,
    paymentMethod?: PaymentMethod | null
  ) => void;
  refetchCmsOffers: ReturnType<typeof useFeatureSortedLoyaltyOffersQuery>['refetch'];
  refetchUserOffers: ReturnType<typeof useLoyaltyUserOffersQuery>['refetch'];
}
export interface IUseLoyaltyRewards {
  getAvailableRewardFromCartEntry: (cartEntry: ICartEntry) => IRewardFragment | undefined;
  refetchRewards: (loyaltyId: string) => void;
  engineRewardsMap: IEngineRewardsMap;
  isLimitRewardRedemptionPerOrderReached: boolean;
  rewardLimitePerOrder: number | null | undefined;
  enableLoyaltyTiers: boolean;
  isRewardTierLocked: (
    engineReward: IRewardFragment,
    currentUserTier: LoyaltyTierKey | null | undefined
  ) => boolean;
}
export interface IUseLoyaltyRewardsFeedback {
  loading: boolean;
  error?: ApolloError;
}

export interface IRewardAction {
  cartId: string;
  rewardBenefitId: string;
  rewardObj?: IRewardFragment;
  quantity?: number;
  minimumSpend?: number | null;
  minimumTotalSpend?: number | null;
}

export interface IRemoveAppliedRewardsAction {
  cartIds: string[];
}

// Swap type guard
export const isSwap = (benefit: IBenefit): benefit is IBenefitSwap => {
  return (benefit as IBenefitSwap)?.value && benefit.type === BenefitType.SWAP;
};

export type NotificationDetails = INotificationDetailsFragment & {
  id: IRewardOrSystemwideOfferOrSystemwidePromotion['loyaltyEngineId'];
};

export interface IUseLoyaltyNotifications {
  notifications: NotificationDetails[];
  loadingNotifications: boolean;
}
export interface IQueryLoyaltyUserOffersOptions {
  loyaltyId: string;
  cartEntries?: ICartEntry[];
  appliedRewards?: IAppliedRewards;
  subtotalAmount?: number;
  totalAmount?: number;
  redemptionTypes?: OfferRedemptionType[];
  appliedLoyaltyOffers?: LoyaltyAppliedOffer[];
}

export interface IQueryLoyaltyOffersOptions {
  subtotalAmount?: number;
  totalAmount?: number;
}

export type LoyaltyNotifications = (loyaltyUser: LoyaltyUser | null) => IUseLoyaltyNotifications;

export type RedeemableItem = {
  readonly id: string;
};

export type RedeemableItems = readonly (RedeemableItem | null)[] | null;

export type IIncentiveEvaluationResult = Omit<IRuleConditionEvaluation, '__typename'>;

export type IncentiveEvaluationMap = { [incentiveId: string]: IIncentiveEvaluationResult[] };

export enum IncentiveEvaluationErrorCodes {
  UNSATISFIED_CART_REQUIREMENTS = 'unsatisfied-cart-requirements',
  UNSATISFIED_CART_REQUIREMENTS_EXCLUSION = 'unsatisfied-cart-requirements-exclusion',
  WITHIN_COOL_DOWN_PERIOD = 'within-cool-down-period',
  EXCEEDS_LIMIT_PER_ORDER = 'exceeds-limit-per-order',
  INVALID_SERVICE_MODE = 'invalid-service-mode',
  INVALID_STORE_ID = 'invalid-store-id',
  NOT_FIRST_ORDER = 'not-first-order',
  NOT_USER_BIRTHDAY = 'not-user-birthday',
  INVALID_END_DATE = 'invalid-end-date',
  INVALID_START_DATE = 'invalid-start-date',
  INVALID_DAY_OF_THE_WEEK = 'invalid-day-of-the-week',
  INVALID_STACKING = 'invalid-stacking',
  EXCEEDS_MAX_REDEMPTION = 'exceeds-max-redemption',
  BELOW_MINIMUM_SPEND = 'below-minimum-spend',
  BELOW_MINIMUM_TOTAL_SPEND = 'below-minimum-total-spend',
  INVALID_PAYMENT_METHOD = 'invalid-payment-method',
  INSUFFICIENT_POINT_BALANCE = 'insufficient-point-balance',
  INVALID_SWAPS = 'invalid-swaps',
  INVALID_TIME_SPAN = 'invalid-time-span',
  USER_ENROLLMENT = 'user-enrollment',
  OUT_OF_DAY_PART = 'out-of-day-part',
  NOT_AVAILABLE_IN_STORE = 'not-available-in-store',
  OFFER_NOT_AVAILABLE = 'offer-not-available',
  AUTHENTICATION_REQUIRED = 'missing-user',
}

export const FixableEvaluationErrors = new Set([
  IncentiveEvaluationErrorCodes.BELOW_MINIMUM_SPEND,
  IncentiveEvaluationErrorCodes.BELOW_MINIMUM_TOTAL_SPEND,
  IncentiveEvaluationErrorCodes.UNSATISFIED_CART_REQUIREMENTS,
  IncentiveEvaluationErrorCodes.INVALID_PAYMENT_METHOD,
  IncentiveEvaluationErrorCodes.INVALID_SERVICE_MODE,
  IncentiveEvaluationErrorCodes.AUTHENTICATION_REQUIRED,
]);

export interface IUseLoyaltyUserState {
  loyaltyUser: LoyaltyUser;
  error: ApolloError | undefined;
  refetch: ReturnType<typeof useLoyaltyUserQuery>['refetch'];
  loading: boolean;
}

export interface IUseLoyaltyQuests {
  userAvailableQuests: Partial<IQuest>[] | undefined;
  questsLoading: boolean;
  challengeIncentiveSelected: boolean | undefined;
  challengeIncentives: ILoyaltyOfferConfigsQuery['allConfigOffer'] | undefined;
  onChallengeIcentiveSelect: (challengeIncentiveSelected: boolean | undefined) => void;
  onChangeUnlockedIncentivesModalVisibilityStatus: (
    unlockeIncentiveData: IUnlockedIncentiveModalStatus
  ) => void;
  onChangeSignUpModalOfferVisibility: (visibility: boolean) => void;
  unlockedIncentivesModalVisibilityStatus: IUnlockedIncentiveModalStatus;
  signUpModalOfferVisibility: boolean | undefined;
}

export type UseLoyaltyQuests = (loyaltyId: string) => IUseLoyaltyQuests;
