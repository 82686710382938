import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIdCounter } from '@rbilabs/components-library/build/hooks/use-id-counter';
import isUndefined from 'lodash/isUndefined';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ActionButtonSizes } from 'components/action-button';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePrevious } from 'hooks/use-previous';
import { selectors, useAppSelector } from 'state/global-state';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyRewardsFeedback } from 'state/loyalty/hooks/use-loyalty-rewards-feedback';
import { isCartDiscountOffer } from 'state/loyalty/utils';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';

import { CartPreview } from './cart-preview';
import defaultConfig from './config';
import { ActionButtonStyled } from './styled/cart-button';

export const CartPreviewToggleButton = () => {
  const {
    cartEntries,
    calculateCartTotal,
    checkoutPriceLimit,
    canUserCheckout,
    isCartTotalGreaterThanDiscountOfferValue,
    isCartEmpty,
    cartPreviewEntries,
    numCartPreviewEntries,
  } = useOrderContext();
  useLoyaltyRewardsFeedback(cartEntries);
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const cartPreviewID = useIdCounter('cartPreview');
  const [visible, setVisible] = useState(false);
  const { formatCurrencyForLocale } = useUIContext();
  const cartButton = useRef<HTMLButtonElement>(null);
  const hide = useCallback(() => setVisible(false), []);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const appliedLoyaltyRewardWithDiscountOffer = useAppSelector(
    selectors.loyalty.selectAppliedDiscountRewards
  );
  const loyaltyRewardsMap = useAppSelector(selectors.loyalty.selectAvailableLoyaltyRewardsMap);
  const loyaltyAppliedDiscountCmsOffer = useAppSelector(
    selectors.loyalty.selectDiscountAppliedCmsOffer
  );
  const loyaltyAppliedDiscountPersonalizedOffer = useAppSelector(
    selectors.loyalty.selectDiscountAppliedPersonalizedOffer
  );
  const toggleVisible = useCallback(() => setVisible(state => !state), []);

  const loyaltyAppliedDiscountOffer =
    loyaltyAppliedDiscountCmsOffer || loyaltyAppliedDiscountPersonalizedOffer;

  const prevDiscountAppliedOffers = usePrevious(loyaltyAppliedDiscountOffer);

  const discountOffers = useMemo(
    () => (loyaltyAppliedDiscountOffer ? [loyaltyAppliedDiscountOffer] : []),
    [loyaltyAppliedDiscountOffer]
  );

  const cartDiscountOffers = discountOffers.filter(offer => isCartDiscountOffer(offer));

  useEffectOnUpdates(() => {
    if (visible || isUndefined(prevDiscountAppliedOffers)) {
      return;
    }
    setVisible(true);

    // Needs setTimeout to work, might be due to conflict
    // w/reach-router's focus management since this is
    // typically triggered at the same time as a route change?
    window.setTimeout(() => {
      cartButton.current?.focus();
    }, 100);
  }, [cartEntries.length, discountOffers?.length]);

  useEffect(() => {
    if (!cartEntries.length) {
      LocalStorage.removeItem(StorageKeys.FREE_MODAL_ITEMS_SHOWED_IN_CART);
    }
  }, [cartEntries]);

  const onCancelAlert = () => {
    navigate(routes.menu);
  };

  const [OfferCheckoutConfirmationModal, openOfferCheckoutConfirmationModal] = useDialogModal({
    showCancel: true,
    onDismiss: onCancelAlert,
    onCancel: onCancelAlert,
    modalAppearanceEventMessage: 'Valid offers',
  });

  const total = calculateCartTotal();

  return (
    <>
      <ActionButtonStyled
        variant={defaultConfig.buttonVariant}
        size={ActionButtonSizes.SMALL}
        data-testid="cart-button-desktop"
        onClick={toggleVisible}
        className="ignore-react-onclickoutside"
        aria-expanded={visible}
        aria-controls={cartPreviewID}
        ref={cartButton}
        title="Shopping Cart"
        startIcon={<Icon icon="cart" color="icon-default" aria-hidden />}
        endIcon={false}
      >
        <VisuallyHidden>{formatMessage({ id: 'shoppingCartPreview' })}</VisuallyHidden>
        {defaultConfig.showNumItems ? numCartPreviewEntries : formatCurrencyForLocale(total)}
        <VisuallyHidden>
          {defaultConfig.showNumItems
            ? formatMessage({ id: 'items' })
            : formatMessage({ id: 'cartTotal' })}
        </VisuallyHidden>
      </ActionButtonStyled>
      {visible && (
        <CartPreview
          id={cartPreviewID}
          calculateCartTotal={calculateCartTotal}
          checkoutPriceLimit={checkoutPriceLimit}
          discountOffers={cartDiscountOffers}
          isStoreOpenAndAvailable={isStoreOpenAndAvailable}
          openOfferCheckoutConfirmationModal={openOfferCheckoutConfirmationModal}
          canUserCheckout={canUserCheckout}
          isCartTotalGreaterThanDiscountOfferValue={isCartTotalGreaterThanDiscountOfferValue}
          isCartEmpty={isCartEmpty}
          cartPreviewEntries={cartPreviewEntries}
          cartEntries={cartEntries}
          navigate={navigate}
          loyaltyEnabled={loyaltyEnabled}
          appliedLoyaltyRewards={appliedLoyaltyRewards}
          appliedLoyaltyRewardWithDiscountOffer={appliedLoyaltyRewardWithDiscountOffer}
          loyaltyRewardsMap={loyaltyRewardsMap}
          hideCartPreview={hide}
          cartButton={cartButton}
        />
      )}
      <OfferCheckoutConfirmationModal
        heading={formatMessage({ id: 'headsUpModalTitle' })}
        body={formatMessage({ id: 'redeemOfferConfirmation' })}
        confirmLabel={formatMessage({ id: 'continueWithoutOffer' })}
        cancelLabel={formatMessage({ id: 'returnToMenu' })}
      />
    </>
  );
};
