import { primitive } from '@rbilabs/components-library/build/designTokens/primitives';
import { styled } from 'styled-components';

import { brandFont } from 'components/layout/brand-font';

export const QueueModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-block-start: 0.3rem;
`;

export const ModalInner = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${p => p.theme.token('background-default')};

  h2 {
    text-align: center;
    text-transform: ${Styles.textTransform.headlines};
    font: ${brandFont.headerOne};
  }
`;

export const ModalHead = styled.div`
  position: fixed;
  width: 100%;
  height: 5.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: ${Styles.zIndex.top};
  border-block-end: 1px solid ${p => p.theme.token('border-color-default')};
  background-color: ${p => p.theme.token('background-default')};

  ${Styles.desktop} {
    flex-direction: row;
  }

  h2 {
    margin-block: 0;
    margin-inline: 0;
  }
`;

export const QueueDescriptionContainer = styled.div`
  margin: auto;
  ${Styles.desktopLarge} {
    max-width: ${Styles.layout.smallerSectionMaxWidth};
    padding: 0 2.75rem;
  }
`;

export const QueueDescription = styled.div`
  background-color: ${Styles.color.background};
  border-radius: ${Styles.borderRadius};
  padding-block: 1.75rem;
  padding-inline: 1.75rem;
  display: grid;
  justify-items: center;
`;

export const ExitQueueButtonContainer = styled.div`
  width: 200px;
  margin-block-start: 1rem;
  margin-block-end: 1.5rem;
`;

export const PositionContainer = styled.div`
  overflow: auto;
  background-color: ${Styles.color.cardBackground};
  border-radius: 20px;
  padding-block: 1rem;
  padding-inline: 1rem;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: ${Styles.fontWeight.heavy};
  font-size: 1.5rem;
  font-family: ${Styles.fontFamily.base};
  text-align: center;
  margin-block: ${primitive.$spacing2};
  margin-inline: 0;
`;

export const Subtitle = styled.p`
  font-weight: ${Styles.fontWeight.normal};
  font-size: 1rem;
  font-family: ${Styles.fontFamily.body};
  text-align: center;
  margin-block: ${primitive.$spacing2};
  margin-inline: 0;
`;

export const StyledBlockContent = styled.p`
  font-weight: ${Styles.fontWeight.normal};
  font-size: 1.125rem;
  font-family: ${Styles.fontFamily.body};
  text-align: center;
  margin-block: ${primitive.$spacing0};
  margin-inline: ${primitive.$spacing0};
`;

export const StyledPosition = styled.h1`
  font-weight: ${Styles.fontWeight.heavy};
  font-size: 7rem;
  font-family: ${Styles.fontFamily.base};
  text-align: center;
  margin-block: ${primitive.$spacing0};
  margin-inline: ${primitive.$spacing0};
  color: ${Styles.color.primary};
`;

export const StyledEstimates = styled.h2`
  font-weight: ${Styles.fontWeight.heavy};
  font-size: 2rem;
  font-family: ${Styles.fontFamily.base};
  text-align: center;
  color: ${Styles.color.grey.one};
  margin-block: ${primitive.$spacing2};
  margin-inline: 0;
`;

export const StyledStoreCard = styled.div`
  position: relative;
  width: 100%;
  margin-block-start: 1rem;
  margin-block-end: 0.5rem;
  background: ${Styles.color.cardBackground};
  padding-block: 1rem;
  padding-inline: 1.125rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
`;

export const StreetAddress = styled.div`
  color: ${Styles.color.black};
  font-weight: ${Styles.fontWeight.heavy};
  font-size: 1.1rem;
`;

export const Address = styled.div`
  color: ${Styles.color.black};
  text-transform: capitalize;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
