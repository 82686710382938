import { useCallback, useRef, useState } from 'react';

import { isFunction, isNil } from 'lodash';

import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { parseStringifiedJSON } from 'utils/parse-string';

import { IUseLocalStorageState, IUseLocalStorageStateParams } from './types';

const getDataFromStorage = <TData = object>(key: StorageKeys): TData | null => {
  const storedValue = LocalStorage.getItem(key);
  return parseStringifiedJSON({ value: storedValue });
};

const setDataStorage = <TData = object>(key: StorageKeys, data: TData) => {
  // is a nullish value
  if (!isNil(data)) {
    LocalStorage.setItem(key, JSON.stringify(data));
  }
};

const clearDataStorage = (key: StorageKeys) => LocalStorage.removeItem(key);

/**
 * useLocalStorageData reads data from LS and makes sync with internal state.
 */
export const useLocalStorageState = <TData = object>({
  key,
  updateLocalStorageOnMount,
  defaultReturnValue,
}: IUseLocalStorageStateParams<TData>): IUseLocalStorageState<TData> => {
  const defaultValueRef = useRef(defaultReturnValue);
  const [storedData, _setStoredData] = useState<TData>(() => {
    let data = getDataFromStorage<TData>(key);

    if (updateLocalStorageOnMount) {
      const { shouldUpdate, newState = defaultValueRef.current } = updateLocalStorageOnMount(data);

      if (shouldUpdate) {
        data = newState;

        if (newState) {
          setDataStorage(key, newState);
        } else {
          clearDataStorage(key);
        }
      }
    }

    return data || defaultValueRef.current;
  });

  const setStoredData = useCallback(
    (newDataUpdate: TData | ((prev: TData) => TData)) => {
      _setStoredData((prevState: TData) => {
        const newData = isFunction(newDataUpdate) ? newDataUpdate(prevState) : newDataUpdate;

        setDataStorage<TData>(key, newData);
        return newData;
      });
    },
    [key]
  );

  const clearStoredData = useCallback(() => {
    _setStoredData(defaultValueRef.current);
    clearDataStorage(key);
  }, [key]);

  return [storedData, setStoredData, clearStoredData];
};
