import React from 'react';

import { useFlag } from 'state/launchdarkly';
import { getConfigValue, isWeb } from 'utils/environment';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { CookieBannerContainerMemo as DefaultCookieBannerContainerMemo } from './default-cookie-banner';
import { OneTrustCookieBannerMemo } from './one-trust-cookie-banner';
import { CookieBannerType } from './types';

const banners: Record<CookieBannerType, React.FC> = {
  [CookieBannerType.RBI_COOKIE_BANNER]: DefaultCookieBannerContainerMemo,
  [CookieBannerType.ONE_TRUST_COOKIE_BANNER]: OneTrustCookieBannerMemo,
};

interface ICookieBannerProps {
  hasUserDeniedTracking: boolean;
}

export const CookieBanner = (props: ICookieBannerProps) => {
  const enableMobileCookieBanner = useFlag(LaunchDarklyFlag.ENABLE_MOBILE_COOKIE_BANNER);
  const { cookieBanner }: { cookieBanner: string } = getConfigValue('cookieBanners');
  const isConfigured = Boolean(cookieBanner);
  const showCookieBanner = isWeb || enableMobileCookieBanner;

  if (!showCookieBanner || !isConfigured || props.hasUserDeniedTracking) {
    return null;
  }

  const ConfiguredCookieBanner =
    banners[cookieBanner as CookieBannerType] || DefaultCookieBannerContainerMemo;

  return <ConfiguredCookieBanner />;
};
