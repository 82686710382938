import React, { createContext, useCallback, useContext, useRef } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

interface ScrollToOptions {
  x?: number;
  y?: number;
  animated?: boolean;
}

type ScrollToHandler = (options: ScrollToOptions) => void;

export interface IScrollContext {
  scrollRef: React.MutableRefObject<HTMLDivElement | null>;
  scrollTo: ScrollToHandler;
}

export const ScrollContext = createContext<IScrollContext>({} as IScrollContext);
export const useScrollContext = () => useContext(ScrollContext);

export default ScrollContext.Consumer;

export const ScrollProvider = ({ children }: IBaseProps) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  // If no coordinates given, default scroll to top
  const scrollTo: ScrollToHandler = useCallback(
    ({ x, y, animated } = { x: 0, y: 0, animated: true }) =>
      scrollRef.current?.scrollTo({ left: x, top: y, behavior: animated ? 'smooth' : 'instant' }),
    []
  );

  return (
    <ScrollContext.Provider
      value={{
        scrollRef,
        scrollTo,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
