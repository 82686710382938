import { useAppDownloadQuery } from 'generated/sanity-graphql';

export const useAppDownload = (id?: string) => {
  const appDownloadId = id ?? '';
  const { data, loading } = useAppDownloadQuery({
    variables: {
      appDownloadId,
    },
    skip: !appDownloadId,
  });

  const appDownload = data?.AppDownload;

  return {
    appDownload,
    appDownloadLoading: loading,
  };
};
