import styled from 'styled-components';

export const EmptyCartMessage = styled.h3`
  color: ${Styles.color.grey.one};
  display: flex;
  font-size: 1.125rem;
  font-weight: ${Styles.fontWeight.normal};
  font-family: ${Styles.fontFamily.body};
  justify-content: center;
  padding-block-start: 0;
  padding-block-end: 1rem;
  padding-inline: 0;
`;
