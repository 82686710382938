import React, { createContext, useContext, useRef } from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import {
  IGetEncryptionDetailsMutation,
  IPrepaidsMergeInput,
  UserAccountsDocument,
  useAddCreditAccountMutation,
  useGetEncryptionDetailsMutation,
} from 'generated/rbi-graphql';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { useAuthContext } from 'state/auth';
import { useAuthGuestContext } from 'state/auth-guest';
import { useCdpContext } from 'state/cdp';
import { useLocale } from 'state/intl';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import { RedirectData, ThreeDSType } from 'state/payment/hooks/types';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { IAdyenPaymentState, IPaymentPayload, IPaymentState } from 'utils/payment';

import usePayment from './hooks/use-payment';
import {
  IAddPaymentMethodOptions,
  IPaymentDetails,
  IPaymentMethod,
  IReloadPrepaidCard,
} from './types';

interface ICurrentBalance {
  currentBalance: number;
  fdAccountId?: string | null;
}

export interface IPaymentContext {
  addPaymentMethod: (
    method: IPaymentPayload,
    options?: IAddPaymentMethodOptions,
    threeDSChallengeTokenResponse?: string | null
  ) => Promise<string>;
  checkoutPaymentMethod: IPaymentMethod | undefined;
  checkoutPaymentMethodId: string;
  canUseApplePay: boolean;
  canUseGooglePay: boolean;
  cleanThreeDSFlow: VoidFunction;

  clearPaymentValues(): void;

  defaultPaymentMethodId: string;
  defaultReloadPaymentMethodId: string;
  deletePaymentMethod: (accountId: string) => Promise<void>;
  getBalanceFromPaymentMethods: (prepaidPaymentMethod: IPaymentMethod) => number;
  getCounter?: () => Promise<void>;
  getEncryptionDetails: () => Promise<
    Pick<
      IGetEncryptionDetailsMutation['encryptionDetails'],
      'fdPublicKey' | 'fdApiKey' | 'fdAccessToken' | 'fdCustomerId'
    >
  >;
  getPaymentMethods: () => Promise<void>;
  getPrepaidPaymentMethod: () => IPaymentMethod | null;
  getPrepaidCardNumber: () => string | null;
  hasGetPaymentMethodsError: boolean;
  isAdyen: boolean;
  isCheckoutDotCom: boolean;
  isCybersource: boolean;
  isEvertec: boolean;
  isFirstData: boolean;
  isFirstpay: boolean;
  isVrPayment: boolean;
  isOrbital: boolean;
  isPayMark: boolean;
  isPaycomet: boolean;
  isHostedPage: boolean;
  isFreeOrder: (totalCents: number) => boolean;
  isFreeOrderPayment: boolean;
  loading: boolean;
  mergePrepaidCardBalances: (input: IPrepaidsMergeInput) => Promise<ICurrentBalance>;
  allPaymentMethods: IPaymentMethod[];
  paymentMethods: IPaymentMethod[];
  paymentValues: IPaymentDetails;
  paymentProcessor: string | null | undefined;
  prepaidReloadPaymentMethodId: string;
  redirectResult: string | undefined;
  setRedirectResult: (redirectResult: string) => void;
  redirectData: RedirectData | undefined;
  setRedirectData: (redirectData: RedirectData) => void;
  isPending: boolean;
  setIsPending: (isPending: boolean) => void;
  reloadPrepaidCard: (input: IReloadPrepaidCard) => Promise<number | undefined>;
  setCheckoutPaymentMethodId: (accountId: string) => void;
  setDefaultPaymentMethodId: (accountId: string) => void;
  setDefaultReloadPaymentMethodId: (accountId: string) => void;
  setPrepaidReloadPaymentMethodId: (accountId: string) => void;
  setSelected?: (fdAccountId: string) => void;
  storePaymentValues: (data: IPaymentDetails) => void;
  threeDS: {
    iframeContent?: string | null;
    transactionId?: string | null;
    activeFlow?: ThreeDSType | null;
    acsUrl?: string | null;
    challengeRequest?: string | null;
    isChallengeRequest: () => boolean;
    isThreeDSMethodFlow: () => boolean;
    setThreeDSChallengeTokenResponse: (threeDSChallengeTokenResponse: string) => void;
    threeDSChallengeTokenResponse: string | null;
  };
  transformPaymentValues: ({
    paymentValues,
    paymentFieldVariations,
  }: {
    paymentValues: IPaymentState | IAdyenPaymentState;
    paymentFieldVariations: PaymentFieldVariations;
  }) => IPaymentPayload;
}

export const PaymentContext = createContext<IPaymentContext>({} as IPaymentContext);
export const usePaymentContext = () => useContext(PaymentContext);

const clearPersistedPaymentValues = (): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, {});
};

const persistPaymentValues = (paymentValues: IPaymentDetails): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, paymentValues);
};

const retrievePersistedPaymentValues = (): IPaymentDetails | null => {
  return LocalStorage.getItem(StorageKeys.PAYMENT);
};

export function PaymentProvider(props: IBaseProps) {
  const { feCountryCode } = useLocale();
  const { updateUserInfo, user } = useAuthContext();
  const { isAuthenticated: isGuestAuthenticated } = useAuthGuestContext();
  const [getEncryptionDetailsMutation] = useGetEncryptionDetailsMutation();
  const [addCreditAccountMutation] = useAddCreditAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });
  const cdp = useCdpContext();

  const paymentValues = useRef({});

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Payment Error',
  });

  const storePaymentValues = (data: IPaymentDetails): void => {
    paymentValues.current = data;
    persistPaymentValues(data);
  };

  const clearPaymentValues = () => {
    paymentValues.current = {};
    clearPersistedPaymentValues();
  };

  useEffectOnce(() => {
    const values = retrievePersistedPaymentValues();
    if (values) {
      paymentValues.current = values;
    }
  });

  const {
    addPaymentMethod,
    canUseApplePay,
    canUseGooglePay,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    deletePaymentMethod,
    getEncryptionDetails,
    getBalanceFromPaymentMethods,
    getPaymentMethods,
    getPrepaidCardNumber,
    getPrepaidPaymentMethod,
    hasGetPaymentMethodsError,
    isAdyen,
    isCheckoutDotCom,
    isCybersource,
    isEvertec,
    isFirstData,
    isFirstpay,
    isVrPayment,
    isOrbital,
    isPayMark,
    isPaycomet,
    isHostedPage,
    isChallengeRequest,
    isThreeDSMethodFlow,
    loading,
    mergePrepaidCardBalances,
    allPaymentMethods,
    paymentMethods,
    paymentProcessor,
    prepaidReloadPaymentMethodId,
    reloadPrepaidCard,
    redirectResult,
    setRedirectResult,
    redirectData,
    setRedirectData,
    isPending,
    setIsPending,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId,
    setPrepaidReloadPaymentMethodId,
    threeDSAcsUrl,
    threeDSActiveFlow,
    threeDSChallengeRequest,
    threeDSIframeContent,
    threeDSTransactionId,
    transformPaymentValues,
    cleanThreeDSFlow,
    setThreeDSChallengeTokenResponse,
    threeDSChallengeTokenResponse,
    isFreeOrder,
    isFreeOrderPayment,
  } = usePayment({
    getEncryptionDetailsMutation,
    cdp,
    openErrorDialog,
    user,
    isGuestAuthenticated: isGuestAuthenticated(),
    updateUserInfo,
    addCreditAccountMutation,
  });

  return (
    <PaymentContext.Provider
      value={{
        addPaymentMethod,
        canUseApplePay,
        canUseGooglePay,
        checkoutPaymentMethod,
        checkoutPaymentMethodId,
        cleanThreeDSFlow,
        clearPaymentValues,
        defaultPaymentMethodId,
        defaultReloadPaymentMethodId,
        deletePaymentMethod,
        getPaymentMethods,
        getBalanceFromPaymentMethods,
        getPrepaidPaymentMethod,
        getPrepaidCardNumber,
        getEncryptionDetails,
        hasGetPaymentMethodsError,
        isAdyen,
        isCheckoutDotCom,
        isCybersource,
        isEvertec,
        isFirstData,
        isFirstpay,
        isVrPayment,
        isOrbital,
        isPayMark,
        isPaycomet,
        isHostedPage,
        loading,
        mergePrepaidCardBalances,
        allPaymentMethods,
        paymentMethods,
        paymentProcessor,
        paymentValues: paymentValues.current,
        prepaidReloadPaymentMethodId,
        redirectResult,
        setRedirectResult,
        redirectData,
        setRedirectData,
        isPending,
        setIsPending,
        reloadPrepaidCard,
        setCheckoutPaymentMethodId,
        setDefaultPaymentMethodId,
        setDefaultReloadPaymentMethodId,
        setPrepaidReloadPaymentMethodId,
        storePaymentValues,
        threeDS: {
          iframeContent: threeDSIframeContent,
          transactionId: threeDSTransactionId,
          activeFlow: threeDSActiveFlow,
          acsUrl: threeDSAcsUrl,
          challengeRequest: threeDSChallengeRequest,
          isChallengeRequest,
          isThreeDSMethodFlow,
          setThreeDSChallengeTokenResponse,
          threeDSChallengeTokenResponse,
        },
        transformPaymentValues,
        isFreeOrder,
        isFreeOrderPayment,
      }}
    >
      {props.children}
      <ErrorDialog />
    </PaymentContext.Provider>
  );
}

export default PaymentContext.Consumer;
