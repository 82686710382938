/**
 * AUTOGENERATED - DO NOT EDIT DIRECTLY
 *
 * Please see "scripts/regionalization/README.md" for how to modify
 */

export enum ISOs {
  ARE = 'ARE',
  ARG = 'ARG',
  ATA = 'ATA',
  AUT = 'AUT',
  AUS = 'AUS',
  BRA = 'BRA',
  BHR = 'BHR',
  CAN = 'CAN',
  CHE = 'CHE',
  CHL = 'CHL',
  CZE = 'CZE',
  DEU = 'DEU',
  ESP = 'ESP',
  EGY = 'EGY',
  FRA = 'FRA',
  GBR = 'GBR',
  IRL = 'IRL',
  ITA = 'ITA',
  KOR = 'KOR',
  KWT = 'KWT',
  MAR = 'MAR',
  MEX = 'MEX',
  MYS = 'MYS',
  NLD = 'NLD',
  NZL = 'NZL',
  OMN = 'OMN',
  PHL = 'PHL',
  POL = 'POL',
  PRT = 'PRT',
  PRI = 'PRI',
  QAT = 'QAT',
  ROU = 'ROU',
  SAU = 'SAU',
  SGP = 'SGP',
  TWN = 'TWN',
  USA = 'USA',
  ZAF = 'ZAF',
  AE = 'ARE',
  AR = 'ARG',
  AQ = 'ATA',
  AT = 'AUT',
  AU = 'AUS',
  BH = 'BHR',
  BR = 'BRA',
  CA = 'CAN',
  CH = 'CHE',
  CL = 'CHL',
  CZ = 'CZE',
  DE = 'DEU',
  ES = 'ESP',
  EG = 'EGY',
  FR = 'FRA',
  GB = 'GBR',
  IE = 'IRL',
  IT = 'ITA',
  KR = 'KOR',
  KW = 'KWT',
  MA = 'MAR',
  MY = 'MYS',
  MX = 'MEX',
  NL = 'NLD',
  NZ = 'NZL',
  OM = 'OMN',
  PH = 'PHL',
  PL = 'POL',
  PT = 'PRT',
  PR = 'PRI',
  QA = 'QAT',
  RO = 'ROU',
  SA = 'SAU',
  SG = 'SGP',
  TW = 'TWN',
  US = 'USA',
  ZA = 'ZAF',
}
