import { GraphQLError } from 'graphql';
import { IntlShape } from 'react-intl';

// @todo Share with `backend/functions/graphql/src/errors`
export enum GraphQLErrorCodes {
  APPLE_PASS_ERROR = 'APPLE_PASS_ERROR',
  AUTH_EMAIL_NOT_REGISTERED = 'AUTH_EMAIL_NOT_REGISTERED',
  AUTH_PHONE_NOT_REGISTERED = 'AUTH_PHONE_NOT_REGISTERED',
  AUTH_EMAIL_ALREADY_EXIST = 'AUTH_EMAIL_ALREADY_EXIST',
  AUTH_PHONE_ALREADY_EXIST = 'AUTH_PHONE_ALREADY_EXIST',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  CHASE_PAYMENTS_ERROR = 'CHASE_PAYMENTS_ERROR',
  COMMIT_ORDER_COOLING_PERIOD = 'COMMIT_ORDER_COOLING_PERIOD',
  GOOGLE_PASS_ERROR = 'GOOGLE_PASS_ERROR',
  GUEST_ORDER_LIMIT = 'GUEST_ORDER_LIMIT',
  INVITATION_CODE_NOT_EXISTING = 'INVITATION_CODE_NOT_EXISTING',
  LEGACY_USER_IMPORT_FAILED = 'LEGACY_USER_IMPORT_FAILED',
  LOYALTY_ERROR = 'LOYALTY_ERROR',
  LOYALTY_CARD_ALREADY_LINKED_ERROR = 'LOYALTY_CARD_ALREADY_LINKED_ERROR',
  MAX_CREDIT_CARDS = 'MAX_CREDIT_CARDS',
  MAX_GIFT_CARDS = 'MAX_GIFT_CARDS',
  MISSING_PHONE_NUMBER = 'MISSING_PHONE_NUMBER',
  PAYMENTS_AVS_ERROR = 'PAYMENTS_AVS_ERROR',
  PAYMENTS_CUSTOMER_CREATION_ERROR = 'PAYMENTS_CUSTOMER_CREATION_ERROR',
  PAYMENTS_DECLINED_ERROR = 'PAYMENTS_DECLINED_ERROR',
  PAYMENTS_DETAILS_ERROR = 'PAYMENTS_DETAILS_ERROR',
  PAYMENTS_ERROR = 'PAYMENTS_ERROR',
  PAYMENT_NOT_SUPPORTED = 'PAYMENT_NOT_SUPPORTED',
  PREPAID_METHOD_NOT_SUPPORTED_BY_STORE = 'PREPAID_METHOD_NOT_SUPPORTED_BY_STORE',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  SIGNUP_VALIDATION_ERROR = 'SIGNUP_VALIDATION_ERROR',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  VOUCHER_ERROR = 'VOUCHER_ERROR',
  VOUCHER_NOT_FOUND = 'VOUCHER_NOT_FOUND',
  VOUCHER_USED = 'VOUCHER_USED',
  // Previously AUTH_EMAIL_NOT_REGISTERED in the sign in step now is: CREATE_OTP_FAILED
  // more info: https://github.com/rbilabs/ctg-user-service/pull/420
  CREATE_OTP_FAILED = 'CREATE_OTP_FAILED',
  BLOCKED_USER_SIGN_IN_ERROR = 'BLOCKED_USER_SIGN_IN_ERROR',
  AUTH_PHONE_COUNTRY_NOT_ALLOWED = 'AUTH_PHONE_COUNTRY_NOT_ALLOWED',
  AUTH_PHONE_NOT_IN_USE = 'AUTH_PHONE_NOT_IN_USE',
  AUTH_PHONE_QUEUE_IN_PROGRESS = 'AUTH_PHONE_QUEUE_IN_PROGRESS',
}

type paymentRelatedGraphqlError =
  | GraphQLErrorCodes.PAYMENT_NOT_SUPPORTED
  | GraphQLErrorCodes.PAYMENTS_DECLINED_ERROR
  | GraphQLErrorCodes.PAYMENTS_DETAILS_ERROR
  | GraphQLErrorCodes.PAYMENTS_ERROR
  | GraphQLErrorCodes.TOO_MANY_REQUESTS
  | GraphQLErrorCodes.GRAPHQL_VALIDATION_FAILED
  | GraphQLErrorCodes.COMMIT_ORDER_COOLING_PERIOD
  | GraphQLErrorCodes.PREPAID_METHOD_NOT_SUPPORTED_BY_STORE;

export const paymentErrorModalMaps: Record<paymentRelatedGraphqlError, string> = {
  [GraphQLErrorCodes.PAYMENT_NOT_SUPPORTED]: 'paymentNotSupported',
  [GraphQLErrorCodes.PAYMENTS_DECLINED_ERROR]: 'paymentDeclined',
  [GraphQLErrorCodes.PAYMENTS_DETAILS_ERROR]: 'paymentDetailsError',
  [GraphQLErrorCodes.PAYMENTS_ERROR]: 'paymentDeclined',
  [GraphQLErrorCodes.GRAPHQL_VALIDATION_FAILED]: 'validationError',
  [GraphQLErrorCodes.TOO_MANY_REQUESTS]: 'duplicateSupportFormSubmission',
  [GraphQLErrorCodes.COMMIT_ORDER_COOLING_PERIOD]: 'coolingPeriodError',
  [GraphQLErrorCodes.PREPAID_METHOD_NOT_SUPPORTED_BY_STORE]: 'giftCardUnavailableAtLocation',
};

export interface IRbiError {
  errorCode: string;
  rbiErrorCode: string;
  rbiErrorDomain: string;
  message: string;
}

export interface IGraphqlErrorMap {
  [k: string]: GraphQLError;
}

export interface IRbiErrorTranslation {
  errorCode: string;
  errorTranslation?: RbiErrorTranslation;
  defaultTranslationId: string;
  formatMessage: IntlShape['formatMessage'];
}

export enum RbiErrorTranslation {
  DEFAULT = 'default',
  TITLE = 'title',
  CTA = 'cta',
}
