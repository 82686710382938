import { keyBy } from 'lodash';

import { ICartEntry, IHistoricalOrder } from '@rbi-ctg/menu';
import { IAppliedOffer } from 'generated/graphql-gateway';
import { IAppliedRewards } from 'state/loyalty/hooks/types';
import { CartEntryType } from 'utils/cart/types';

import { IAdditionalDetailsState, ILimitProps, IMinimumProps } from './types';

const determineLimit = ({ maxLimit, maxCateringLimit, isCatering }: ILimitProps): number =>
  isCatering ? maxCateringLimit : maxLimit;

const determineMinimum = ({ minLimit, minCateringLimit, isCatering }: IMinimumProps): number =>
  isCatering ? minCateringLimit : minLimit;

const isOfferType = (type: CartEntryType) => /^offer/i.test(type);

const replaceEntryArrayItem = (array: ICartEntry[], newEntry: ICartEntry) => {
  const index = array.findIndex(entry => String(entry.cartId) === String(newEntry.cartId));
  return index !== -1
    ? [...array.slice(0, index), newEntry, ...array.slice(index + 1)]
    : [...array, newEntry];
};

// This function traverses cartEntries and when finds an item in any level, it replaces
// the item with newEntry
const replaceEntry = (cartEntries: ICartEntry[], from: string, newEntry: ICartEntry) => {
  const iterator = (entry: ICartEntry, index: number, array: ICartEntry[]) => {
    if (entry._id === from) {
      array[index] = newEntry;
      return true;
    }
    return entry.children.length && entry.children.some(iterator);
  };

  cartEntries.some(iterator);

  return [...cartEntries];
};

// Util function to find item by id in cartEntry with children
const deepFindCartEntry = (cartEntry: ICartEntry, id: string) => {
  let result;

  if (cartEntry._id === id) {
    return cartEntry;
  }

  const found = cartEntry.children.some(child => (result = deepFindCartEntry(child, id)));

  return found && result;
};

// This function traverses the cartEntries keeping reference to the entry in the root level
// while searching for an entry in children
const findEntriesByCmsId = (cartEntries: ICartEntry[], id: string) => {
  return cartEntries.reduce(
    (acc: { rootEntry: ICartEntry | null; childEntry: ICartEntry | null }, entry) => {
      const foundChild = deepFindCartEntry(entry, id);
      if (foundChild && !acc.rootEntry) {
        acc = { rootEntry: entry, childEntry: foundChild };
      }
      return acc;
    },
    { rootEntry: null, childEntry: null }
  );
};

const validateCartEntries = (
  cartEntries: ICartEntry[],
  appliedOffers: IAppliedOffer[],
  incentivesIds: Set<string>,
  isOfferInCart: Boolean,
  validateCallback: (entry: ICartEntry) => void
) => {
  const appliedOffersByCartId = keyBy(appliedOffers, 'cartId');
  cartEntries.forEach(entry => {
    const { _id, type, cartId } = entry;
    const isOffer = incentivesIds.has(_id);

    if (!isOffer || !isOfferInCart) {
      return;
    }

    if (
      (type !== CartEntryType.offerCombo && type !== CartEntryType.offerItem) ||
      !appliedOffersByCartId[cartId]
    ) {
      validateCallback(entry);
    }
  });
};

const validateCartRewards = ({
  appliedRewards,
  rewardLimitePerOrder,
  validateCallback,
}: {
  appliedRewards: IAppliedRewards;
  rewardLimitePerOrder: number;
  validateCallback: (rewardIds: string[]) => void;
}) => {
  const appliedRewardIds = Object.keys(appliedRewards);
  // If for some reason we have more rewards in cart than allowed remove until limit is correct
  if (appliedRewardIds.length > rewardLimitePerOrder) {
    // This creates an array of rewards that need to be removed, except the last N that will be kept, where N = rewardLimitePerOrder
    const rewardsToBeRemoved = appliedRewardIds.slice(0, -rewardLimitePerOrder);
    validateCallback(rewardsToBeRemoved);
  }
};

/**
 * Determine whether the provided order-like object is a "migrated" order imported
 * from a prior platform.
 *
 * This was first done for the PLK ES launch.
 *
 * The below logic is dependent on actions taken by our migration script during import.
 * At the time of writing, we do not have a well defined property to identify such orders.
 *
 * @see https://github.com/rbilabs/intl-user-service/tree/master/scripts
 * @see https://rbictg.atlassian.net/browse/NMS-348
 */
const isHistoricalMigratedOrder = (order: Partial<IHistoricalOrder>): boolean => {
  return order?.cart?.cartEntries[0]?.lineId === 'unavailable-migration';
};

const filterAdditionDetails = (
  additionalDetails: IAdditionalDetailsState[],
  orderData: boolean = true
): Record<string, string> | false => {
  if (additionalDetails.length === 0) {
    return false;
  }

  return additionalDetails.reduce(
    (orderAdditionalDetails, detail) => {
      if (detail.value.trim().length > 0 && detail.key && detail.isPii !== orderData) {
        orderAdditionalDetails[detail.key] = detail.value;
      }
      return orderAdditionalDetails;
    },
    {} as Record<string, string>
  );
};

export {
  determineLimit,
  determineMinimum,
  isHistoricalMigratedOrder,
  isOfferType,
  findEntriesByCmsId,
  filterAdditionDetails,
  replaceEntryArrayItem,
  replaceEntry,
  validateCartEntries,
  validateCartRewards,
};
