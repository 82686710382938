import React from 'react';

import { useFavoriteStore } from 'hooks/favorite-stores';

import { FavoriteStoreIconView } from './favorite-store-icon.view';

export const FavoriteStoreIconContainer: React.FC<{ storeId: string; storeNumber: string }> = ({
  storeId,
  storeNumber,
}) => {
  const { toggleFavorite, isFavorite, ErrorFavoriteStoreDialog, favStoreLoading } =
    useFavoriteStore(storeId, storeNumber);

  return (
    <>
      <FavoriteStoreIconView
        favStoreLoading={favStoreLoading}
        isFavorite={isFavorite}
        toggleFavorite={toggleFavorite}
      />
      <ErrorFavoriteStoreDialog />
    </>
  );
};
