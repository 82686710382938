import { IEncryptCreditArgs } from './types';

const encrypt = (publicKey: string) => {
  /**
   * Currently the encryption PKCS is used by FD and THDigital.
   * If the algorithm is undefined or equals to ${algorithmType.PKCS}
   * the current solution will be used, otherwise the new encryption
   * will be defined as the encryption function.
   */
  return import('node-rsa').then(({ default: NodeRSA }) => {
    const key = new NodeRSA(`-----BEGIN PUBLIC KEY-----\n${publicKey}\n-----END PUBLIC KEY-----`);
    key.setOptions({ encryptionScheme: 'pkcs1' });
    return (field: string) => key.encrypt(field, 'base64');
  });
};

export const encryptCredit = ({
  credit,
  fdPublicKey,
  isUsingTokenEx = false,
}: IEncryptCreditArgs) => {
  return encrypt(fdPublicKey).then(encryptWithKey => {
    const expiryMonth = credit.expiryDate?.month ?? '';
    const expiryYear = credit.expiryDate?.year ?? '';

    return {
      ...credit,
      cardNumber: encryptWithKey(credit.cardNumber),
      expiryDate: {
        month: isUsingTokenEx ? expiryMonth : encryptWithKey(expiryMonth),
        year: isUsingTokenEx ? expiryYear : encryptWithKey(expiryYear),
      },
      securityCode: encryptWithKey(credit.securityCode ?? ''),
    };
  });
};

export { getStoredPrepaidCard, storePrepaidCard } from './store-prepaid-card';
