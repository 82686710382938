export const getName = <O extends object>({ name }: { name?: string }, previous: O): O => {
  if (name) {
    const trimmedName = name.trim();
    const [firstName, ...last] = (trimmedName || '').split(' ');
    const lastName = last.join(' ');
    const n = [firstName, lastName];
    return Object.keys(previous).reduce(
      (acc, key, i) => ({
        ...acc,
        [key]: n[i],
      }),
      {} as O
    );
  }
  return previous;
};
