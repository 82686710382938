import { IHistoricalOrder, IServerOrder } from '@rbi-ctg/menu';

import { getOrderNumber as getOrderNumberWithPosFallback } from '../../pages/order-confirmation/util';

export function getOrderNumber(
  serverOrder: IServerOrder | IHistoricalOrder,
  enableOrderNumberGeneration: boolean
): string | null {
  const orderNumber = getOrderNumberWithPosFallback(serverOrder as IServerOrder);
  if (!enableOrderNumberGeneration) {
    return orderNumber;
  }

  return serverOrder?.orderNumberFormatted || orderNumber;
}
