import React, { FC } from 'react';

import { ToastContainer as ToastifiyContainer, cssTransition } from 'react-toastify';

import { DEFAULT_TOAST_LIMIT } from 'utils/toast/constants';

import { ReactToastifyContainer } from './toast-container.styled';

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
  duration: 250,
});

export const ToastContainer: FC = () => (
  <ReactToastifyContainer>
    <ToastifiyContainer
      closeButton={false}
      transition={Zoom}
      position="bottom-center"
      limit={DEFAULT_TOAST_LIMIT}
      hideProgressBar={false}
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
    />
  </ReactToastifyContainer>
);
