import { FC } from 'react';

import styled, { css } from 'styled-components';

import ActionButton from 'components/action-button';

import DirectionsLink from './directions-link';
import theme from './theme';

export interface IStoreCardContainer {
  isListView?: boolean;
}

const notListViewCss = css`
  ${Styles.mobileSmall} {
    width: 90vw;
  }
`;

export const SmallText = styled.div`
  font-size: 0.875rem;
`;

export const StoreCardContainer = styled.div<IStoreCardContainer>`
  position: relative;
  width: ${p => (p.isListView ? '100%' : '25rem')};
  margin-block-end: ${p => (p.isListView ? '0.5rem' : 'auto')};
  background: ${Styles.color.cardBackground};
  padding: 1rem 1.125rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${p => !p.isListView && notListViewCss}

  ${Styles.desktop} {
    width: 100%;
    margin-block-end: 0.5rem;
  }
`;

export const EditOrderContainer = styled.div`
  padding-block-start: 0.5rem;
  display: flex;
  justify-content: space-evenly;
`;

export const StoreCardRightContainer = styled.div`
  width: 100%;
  padding-inline-start: 0.5rem;
`;

export const StoreCardLeftContainer = styled.div`
  width: 100%;
  padding-inline-end: 0.5rem;
`;

export const SelectedBorder = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 3px;
  background: ${Styles.color.primary};
  width: 100%;
`;

export const SelectedBorderVertical = styled.div`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 3px;
  background: ${Styles.color.primary};
  height: 100%;
`;

const determineAddressHeaderMaxWidth = (diagnosticsEnabled: boolean | undefined) => {
  if (!diagnosticsEnabled) {
    return 'calc(100% - 30px)';
  }
  return 'calc(100% - 70px)';
};

export const AddressHeader = styled.div<{
  areDiagnosticsEnabled?: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${p => determineAddressHeaderMaxWidth(p.areDiagnosticsEnabled)};
  margin-block-end: 0.25rem;
  h2 {
    color: ${Styles.color.black};
    font-family: ${Styles.fontFamily.base};
    margin: 0;
    text-transform: ${theme.addressTextTransform};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: ${Styles.fontWeight.normal};
  }
`;

export const StoreCardIconsButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AddressSubheader = styled.div`
  p {
    color: ${Styles.color.grey.one};
    font-size: 0.875rem;
    margin: 0;
    text-transform: capitalize;
  }
`;

export const Divider: FC = () => null;

export const TimeAndInfoWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-block-end: 0.875rem;

  div:nth-child(1) {
    justify-content: flex-start;
  }

  p {
    margin: 0 6px;
  }
`;

export const SubheaderContainer: FC = () => null;

export const DirectionsLinkStyled = styled(DirectionsLink)`
  display: flex;
  flex: 0 1;
  align-self: flex-start;
  margin-block-end: 0.5rem;
  color: ${Styles.color.primary};
`;
export const TextCancelModal = styled.p`
  padding: 1rem 0;
`;

export const ButtonGroupsCancelDialog = styled.div``;

export const NewLayoutButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
`;

export const NewLayoutCancelButtonContainer = styled.div`
  position: absolute; /* Changed to fixed to stick to viewport */
  inset-inline-start: 50%;
  transform: translateX(-50%);
  inset-block-end: 15px;
`;

export const StyledCancelButton = styled(ActionButton)`
  background-color: #fff;
  color: ${Styles.color.tertiary};
  &:active,
  &:hover {
    background-color: #fff;
    color: ${Styles.color.tertiary};
  }
  &:active,
  &:focus {
    border: none;
    box-shadow: none;
  }
`;

export const NewLayoutDivider = styled.hr`
  width: 100%;
`;

export const NewLayoutSaveCancelContainer = styled.div`
  justify-content: space-between;
  margin-block-start: 24px;
  display: flex;
`;

export const NewLayoutCancelWrapper = styled.div`
  width: 100px;
  margin-inline-end: 0.5rem;
`;

export const NewLayoutSaveWrapper = styled.div`
  width: 100px;
  margin-inline-start: 0.5rem;
`;

export const NewLayoutDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NewLayoutDetailsWrapper = styled.div`
  min-inline-size: 50%;
`;

export const NewLayoutDetailsWrapperSpan = styled.div`
  display: inline-flex;
`;

export const NewLayoutDetailsSpan = styled.span`
  font-size: 0.75rem;
  margin-inline-end: 4px;
`;

export const NewLayoutEditSpan = styled.span`
  font-size: 1rem;
  margin-inline-end: 8px;
`;

export const NewLayoutDismissButton = styled(ActionButton)`
  width: 100%;
  inset-inline-start: 0;
  color: ${Styles.color.black};
  text-align: end;
  ${Styles.desktop} {
    width: 100%;
    margin-block: 0;
    margin-inline: auto;
  }

  &:active,
  &:focus {
    border: none !important;
    color: ${Styles.color.black} !important;
  }
`;
