import { ApolloClient } from '@apollo/client';
import { keyBy } from 'lodash';

import { IOffersFragment } from 'generated/graphql-gateway';
import {
  ILoyaltyOfferConfigsQuery,
  ILoyaltyOfferTemplatesQuery,
  IPlaceholderFieldsQuery,
  LoyaltyOfferConfigsDocument,
  LoyaltyOfferTemplatesDocument,
  Maybe,
  PlaceholderFieldsDocument,
} from 'generated/sanity-graphql';
import {
  ILocaleSmartBlockContent,
  PlaceholderFieldsMap,
  TransformSmartBlockContentFn,
} from 'hooks/use-locale-smart-block-content/types';

import { LoyaltyOffer } from '../../types';

const buildEngineIdMap = <T>(
  data?: readonly (T & { loyaltyEngineId: Maybe<string> })[]
): Record<string, T> => {
  return (data || []).reduce<Record<string, T & { loyaltyEngineId: Maybe<string> }>>((acc, val) => {
    const { loyaltyEngineId } = val;
    if (loyaltyEngineId) {
      acc[loyaltyEngineId] = val;
    }
    return acc;
  }, {});
};

export const fetchPersonalizedOfferData = async ({
  client,
  uri,
  templatesIds,
  configOffersIds,
}: {
  client: ApolloClient<object>;
  uri: string;
  templatesIds: string[];
  configOffersIds: string[];
}) => {
  const [configs, templates, placeholderFields] = await Promise.all([
    client.query<ILoyaltyOfferConfigsQuery>({
      query: LoyaltyOfferConfigsDocument,
      context: { uri },
      variables: {
        ids: configOffersIds,
      },
    }),
    client.query<ILoyaltyOfferTemplatesQuery>({
      query: LoyaltyOfferTemplatesDocument,
      context: { uri },
      variables: {
        ids: templatesIds,
      },
    }),
    client.query<IPlaceholderFieldsQuery>({
      query: PlaceholderFieldsDocument,
      context: { uri },
    }),
  ]);

  const configMap = buildEngineIdMap(configs?.data?.allConfigOffer);
  const templateMap = buildEngineIdMap(templates?.data?.allOfferTemplate);
  const placeholderFieldsMap = keyBy(
    placeholderFields?.data?.allPlaceholderField ?? [],
    '_id'
  ) as PlaceholderFieldsMap;

  return { configMap, templateMap, placeholderFieldsMap };
};

type Await<T> = T extends Promise<infer U> ? U : T;

export interface IMergePersonalizedData extends IPersonalizedData {
  engineOffer: IOffersFragment;
  transformSmartBlockContent: TransformSmartBlockContentFn;
}
export interface IPersonalizedData {
  configMap: Await<ReturnType<typeof fetchPersonalizedOfferData>>['configMap'];
  templateMap: Await<ReturnType<typeof fetchPersonalizedOfferData>>['templateMap'];
  placeholderFieldsMap: Await<
    ReturnType<typeof fetchPersonalizedOfferData>
  >['placeholderFieldsMap'];
}

export const mergePersonalizedData = ({
  engineOffer,
  configMap,
  templateMap,
  placeholderFieldsMap,
  transformSmartBlockContent,
}: IMergePersonalizedData): LoyaltyOffer => {
  const { templateId, configId } = engineOffer || {};
  const config = configMap[configId || ''];
  const template = templateMap[templateId || ''];

  const { localizedImage, backgroundImage } = template || {};
  const hasLocalizedImage = Boolean(localizedImage?.locale?.app);

  const transformField = (blockContent: ILocaleSmartBlockContent | null) =>
    blockContent &&
    transformSmartBlockContent({
      blockContent,
      interpolationSourceObject: {
        engineOffer,
        template,
        config,
      },
      placeholderFieldsMap,
    });

  const mergedOffer = {
    ...config,
    isStackable: engineOffer.isStackable,
    loyaltyEngineId: engineOffer.id,
    metadata: engineOffer.metadata,
    // Override offer config data with personalization from template
    ...(template?.name && { name: transformField(template.name) }),
    ...(template?.description && { description: transformField(template.description) }),
    ...(hasLocalizedImage && { localizedImage }),
    ...(backgroundImage && { backgroundImage }),
  };

  return mergedOffer;
};
