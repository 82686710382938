import React from 'react';

import styled from 'styled-components';

import {
  IHighlightedVerticalLinkListWidgetFragment,
  ILinkWidgetFragment,
} from 'generated/sanity-graphql';

import { Links } from './links';
import { Header, HighlightedExternalLink, HighlightedInternalLink, Subheader } from './styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

type IHighlightedVerticalLinkListProps = IHighlightedVerticalLinkListWidgetFragment;

export const HighlightedVerticalLinkList: React.FC<IHighlightedVerticalLinkListProps> = props => {
  return (
    <Container>
      <Header>{props.header?.locale}</Header>
      <Subheader>{props.subheader?.locale}</Subheader>
      <Links
        links={props.highlightedLinks as ILinkWidgetFragment[]}
        InternalLink={HighlightedInternalLink}
        ExternalLink={HighlightedExternalLink}
      />
    </Container>
  );
};
