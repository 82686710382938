import styled, { css } from 'styled-components';

import ActionButton, { IActionButtonProps } from 'components/action-button';
import { ClickableContainer } from 'components/clickable-container';
import Picture from 'components/picture';

import { Direction, HorizontalPosition, TileSize } from './types';

interface ITileSizeable {
  tileSize: TileSize;
}

interface ILockedAspectRatio {
  lockedAspectRatio: boolean;
}

interface ITileStyleable {
  hasBoxShadow: boolean;
  hasBorder: boolean;
  additionalPadding: string;
}

export const Container = styled(ClickableContainer)`
  display: flex;
  flex: 1;
`;

export const Wrapper = styled.div<ITileSizeable & ITileStyleable & ILockedAspectRatio>`
  display: flex;
  flex: 1 1 auto;
  display: block;
  appearance: none;
  border: none;
  margin: 0;
  font-size: inherit;
  position: relative;
  text-align: start;
  background: transparent;
  z-index: 0;
  user-select: none;

  background-color: ${Styles.color.white};
  border-radius: ${Styles.borderRadius};
  padding: ${props => (props.additionalPadding ? props.additionalPadding : `0`)};
  border: ${props => (props.hasBorder ? `2px solid #fff` : 'none')};
  box-shadow: ${props => (props.hasBoxShadow ? Styles.boxShadow : 'none')};
  transition: box-shadow 0.3s;

  ${({ tileSize, lockedAspectRatio }) => {
    if (lockedAspectRatio) {
      return;
    }
    switch (tileSize) {
      case TileSize.FULL:
        return css`
          height: 266px;
        `;
      case TileSize.HALF:
        return css`
          height: 300px;
        `;
      default:
        return null;
    }
  }}

  &:hover {
    box-shadow: ${props => (props.hasBoxShadow ? `0 0 0 0 ${Styles.color.grey.four}` : 'none')};
  }
`;

export const BackgroundImageContainer = styled(Picture)`
  display: flex;
`;

const horizontalPositionToFlexMap = {
  [HorizontalPosition.LEFT]: 'flex-start',
  [HorizontalPosition.CENTER]: 'center',
  [HorizontalPosition.RIGHT]: 'flex-end',
};

export const Content = styled.div<ITileSizeable>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${({ tileSize }) => {
    switch (tileSize) {
      case TileSize.FULL:
        return css`
          padding: 1rem;
        `;
      case TileSize.HALF:
        return css`
          padding: 0.75rem;
        `;
      default:
        return null;
    }
  }}
`;

interface IButtonContainer {
  isShowingTwoButtons: boolean;
  buttonPosition: HorizontalPosition;
  $direction: Direction;
}

export const ButtonContainer = styled.div<IButtonContainer>`
  display: flex;
  width: 100%;
  flex-direction: ${({ $direction }) => ($direction === Direction.REVERSE ? 'row-reverse' : 'row')};
  justify-content: ${({ buttonPosition, isShowingTwoButtons }) =>
    isShowingTwoButtons ? 'space-between' : horizontalPositionToFlexMap[buttonPosition]};
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

interface ITermsButton {
  $color: string;
}

export const StyledTermsButton = styled(ActionButton)<ITermsButton & IActionButtonProps>`
  color: ${({ $color }) => $color || Styles.color.black};
  letter-spacing: normal;
  font-family: ${Styles.fontFamily.body};
  &:hover {
    background: transparent;
    color: ${({ $color }) => $color || Styles.color.black};
  }
  &:focus {
    border: none;
  }
`;
