import { ICartEntry } from '@rbi-ctg/menu';

export const getCartEntryRef = (entry: ICartEntry): string => {
  const sanityId = entry?.sanityId || entry?._id;
  const childrenRefs = entry?.children
    ?.map(child => getCartEntryRef(child))
    .sort()
    .join(',');
  return `${sanityId}(${entry.quantity})[${childrenRefs}]`;
};
