import { millisecondsToMinutes, minutesToHours } from 'date-fns';

interface IGetEstimatedTimeMessageOptions {
  firingTimestamp?: Date | null;
  dateNow: Date;
  formatHourAndMinutesSkipZero: Function;
}

export const getEstimatedTimeMessage = ({
  firingTimestamp,
  dateNow,
  formatHourAndMinutesSkipZero,
}: IGetEstimatedTimeMessageOptions): string | undefined => {
  if (!firingTimestamp) {
    return;
  }

  const estimatedInMinutes = Math.max(
    Math.floor(millisecondsToMinutes(firingTimestamp.getTime() - dateNow.getTime())),
    1
  );
  const estimatedHour = Math.floor(minutesToHours(estimatedInMinutes));
  const estimatedMinute = estimatedInMinutes % 60;

  return formatHourAndMinutesSkipZero(estimatedHour, estimatedMinute);
};
