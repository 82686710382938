import { getSupportedMarketLanguages } from 'utils/i18n';
import * as location from 'utils/location';

export const toRelativePath = (path: string) => (path.startsWith('/') ? path : `/${path}`);

/**
 * Normalizes a path, ensuring:
 *  - There are no consecutive slashes.
 *  - A leading slash is always present.
 */
export const normalize = (path: string) => path.replace(/\/\/+/g, '/').replace(/^\/*/, '/');

/**
 * Joins path parts together, removing any duplicate separators.
 */
const join = (...paths: string[]) => paths.filter(Boolean).join('/');

/**
 * Checks if path starts with given sub-path
 */
const pathStartsWith = (a: string, b: string) => normalize(`${a}/`).startsWith(normalize(`${b}/`));

/**
 * Checks if path starts with one of the supported languages
 */
const pathStartsWithLanguage = (path: string) =>
  getSupportedMarketLanguages().some(lang => pathStartsWith(path, lang));

/**
 * Removes a leading language code from a path, provided it refers to a valid
 * market language code.
 */
export const removeLanguageFromPath = (path: string) => {
  const normalizedPath = normalize(path);
  return pathStartsWithLanguage(normalizedPath)
    ? `/${normalizedPath.split('/').splice(2).join('/')}`
    : normalizedPath;
};

/**
 * Creates a function to construct basepath-aware pathnames.
 * When the path already includes the basepath it returns it as is.
 */
export const pathname =
  (basepath: string = '') =>
  (path: string) => {
    const finalPath = pathStartsWith(path, basepath) ? path : join(basepath, path);

    return normalize(finalPath);
  };

/**
 * Creates a function to construct basepath-aware fully-qualified application URLs.
 */
export const appUrl =
  (basepath: string = '') =>
  (path: string) =>
    new URL(pathname(basepath)(path), location.get('origin')).toString();
