import { RbiOrderStatus } from 'generated/rbi-graphql';
import { DeliveryStatus, OrderStatus } from 'state/order/types';

const statusToPercentMap = (
  status: DeliveryStatus,
  rbiOrderStatus?: OrderStatus | RbiOrderStatus
): number => {
  switch (status) {
    case DeliveryStatus.ORDER_CREATED:
    case DeliveryStatus.DRIVER_ASSIGNED:
    case DeliveryStatus.DRIVER_UNASSIGNED:
    case DeliveryStatus.DRIVER_STARTING:
      return rbiOrderStatus === OrderStatus.UPDATE_SUCCESSFUL ? 33 : 20;
    case DeliveryStatus.DRIVER_AT_STORE:
      return 50;
    case DeliveryStatus.ORDER_PICKED_UP:
      return 66.7;
    case DeliveryStatus.DRIVER_AT_CUSTOMER:
      return 83.3;
    case DeliveryStatus.ORDER_DROPPED_OFF:
    case DeliveryStatus.ORDER_ABANDONED:
      return 100;
    default:
      return 0;
  }
};

export const getCustomerFacingDeliveryStatuses = (
  deliveryStatus: DeliveryStatus,
  rbiOrderStatus: OrderStatus | RbiOrderStatus
) => {
  const percentComplete = statusToPercentMap(deliveryStatus, rbiOrderStatus);
  const isOrderPlaced =
    percentComplete >= statusToPercentMap(DeliveryStatus.ORDER_CREATED, rbiOrderStatus);
  const isBeingPrepared =
    percentComplete >= statusToPercentMap(deliveryStatus, OrderStatus.UPDATE_SUCCESSFUL) &&
    deliveryStatus !== DeliveryStatus.ORDER_CANCELLED;
  const isDriverEnroute =
    percentComplete >= statusToPercentMap(DeliveryStatus.ORDER_PICKED_UP, rbiOrderStatus);
  const isDelivered =
    percentComplete >= statusToPercentMap(DeliveryStatus.ORDER_DROPPED_OFF, rbiOrderStatus);

  return { percentComplete, isOrderPlaced, isBeingPrepared, isDriverEnroute, isDelivered };
};
