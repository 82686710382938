import { getDescriptionDetailTextType } from './types';

export const getDescriptionDetailText: getDescriptionDetailTextType = ({
  formatMessage,
  points,
}) => {
  if (!points) {
    return formatMessage({ id: 'loyaltyFree' });
  }
  return formatMessage({ id: 'numberOfCrowns' }, { crowns: points });
};
