import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';

import { ISanityBlockContent } from '@rbi-ctg/menu';
import {
  useAllFeatureIdsQuery,
  useFeatureAccountUpdatedAgreementsQuery,
} from 'generated/sanity-graphql';
import { TLocalizationKey } from 'types/i18n';
import { brand } from 'utils/environment';

export interface IUseFeatureAccountUpdatedAgreements {
  featureAccountUpdatedAgreementsLoading: boolean;
  marketingCommunicationText: string;
  updateAgreementText: string;
}

export interface ISanityAccountUpdatedAgreements {
  locale?: [ISanityBlockContent];
  __typename?: string;
}

export type SanityObject = ISanityAccountUpdatedAgreements | null | undefined;

export const useFeatureAccountUpdatedAgreements = (): IUseFeatureAccountUpdatedAgreements => {
  const { formatMessage } = useIntl();

  const { data: dataAllFeatureIds, loading: featureIdsLoading } = useAllFeatureIdsQuery();

  const featureAccountUpdatedAgreementsId = useMemo(
    () => dataAllFeatureIds?.allFeatureAccountUpdatedAgreements[0]?._id || '',
    [dataAllFeatureIds]
  );

  const { data, loading } = useFeatureAccountUpdatedAgreementsQuery({
    variables: { featureAccountUpdatedAgreementsId },
    skip: !featureAccountUpdatedAgreementsId,
  });

  const isLoading = useMemo(() => featureIdsLoading || loading, [featureIdsLoading, loading]);

  const getText = useCallback(
    (sanityObject: SanityObject, type: TLocalizationKey) => {
      const lokaliseUpdatedAgreementsText = formatMessage({ id: type });
      const sanityText = sanityObject?.locale?.[0]?.children?.[0]?.text;

      return sanityText ?? lokaliseUpdatedAgreementsText;
    },
    [formatMessage]
  );

  return {
    featureAccountUpdatedAgreementsLoading: isLoading,
    marketingCommunicationText: getText(
      data?.FeatureAccountUpdatedAgreements?.marketingCommunicationText,
      `acceptEmailsAndSpecialOffers.${brand()}`
    ),
    updateAgreementText: getText(
      data?.FeatureAccountUpdatedAgreements?.updateAgreementText,
      'updatedAgreementsText'
    ),
  };
};
