import React, { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';

import { SupportedLanguages } from '@rbi-ctg/frontend';
import Modal from 'components/modal';
import { useSendUpdateUserAttributesEventMutation } from 'generated/graphql-gateway';
import { useCdpContext } from 'state/cdp';
import { useLocale } from 'state/intl';
import { inferSupportedLocale } from 'state/intl/infer-supported-locale';
import { useLocationContext } from 'state/location';
import { getRedirectUrl } from 'utils/language/language-selector';

import { LanguageSelectorModalContents } from './language-selector-modal-contents';
import { ContentContainer, StyledModalHeading } from './styled';

interface ILanguageModalProps {
  onModalDismiss: VoidFunction;
  disclaimer?: string;
}

const LanguageSelectorModal: React.FC<ILanguageModalProps> = ({ onModalDismiss, disclaimer }) => {
  const { formatMessage } = useIntl();
  const {
    redirect,
    location: { pathname },
  } = useLocationContext();
  const { updateUserAttributes } = useCdpContext();
  const { language, locale, region, setCurrentLanguage, shouldUseLanguageCodeInUrls } = useLocale();
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const [sendUpdateUserAttributesEvent] = useSendUpdateUserAttributesEventMutation();

  const handleLanguageChange = (nextLanguage: SupportedLanguages) => () => {
    setSelectedLanguage(nextLanguage);
  };

  const isNewLocaleSelectionSameAsCurrent = useCallback(() => {
    const inferredLocaleFromArgs = inferSupportedLocale(selectedLanguage, region);

    return inferredLocaleFromArgs === locale;
  }, [selectedLanguage, region, locale]);

  const handleSaveClick = useCallback(() => {
    if (isNewLocaleSelectionSameAsCurrent()) {
      onModalDismiss();
      return;
    }

    const redirectUrl = getRedirectUrl({
      pathname,
      selectedLanguage,
      shouldUseLanguageInUrl: shouldUseLanguageCodeInUrls,
    });

    updateUserAttributes({ language: selectedLanguage }, {}, sendUpdateUserAttributesEvent);
    setCurrentLanguage(selectedLanguage);
    redirect(redirectUrl.toString());
  }, [
    isNewLocaleSelectionSameAsCurrent,
    onModalDismiss,
    pathname,
    redirect,
    selectedLanguage,
    setCurrentLanguage,
    shouldUseLanguageCodeInUrls,
    updateUserAttributes,
    sendUpdateUserAttributesEvent,
  ]);

  return (
    <Modal
      data-testid="select-language-modal"
      onDismiss={onModalDismiss}
      eventData={{
        modalAppearanceEventMessage: 'Language and region selector',
      }}
    >
      <ContentContainer>
        <StyledModalHeading id="language-select-modal-heading">
          {formatMessage({ id: 'selectLanguage' })}
        </StyledModalHeading>
        <LanguageSelectorModalContents
          ariaRadioGroupLabeledBy="language-select-modal-heading"
          langKey={selectedLanguage}
          disclaimer={disclaimer}
          applyButtonText={formatMessage({ id: 'apply' })}
          onLanguageChange={handleLanguageChange}
          onSaveClick={handleSaveClick}
        />
      </ContentContainer>
    </Modal>
  );
};

export default LanguageSelectorModal;
