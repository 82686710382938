import styled, { css } from 'styled-components';

import Picture from 'components/picture';

const defaultTextAlign = css`
  text-align: center;
  ${Styles.desktop} {
    text-align: start;
  }
`;

export const BasicTileContainer = styled.div`
  border-radius: 0.75rem;
  background-color: ${Styles.color.grey.three};
  display: flex;
  padding-block-start: 0.5625rem;
  padding-block-end: 0.25rem;
  padding-inline: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  ${Styles.desktop} {
    flex-direction: row;
    justify-content: flex-start;
    padding: 1.5rem;
  }
`;

export const BasicTileTextContainer = styled.div`
  p {
    color: ${Styles.color.white};
    margin: 0;
    font-size: 0.75rem;
    ${defaultTextAlign};
    ${Styles.desktop} {
      font-size: 0.9375rem;
    }
  }
`;

export const BasicTileTitle = styled.h3`
  ${defaultTextAlign};
  color: ${Styles.color.white};
  font-family: ${Styles.fontFamily.brand};
  font-size: 0.875rem;
  line-height: 1;
  margin: 0;
  ${Styles.desktop} {
    font-size: 1.25rem;
    margin-block-start: 0;
    margin-block-end: 0.43rem;
    margin-inline: 0;
  }
`;

export const BasicTileImage = styled(Picture)`
  max-height: 2.5em;
  max-width: 2.5rem;
  margin-block-start: 0;
  margin-block-end: 0.31rem;
  margin-inline: 0;
  ${Styles.desktop} {
    margin-block: 0;
    margin-inline-start: 0;
    margin-inline-end: 1.5rem;
    max-height: 5em;
    max-width: 5rem;
  }
`;
