import React, { useRef } from 'react';

import { useOnClickOutside } from 'hooks/use-on-click-outside';

import { DrawerContainer } from './styled';

interface DrawerProps extends React.PropsWithChildren {
  isOpen: boolean;
  onDismiss: VoidFunction;
  buttonRef: React.RefObject<HTMLElement>;
}

export const Drawer: React.FC<DrawerProps> = ({ isOpen, onDismiss, buttonRef, children }) => {
  const sideNavContainerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(sideNavContainerRef, onDismiss, buttonRef);

  return (
    <DrawerContainer
      $isOpen={isOpen}
      aria-expanded={isOpen}
      data-testid="side-navigation-container"
      ref={sideNavContainerRef}
    >
      {children}
    </DrawerContainer>
  );
};
