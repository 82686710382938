import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { StoreIconWrap, UnstyledButtonNoSpacing } from '../styled';

interface DiagnosticCogIconViewProps {
  onClick: VoidFunction;
}

export const DiagnosticCogIconView: React.FC<DiagnosticCogIconViewProps> = ({ onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <StoreIconWrap>
      <UnstyledButtonNoSpacing
        onClick={onClick}
        aria-label={formatMessage({ id: 'openStoreDiagnosticModal' })}
      >
        <Icon icon="settings" color="icon-button-secondary" width="24px" aria-hidden />
      </UnstyledButtonNoSpacing>
    </StoreIconWrap>
  );
};
