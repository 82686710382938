import { createGlobalStyle } from 'styled-components';

import { PREVENT_FOCUS_CLASS } from './constants';

/**
 * Contains global styling to remove :focus outlines
 */
export const FocusToggleStyles = createGlobalStyle`
  body.${PREVENT_FOCUS_CLASS} *:focus {
    outline: none;
  }

  /* Hack for SelectionIndicator */
  body.${PREVENT_FOCUS_CLASS} *::after {
    outline: none !important;
  }
`;
