import { useCallback } from 'react';

import { ApolloQueryResult, useApolloClient } from '@apollo/client';

import { ServiceMode } from '@rbi-ctg/menu';
import {
  ILoyaltyUserOffersQuery,
  IPromotionInput,
  LoyaltyUserOffersDocument,
  PaymentMethod,
} from 'generated/graphql-gateway';
import { ICartEntryAdapter } from 'state/global-state/models/loyalty/offers/offers.types';
import { parseOffer } from 'state/global-state/models/loyalty/offers/offers.utils';
import logger from 'utils/logger';

import { LoyaltyAppliedOffer } from '../types';

import {
  IAppliedRewards,
  IIncentiveEvaluationResult,
  IncentiveEvaluationErrorCodes,
} from './types';
import { createFeedbackMaps } from './utils/helpers';

interface EvaluateLoyaltyOffersParams {
  appliedOffers: LoyaltyAppliedOffer[];
  appliedLoyaltyRewards?: IAppliedRewards | null;
  cartEntries?: ICartEntryAdapter[];
  loyaltyId: string;
  paymentMethod?: PaymentMethod | null;
  serviceMode?: ServiceMode | null;
  storeId?: string | null;
  subtotalAmount?: number;
  totalAmount?: number;
  options?: {
    incompleteEvaluation?: boolean;
    omitInvalids?: boolean;
  };
}

const OFFER_NOT_AVAILABLE_EVALUATION_RESULT: IIncentiveEvaluationResult = {
  code: IncentiveEvaluationErrorCodes.OFFER_NOT_AVAILABLE,
  currentValue: null,
  message: '',
  ruleId: '',
  targetValue: null,
};

export const useLoyaltyOffersEvaluation = () => {
  const client = useApolloClient();

  const evaluateLoyaltyOffers = useCallback(
    async ({
      appliedOffers,
      cartEntries,
      loyaltyId,
      paymentMethod,
      serviceMode,
      storeId,
      subtotalAmount,
      totalAmount,
      options = {},
    }: EvaluateLoyaltyOffersParams) => {
      const { omitInvalids = false, incompleteEvaluation = false } = options;
      let evaluationResult = null;

      // parsing applied offers array
      const { appliedOffersIds, appliedIncentives } = appliedOffers.reduce<{
        appliedOffersIds: string[];
        appliedIncentives: IPromotionInput[];
      }>(
        (acc, appliedOffer) => {
          const parsedOffer = parseOffer(appliedOffer);

          if (parsedOffer?.id) {
            acc.appliedOffersIds.push(parsedOffer.id);
            acc.appliedIncentives.push(parsedOffer);
          }

          return acc;
        },
        { appliedOffersIds: [], appliedIncentives: [] }
      );

      try {
        const { data }: ApolloQueryResult<ILoyaltyUserOffersQuery> = await client.query({
          query: LoyaltyUserOffersDocument,
          fetchPolicy: 'network-only',
          variables: {
            loyaltyId,
            where: {
              appliedIncentives,
              cartEntries,
              ids: appliedOffersIds,
              paymentMethod,
              serviceMode,
              storeId,
              subtotalAmount,
              totalAmount,
              omitInvalids,
              // This boolean forces a full evaluation of all applied offers rules
              isTransactionCreation: !incompleteEvaluation,
            },
          },
        });

        const offers = data?.loyaltyUser?.offers;
        if (offers) {
          const { offerFeedbackMap, offersMapById } = createFeedbackMaps(offers);

          // mapping no longer existing offers
          // if the offer didn't come back on the response then it doesn't exist
          appliedOffersIds.forEach(id => {
            if (id && !offersMapById[id]) {
              offerFeedbackMap[id] = [OFFER_NOT_AVAILABLE_EVALUATION_RESULT];
            }
          });

          evaluationResult = offerFeedbackMap;
        }
      } catch (e) {
        logger.error(`Offers evaluation error: ${e}`);

        throw e;
      }

      return evaluationResult;
    },
    [client]
  );

  return { evaluateLoyaltyOffers };
};
