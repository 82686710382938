import * as React from 'react';
import { FC } from 'react';

import { ISanityTextNode, ISanityTypographyBlock } from '@rbi-ctg/menu';
import { IMarketingTileGroupProps } from 'components/features/components/marketing-tile-group/types';

import {
  DisclaimerMessage,
  MarketingTileGroup,
  RewardsTitle,
} from './loyalty-marketing-tile-widget.styled';

/**
 *
 * LoyaltyMarketingTileGroupWidget shows a group of tiles in a given widget slot
 *
 */
const LoyaltyMarketingTileGroupWidget: FC<{
  disclaimer: { localeRaw: ISanityTypographyBlock[] } | null;
  marketingTileGroup: IMarketingTileGroupProps['item'];
  title?: ISanityTextNode;
}> = ({ marketingTileGroup, title, disclaimer }) => {
  return (
    <div data-testid="loyalty-marketing-tile-widget">
      {title && <RewardsTitle>{title?.locale}</RewardsTitle>}
      <MarketingTileGroup item={marketingTileGroup} />
      {disclaimer && <DisclaimerMessage content={disclaimer?.localeRaw} />}
    </div>
  );
};

export default LoyaltyMarketingTileGroupWidget;
