import React from 'react';

import {
  BottomDrawerBody,
  BottomDrawerHeader,
} from '@rbilabs/components-library/build/components/bottom-drawer/bottom-drawer.styled';
import { Box } from '@rbilabs/components-library/build/components/layout/Box';
import styled from 'styled-components';

import Modal, { modalSizeStyles } from 'components/modal';

interface UpsellDialogProps {
  title: string;
  open: boolean;
  onCloseModal: VoidFunction;
  children: NonNullable<React.ReactNode>;
  isMaxHeight?: boolean;
}

const StyledModal = styled(Modal)<{ isMaxHeight?: boolean }>`
  &[data-reach-dialog-content] {
    background-color: ${Styles.color.dialogBackground};
    border-radius: 1rem;
    overflow: hidden;
    height: ${props => (props.isMaxHeight ? 'calc(100vh - 3.675rem)' : 'fit-content')};
    max-height: ${modalSizeStyles.height.REGULAR};

    > button {
      background-color: transparent;
    }

    ${Styles.desktop} {
      min-width: 35vw;
      width: 35vw;
    }

    ${Styles.mobile} {
      max-height: calc(100vh - 3.675rem);
      inset: unset;
      inset-block-end: 0;

      > button {
        position: absolute;
        inset-block-start: 0.75rem;
        inset-inline-start: 0.5rem;
      }
    }
  }
`;

const StyledBottomDrawerHeader = styled(BottomDrawerHeader)`
  background-color: ${Styles.color.dialogBackground};
  border-block-end: none;
  padding-block-start: 1rem;
  ${Styles.mobile} {
    padding-block-start: 0.5rem;
  }
`;

export const UpsellDialog: React.FC<UpsellDialogProps> = ({
  open,
  title,
  children,
  onCloseModal,
  isMaxHeight = false,
}) => {
  return open ? (
    <StyledModal isMaxHeight={isMaxHeight} onDismiss={onCloseModal}>
      <Box width="100%" flexDirection="column">
        <StyledBottomDrawerHeader>{title}</StyledBottomDrawerHeader>
        <BottomDrawerBody>{children}</BottomDrawerBody>
      </Box>
    </StyledModal>
  ) : null;
};
