import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import { Action, Layout, Message } from './styled';

interface IOrderTrackingProps {
  message: string;
  onClick: () => void;
}

export const OrderTrackingBanner: FC<IOrderTrackingProps> = ({ message, onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <Layout data-testid="order-tracking-banner">
      <Message>{message}</Message>
      <Action onClick={onClick}>{formatMessage({ id: 'track' })}</Action>
    </Layout>
  );
};
