import { Dispatch, MutableRefObject, SetStateAction } from 'react';

import { Nullable } from '@rbi-ctg/frontend';
import { ICartEntry, IOffer, IPrices, PreOrderTimeSlot } from '@rbi-ctg/menu';
import { IStore, IStoreAddress } from '@rbi-ctg/store';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { MapProviders } from 'hooks/geolocation/enum';
import { IAddressPredictions } from 'pages/store-locator/context/types';
import { ServiceMode, ServiceModeStatus } from 'state/service-mode/types';

/**
 * The possible states of a restaurant based on:
 * 1. Availability (whether the restaurant and the restaurants service mode is configured correctly)
 * 2. The hours of the store
 */
export enum StorePermissions {
  OPEN_AND_AVAILABLE = 'openAndAvailable',
  OPEN_AND_UNAVAILABLE = 'openAndUnavailable',
  CLOSED = 'closed',
  NO_STORE_SELECTED = 'noStoreSelected',
}

// Type of store for Store Selection 1.0
export type SelectedStore = IStore & { hasSelection: true };
export type SelectedUnavailableStore = {
  hasSelection: false;
  physicalAddress: IStoreAddress;
  selectedUnavailableStoreName: string;
  chaseMerchantId: '';
  // Allows us to show/query data for a store that is unavailable
  selectedUnavailableStoreNumber: null | string;
};

// Type of store for Store Selection 1.0
// Store Selection 2.0 uses the IStore type
export type StoreState = SelectedStore | SelectedUnavailableStore;

// Type of store for Store Selection 2.0
export type Store = IStore | null;

export type StoreProxy = { [K in keyof IStore]: IStore[K] | null };

export interface IRestaurantLogAttributes {
  restaurantId: string;
  restaurantAddress: string;
  restaurantZip: string;
  restaurantCity: string;
  restaurantState: string;
  restaurantCountry: string;
  storeId?: string;
  restaurantName?: string | undefined;
  restaurantNumber?: String | undefined;
  restaurantPOS?: String | undefined;
}

export interface ISelectStoreOptions {
  sanityStore: IStore;
  hasCartItems: boolean;
  callback: VoidFunction;
  requestedServiceMode?: ServiceMode;
  selectedOffer?: IOffer;
  unavailableCartEntries?: ICartEntry[];
}

interface IStoreStatusFlags {
  isStoreOpenAndAvailable: boolean;
  isStoreOpenAndUnavailable: boolean;
  isStoreClosed: boolean;
  noStoreSelected: boolean;
}

export interface IStoreContext extends IStoreStatusFlags {
  email: string;
  isPricesLoading: boolean;

  isRestaurantAvailable(restaurant: StoreProxy | IRestaurantNode): Promise<boolean>;

  openOrderingUnavailableDialog(): void;

  prices: IPrices;

  resetStore(): void;

  selectStore(options: ISelectStoreOptions): Promise<void>;

  fetchStore: (storeId: string) => Promise<void>;
  setStore: Dispatch<SetStateAction<StoreState>>;

  selectUnavailableStore(store: IStore & { hasSelection: false }): void;

  resetLastTimeStoreUpdated(currentTime?: number): void;

  serviceModeStatus: ServiceModeStatus;
  getStoreStatusFlags: (
    store: StoreState | Store,
    serviceMode?: Nullable<ServiceMode>
  ) => IStoreStatusFlags;
  store: StoreProxy;
  storeAddresses: IAddressPredictions[];
  setStoreAddresses: (storeAddresses: IAddressPredictions[]) => void;
  addressProvider: MapProviders;
  setAddressProvider: (addressProvider: MapProviders) => void;
  restaurantLogAttributesRef: MutableRefObject<IRestaurantLogAttributes>;

  preselectedPreOrderTimeSlot?: PreOrderTimeSlot | null;
  setPreselectedPreOrderTimeSlot(preselectedPreOrderTimeSlot: PreOrderTimeSlot | null): void;
}

export interface IPreloadedStoreState {
  prices: IPrices;
  store: IStore | null;
}

export type IUseStore = {
  onConfirmStoreChange({ newStore, callback }: { newStore: IStore; callback: VoidFunction }): void;
  storeProxy: StoreProxy;
  store: StoreState | Store;
  setStore: Dispatch<SetStateAction<StoreState | Store>>;
  updateUserStoreWithCallback: (store: Store, cb: () => Promise<void>) => void;
} & Pick<IStoreContext, 'resetStore' | 'selectUnavailableStore'>;

export type curriedGetStoreStatus = (args: {
  store: StoreState | Store;
  serviceModeStatuses: ServiceModeStatus;
  selectedServiceMode?: Nullable<ServiceMode>;
  preselectedPreOrderTimeSlot?: PreOrderTimeSlot | null;
}) => (serviceMode?: Nullable<ServiceMode>) => StorePermissions;

export interface IUseRefreshStoreArgs {
  getRestaurantPosData(): void;

  store: StoreProxy;
}
