import { SupportedLanguages } from '@rbi-ctg/frontend';
import * as location from 'utils/location';
import { pathname as getPathname } from 'utils/routing';

interface IGetRedirectUrlParams {
  pathname: string;
  selectedLanguage: SupportedLanguages;
  shouldUseLanguageInUrl: boolean;
}

// Get redirect URL for language change redirect.
// First we get the base redirect pathname without any language. That results in
// either a new pathname for a static page or remain unchanged in the other
// case.
// Then, for multilingual markets we enhance the pathname with the language
// information to return the full URL.
export const getRedirectUrl = ({
  pathname,
  shouldUseLanguageInUrl,
  selectedLanguage,
}: IGetRedirectUrlParams) => {
  const redirectPath = shouldUseLanguageInUrl ? getPathname(selectedLanguage)(pathname) : pathname;

  return new URL(`${location.get('origin')}${redirectPath}`);
};
