import type { ICart, ICartEntryOffer, ICartEntryReward } from './incentive-cart';
import {
  ICartEntryType,
  addToCart,
  filterCartEntries,
  findCartEntry,
  isCartEntryEqual,
  updateCartEntry,
} from './incentive-cart';
import { isCartEntryQuantityValid, isStackableOffer } from './utils';

export const addReward = (cart: ICart, newCartEntry: ICartEntryReward) => {
  let cartResult = cart;

  // try to find existing cart entry
  const existingCartEntry = findCartEntry(cart, newCartEntry);

  // if not update the quantity by 1
  if (existingCartEntry) {
    const newQuantity = existingCartEntry.quantity + 1;

    if (isCartEntryQuantityValid(newQuantity)) {
      cartResult = updateCartEntry(cart, existingCartEntry, { quantity: newQuantity });
    }
  } else {
    cartResult = addToCart(cart, newCartEntry);
  }

  return cartResult;
};

export const addOffer = (cart: ICart, newCartEntry: ICartEntryOffer) => {
  // for stackable offer try to remove it from the list otherwise,
  // remove all no stackable from the list
  const filterPredicate: Parameters<typeof filterCartEntries>[1] = isStackableOffer(newCartEntry)
    ? ce => !isCartEntryEqual(ce, newCartEntry)
    : ce => ce.type !== ICartEntryType.OFFER || isStackableOffer(ce);

  const filteredCart = filterCartEntries(cart, filterPredicate);

  return addToCart(filteredCart, newCartEntry);
};
