import { TimedFireGenerateOptions } from 'state/launchdarkly/variations';

export const pickupOptions: TimedFireGenerateOptions = {
  startNow: true,
  timeInterval: 15,
  length: 5,
};

export const deliveryOptions: TimedFireGenerateOptions = {
  startNow: true,
  timeInterval: 15,
  length: 96, // 24h
  offset: 60,
};
