import React, { useRef, useState } from 'react';

import { isFunction } from 'lodash';
import { createPortal } from 'react-dom';

import { DropdownContent } from './dropdown-content';

interface DropdownContentRendererOptions {
  dismiss: VoidFunction;
}

type DropdownContentRenderer = (params: DropdownContentRendererOptions) => React.ReactNode;

interface DropdownProps {
  id: string;
  children: React.ReactElement;
  content: React.ReactNode | DropdownContentRenderer;
}

export const Dropdown: React.FC<DropdownProps> = ({ id, content, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [insetInlineStart, setInsetInlineStart] = useState(0);
  const triggerRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(v => !v);
    if (triggerRef.current) {
      const node = triggerRef.current.getBoundingClientRect();
      setInsetInlineStart((node.right + node.left) / 2);
    }
  };

  const dismissDropdown = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div ref={triggerRef}>
        {React.cloneElement(children, {
          onClick: toggleDropdown,
        })}
      </div>
      {createPortal(
        <DropdownContent
          id={id}
          insetInlineStart={insetInlineStart}
          isOpen={isOpen}
          onDismiss={dismissDropdown}
          triggerRef={triggerRef}
        >
          {isFunction(content) ? content({ dismiss: dismissDropdown }) : content}
        </DropdownContent>,
        document.body
      )}
    </>
  );
};
