import { ApolloLink, Observable } from '@apollo/client';
import { GraphQLError } from 'graphql';

import logger from 'utils/logger';

export const REQUEST_CANCELLED_KEY = 'requestCancelled';

/**
 * A terminating Apollo Link used to return an error message when an
 * authenticated query is attempted to be sent without an authenticated user.
 * The request will not be sent, and a custom error message will be
 * returned instead.
 */
export const cancelRequestLink = (cancellationReason: string) =>
  new ApolloLink(operation => {
    const message = `Fetch query [${operation.operationName}] cancelled. ${cancellationReason}`;

    logger.warn({
      message,
      queryName: operation.operationName,
    });

    const error = new GraphQLError(
      message, // message
      undefined, // nodes
      undefined, // source
      undefined, // positions
      [operation.operationName], // path
      undefined, // original error
      { [REQUEST_CANCELLED_KEY]: true } // extensions
    );

    return new Observable(observer => {
      observer.next({ errors: [error], data: null });
      observer.complete();
    });
  });
