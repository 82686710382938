import { NavLink as RouterNavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

type LinkProps = {
  $noBorder?: boolean;
};

export const linkStyles = (props: LinkProps) => css`
  display: inline-block;
  color: ${Styles.color.black};
  font-size: 0.9375rem;
  text-decoration: none;
  text-transform: ${Styles.textTransform.headlines};
  position: relative;
  margin: 0 auto;

  &.selected::before,
  &::before {
    content: '';
    background-color: transparent;
    height: 2px;
    width: 100%;
    position: absolute;
    inset-block-end: 0;
  }

  &:hover,
  &.selected {
    color: ${Styles.color.tertiary};
    text-decoration: none;
  }

  &.selected::before {
    background-color: ${!props.$noBorder ? 'currentColor' : 'transparent'};
  }
`;

const Link = styled(RouterNavLink)<LinkProps>`
  ${linkStyles}
`;

export const LinkExternal = styled.a<LinkProps>`
  ${linkStyles}
`;

export default Link;
