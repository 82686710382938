import React from 'react';

import { PrimaryButton, TertiaryButton } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { toast } from 'utils/toast';

const ButtonContainer = styled.div`
  height: 9vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 1rem;

  position: fixed;
  inset-block-end: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  background-color: ${Styles.color.dialogBackground};

  ${Styles.desktop} {
    height: 7vh;
  }

  ${Styles.mobile} {
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

interface UpsellModalBottomButtonProps {
  hasAddedUpsellItem: boolean;
  onClose: VoidFunction;
}

export const UpsellModalBottomButton: React.FC<UpsellModalBottomButtonProps> = ({
  onClose,
  hasAddedUpsellItem,
}) => {
  const { formatMessage } = useIntl();

  const closeWithToastMessage = () => {
    toast.success(formatMessage({ id: 'upsellModalContinueToast' }));
    onClose();
  };

  return (
    <ButtonContainer>
      {hasAddedUpsellItem ? (
        <PrimaryButton
          fullWidth
          onClick={closeWithToastMessage}
          data-testid="upsell-modal-continue-button"
        >
          {formatMessage({ id: 'continue' })}
        </PrimaryButton>
      ) : (
        <TertiaryButton data-testid="upsell-modal-not-now-button" onClick={onClose}>
          {formatMessage({ id: 'upsellNotNow' })}
        </TertiaryButton>
      )}
    </ButtonContainer>
  );
};
