import { IFeatureDisclaimersQuery, useFeatureDisclaimersQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureDisclaimerValue {
  featureDisclaimerLoading: boolean;
  featureDisclaimers: IFeatureDisclaimersQuery['FeatureDisclaimers'];
}

export const useFeatureDisclaimers = (): IUseFeatureDisclaimerValue => {
  const { featureDisclaimersId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureDisclaimersQuery({
    variables: { featureDisclaimersId },
    skip: !featureDisclaimersId,
  });

  const featureDisclaimerLoading = featureIdsLoading || loading;
  const featureDisclaimers = data?.FeatureDisclaimers ?? null;

  return {
    featureDisclaimerLoading,
    featureDisclaimers,
  };
};
