import React, { FC, createContext, useCallback, useContext, useState } from 'react';

import { noop } from 'lodash';

import { IBaseProps, SetState } from '@rbi-ctg/frontend';
import LoadingIndicatorBar from 'components/loading-indicator-bar';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import useMediaQuery from 'hooks/use-media-query';
import { useLocationContext } from 'state/location';
import { routes } from 'utils/routing';

export interface ILoadingContext {
  loading: boolean;
  LoadingIndicator: FC<IBaseProps>;
  setLoading: SetState<boolean>;
}

export const LoadingContext = createContext<ILoadingContext>({
  loading: false,
  setLoading: noop,
  LoadingIndicator: LoadingIndicatorBar,
});

const useShouldShow = () => {
  const { location } = useLocationContext();
  const match = useMediaQuery('desktop');
  if (!match) {
    return true;
  }
  return location.pathname !== routes.base;
};

export const LoadingProvider = ({ children }: IBaseProps) => {
  const [loading, setLoading] = useState(false);
  const show = useShouldShow();

  const LoadingIndicator = useCallback(
    ({ className }: IBaseProps) => (
      <LoadingIndicatorBar show={show && loading} className={className} />
    ),
    [loading, show]
  );

  return (
    <LoadingContext.Provider value={{ loading, setLoading, LoadingIndicator }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => useContext(LoadingContext);

export const useLoadingIndicator = () => useLoadingContext().LoadingIndicator;

export const useLoadingState = () => {
  const { loading, setLoading } = useLoadingContext();
  useEffectOnUnmount(() => {
    setLoading(false);
  });
  return { loading, setLoading };
};
