import { IServerOrder } from '@rbi-ctg/menu';
import { isCatering as isCateringOrder, isDelivery as isDeliveryOrder } from 'utils/service-mode';

import { DeliveryStatus, OrderStatus } from './types';

interface IOrderPollSuccessfulOptions {
  order: IServerOrder;
  orderSuccessStatus: OrderStatus | OrderStatus[];
  cateringSuccessStatus?: OrderStatus | OrderStatus[];
  deliverySuccessStatus: DeliveryStatus | DeliveryStatus[];
}

interface IOrderPollFailureOptions {
  order: IServerOrder;
  cateringFailureStatus?: OrderStatus | OrderStatus[];
  orderFailureStatus: OrderStatus | OrderStatus[];
  deliveryFailureStatus: DeliveryStatus | DeliveryStatus[];
}

export function orderPollSuccessful({
  order,
  orderSuccessStatus,
  cateringSuccessStatus,
  deliverySuccessStatus,
}: IOrderPollSuccessfulOptions): boolean {
  if (!Array.isArray(orderSuccessStatus)) {
    orderSuccessStatus = [orderSuccessStatus];
  }

  if (!Array.isArray(deliverySuccessStatus)) {
    deliverySuccessStatus = [deliverySuccessStatus];
  }

  if (!Array.isArray(cateringSuccessStatus) && cateringSuccessStatus) {
    cateringSuccessStatus = [cateringSuccessStatus];
  }

  const orderSuccessful = orderSuccessStatus.includes(order.status);
  const isDelivery = isDeliveryOrder(order.cart.serviceMode);
  const isCatering = isCateringOrder(order.cart.serviceMode);

  if (isDelivery) {
    const deliverySuccessful =
      order.delivery && deliverySuccessStatus.includes(order.delivery.status);
    return Boolean(orderSuccessful && deliverySuccessful);
  } else if (isCatering) {
    const cateringSuccessful =
      cateringSuccessStatus && cateringSuccessStatus.includes(order.status);
    return Boolean(cateringSuccessful);
  } else {
    return orderSuccessful;
  }
}

export function orderPollFailure({
  order,
  orderFailureStatus,
  cateringFailureStatus,
  deliveryFailureStatus,
}: IOrderPollFailureOptions): boolean {
  if (!Array.isArray(orderFailureStatus)) {
    orderFailureStatus = [orderFailureStatus];
  }

  if (!Array.isArray(deliveryFailureStatus)) {
    deliveryFailureStatus = [deliveryFailureStatus];
  }

  const orderFailure = orderFailureStatus.includes(order.status);
  const isDelivery = isDeliveryOrder(order.cart.serviceMode);
  const isCatering = isCateringOrder(order.cart.serviceMode);

  if (isDelivery) {
    const deliveryFailure = order.delivery && deliveryFailureStatus.includes(order.delivery.status);
    return Boolean(orderFailure || deliveryFailure);
  } else if (isCatering) {
    const cateringFailure = cateringFailureStatus && cateringFailureStatus.includes(order.status);
    return Boolean(cateringFailure);
  } else {
    return orderFailure;
  }
}
