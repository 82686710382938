import React, { FC } from 'react';

import { useRedeemRewardState } from 'components/cart-item/redeem-reward/use-redeem-reward';
import { useFeatureEarningCalculationQuery } from 'generated/sanity-graphql';
import { selectors, useAppSelector } from 'state/global-state';
import { useLoyaltyRewards } from 'state/loyalty/hooks/use-loyalty-rewards';
import {
  MAX_IN_RESTAURANT_CART_QUANTITY,
  MIN_IN_RESTAURANT_CART_QUANTITY,
} from 'state/loyalty/in-restaurant-redemption';

import { StyledIncrementor } from './styled';
import { IInRestaurantIncrementorProps } from './types';

const RewardRedemptionIncrementor: FC<IInRestaurantIncrementorProps> = ({
  onChange,
  quantity,
  details,
}) => {
  const loyaltyUser = useAppSelector(selectors.loyalty.selectUser);
  const { isLimitRewardRedemptionPerOrderReached } = useLoyaltyRewards(loyaltyUser);
  const { incentiveNotInMenu, limitPerOrderMet, hasSufficientBalance, redeemRewardLoading } =
    useRedeemRewardState({
      reward: details.engineReward,
      quantity,
      cartId: details.engineReward.id,
    });

  const disableIncrement =
    incentiveNotInMenu ||
    limitPerOrderMet ||
    !hasSufficientBalance ||
    isLimitRewardRedemptionPerOrderReached;

  const { data: earningCalculationData } = useFeatureEarningCalculationQuery({
    variables: { id: 'earningCalculation' },
  });

  const limitRewardPerOrder =
    earningCalculationData?.EarningCalculation?.rewardsRedemptionConfig?.standardRewardsLimit;

  return (
    <StyledIncrementor
      aria-label={`${quantity}`}
      min={MIN_IN_RESTAURANT_CART_QUANTITY}
      max={limitRewardPerOrder || MAX_IN_RESTAURANT_CART_QUANTITY}
      value={quantity}
      onChange={onChange}
      disableIncrement={disableIncrement}
      disabled={redeemRewardLoading}
      name={details.engineReward.name}
    />
  );
};

export default RewardRedemptionIncrementor;
