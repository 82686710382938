import { combineReducers } from '@reduxjs/toolkit';

import { cartSlice, initialState as initialCartState } from './cart/cart.slice';

export const initialOrderingState = {
  cart: initialCartState,
};

export const orderingReducer = combineReducers({
  cart: cartSlice.reducer,
});
