import { DeliveryStatus } from 'generated/rbi-graphql';
import logger from 'utils/logger';
import { isDelivery } from 'utils/service-mode';

import { IOnOrderStatusChangeOptions } from './types';

type OrderAndStatuses = 'orderStatus' | 'orderDeliveryStatus' | 'serverOrder';
type OrderStatusesAndPrevious = OrderAndStatuses | 'prevOrderStatus' | 'prevOrderDeliveryStatus';

export const statusHasChanged = ({
  orderStatus,
  orderDeliveryStatus,
  prevOrderDeliveryStatus,
  prevOrderStatus,
  serverOrder,
}: Pick<IOnOrderStatusChangeOptions, OrderStatusesAndPrevious>) => {
  const isDeliveryOrder = isDelivery(serverOrder && serverOrder.cart.serviceMode);
  const posStatusHasChanged = orderStatus !== prevOrderStatus;
  const deliveryStatusHasChanged =
    (!!prevOrderDeliveryStatus && orderDeliveryStatus !== prevOrderDeliveryStatus) ||
    (prevOrderDeliveryStatus === null && DeliveryStatus.QUOTE_SUCCESSFUL === orderDeliveryStatus);

  return isDeliveryOrder ? posStatusHasChanged || deliveryStatusHasChanged : posStatusHasChanged;
};

export const handleOrderStatusChange = ({
  failure,
  logOrderLatency,
  onSuccess,
  orderStatus,
  orderDeliveryStatus,
  prevOrderStatus,
  prevOrderDeliveryStatus,
  serverOrder,
  success,
}: IOnOrderStatusChangeOptions) => {
  if (!serverOrder) {
    return;
  }

  const orderStatusHasChanged = statusHasChanged({
    orderStatus,
    orderDeliveryStatus,
    prevOrderStatus,
    prevOrderDeliveryStatus,
    serverOrder,
  });

  if ((!success && !failure) || !orderStatusHasChanged) {
    return;
  }

  try {
    logOrderLatency?.(serverOrder);
  } catch (error) {
    logger.error({ error, message: 'Error logging order latency metric' });
  }

  if (success) {
    onSuccess(serverOrder);
  }
};
