import { useGetRefillDrinkQrCodeLazyQuery } from 'generated/rbi-graphql';

type QRCodeInfo = {
  orderUpdateAt: string;
  storeNumber: string;
  posOrderId: string;
};

export const useRefillDrinkQRCode = () => {
  const [fetchRefillDrinkQRCodeInfo, { data, loading }] = useGetRefillDrinkQrCodeLazyQuery();

  /**
   * Function to get the refill drink QR code information. This will be read by the drink machine
   * @param {string} orderUpdateAt - UpdateAt from the order context. eg when the order finishes
   * @param {string} storeNumber - Store number used in the finished order
   * @param {string} posOrderId - This field is also known as the transactionId
   */
  const getRefillDrinkQRCodeInfo = ({ orderUpdateAt, storeNumber, posOrderId }: QRCodeInfo) => {
    fetchRefillDrinkQRCodeInfo({
      variables: {
        orderUpdateAt,
        storeNumber,
        transactionId: posOrderId,
      },
    });
  };

  return {
    getRefillDrinkQRCodeInfo,
    qrCodeInfo: data?.getRefillDrinkQRCode.qrCodeInfo,
    isLoading: loading,
  };
};
