import React from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import { ActionButtonVariants } from 'components/action-button';
import { BkPayButton } from 'components/bkpay-button';
import { useAuthContext } from 'state/auth';
import { useBluecodeContext } from 'state/bluecode';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { routes } from 'utils/routing';

import { LoyaltyPointsButton } from '../loyalty-points-button';
import { SignupButton } from '../signup-button';
import { LinkNoDecoration, SecondaryActionContainer } from '../styled';

import { MyCodeButton } from './mycode-button';

interface SecondaryActionProps {
  isHamburgerMenuEnabled: boolean;
}

export const SecondaryAction: React.FC<SecondaryActionProps> = ({ isHamburgerMenuEnabled }) => {
  const { formatMessage } = useIntl();

  const enableSignUp = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableAccountInFooter = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_IN_FOOTER);
  const enableLoyaltyPointsInHeader = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_POINTS_IN_HEADER);
  const enableLoyaltyQRCodeHeaderButton = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_QR_CODE_HEADER_BUTTON
  );
  const { isAuthenticated } = useAuthContext();
  const { isBluecodeEnabled } = useBluecodeContext();

  const signupButtonVariant = enableLoyaltyPointsInHeader
    ? ActionButtonVariants.OUTLINE
    : ActionButtonVariants.PRIMARY;

  if (!enableSignUp) {
    return null;
  }

  return (
    <SecondaryActionContainer>
      {isAuthenticated() ? (
        <>
          <LoyaltyPointsButton />
          {isBluecodeEnabled && <BkPayButton />}
          {enableLoyaltyQRCodeHeaderButton && <MyCodeButton />}
          {!enableAccountInFooter && !isHamburgerMenuEnabled && (
            <LinkNoDecoration data-testid="mobile-nav-account-link" to={routes.account}>
              <Icon icon="profile" color="primary" height="1.5rem" width="2rem" aria-hidden />
              <VisuallyHidden>{formatMessage({ id: 'account' })}</VisuallyHidden>
            </LinkNoDecoration>
          )}
        </>
      ) : (
        <SignupButton variant={signupButtonVariant} data-testid="mobile-nav-signup-link" />
      )}
    </SecondaryActionContainer>
  );
};
