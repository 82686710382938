import React from 'react';

import { FavIconHeartFavorite, FavIconHeartUnfavorite } from './styled';
import { IFavoriteIcon } from './types';

export const FavoriteIcon: React.FC<IFavoriteIcon> = ({ isFavorite }) => {
  return isFavorite ? (
    <FavIconHeartFavorite isFavorite={isFavorite} />
  ) : (
    <FavIconHeartUnfavorite isFavorite={isFavorite} />
  );
};
