export const DAYPART_FORMAT = 'HH:mm';
export const PARSE_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSSSSSS';
export const ALT_PARSE_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const LOCALE_TIME_PARSE_FORMAT = 'HH:mm:ss';
export const TWELVE_HOUR_TIME_PARSE_FORMAT = 'h:mm aaaa';
export const MIDNIGHT = '12:00 a.m.';

export enum OrderingStatus {
  ALPHA = 'alpha',
  BETA = 'beta',
  DEV = 'dev',
  LIVE = 'live',
  PROD = 'prod',
}
