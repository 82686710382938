import { useCallback, useMemo } from 'react';

import { IGetStaticPageQuery, useGetStaticPageLazyQuery } from 'generated/sanity-graphql';
import { useErrorContext } from 'state/errors';

export const useStaticPage = () => {
  const { setSanityDataRef } = useErrorContext();
  const [
    getPage,
    {
      data: normalPageData,
      error: normalPageError,
      loading: normalPageLoading,
      refetch: normalPageRefetch,
    },
  ] = useGetStaticPageLazyQuery();

  const retrieveStaticPageById = useCallback(
    (id: string): void => {
      getPage({ variables: { id } });
    },
    [getPage]
  );

  const currentPage = useMemo<IGetStaticPageQuery['StaticPage'] | undefined>(() => {
    const staticPage = normalPageData?.StaticPage;
    setSanityDataRef(normalPageData?.StaticPage);
    return staticPage;
  }, [normalPageData, setSanityDataRef]);

  return {
    currentPage,
    error: normalPageError,
    loading: normalPageLoading,
    refetch: normalPageRefetch,
    retrieveStaticPageById,
  };
};
