import { useEffect, useState } from 'react';

import { isPast, isValid } from 'date-fns';
import { useIntl } from 'react-intl';

import { IDeliveryDropoff } from '@rbi-ctg/menu';
import { useLocale } from 'state/intl';
import { DeliveryStatus } from 'state/order/types';
import { POSVendor } from 'state/store/hooks/enums';
import { TFormatDistanceToNowFn } from 'utils/i18n/distance';

import useInterval from '../use-interval';

const ONE_MINUTE_MS = 60000;

const calcEtaRange = (
  text: Record<'delivered' | 'calculatingEta', string>,
  status: DeliveryStatus,
  formatDistanceToNow: TFormatDistanceToNowFn,
  dropoff?: IDeliveryDropoff,
  vendor?: POSVendor
) => {
  if (status === DeliveryStatus.ORDER_DROPPED_OFF) {
    return text.delivered;
  }

  if (!dropoff) {
    return text.calculatingEta;
  }

  const deliveryDateTime =
    vendor == POSVendor.PARTNER
      ? getDeliveryDateTime(dropoff)
      : getBringgDeliveryDateTime(dropoff, status);

  if (!isValid(deliveryDateTime)) {
    return text.calculatingEta;
  }

  if (isPast(deliveryDateTime)) {
    return text.calculatingEta;
  }

  return formatDistanceToNow(deliveryDateTime);
};

const getBringgDeliveryDateTime = (dropoff: IDeliveryDropoff, status: DeliveryStatus): Date => {
  const { arrivalTime, beforeTime } = dropoff;

  // Bringg uses arrivalTime as ETA and beforeTime as a pessimistic ETA
  // We will use the latest of the 2 ETAs since the beforeTime is used on order start
  // but the arrivalTime is updated later on if a driver is unassigned, etc
  // Once the drive is on their way Bringg updates arrivalTime to be accurate based on driving time
  // so we will use that
  const arrivalDateTime = new Date(arrivalTime);
  const beforeDateTime = new Date(beforeTime);

  const pessimisticEta = arrivalDateTime > beforeDateTime ? arrivalDateTime : beforeDateTime;
  const deliveryDateTime =
    status === DeliveryStatus.ORDER_PICKED_UP ? arrivalDateTime : pessimisticEta;
  return deliveryDateTime;
};

const getDeliveryDateTime = (dropoff: IDeliveryDropoff): Date => {
  const { arrivalTime, afterTime } = dropoff;
  return new Date(arrivalTime ?? afterTime);
};

export const useEtaCounter = (
  status: DeliveryStatus,
  dropoff?: IDeliveryDropoff,
  vendor?: POSVendor
) => {
  const { formatDistanceToNow } = useLocale();
  const { formatMessage } = useIntl();
  const text = {
    delivered: formatMessage({ id: 'delivered' }),
    calculatingEta: formatMessage({ id: 'calculatingEta' }),
  };

  const [etaString, setEtaString] = useState<string>(
    calcEtaRange(text, status, formatDistanceToNow, dropoff, vendor)
  );

  // Update string when props change: poll of new data
  useEffect(() => {
    setEtaString(calcEtaRange(text, status, formatDistanceToNow, dropoff, vendor));
  }, [status, dropoff, text, vendor, formatDistanceToNow]);

  // Update string in 1 minute intervals to refresh counter
  useInterval(() => {
    const newEtaString = calcEtaRange(text, status, formatDistanceToNow, dropoff, vendor);
    setEtaString(newEtaString);
  }, ONE_MINUTE_MS);

  return etaString;
};
