import { IFeatureMembershipsQuery, useFeatureMembershipsQuery } from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureMemberships {
  featureMemberships: IFeatureMembershipsQuery['LiveMemberships'] | null;
  enableMemberships: boolean;
  featureMembershipsLoading: boolean;
}

const useFeatureMemberships = (): IUseFeatureMemberships => {
  const { featureMembershipsId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureMembershipsQuery({
    variables: { featureMembershipsId },
    skip: !featureMembershipsId,
  });

  const featureMemberships = data?.LiveMemberships ?? null;
  const enableMemberships = Boolean(featureMemberships?.liveMemberships?.length);

  return {
    featureMemberships,
    enableMemberships,
    featureMembershipsLoading: loading || featureIdsLoading,
  };
};

export default useFeatureMemberships;
