import { INITIAL_REDEEMABLE_ITEM_SET } from '../constants/redeemable-item-set';

import { UseRewardDiscount } from './types';

const useRewardDiscount = (() => {
  return {
    cartHasRewardEligibleItem: false,
    redeemableItemsSet: INITIAL_REDEEMABLE_ITEM_SET,
  };
}) as UseRewardDiscount;

export default useRewardDiscount;
