import styled from 'styled-components';

import { ModalContent, ModalHeading } from 'components/modal';

import { modalHeadingStyles } from './constants';

export const ContentContainer = styled(ModalContent)`
  padding-block-end: 2rem;
  & div.modal-inner {
    margin: 0;
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    grid-auto-rows: auto;
    text-align: justify;
  }
`;

export const StyledModalHeading = styled(ModalHeading)`
  justify-self: center;

  ${modalHeadingStyles}

  ${Styles.desktop} {
    max-width: none;
  }
`;
