import styled from 'styled-components';

import { View } from 'components/view';

// Styles from `react-native-web`
// https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/ScrollView/index.js#L726-L772
export const ScrollView = styled(View)`
  flex-grow: 1;
  flex-shrink: 1;
  /* Enable hardware compositing in modern browsers.
  Creates a new layer with its own backing surface that can significantly
  improve scroll performance. */
  transform: translateZ(0);
  /* iOS native scrolling */
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  overflow-x: hidden;
  overflow-x: auto;
`;
