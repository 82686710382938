import {
  ICombo,
  IItem,
  IItemOption,
  IPicker,
  IPickerAspect,
  IPickerOption,
  ISection,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';

import {
  IMinMaxCalories,
  formatCalorieRange,
  getCaloriesRange,
  updateMinMax,
  zeroCals,
} from './calories';
import { isItem } from './is-menu-type';

export type PickerAspectCalorieMap = {
  [identifier: string]: PickerAspectCalorieMap | IMinMaxCalories;
};

// helper functions for buildCalorieMapForPickerPossibilities
/**
 * @todo remove displayNutritionWithModifiersFromSanity
 * once MDM nutrition sync is fixed
 */

// in each step, we reduce the number of options to
// those that can still be selected given the
// "parent" identifiers
const filterRemainingOptionsForIdentifier = (identifier: string, options: IPickerOption[]) => {
  return options.filter(({ pickerItemMappings }) => {
    return (
      pickerItemMappings.findIndex(
        ({ pickerAspectValueIdentifier }) => identifier === pickerAspectValueIdentifier
      ) !== -1
    );
  });
};

const getCaloriesRangeForOption = (
  option: ICombo | IItem | IPicker | ISection,
  displayNutritionWithModifiersFromSanity?: boolean
) => {
  // items should only display the default calories rather than a range
  if (isItem(option)) {
    const nutritionWithModifiers = option.nutritionWithModifiers?.calories ?? null;
    const baseCalories = option.nutrition?.calories ?? 0;

    const shouldReturnNutritionWithModifiers =
      displayNutritionWithModifiersFromSanity && nutritionWithModifiers !== null;

    const defaultCalories = shouldReturnNutritionWithModifiers
      ? nutritionWithModifiers
      : calculateCaloriesForItem(baseCalories, option.options ?? []);

    return { max: defaultCalories, min: defaultCalories, length: 1 };
  }

  return getCaloriesRange(option);
};

const calculateCaloriesForItem = (baseCalories: number, itemOptions: IItemOption[]) =>
  baseCalories +
    itemOptions.reduce(
      (sum, next) => sum + (next.options.find(o => o.default)?.nutrition?.calories ?? 0),
      0
    ) || 0;

const reducePickerAspectsToMapping = (
  aspects: IPickerAspect[],
  options: IPickerOption[],
  displayNutritionWithModifiersFromSanity = false,
  aspectIndex = 0
): PickerAspectCalorieMap | IMinMaxCalories => {
  if (!aspects[aspectIndex]) {
    return (options || []).reduce(
      (acc, nextOption) => {
        const nextOptionMinMax = getCaloriesRangeForOption(
          nextOption.option,
          displayNutritionWithModifiersFromSanity
        );

        return {
          length: (nextOptionMinMax.length || 0) + acc.length,
          ...updateMinMax(acc, nextOptionMinMax),
        };
      },
      {
        length: 0,
        max: 0,
        min: 0,
      }
    );
  }

  // reduce the current picker aspect's picker aspect options
  // to a mapping of identifier to all possible subsequent identifiers
  return (aspects[aspectIndex].pickerAspectOptions || []).reduce((childMap, pickerAspectOption) => {
    const { identifier } = pickerAspectOption;

    const remainingOptions = filterRemainingOptionsForIdentifier(identifier, options);

    if (!remainingOptions.length) {
      return childMap;
    }

    return {
      ...childMap,
      [identifier]: reducePickerAspectsToMapping(
        aspects,
        remainingOptions,
        displayNutritionWithModifiersFromSanity,
        aspectIndex + 1
      ),
    };
  }, {});
};

/**
 * creates a tree-like structure that can be traversed by picker aspect identifier
 * to compute a picker aspect identifier's possible calorie ranges
 * example:
 * given picker - pickeraspects [size - [small, large], bacon - [bacon, nobacon]]
 * returns {
 *   small: {
 *     bacon: calorieRange,
 *     nobacon: calorieRange
 *   },
 *   large: {
 *     bacon: calorieRange,
 *     nobacon: calorieRange
 *   }
 * }
 *
 * @param {IPicker | null} picker the picker to build the map for
 *
 * @returns {PickerAspectCalorieMap | null} a mapping of picker aspect
 *   option values to possible calorie ranges, or null if the mapping
 *   could not be computed
 */
export function buildCalorieMapForPickerPossibilities(
  picker: IPicker | null,
  displayNutritionWithModifiersFromSanity = false
): PickerAspectCalorieMap | null {
  // defend from bad data
  if (
    !picker ||
    picker._type !== MenuObjectTypes.PICKER ||
    !picker.pickerAspects ||
    !picker.pickerAspects.length ||
    !picker.options ||
    !picker.options.length
  ) {
    return null;
  }

  const { pickerAspects, options } = picker;

  /**
   * create a mapping of each picker aspect identifier
   * to a tree representing the choices or calories "possible"
   * for a subsequent picker aspect selection
   * Note: Picker Aspects are ordered. On Series UI
   * the user will make selections determined by the
   * ordering of the array, meaning from each identifier
   * there are only combinations possible with the aspects
   * coming later in the pickerAspects array
   */
  return reducePickerAspectsToMapping(
    pickerAspects,
    options,
    displayNutritionWithModifiersFromSanity
  ) as PickerAspectCalorieMap;
}

const isMinMax = (object: IMinMaxCalories | PickerAspectCalorieMap): object is IMinMaxCalories =>
  'min' in object &&
  'max' in object &&
  typeof object.min === 'number' &&
  typeof object.max === 'number';

const isCalorieMap = (
  object: IMinMaxCalories | PickerAspectCalorieMap
): object is PickerAspectCalorieMap => !isMinMax(object);

function reduceCalorieMapToMinMax(
  acc: IMinMaxCalories,
  minMaxOrCalorieMap: IMinMaxCalories | PickerAspectCalorieMap
): IMinMaxCalories {
  if (isMinMax(minMaxOrCalorieMap)) {
    return {
      length: (acc.length || 0) + (minMaxOrCalorieMap.length || 0),
      ...updateMinMax(acc, minMaxOrCalorieMap),
    };
  }

  if (isCalorieMap(minMaxOrCalorieMap)) {
    const reducedMinMaxForCalorieMap = Object.values(minMaxOrCalorieMap).reduce(
      reduceCalorieMapToMinMax,
      {
        max: 0,
        min: 0,
      }
    ) as IMinMaxCalories;
    return {
      length: (acc.length || 0) + (reducedMinMaxForCalorieMap.length || 0),
      ...updateMinMax(acc, reducedMinMaxForCalorieMap),
    };
  }

  return zeroCals;
}

// reduces a calorie map to a min/max calorie range
export function minMaxCaloriesForPickerAspectCalorieMap(
  calorieMap: PickerAspectCalorieMap | IMinMaxCalories
): IMinMaxCalories {
  if (isMinMax(calorieMap)) {
    return calorieMap;
  }

  return Object.values(calorieMap).reduce(reduceCalorieMapToMinMax, {
    max: 0,
    min: 0,
  }) as IMinMaxCalories;
}

// reduces a calorie map for a given picker aspect to a map of
// { [identifier]: calorieRange }
export function reducePickerCalorieMapToRanges(
  calorieMapForCurrentPickerStep: PickerAspectCalorieMap | null
): { [identifier: string]: IMinMaxCalories } | null {
  return calorieMapForCurrentPickerStep
    ? Object.entries(calorieMapForCurrentPickerStep).reduce(
        (acc, [identifier, childCalorieMapOrMinMax]) => ({
          ...acc,
          [identifier]: minMaxCaloriesForPickerAspectCalorieMap(childCalorieMapOrMinMax),
        }),
        {}
      )
    : null;
}

// given a mapping of picker aspect identifiers to
// a min/max calories object, returns a mapping of
// identifiers to formatted calorie range strings
export function formattedCalorieMapForPickerAspectCalorieMap(
  calorieMap: { [identifier: string]: IMinMaxCalories } | null,
  formatFn: (calories: number) => string
): { [identifier: string]: string } | undefined {
  if (!calorieMap) {
    return;
  }

  return Object.entries(calorieMap).reduce((acc, [identifier, range]) => {
    if (!range || !isMinMax(range)) {
      return '';
    }

    return {
      ...acc,
      [identifier]: formatCalorieRange({
        min: range.min,
        max: range.max,
        length: range.max === range.min ? 1 : Infinity,
        formatFn,
      }),
    };
  }, {});
}
