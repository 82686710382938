import React from 'react';

import { useIntl } from 'react-intl';

import { FavoriteIcon } from 'components/favorite-icon';
import { ButtonForIconFav } from 'components/favorite-icon/styled';
import Animation from 'components/lottie/favorite/loading';

import { UnstyledButtonNoSpacing, Wrapper } from '../styled';

interface FavoriteStoreIconProps {
  favStoreLoading: boolean;
  isFavorite: boolean;
  toggleFavorite: () => Promise<void>;
}

export const FavoriteStoreIconView: React.FC<FavoriteStoreIconProps> = ({
  favStoreLoading,
  isFavorite,
  toggleFavorite,
}) => {
  const { formatMessage } = useIntl();

  // Use the current state of the Fav Icon as fallback when we're lazy loading the lottie animation
  const FavIcon = (
    <UnstyledButtonNoSpacing>
      <ButtonForIconFav
        data-testid={isFavorite ? 'fav-icon-selected' : 'fav-icon'}
        onClick={toggleFavorite}
        aria-label={formatMessage({
          id: isFavorite ? 'removeRestaurantFromFavorites' : 'setRestaurantAsFavorite',
        })}
        isTransparent={true}
      >
        <FavoriteIcon isFavorite={isFavorite} />
      </ButtonForIconFav>
    </UnstyledButtonNoSpacing>
  );

  return (
    <Wrapper>
      {favStoreLoading ? (
        <React.Suspense data-testid="fav_store_loading_animation" fallback={FavIcon}>
          <Animation />
        </React.Suspense>
      ) : (
        FavIcon
      )}
    </Wrapper>
  );
};
