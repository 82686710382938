import styled from 'styled-components';

import { ModalContent } from 'components/modal';

export const ContentContainer = styled(ModalContent)`
  font-size: 1rem;

  & div.modal-inner {
    max-width: none;
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 0 1.5rem;
    ${Styles.desktop} {
      padding-block-start: 3rem;
      padding-block-end: 2rem;
      padding-inline: 15%;
    }
  }

  & div.modal-scroller {
    padding-block-start: 3rem;
    ${Styles.desktop} {
      padding: 0;
    }
  }
`;
