import styled from 'styled-components';

const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 6px;
  font-size: 1.5rem;
  color: ${Styles.color.primary};
  display: flex;
  font-family: ${Styles.fontFamily.base};
  &[disabled] {
    svg {
      pointer-events: none;
    }
  }
`;

export default Button;
