import React, { SVGAttributes } from 'react';

export const IconRating = (props: SVGAttributes<SVGElement>) => (
  <svg fill="none" width="27" height="26" viewBox="0 0 27 26">
    <path
      d="M13.5 18.3232L8.424 21.4295L9.7605 15.5726L5.2785 11.6316L11.1915 11.1253L13.5 5.59684L15.8085 11.1253L21.7215 11.6316L17.2395 15.5726L18.576 21.4295M27 9.90737L17.2935 9.07263L13.5 0L9.7065 9.07263L0 9.90737L7.3575 16.38L5.157 26L13.5 20.8958L21.843 26L19.629 16.38L27 9.90737Z"
      {...props}
    />
  </svg>
);

export const IconRatingFilled = (props: SVGAttributes<SVGElement>) => (
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none">
    <path
      d="M13.5 20.6145L21.843 25.65L19.629 16.1595L27 9.774L17.2935 8.937L13.5 0L9.7065 8.937L0 9.774L7.3575 16.1595L5.157 25.65L13.5 20.6145Z"
      {...props}
    />
  </svg>
);
