import styled, { css } from 'styled-components';

import { brandFont } from 'components/layout/brand-font';
import Modal from 'components/modal';
import { primitive } from 'styles/constants/primitives';

const bgColor = css`
  background-color: ${p => p.theme.token('background-default')};
`;

export const StyledModal = styled(Modal)`
  ${Styles.desktop} {
    &[data-reach-dialog-content] {
      width: 90%;
      min-height: 95%;
      max-width: 650px;
    }
  }
`;

export const Container = styled.div`
  width: 90%;
  max-width: 390px;
  margin: 0 auto;
`;

export const ModalHead = styled.div`
  position: fixed;
  width: 100%;
  height: 5.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: ${Styles.zIndex.top};
  border-block-end: 1px solid ${p => p.theme.token('border-color-default')};
  ${bgColor}

  ${Styles.desktop} {
    flex-direction: row;
  }

  h2 {
    margin: 0;
  }
`;

export const ModalInner = styled.div`
  height: 100%;
  width: 100%;
  ${bgColor}

  h2 {
    text-align: center;
    margin: ${primitive.$spacing7} 0;
    text-transform: ${Styles.textTransform.headlines};
    font: ${brandFont.headerOne};
  }
`;

export const Block = styled.div`
  padding-block-start: 0;

  padding-block-end: ${primitive.$spacing4};

  padding-inline: 0;

  &:nth-of-type(n + 2) {
    border-block-start: 1px solid ${p => p.theme.token('border-color-default')};
    padding: ${primitive.$spacing4} 0;
  }
  &:last-of-type {
    margin-block-end: ${primitive.$spacing6};
  }
`;

export const OrderDetailsScrollContainer = styled.div`
  position: relative;
  height: calc(100% - 164px);
  padding-block-start: 7.75rem;
  padding-block-end: 2.25rem;
  overflow-y: auto;
`;

export const Footer = styled.div`
  position: absolute;
  width: 100%;
  inset-block-end: 0;
  border-block-start: 1px solid ${p => p.theme.token('border-color-default')};
  padding: ${primitive.$spacing4} 0;
  ${bgColor}

  p {
    color: ${p => p.theme.token('text-default')};
  }
`;

export const LoadingContainer = styled.div`
  inset-inline-start: 0;
  inset-block-start: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

/*
 * this duplicates the amount of spacing around an ActionLink
 * so that the loading indicator can transition to an ActionLink
 * without elements around it shifting on the page
 */
export const NeedHelpButtonLoadingIndicatorWrapper = styled.div`
  padding: ${primitive.$spacing3};
  border: '1px solid transparent';
`;

// TH exports a different style for this component
export const StyledCartEntries = Block;
