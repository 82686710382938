import { isString } from 'lodash';

import * as location from 'utils/location';

import { pathname } from './path';
import { relativeRoutes, routes } from './routes';

export const isHome = (path: string) => path === routes.base;

export const isLocalRoute = (path: string) =>
  isHome(path) || Object.values(routes).some(route => !isHome(route) && path.startsWith(route));

export const isSectionRoute = (path: string) => path.startsWith(`${routes.menu}/section-`);

export const isRecentItemsRoute = (path: string) =>
  path.startsWith(`${routes.menu}/${relativeRoutes.recentItems}`);

export const isFavoritesRoute = (path: string) => path.startsWith(routes.favorites);

export const isItemPickerOrComboRoute = (path: string) =>
  new RegExp(`^${routes.menu}/(item|picker|combo)-`).test(path);

export const getPathMatcher =
  (basename: string = '') =>
  (path: string | string[]) => {
    if (!isString(path) && !Array.isArray(path)) {
      return false;
    }
    const currentPathname = location.get('pathname');
    const getFullPath = pathname(basename);
    const fullPaths = ([] as string[]).concat(path).map(val => new RegExp(`^${getFullPath(val)}$`));

    return fullPaths.some(regex => regex.test(currentPathname));
  };
