import React, { FC, useCallback } from 'react';

import { useIntl } from 'react-intl';

import { IncrementorActions } from 'components/incrementor';
import { ICartEntryDetailsReward, ICartEntryType } from 'state/loyalty/in-restaurant-redemption';
import { OfferType, isDiscountReward } from 'state/loyalty/types';

import { RewardItemDescription } from '../map-components';

import OfferRedemptionIncrementor from './offer-redemption-incrementor';
import RewardRedemptionIncrementor from './reward-redemption-incrementor';
import { Badge, BadgeCrownPoints, BottomContent } from './styled';
import { BadgeAndIncrementorProps } from './types';

const BadgeAndIncrementor: FC<BadgeAndIncrementorProps> = ({ cartEntry, handleUpdateEntry }) => {
  const { formatMessage } = useIntl();
  const isDetailsReward = cartEntry.type === ICartEntryType.REWARD;
  const isOfferDiscount =
    cartEntry.type === ICartEntryType.REWARD &&
    isDiscountReward((cartEntry.details as ICartEntryDetailsReward).reward);
  const isSystemwideOffer =
    cartEntry.type === ICartEntryType.OFFER &&
    cartEntry.details.offer._type === OfferType.SYSTEMWIDE;

  const backgroundColor = Styles.color.black;

  const handleChangeQuantity = useCallback(
    (value: number, incrementorActions: IncrementorActions) => {
      handleUpdateEntry(cartEntry, { quantity: value }, incrementorActions);
    },
    [handleUpdateEntry, cartEntry]
  );
  return (
    <BottomContent>
      <Badge data-testid="badge" $backgroundColor={backgroundColor}>
        {isOfferDiscount || isDetailsReward ? (
          <BadgeCrownPoints>
            <RewardItemDescription
              entryCartDetails={cartEntry.details as ICartEntryDetailsReward}
            />
          </BadgeCrownPoints>
        ) : (
          formatMessage({ id: 'offer' })
        )}
      </Badge>

      {!isOfferDiscount && cartEntry.type === ICartEntryType.REWARD && (
        <RewardRedemptionIncrementor
          quantity={cartEntry.quantity}
          details={cartEntry.details}
          onChange={handleChangeQuantity}
        />
      )}

      {isSystemwideOffer && (
        <OfferRedemptionIncrementor quantity={cartEntry.quantity} onChange={handleChangeQuantity} />
      )}
    </BottomContent>
  );
};

export default BadgeAndIncrementor;
