import * as React from 'react';
import { FC } from 'react';

import { useIntl } from 'react-intl';

import {
  RewardItemDescriptionDetail,
  RewardItemDescriptionWrapper,
  StyledDescriptionSanityBlockRenderer,
  StyledLoyaltyPointsIcon,
} from './styled';
import { IItemDescriptionProps } from './types';
import { getDescriptionDetailText } from './utils';

export const OfferItemDescription: FC<IItemDescriptionProps<'offer'>> = ({
  entryCartDetails: { offer },
}) => <StyledDescriptionSanityBlockRenderer content={offer?.description?.localeRaw} />;

export const RewardItemDescription: FC<IItemDescriptionProps<'reward'>> = ({
  entryCartDetails: { engineReward },
}) => {
  const { formatMessage } = useIntl();

  const detailText = getDescriptionDetailText({
    formatMessage,
    points: engineReward.pointCost,
  });

  return (
    <RewardItemDescriptionWrapper>
      <StyledLoyaltyPointsIcon />
      <RewardItemDescriptionDetail>{detailText} </RewardItemDescriptionDetail>
    </RewardItemDescriptionWrapper>
  );
};
