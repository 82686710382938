import {
  ISanityCombo,
  ISanityItem,
  ISanityPicker,
  ISanitySection,
  SanityMenuObject,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';

/**
 * Our Sanity GraphQL schema is automatically generated.
 * Due to existing constraints of their public API,
 * the field '_type' is a non-nullable field in GraphQL
 * on Documents, but on objects it is nullable. GraphQL
 * requires that a union type where the types share a field
 * name have the same type, i.e. _type must always be either
 * String! or String. Because of this we have to alias
 * _type to type on object types in some generated queries,
 * due to the pseudo-recursive nature of the menu object schema.
 *
 * This function serves as a transformation layer to change
 * all keys to _type, for API consistency.
 *
 * Note: some casting to any is required in the helper functions,
 * because we do not want to add 'type' to our interfaces, or have
 * to handle both _type and type in code that maps over menu data
 * recursively.
 */
export function fixTypes<I extends SanityMenuObject>(item: I): I;
export function fixTypes(item: SanityMenuObject): SanityMenuObject {
  switch (item?._type) {
    case MenuObjectTypes.ITEM: {
      return fixItemTypes(item);
    }
    case MenuObjectTypes.PICKER: {
      return fixPickerTypes(item);
    }
    case MenuObjectTypes.COMBO: {
      return fixComboTypes(item);
    }
    case MenuObjectTypes.SECTION: {
      const fixedItem: ISanitySection = {
        ...item,
        options: (item.options || []).map(fixTypes),
      };

      return fixedItem;
    }
    default:
      return item;
  }
}

function fixItemTypes<I extends ISanityItem | null>(item: I): I {
  if (!item) {
    return item;
  }

  (item.options || []).forEach(itemOption => {
    itemOption._type = MenuObjectTypes.ITEM_OPTION;
    delete (itemOption as any).type;

    (itemOption.options || []).forEach(itemOptionModifiers => {
      itemOptionModifiers._type = MenuObjectTypes.ITEM_OPTION_MODIFIER;
      delete (itemOptionModifiers as any).type;
    });
  });

  return item;
}

function fixPickerTypes(picker: ISanityPicker): ISanityPicker {
  (picker.options || []).forEach(pickerOption => {
    pickerOption._type = MenuObjectTypes.PICKER_OPTION;
    delete (pickerOption as any).type;
    fixTypes(pickerOption.option);
  });

  return picker;
}

function fixComboTypes(combo: ISanityCombo): ISanityCombo {
  fixItemTypes(combo.mainItem);

  (combo.options || []).forEach(comboSlot => {
    (comboSlot.options || []).forEach(comboSlotOption => {
      fixTypes(comboSlotOption.option);
    });
  });

  return combo;
}
