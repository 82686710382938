import { differenceInMinutes } from 'date-fns';

import { IUserOrderFragment, RbiOrderStatus } from 'generated/rbi-graphql';

import { MAX_MINUTES_ORDER_CHECK } from './constants';

interface IGetOrderInProgressOptions {
  dateNow: Date;
  order?: IUserOrderFragment | null;
  allowedStatuses: RbiOrderStatus[];
}

export const getOrderInProgress = ({
  dateNow,
  order,
  allowedStatuses,
}: IGetOrderInProgressOptions) => {
  if (!order?.status || !allowedStatuses.includes(order?.status)) {
    return null;
  }

  const createdAtDate = new Date(order.createdAt);

  // ignore order if it was created too long ago
  if (differenceInMinutes(dateNow, createdAtDate) > MAX_MINUTES_ORDER_CHECK) {
    return null;
  }

  return order;
};

export interface ITrackingBannerDetailsModalProps {
  setShowDetails: (showDetails: boolean) => void;
  rbiOrderId: string;
}
