import { ReactNode } from 'react';

import { IConfirmProps } from 'components/confirm-dialog';

import { ICurbsideConfirmationModalProps } from './curbside-confirmation-modal/curbside-confirmation-modal';

export interface ITopServiceModeProps {}

// @TODO: extend component and hook props ( they are pretty much the same )
export interface ITopServiceModeViewProps {
  heading: string;
  details: ReactNode;
  icon: ReactNode;
  button?: ReactNode;
  onTopServiceClick?: VoidFunction;
  variant?: TopServiceVariants;
  className?: string;
  isLoading?: boolean;
}

export interface ITopServiceModeDetail {
  heading: string;
  details: ReactNode;
  icon: ReactNode;
  button?: ReactNode;
  onTopServiceClick?: VoidFunction;
  variant?: TopServiceVariants;
  ConfirmationModal: React.FC<Pick<IConfirmProps, 'heading' | 'body'>>;
  CurbsideConfirmationModal: React.FC<ICurbsideConfirmationModalProps>;
  curbsideConfirmationModalOpen: boolean;
  setCurbsideConfirmationModalOpen: React.Dispatch<any>;
  refetchUserOrder: VoidFunction;
}

export enum TopServiceVariants {
  DEFAULT = 'DEFAULT',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_STORE = 'NO_STORE',
  IN_RESTAURANT_LOYALTY = 'IN_RESTAURANT_LOYALTY',
}
